import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useCallback } from "react";
import styled from "styled-components";
import { getCookie, handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "../product/ProductsPolaris";
import { useToastContext } from "../shared/ToastContext";
import { MapOrderFormPolaris } from "./MapOrderFormPolaris";

const LOAD_QUERY = gql`
  query productBasesForAssortment($includeDigital: Boolean) {
    fulfillments {
      id
      name
      slug
    }
    fulfillmentConfigs {
      id
      fulfillmentId
    }
    productBasesForAssortment(includeDigital: $includeDigital) {
      id
      title
      fulfillment {
        fulfillmentId
        productId
      }
      attributes {
        name
        slug
        options
      }
      variants {
        id
        attributes {
          name
          slug
          option
        }
        sorting
        fulfillmentProductId
        fulfillmentSku
      }
      isDigitalProduct
    }
  }
`;

const MUTATE = gql`
  mutation createProductOrderFromMapping($input: NewProductMapping!) {
    createProductOrderFromMapping(input: $input) {
      id
      title
      sku
      description
      designStatus
      personalized
      status
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          url
          thumbnailUrl
        }
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
    }
  }
`;

const Container = styled.div`
  margin-top: 1.6rem;
`;

export const DEFAULT_PRODUCT_LIST_FILTER = {
  limit: Number(getCookie("perPageProducts")) || 20,
  offset: 0,
  search: "",
  productBaseId: null,
  storeId: null,
  collections: null,
  tags: null,
  personalized: null,
};

export const MapOrderPolaris = ({ item, onCompleted, onDismiss }) => {
  const { data, loading } = useQuery(LOAD_QUERY, {
    variables: {
      includeDigital: true,
    },
  });

  // Context
  const { toggleToast, setNotify } = useToastContext();

  const timeoutRef = React.useRef(null);
  const [
    createProductOrderFromMapping,
    {
      data: dataMutation,
      loading: loadingMutation,
      error: errorMutation,
      client,
    },
  ] = useMutation(MUTATE, {
    onCompleted: () => {
      setNotify({
        msg: "Map Order to product base  success",
        err: false,
      });
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onCompleted && onCompleted();
      }, 1000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  //
  React.useEffect(() => {
    if (onDismiss) {
      onDismiss(loadingMutation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMutation]);

  const handleSubmit = useCallback(
    (input) => {
      toggleToast && toggleToast();
      setNotify && setNotify({ msg: null, err: false });
      createProductOrderFromMapping({
        variables: {
          input,
        },
      }).then((res) => {
        const product = res.createProductOrderFromMapping;
        try {
          const variables = {
            filter: DEFAULT_PRODUCT_LIST_FILTER,
          };
          const cache = client.readQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
          });
          client.writeQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
            data: {
              ...cache,
              products: {
                ...cache.products,
                total: cache.products.total + 1,
                nodes: [product, ...cache.products.nodes],
              },
            },
          });
        } catch (e) {}
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleToast, setNotify, createProductOrderFromMapping],
  );

  return (
    <Container>
      <MapOrderFormPolaris
        data={data}
        item={item}
        loading={loading}
        loadingMutation={loadingMutation}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};
