import React from "react";
import { DataTable, Avatar, Badge } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../../../shared/PaginationPolaris";
import CopyBox from "../../../shared/CopyBox";
import { Actions } from "./Actions";
import { ColOwnerPolaris } from "../../ColOwnerPolaris";
import { useAppContext } from "../../../../context";
import { useSelectRowsCtx } from "../../SelectRowsProvider";

const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  .Polaris-DataTable__ScrollContainer {
    overflow-x: initial;
  }

  .avatar-container {
    min-width: calc(30rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
  }

  .Polaris-DataTable__TableRow {
    &:last-child {
      .stores-wrap {
        padding-bottom: 2rem;
      }
    }
  }
`;
const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

const badgeStatus = {
  Connected: "success",
  Suspended: "critical",
  Checkpointed: "attention",
  Deactivated: "attention",
};

export default function FBMDataTable({
  data,
  filter,
  setFilter,
  isStoreManager,
  userId,
  refetch,
}) {
  // State
  const [rows, setRows] = React.useState([]);

  // Context
  const { isMobile } = useAppContext();
  const { checkAllMarkup, checkItemMarkup } = useSelectRowsCtx();

  // Get data
  React.useEffect(() => {
    const newData =
      data?.value?.length > 0
        ? data.value.filter(Boolean).map((item) => {
            const { title, email, group, apiKey, status, facebookUid } = item;
            // Group
            const groupName = group?.name;

            let owners = item.owners;
            if (isStoreManager) {
              owners = owners.filter(
                (owner) => owner.user && owner.user.id === userId,
              );
            }

            const actionsMarkup = (
              <div className={"actions"}>
                <Actions value={item} refetch={refetch} />
              </div>
            );
            let actions = [
              checkItemMarkup(item.id),
              <div className={"avatar-container"}>
                <div>
                  <Avatar customer name="John Smith" />
                </div>
                <div className={"avatar-right"}>
                  <div>{title}</div>
                  {email}
                </div>
              </div>,
              <div className="stores-wrap">
                <ColOwnerPolaris owners={owners} />
              </div>,
              <div className="facebook-uid-wrap">
                <span>{facebookUid}</span>
              </div>,
              <div className="group-wrap">{groupName}</div>,
              <div>
                <CopyBox text={apiKey} />
              </div>,
              <div className={"status-container"}>
                <Badge status={badgeStatus[status]}>{status}</Badge>
              </div>,
              <div className="note-wrap">
                <span>{item.note}</span>
              </div>,
            ];

            if (!isMobile) {
              actions.push(actionsMarkup);
            }

            return actions;
          })
        : [];
    setRows(newData);
  }, [data, isMobile, isStoreManager, userId, refetch, checkItemMarkup]);

  // Variables
  let { limit, offset } = filter;
  const total = get(data, "total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };
  let headings = [
    checkAllMarkup,
    "Account",
    "Owners",
    "Facebook UID",
    "Group",
    "API Key",
    "Status",
    "Note",
  ];
  let colType = new Array(headings.length).fill("text");

  if (!isMobile) {
    headings.push("Actions");
    colType.push("numeric");
  }

  return (
    <React.Fragment>
      <Container>
        <DataTable
          rows={rows}
          columnContentTypes={colType}
          headings={headings}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </React.Fragment>
  );
}
