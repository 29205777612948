import { Badge, Tabs } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { isPME_TEAM } from "../../helper";
import { AssignToMe } from "./FilterControlPolaris";

export const MockupGenerating = "File Generating";
export const NeedBuyerConfirm = "Need Buyer Confirm";
export const AwaitingBuyerConfirm = "Awaiting Buyer Confirm";
export const FileGenerating = "File Generating";

const Container = styled.div`
  .Polaris-Tabs__DisclosureActivator:focus {
    box-shadow: none;
  }
`;

const Draft = "draft";
const Declined = "declined";
const Denied = "denied";
export const Pending = "Pending";
const ArtworkConfigurable = "Artwork Configurable";
const ArtworkConfigurableObj = {
  value: "artworkConfigurable",
  label: ArtworkConfigurable,
};

const TABS_DESIGN_TASKS = [
  { value: "all", label: "All Tasks" },
  { value: Draft, label: "Draft" },
  { value: "pending", label: Pending },
  { value: "doing", label: "Doing" },
  { value: "fileGenerating", label: MockupGenerating },
  { value: "needReview", label: "Need Review" },
  { value: "rejected", label: "Rejected" },
  { value: "expiredSoon", label: "Expired Soon" },
  { value: Denied, label: "Denied" },
  { value: "expired", label: "Expired" },
  { value: "cancel", label: "Cancel" },
  { value: "partiallyCompleted", label: "Partially Completed" },
  { value: "done", label: "Done" },
];

const DECLINED_ELEMENT = { value: Declined, label: "Declined" };
export const assignToMe = "Assign To Me";

const pendingDesign = "Pending Design";
const TABS_DESIGN_TASKS_LEADER = [
  { value: "pendingDesign", label: "Pending Design" },
  { value: "assigned", label: "Assigned" },
  { value: "unassigned", label: "Un-assigned" },
];

const IDEA_TASK_EXCLUDES = [
  "expiredSoon",
  "expired",
  "pendingDesign",
  "assigned",
];
const DESIGN_TASK_EXCLUDES = ["partiallyCompleted", Draft, Declined];
const DESIGN_EXCLUDES = ["all"];

const needBuyerConfirmOb = {
  value: "needBuyerConfirm",
  label: NeedBuyerConfirm,
};

const awaitingBuyerConfirmOb = {
  value: "awaitingBuyerConfirm",
  label: AwaitingBuyerConfirm,
};

export const TabsDesignTasksPolaris = ({
  isLeader,
  isDesigner,
  onChange,
  value,
  hasAssign,
  dataCountV2,
  isIdeaTask,
  ideaRoles,
  isRoleIdeaLeader,
  // isDesignLeader,
  // assigneeId,
  // userId,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const { currentUser } = useAppContext();
  const isPMETeam = isPME_TEAM(currentUser);

  useEffect(() => {
    let newTabsDT = TABS_DESIGN_TASKS;
    if (isLeader) {
      newTabsDT = [
        ...newTabsDT.slice(0, 1),
        ...TABS_DESIGN_TASKS_LEADER,
        ...newTabsDT.slice(1),
      ];

      const index = newTabsDT.findIndex(
        ({ label }) => label === Pending,
      );
      if (index > -1 && !isIdeaTask) {
        newTabsDT.splice(index + 1, 0, ArtworkConfigurableObj);
      }
    }

    if (isIdeaTask) {
      newTabsDT = newTabsDT.filter(
        (i) => !IDEA_TASK_EXCLUDES.includes(i.value),
      );

      if (!ideaRoles) {
        newTabsDT = newTabsDT.filter((i) => ![Draft].includes(i.value));
      } else {
        const newDT = [...newTabsDT];
        const indexDenied = newDT.findIndex((i) => i.value === Denied);
        if (indexDenied > -1) {
          newDT.splice(indexDenied, 0, DECLINED_ELEMENT);
        }
        newTabsDT = [...newDT];
      }

      // if (isDesignLeader) {
      //     const newDT = [...newTabsDT];
      //     const index = newDT.findIndex((i) => i.value === "pending");
      //     if (index > -1) {
      //         newDT.splice(index, 0, {
      //             value: assignToMe,
      //             label: assignToMe,
      //         });
      //     }

      //     newTabsDT = [...newDT];
      // }
    } else {
      newTabsDT = newTabsDT.filter(
        (i) => !DESIGN_TASK_EXCLUDES.includes(i.value),
      );
    }

    if (isDesigner) {
      newTabsDT = newTabsDT.filter((i) => !DESIGN_EXCLUDES.includes(i.value));
    }

    if (isPMETeam) {
      const index = newTabsDT.findIndex(
        ({ label }) => label === FileGenerating,
      );
      if (index > -1 && !isIdeaTask) {
        newTabsDT.splice(
          index + 1,
          0,
          needBuyerConfirmOb,
          awaitingBuyerConfirmOb,
        );
      }
    }

    if (dataCountV2 != null) {
      for (let [key, value] of Object.entries(dataCountV2)) {
        newTabsDT.forEach((i) => {
          if (i.value === key) {
            i.total = value;
          }
        });
      }
    }

    let newTabs = newTabsDT.map((i) => {
      let status;
      if ((i.value === "expired" || i.value === "expiredSoon") && i.total > 0) {
        status = "critical";
      }

      let customLabel = i.label;
      if (isDesigner) {
        if (i.value === "pending") {
          customLabel = AssignToMe;
        }
        if (i.value === "needReview") {
          customLabel = "In Review";
        }
      }

      return {
        id: i.value,
        content: (
          <span>
            <span style={{ marginRight: "0.5rem" }}>{customLabel}</span>
            <Badge status={status}>{i.total}</Badge>
          </span>
        ),
        accessibilityLabel:
          customLabel === MockupGenerating ? FileGenerating : customLabel,
        panelID: i.value,
      };
    });
    setTabs(newTabs);
  }, [
    dataCountV2,
    isLeader,
    isIdeaTask,
    ideaRoles,
    isRoleIdeaLeader,
    isDesigner,
    isPMETeam,
  ]);

  useEffect(() => {
    let index = 0;
    if (value) {
      let newValue = value;
      if (isDesigner) {
        if ("Pending" === newValue) {
          newValue = AssignToMe;
        }
        if ("Need Review" === newValue) {
          newValue = "In Review";
        }
      }
      if (hasAssign == null) {
        index = matchTab(tabs, newValue);
      } else if (value === pendingDesign) {
        let label;
        if (hasAssign) {
          label = "Assigned";
        } else {
          label = "Un-assigned";
        }

        index = matchTab(tabs, label);
      } else {
        index = matchTab(tabs, newValue);
      }

      // if (isDesignLeader) {
      //     if (value === Pending && assigneeId === userId) {
      //         let label = assignToMe;
      //         index = matchTab(tabs, label);
      //     }
      // }
    } else if (typeof hasAssign === "boolean") {
      let label;
      if (hasAssign) {
        label = "Assigned";
      } else {
        label = "Un-assigned";
      }

      index = matchTab(tabs, label);
    }

    setSelectedTab(index > -1 ? index : 0);
  }, [tabs, value, hasAssign, isDesigner]);

  const handleTabChange = useCallback(
    (value) => {
      setSelectedTab(value);

      let isTab =
        tabs && tabs.length > 0 ? tabs.find((_, idx) => idx === value) : null;
      let content = isTab && isTab.accessibilityLabel;
      if (onChange) {
        content = "All Tasks" === content ? null : content;
        if (isDesigner) {
          content = "In Review" === content ? "Need Review" : content;
          content = AssignToMe === content ? "Pending" : content;
        }
        onChange({ status: content });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs, isDesigner],
  );

  return (
    <Container>
      <Tabs tabs={tabs} onSelect={handleTabChange} selected={selectedTab} />
    </Container>
  );
};

function matchTab(arr, value) {
  if (!arr || arr.length === 0) return -1;

  return arr.findIndex((t) => t.accessibilityLabel === value);
}
