import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { ActionList, Button, Popover, Spinner } from "@shopify/polaris";
import {
  AddMajorMonotone,
  CircleCancelMinor,
  CirclePlusMinor,
  EditMajorMonotone,
  FolderUpMajorMonotone,
  MaximizeMinor,
  PauseCircleMajorMonotone,
  PauseMinor,
  PlayCircleMajorMonotone,
  RefreshMajorMonotone,
  RepeatOrderMajorMonotone,
  SearchMinor,
  StoreMajorMonotone,
  UndoMajorMonotone,
  VocabularyMajorMonotone,
  BuyButtonMajorTwotone,
  CircleUpMajorMonotone,
  DuplicateMinor
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _, { get } from "lodash";
import React, { lazy, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import {
  CUSTOM_FULFILLMENT,
  FULFILLMENTS_SLUG,
  PRINTIFY_SLUG,
  PRINTWAY_SLUG,
  SETTING_VARIABLES,
} from "../../constants";
import { useAppContext } from "../../context";
import { GET_VARIABLES } from "../../graphql/queries";
import { checkRole, getTeamID } from "../../helper";
import {
  DEV_TEAM_IDS,
  ORDER_STATUS,
  PLATFORMS,
  PLATFORM_FACEBOOK,
  TEAM_ROLE,
  THIRD_PARTY_FULFILLMENTS,
  USER_ROLE,
} from "../../variable";
import { AddClaimPolaris } from "./actions/AddClaimPolaris";
import { AddNoteByDev } from "./actions/AddNoteByDev";
import { AdminCancelOrderPolaris } from "./actions/AdminCancelOrderPolaris";
import { ChangeProduct } from "./actions/ChangeProduct";
import { CloneOrderToResend } from "./actions/CloneOrderToResend";
// import { MarkUsedCalcOrderCost } from "./actions/MarkUsedCalcOrderCost";
import FetchOrderStatus from "./actions/FetchOrderStatus";
import { FetchTrackingPolaris } from "./actions/FetchTrackingPolaris";
import { ForcePausePolaris } from "./actions/ForcePausePolaris";
import { OrderDetailPolaris } from "./actions/OrderDetailPolaris";
import { PauseOrResumePolaris } from "./actions/PauseOrResumePolaris";
import { QCMarkChecked } from "./actions/QCMarkChecked";
import { RefetchPrintifyOrder } from "./actions/RefetchPrintifyOrder";
import { RefundOrderFacebook } from "./actions/RefundOrderFacebook";
import { RefundOrderPolaris } from "./actions/RefundOrderPolaris";
import RejectCurrentDesign from "./actions/RejectCurrentDesign";
import { ReSentOrderPolaris } from "./actions/ReSentOrderPolaris";
import { ResubmitToCustomCatPolaris } from "./actions/ResubmitToCustomcatPolaris";
import { RetrackPolaris } from "./actions/RetrackPolaris";
import { RevertMappingPolaris } from "./actions/RevertMappingPolaris";
import { SellerCancelOrderPolaris } from "./actions/SellerCancelOrderPolaris";
import { SellerNote } from "./actions/SellerNote";
import { SwitchOrderFulfillmentPolaris } from "./actions/SwitchOrderFulfillmentPolaris";
import { UpdateCarrier } from "./actions/UpdateCarrier";
import { UpdateDesignPolaris } from "./actions/UpdateDesignPolaris";
import { UpdateOrderCost } from "./actions/UpdateOrderCost";
import UpdateQuantity from "./actions/UpdateQuantity";
import { UpdateRevenue } from "./actions/UpdateRevenue";
import { UpdateShippingOption } from "./actions/UpdateShippingOption";
import { UploadDesignPolaris } from "./actions/UploadDesignPolaris";
import { ViewFulfillmentInfoPolaris } from "./actions/ViewFulfillmentInfoPolaris";
import { ViewODPolaris } from "./actions/ViewODPolaris";
import { ChargeBaseCostPolaris } from "./ChargeBaseCostPolaris";
import { useOrderContext } from "./OrdersPolaris";
import { PassOnHoldPolaris } from "./PassOnHoldPolaris";

const AddTrackingV2 = lazy(() =>
  import("../tracking/components/AddTrackingV2"),
);

const UpdateTrackingV2 = lazy(() =>
  import("../tracking/components/UpdateTrackingV2"),
);

const ForceCreateOrderLabel = lazy(() => import("./ForceCreateOrderLabel"));
const UpdateDocURL = lazy(() => import("./UpdateDocumentUrl"));
const PassOnHoldUseThirdParty = lazy(() => import("./PassOnHoldUseThirdParty"));

const IDS_CAN_REJECT_DESIGNS = [
  "nvcWxpGaaw",
  "GQIylA4Xax",
  "etHA",
  "ndvH2AnKpf",
]; // Thuy seller, Hoang Anh Sp

const CLAIM = gql`
  query hasClaim($orderId: ID!) {
    hasClaim(orderId: $orderId)
  }
`;

const Container = styled.div`
  .Polaris-ActionList__Image {
    .Polaris-Icon {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }
  }
`;

export const OrderActionsPolaris = (props) => {
  let {
    role,
    value,
    userRole,
    teamRole,
    isAdmin,
    filter,
    changeStatusCount,
    convertOS,
    refetch,
  } = props;
  // Props
  const hasFF = value?.fulfillmentId;
  const sellerNote = value?.sellerNote;

  const fulfillmentSlug = get(value, "fulfillment.slug");
  const isThirdParty = [
    FULFILLMENTS_SLUG.Customcat,
    FULFILLMENTS_SLUG.Dreamship,
    FULFILLMENTS_SLUG.Scalablepress,
    FULFILLMENTS_SLUG.Gearment,
    FULFILLMENTS_SLUG.Merchize,
    FULFILLMENTS_SLUG.Printify,
    FULFILLMENTS_SLUG.Printway,
    FULFILLMENTS_SLUG.Teezily,
  ].includes(fulfillmentSlug);
  const labelSellerNote = (sellerNote ? "Update" : "Add") + " Note";

  // State
  const [popoverActive, setPopoverActive] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);

  const { currentUser } = useAppContext();
  const ctx = useOrderContext();
  const userId = currentUser.id;
  const canAddNote = useMemo(() => {
    const { id } = currentUser || {};
    if (!id) return false;

    return DEV_TEAM_IDS.includes(id);
  }, [currentUser]);
  const {
    isSeller,
    isSupplier,
    isStoreManager,
    isMarketplaceManager,
    isAdministrator,
  } = checkRole(currentUser);
  const isSellerLead = isSeller || isStoreManager || isMarketplaceManager;

  const [hasClaim, { data: dataHasClaim }] = useLazyQuery(CLAIM);

  // Handle actions
  const togglePopoverActive = useCallback(
    () => setPopoverActive((prevState) => !prevState),
    [],
  );

  const toggleShowModalFn = useCallback(
    (newVal) => setShowModal((prevState) => newVal ?? !prevState),
    [],
  );

  const onAction = useCallback(
    (currentAction) => {
      toggleShowModalFn(true);
      setCurrentAction(currentAction);
      setCurrentItemId(() => value.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [value, toggleShowModalFn],
  );

  let showReSubmitFF = false;
  if (
    !userRole.includes(USER_ROLE.Supplier) ||
    ![TEAM_ROLE.Supplier].includes(teamRole)
  ) {
    showReSubmitFF = true;
  }

  let validAdminUpdateDesignStatus = [
    ...Object.values(ORDER_STATUS).filter(
      (v) => ORDER_STATUS.PendingDesign !== v,
    ),
  ];

  let validOtherUserUpdateDesignStatus = [
    ORDER_STATUS.Pending,
    ORDER_STATUS.OnHold,
    ORDER_STATUS.DesignRejected,
  ];

  let validUpdateDesignStatus = [USER_ROLE.Admin].includes(role)
    ? validAdminUpdateDesignStatus
    : validOtherUserUpdateDesignStatus;

  if (value && value.fulfillmentId && value.fulfillmentId.length > 0) {
    if (
      [USER_ROLE.Seller].includes(role) &&
      teamRole &&
      teamRole.length > 0 &&
      [
        TEAM_ROLE.Admin,
        TEAM_ROLE.MarketplaceManager,
        TEAM_ROLE.StoreManager,
      ].includes(teamRole)
    ) {
      validUpdateDesignStatus = validAdminUpdateDesignStatus;
    }
  }

  let hasFetchTracking = false;
  if (value?.fulfillment?.slug === "merchize") {
    hasFetchTracking = true;
  }

  // Label re submit.
  let labelFF = "Fulfillment";
  let fulfillName = _.get(value, "fulfillment.name", null);
  if (fulfillName) {
    labelFF = fulfillName;
  }

  // Show refund order
  let showRefundOrder = false;
  let fulfillment = _.get(value, "fulfillment", null);
  if (fulfillment) {
    if (["Administrator", USER_ROLE.Seller].includes(userRole[0])) {
      showRefundOrder = true;
    }
  }

  const ffSlug = _.get(value, "fulfillment.slug", null);
  const thirdPartyFF = THIRD_PARTY_FULFILLMENTS[ffSlug];
  const isPrintway = ffSlug === PRINTWAY_SLUG;

  // Platform
  const platform = _.get(value, "store.platform", null);
  const platformFB = [PLATFORM_FACEBOOK].includes(platform);
  const isGearment = FULFILLMENTS_SLUG.Gearment === ffSlug;

  const isPrintify = ffSlug === PRINTIFY_SLUG;
  const isCustomFF = ffSlug === CUSTOM_FULFILLMENT;
  const trackingCodes = value?.trackingCodes || [];
  const trackingCodesLen = trackingCodes.length;
  const isTiktok = PLATFORMS.Tiktok === platform;

  let actions = [
    {
      content: "View",
      icon: VocabularyMajorMonotone,
      status: ["*"],
      role: [USER_ROLE.Admin, USER_ROLE.Seller, USER_ROLE.Supplier].includes(
        role,
      ),
      onAction: () => onAction("view"),
    },
    ...(dataHasClaim && !dataHasClaim.hasClaim && !isCustomFF
      ? [
          {
            content: "Add Claim",
            icon: CirclePlusMinor,
            status: [ORDER_STATUS.Fulfilled],
            role: [USER_ROLE.Seller].includes(role),
            onAction: () => onAction("addClaim"),
          },
        ]
      : []),
    {
      content: "Pause",
      icon: PauseMinor,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.DesignRejected,
      ],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role) && !value.paused,
      onAction: () => onAction("pause"),
    },
    {
      content: "Resume",
      icon: PlayCircleMajorMonotone,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
      ],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role) && value.paused,
      onAction: () => onAction("resume"),
    },
    !!value.fulfillmentId && {
      content: "Update Quantity",
      icon: EditMajorMonotone,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Error,
        ORDER_STATUS.Refunded,
      ],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
      onAction: () => onAction("updateQuantity"),
    },
    {
      content: "Charge Base Cost",
      icon: MaximizeMinor,
      status: [ORDER_STATUS.Pending],
      role: [USER_ROLE.Admin].includes(role),
      onAction: () => onAction("chargeCost"),
    },
    {
      content: "Pass on Hold",
      icon: MaximizeMinor,
      status: [ORDER_STATUS.OnHold],
      role: [USER_ROLE.Admin].includes(role),
      onAction: () => onAction("passOnHold"),
    },
    isThirdParty && !!ctx?.hasPassOnHoldTime && {
      content: "Pass On Hold",
      icon: MaximizeMinor,
      status: [ORDER_STATUS.OnHold],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("passOnHoldUse3Party"),
    },
    {
      content: "Upload Design",
      icon: FolderUpMajorMonotone,
      status: [ORDER_STATUS.PendingDesign],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
      onAction: () => onAction("uploadDesign"),
    },
    {
      content: "Update Design",
      icon: EditMajorMonotone,
      status: validUpdateDesignStatus,
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
      onAction: () => onAction("updateDesign"),
    },
    {
      content: `Re-Submit to ${labelFF}`,
      icon: RefreshMajorMonotone,
      status: [ORDER_STATUS.Error],
      role: showReSubmitFF,
      onAction: () => onAction("resubmitCustomcat"),
    },
    ...(hasFF && !isSupplier
      ? [
          {
            content: "Re-Sent Order",
            icon: RefreshMajorMonotone,
            status: [ORDER_STATUS.ReadyToPrint, ORDER_STATUS.Fulfilled],
            role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
            onAction: () => onAction("resentOrder"),
          },
        ]
      : []),
    {
      content: "Force Pause",
      icon: PauseCircleMajorMonotone,
      status: [ORDER_STATUS.PendingPayment],
      role: [USER_ROLE.Admin].includes(role) || isAdmin,
      onAction: () => onAction("forcePause"),
    },
    trackingCodesLen === 0 && {
      content: "Add Tracking",
      icon: AddMajorMonotone,
      status: [
        ORDER_STATUS.Printing,
        ORDER_STATUS.ReadyToPrint,
        ORDER_STATUS.Processing,
      ],
      role:
        [USER_ROLE.Admin, USER_ROLE.Supplier].includes(role) ||
        isAdmin ||
        isSeller ||
        isStoreManager ||
        isSupplier,
      onAction: () => onAction("addTracking"),
    },
    {
      content: "Update Tracking",
      icon: RefreshMajorMonotone,
      status: [
        ORDER_STATUS.Fulfilled,
        ORDER_STATUS.Printing,
        ORDER_STATUS.ReadyToPrint,
      ],
      role:
        ([USER_ROLE.Admin, USER_ROLE.Supplier].includes(role) ||
          isSeller ||
          isSupplier) &&
        trackingCodesLen > 0,
      onAction: () => onAction("updateTracking"),
    },
    isCustomFF && {
      content: "Update Tracking",
      icon: RefreshMajorMonotone,
      status: [ORDER_STATUS.Fulfilled],
      role: isSeller && trackingCodesLen > 0,
      onAction: () => onAction("updateTracking"),
    },
    {
      content: "View Fulfillment Info",
      icon: SearchMinor,
      status: [ORDER_STATUS.Fulfilled],
      role: [USER_ROLE.Admin, USER_ROLE.Supplier].includes(role),
      onAction: () => onAction("ViewFI"),
    },
    // TODO: Add design task
    // {
    //   content: "Add Design Task",
    //   icon: SearchMinor,
    //   status: [ORDER_STATUS.Fulfilled],
    //   role: [USER_ROLE.Admin, USER_ROLE.Supplier].includes(role),
    // },
    {
      content: "Switch Fulfillment",
      icon: StoreMajorMonotone,
      status: [
        ORDER_STATUS.Error,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.Pending,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.PendingPayment,
      ],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
      onAction: () => onAction("switchFF"),
    },
    // ...(value && value.store && value.store.platform === "amazon"
    {
      content: "Revert Mapping",
      icon: RefreshMajorMonotone,
      status: [ORDER_STATUS.PendingDesign, ORDER_STATUS.Pending],
      role: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
      onAction: () => onAction("revertMapping"),
    },
    {
      content: "Reject Current Designs",
      icon: RefreshMajorMonotone,
      status: [ORDER_STATUS.OnHold, ORDER_STATUS.ReadyToPrint],
      role:
        [USER_ROLE.Seller].includes(role) &&
        IDS_CAN_REJECT_DESIGNS.includes(userId),
      onAction: () => onAction("rejectCurrentDesign"),
    },
    !isSupplier && {
      content: "View Order Design",
      icon: SearchMinor,
      status: [
        ORDER_STATUS.Cancelled,
        ORDER_STATUS.Error,
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Pending,
        ORDER_STATUS.InProduction,
        ORDER_STATUS.Fulfilled,
        ORDER_STATUS.ReadyToPrint,
        ORDER_STATUS.Printing,
        ORDER_STATUS.DesignRejected,
      ],
      role: [USER_ROLE.Seller, USER_ROLE.Admin].includes(role),
      onAction: () => onAction("viewOD"),
    },
    ...(hasFetchTracking
      ? [
          {
            content: "Fetch tracking",
            icon: RepeatOrderMajorMonotone,
            status: [ORDER_STATUS.Fulfilled, ORDER_STATUS.ReadyToPrint],
            role: [
              USER_ROLE.Admin,
              USER_ROLE.Seller,
              USER_ROLE.Supporter,
              TEAM_ROLE.MarketplaceManager,
              TEAM_ROLE.StoreManager,
            ],
            onAction: () => onAction("fetchTracking"),
          },
        ]
      : []),
    ...(showRefundOrder && !platformFB && !isSupplier
      ? [
          {
            content: "Set to Refunded",
            icon: UndoMajorMonotone,
            status: [
              ORDER_STATUS.ReadyToPrint,
              ORDER_STATUS.PendingPayment,
              ORDER_STATUS.Printing,
              ORDER_STATUS.Fulfilled,
              ORDER_STATUS.Cancelled,
              ORDER_STATUS.DesignRejected,
              ORDER_STATUS.Error,
            ],
            role: [
              USER_ROLE.Admin,
              USER_ROLE.Seller,
              TEAM_ROLE.StoreManager,
              TEAM_ROLE.MarketplaceManager,
            ],
            onAction: () => onAction("refund"),
          },
        ]
      : []),
    ...(platformFB
      ? [
          {
            content: "Refund Order Facebook",
            icon: RefreshMajorMonotone,
            status: [
              ORDER_STATUS.PendingDesign,
              ORDER_STATUS.PendingPayment,
              ORDER_STATUS.OnHold,
              ORDER_STATUS.Pending,
              ORDER_STATUS.InProduction,
              ORDER_STATUS.Fulfilled,
              ORDER_STATUS.ReadyToPrint,
              ORDER_STATUS.Printing,
              ORDER_STATUS.DesignRejected,
              ORDER_STATUS.Error,
            ],
            role: [
              USER_ROLE.Admin,
              USER_ROLE.Seller,
              TEAM_ROLE.StoreManager,
              TEAM_ROLE.MarketplaceManager,
              TEAM_ROLE.Supporter,
            ],
            onAction: () => onAction("refundFacebook"),
          },
        ]
      : []),

    // ...(!personalized && !checkedByQC && !isSupplier
    //   ? [
    //       {
    //         content: "Mark checked by QC",
    //         icon: TickMinor,
    //         status: ["*"],
    //         role: [
    //           USER_ROLE.Admin,
    //           USER_ROLE.Seller,
    //           USER_ROLE.Supplier,
    //         ].includes(role),
    //         onAction: () => onAction("QCcheck"),
    //       },
    //     ]
    //   : []),
    (thirdPartyFF || isPrintway || isPrintify) && {
      content: "Update shipping option",
      icon: EditMajorMonotone,
      status: [
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.Pending,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Error,
      ],
      role: ["*"],
      onAction: () => onAction("updateShippingOption"),
    },
    isTiktok && {
      content: "Update Document URL",
      icon: CircleUpMajorMonotone,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Error,
      ],
      role: [USER_ROLE.Seller, USER_ROLE.Admin].includes(role),
      onAction: () => onAction("updateDocumentUrl"),
    },
    fulfillment == null && {
      content: "Update Carrier",
      icon: EditMajorMonotone,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Error,
        ORDER_STATUS.Cancelled,
        ORDER_STATUS.Printing,
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.ReadyToPrint,
      ],
      role: [USER_ROLE.Seller, USER_ROLE.Admin].includes(role),
      onAction: () => onAction("update_carrier"),
    },
    {
      content: "Update Revenue",
      icon: RefreshMajorMonotone,
      status: ["*"],
      role: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.MarketplaceManager,
      ].includes(teamRole),
      onAction: () => onAction("updateRevenue"),
    },
    isSeller && {
      content: "Update Order Cost",
      icon: EditMajorMonotone,
      status: [ORDER_STATUS.Cancelled, ORDER_STATUS.Refunded],
      role: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.Supporter,
      ].includes(teamRole),
      onAction: () => onAction("updateOrderCost"),
    },

    canAddNote && {
      content: "Add Note By Dev",
      icon: EditMajorMonotone,
      status: ["*"],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("addNoteByDev"),
    },
    isPrintify && {
      content: "Refetch Printify order info",
      icon: RefreshMajorMonotone,
      status: [
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.InProduction,
        ORDER_STATUS.ReadyToPrint,
        ORDER_STATUS.Printing,
        ORDER_STATUS.Fulfilled,
      ],
      role: [USER_ROLE.Seller, USER_ROLE.Admin].includes(role),
      onAction: () => onAction("refetchPrintifyOrder"),
    },
    isSellerLead && {
      content: "Change Product for Order",
      icon: EditMajorMonotone,
      status: [
        ORDER_STATUS.Pending,
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Error,
      ],
      role: true,
      onAction: () => onAction("changeProduct"),
    },
    (isSeller || isStoreManager) && {
      content: "Clone to Resend",
      icon: DuplicateMinor,
      status: [
        ORDER_STATUS.Fulfilled,
        ORDER_STATUS.Cancelled,
        ORDER_STATUS.Refunded,
        ORDER_STATUS.Error,
      ],
      role: true,
      onAction: () => onAction("cloneToResend"),
    },
    {
      content: labelSellerNote,
      icon: EditMajorMonotone,
      status: ["*"],
      role: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.MarketplaceManager,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.Supporter,
      ].includes(teamRole),
      onAction: () => onAction("sellerNote"),
    },
    isSellerLead &&
      isGearment && {
        content: "Fetch Order's Status",
        icon: RefreshMajorMonotone,
        status: [
          ORDER_STATUS.InProduction,
          ORDER_STATUS.Printing,
          ORDER_STATUS.ReadyToPrint,
        ],
        role: ["*"],
        onAction: () => onAction("fetchOrderStatus"),
      },
    isSellerLead &&
      isGearment && {
        content: "Force Create Order Label",
        icon: BuyButtonMajorTwotone,
        status: [ORDER_STATUS.Error],
        role: ["*"],
        onAction: () => onAction("forceCreateOrderLabel"),
      },
    // isSellerLead && {
    //    content: "Used calculate cost",
    //    icon: TickMinor,
    //    status: [
    //       ORDER_STATUS.Cancelled,
    //       ORDER_STATUS.Refunded
    //    ],
    //    role: true,
    //    onAction: () => onAction("markUsedCalcOrderCost")
    // },
    {
      content: "Cancel",
      icon: CircleCancelMinor,
      status: [
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Pending,
        ORDER_STATUS.InProduction,
        ORDER_STATUS.Fulfilled,
        ORDER_STATUS.ReadyToPrint,
        ORDER_STATUS.Printing,
        ORDER_STATUS.DesignRejected,
        ORDER_STATUS.Error,
      ],
      role: isAdministrator,
      onAction: () => onAction("adminCancel"),
    },
    {
      content: "Cancel",
      icon: CircleCancelMinor,
      status: [
        ORDER_STATUS.PendingDesign,
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.OnHold,
        ORDER_STATUS.Pending,
        ORDER_STATUS.Error,
        ORDER_STATUS.ReadyToPrint,
      ],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("sellerCancel"),
    },

    // customfulfillment + processing can cancel
    isCustomFF && {
      content: "Cancel",
      icon: CircleCancelMinor,
      status: [ORDER_STATUS.Processing],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("sellerCancel"),
    },

    ...(value.fulfillmentOrders && value.fulfillmentOrders.length
      ? [
          {
            content: "Re-track",
            icon: RefreshMajorMonotone,
            status: [
              ORDER_STATUS.Fulfilled,
              ORDER_STATUS.ReadyToPrint,
              ORDER_STATUS.Printing,
            ],
            role: [USER_ROLE.Seller].includes(role),
            onAction: () => onAction("reTrack"),
          },
        ]
      : []),
    {
      content: "Add Tracking Code",
      icon: AddMajorMonotone,
      status: [ORDER_STATUS.Error],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("addTrackingSeller"),
    },
    trackingCodesLen === 0 && {
      content: "Add Tracking Code",
      icon: AddMajorMonotone,
      status: [ORDER_STATUS.Fulfilled],
      role: [USER_ROLE.Admin].includes(role),
      onAction: () => onAction("addTrackingSeller"),
    },
    isCustomFF && {
      content: "Add Tracking Code",
      icon: AddMajorMonotone,
      status: [
        ORDER_STATUS.PendingPayment,
        ORDER_STATUS.Printing,
        ORDER_STATUS.ReadyToPrint,
      ],
      role: [USER_ROLE.Seller].includes(role),
      onAction: () => onAction("addTrackingSeller"),
    },
  ].filter(Boolean);

  let state = actions.filter((a) => {
    return (
      (!a.status ||
        a.status.includes("*") ||
        a.status.includes(value.status) ||
        a.status.includes(value.fulfillmentStatus)) &&
      a.role
    );
  });

  // Remove duplicate
  state = Array.from(
    new Map(state.map((item) => [item.content, item])).values(),
  );
  state = state.map((i) => ({
    content: i.content,
    icon: i.icon,
    onAction: i.onAction,
  }));

  const activator = (
    <Button
      onClick={() => {
        if (
          [ORDER_STATUS.Fulfilled].includes(value?.status) &&
          ![ORDER_STATUS.DesignRejected].includes(value?.fulfillmentStatus)
        ) {
          if ([USER_ROLE.Admin].includes(role)) {
            if (!userRole.includes(USER_ROLE.Supporter)) {
              hasClaim({ variables: { orderId: value.id } });
            }
          } else {
            if (![USER_ROLE.Supplier].includes(role)) {
              hasClaim({ variables: { orderId: value.id } });
            }
          }
        }
        togglePopoverActive();
      }}
      disclosure
      children="Actions"
    />
  );

  const toggleShowModal = React.useCallback(
    (newVal) => {
      toggleShowModalFn(newVal);
      setCurrentAction(null);
    },
    [toggleShowModalFn],
  );

  const otherProps = {
    toggleShowModal,
    onClose: toggleShowModal,
    open: showModal,
    value,
    refetch,
  };

  const actionMarkup = {
    view: <OrderDetailPolaris {...otherProps} role={role} />,
    addClaim: <AddClaimPolaris {...otherProps} />,
    pause: (
      <PauseOrResumePolaris
        {...otherProps}
        pause
        orderId={value && value.id}
        filter={filter}
      />
    ),
    resume: (
      <PauseOrResumePolaris
        {...otherProps}
        orderId={value && value.id}
        filter={filter}
      />
    ),
    chargeCost: (
      <ChargeBaseCostPolaris
        {...otherProps}
        orderIds={value && [value.id]}
        isAction
      />
    ),
    passOnHold: (
      <PassOnHoldPolaris
        {...otherProps}
        orderIds={value && [value.id]}
        isAction
      />
    ),
    uploadDesign: (
      <UploadDesignPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    updateDesign: (
      <UpdateDesignPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    resubmitCustomcat: (
      <ResubmitToCustomCatPolaris
        {...otherProps}
        orderId={value && value.id}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
        labelFF={labelFF}
      />
    ),
    resentOrder: (
      <ReSentOrderPolaris
        {...otherProps}
        convertOS={convertOS}
        changeStatusCount={changeStatusCount}
      />
    ),
    forcePause: (
      <ForcePausePolaris
        {...otherProps}
        orderId={value && value.id}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    addTracking: (
      // <AddTrackingPolaris
      //   {...otherProps}
      //   orderId={value && value.id}
      //   changeStatusCount={changeStatusCount}
      //   convertOS={convertOS}
      // />
      <AddTrackingV2
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    // updateTracking: <UpdateTrackingPolaris {...otherProps} {...props} />,
    updateTracking: <UpdateTrackingV2 {...otherProps} {...props} />,
    ViewFI: (
      <ViewFulfillmentInfoPolaris {...otherProps} orderId={value && value.id} />
    ),
    switchFF: <SwitchOrderFulfillmentPolaris {...otherProps} />,
    revertMapping: (
      <RevertMappingPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    rejectCurrentDesign: <RejectCurrentDesign {...otherProps} />,
    viewOD: <ViewODPolaris {...otherProps} />,
    adminCancel: (
      <AdminCancelOrderPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    sellerCancel: (
      <SellerCancelOrderPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
      />
    ),
    reTrack: <RetrackPolaris {...otherProps} />,
    // addTrackingSeller: (
    //   <AddTrackingSellerPolaris
    //     {...otherProps}
    //     changeStatusCount={changeStatusCount}
    //     convertOS={convertOS}
    //   />
    // ),
    addTrackingSeller: (
      <AddTrackingV2
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
        isSeller
      />
    ),
    fetchTracking: <FetchTrackingPolaris {...otherProps} />,
    refund: (
      <RefundOrderPolaris
        {...otherProps}
        changeStatusCount={changeStatusCount}
        convertOS={convertOS}
        id={value?.id}
      />
    ),
    refundFacebook: <RefundOrderFacebook {...otherProps} />,
    QCcheck: <QCMarkChecked {...otherProps} />,
    updateShippingOption: (
      <UpdateShippingOption
        {...otherProps}
        thirdPartyFF={thirdPartyFF}
        isPrintway={isPrintway}
        isPrintify={isPrintify}
      />
    ),
    addNoteByDev: <AddNoteByDev {...otherProps} />,
    updateRevenue: <UpdateRevenue {...otherProps} />,
    update_carrier: <UpdateCarrier {...otherProps} />,
    refetchPrintifyOrder: <RefetchPrintifyOrder {...otherProps} />,
    updateOrderCost: <UpdateOrderCost {...otherProps} />,
    changeProduct: <ChangeProduct {...otherProps} />,
    cloneToResend: <CloneOrderToResend {...otherProps} />,
    sellerNote: <SellerNote {...otherProps} title={labelSellerNote} />,
    fetchOrderStatus: <FetchOrderStatus {...otherProps} />,
    updateQuantity: <UpdateQuantity {...otherProps} />,
    forceCreateOrderLabel: <ForceCreateOrderLabel {...otherProps} />,
    updateDocumentUrl: <UpdateDocURL {...otherProps} />,
    passOnHoldUse3Party: <PassOnHoldUseThirdParty {...otherProps} />,
  };

  return (
    <React.Fragment>
      <Popover
        activator={activator}
        onClose={togglePopoverActive}
        active={popoverActive}
      >
        <Container>
          <ActionList items={state} />
        </Container>
      </Popover>
      <React.Suspense fallback={<Spinner size="small" />}>
        {_.isEqual(currentItemId, value.id) && actionMarkup[currentAction]}
      </React.Suspense>
      {/* {_.isEqual(currentItemId, value.id) && (
        <>
          <OrderDetailPolaris
            toggleShowModal={toggleShowModal}
            open={showModal && "view" === currentAction}
            value={value}
            role={role}
          />
          <AddClaimPolaris
            open={showModal && "addClaim" === currentAction}
            toggleShowModal={toggleShowModal}
            value={value}
          />
          <PauseOrResumePolaris
            toggleShowModal={toggleShowModal}
            open={showModal && "pause" === currentAction}
            pause={true}
            orderId={value && value.id}
            filter={filter}
            refetch={refetch}
          />
          <PauseOrResumePolaris
            toggleShowModal={toggleShowModal}
            open={showModal && "resume" === currentAction}
            pause={false}
            orderId={value && value.id}
            filter={filter}
            refetch={refetch}
          />
          <ChargeBaseCostPolaris
            isAction
            orderIds={value && [value.id]}
            open={"chargeCost" === currentAction}
            refetch={refetch}
            toggleShowModal={toggleShowModal}
          />
          <PassOnHoldPolaris
            isAction
            orderIds={value && [value.id]}
            open={showModal && "passOnHold" === currentAction}
            toggleShowModal={toggleShowModal}
          />
          <UploadDesignPolaris
            open={showModal && "uploadDesign" === currentAction}
            toggleShowModal={toggleShowModal}
            value={value}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
            refetch={refetch}
          />
          <UpdateDesignPolaris
            open={showModal && "updateDesign" === currentAction}
            toggleShowModal={toggleShowModal}
            value={value}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
            refetch={refetch}
          />
          <ResubmitToCustomCatPolaris
            orderId={value && value.id}
            changeStatusCount={changeStatusCount}
            open={showModal && "resubmitCustomcat" === currentAction}
            toggleShowModal={toggleShowModal}
            convertOS={convertOS}
            refetch={refetch}
            labelFF={labelFF}
          />
          <ReSentOrderPolaris
            value={value}
            changeStatusCount={changeStatusCount}
            open={showModal && "resentOrder" === currentAction}
            toggleShowModal={toggleShowModal}
            convertOS={convertOS}
            refetch={refetch}
          />
          <ForcePausePolaris
            orderId={value && value.id}
            changeStatusCount={changeStatusCount}
            open={showModal && "forcePause" === currentAction}
            toggleShowModal={toggleShowModal}
            convertOS={convertOS}
            refetch={refetch}
          />
          <AddTrackingPolaris
            orderId={value && value.id}
            changeStatusCount={changeStatusCount}
            open={showModal && "addTracking" === currentAction}
            toggleShowModal={toggleShowModal}
            convertOS={convertOS}
            refetch={refetch}
          />
          <UpdateTrackingPolaris
            value={value}
            open={showModal && "updateTracking" === currentAction}
            toggleShowModal={toggleShowModal}
            {...props}
          />
          <ViewFulfillmentInfoPolaris
            orderId={value && value.id}
            open={showModal && "ViewFI" === currentAction}
            toggleShowModal={toggleShowModal}
          />
          <SwitchOrderFulfillmentPolaris
            value={value}
            open={showModal && "switchFF" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
          />
          <RevertMappingPolaris
            value={value}
            open={showModal && "revertMapping" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
          />
          <RejectCurrentDesign
            value={value}
            open={showModal && "rejectCurrentDesign" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <ViewODPolaris
            value={value}
            open={showModal && "viewOD" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
          />
          <AdminCancelOrderPolaris
            value={value}
            open={showModal && "adminCancel" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
          />
          <SellerCancelOrderPolaris
            value={value}
            open={showModal && "sellerCancel" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
          />
          <RetrackPolaris
            value={value}
            open={showModal && "reTrack" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
          />
          <AddTrackingSellerPolaris
            value={value}
            open={showModal && "addTrackingSeller" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
          />
          <FetchTrackingPolaris
            value={value}
            open={showModal && "fetchTracking" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
          />
          <RefundOrderPolaris
            changeStatusCount={changeStatusCount}
            convertOS={convertOS}
            id={value?.id}
            open={showModal && "refund" === currentAction}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
          />
          <RefundOrderFacebook
            value={value}
            open={showModal && "refundFacebook" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <QCMarkChecked
            value={value}
            open={showModal && "QCcheck" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <UpdateShippingOption
            value={value}
            thirdPartyFF={thirdPartyFF}
            isPrintway={isPrintway}
            onClose={toggleShowModal}
            refetch={refetch}
            open={showModal && "updateShippingOption" === currentAction}
          />
          <AddNoteByDev
            value={value}
            onClose={toggleShowModal}
            refetch={refetch}
            open={showModal && "addNoteByDev" === currentAction}
          />
          <UpdateRevenue
            value={value}
            onClose={toggleShowModal}
            refetch={refetch}
            open={showModal && "updateRevenue" === currentAction}
          />
          <RefetchPrintifyOrder
            value={value}
            toggleShowModal={toggleShowModal}
            refetch={refetch}
            open={showModal && "refetchPrintifyOrder" === currentAction}
          />
          <UpdateOrderCost
            value={value}
            onClose={toggleShowModal}
            refetch={refetch}
            open={showModal && "updateOrderCost" === currentAction}
          />
          <ChangeProduct
            value={value}
            open={showModal && "changeProduct" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <CloneOrderToResend
            value={value}
            open={showModal && "cloneToResend" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <SellerNote
            value={value}
            open={showModal && "sellerNote" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
            title={labelSellerNote}
          />
          <FetchOrderStatus
            value={value}
            open={showModal && "fetchOrderStatus" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
          <UpdateQuantity
            value={value}
            open={showModal && "updateQuantity" === currentAction}
            onClose={toggleShowModal}
            refetch={refetch}
          />
        </>
      )} */}
    </React.Fragment>
  );
};
