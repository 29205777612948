import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import Price from "../Price";

class ProductBaseVariantSupplierPricing extends Component {
  render() {
    let { suppliers, value } = this.props;
    if (!value) {
      value = [];
    }
    let mapSupplierById = {};
    if (suppliers && suppliers.length) {
      for (let i = 0; i < suppliers.length; i++) {
        const userId = suppliers[i].id;
        mapSupplierById[userId] = suppliers[i];
      }
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <div>
        {value.map((item, index) => {
          const supplier = mapSupplierById[item.userId];
          if (!supplier) {
            return null;
          }
          const label = `${supplier.firstName} ${supplier.lastName}`;
          return (
            <Form.Item {...formItemLayout} key={index} label={label}>
              <Price
                onChange={(newPrice) => {
                  value[index].price = newPrice;
                  if (this.props.onChange) {
                    this.props.onChange(value);
                  }
                }}
                value={item.price ? item.price : 0}
              />
            </Form.Item>
          );
        })}
      </div>
    );
  }
}

ProductBaseVariantSupplierPricing.propTypes = {
  value: PropTypes.array,
  suppliers: PropTypes.array,
  onChange: PropTypes.func,
};

export default ProductBaseVariantSupplierPricing;
