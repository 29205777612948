import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Query } from "@apollo/react-components";
import { Select, Spin, Typography } from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";
import { AppContext } from "../../context";
import { checkRole } from "../../helper";

const { Text } = Typography;
const LOAD = gql`
  query fulfillmentProductById(
    $id: ID!
    $limit: Int
    $offset: Int
    $search: String
    $status: Boolean
  ) {
    fulfillmentProductById(
      id: $id
      limit: $limit
      offset: $offset
      search: $search
      status: $status
    ) {
      total
      nodes {
        id
        title
        originId
        status
        description
        attributes {
          name
          slug
          options
        }
        variants {
          regularPrice
          salePrice
          sellerPrice
          fulfillmentProductId
          sorting
          attributes {
            name
            slug
            option
          }
        }
        fulfillmentSkus
        printAreas
        type
      }
    }
  }
`;

export default class ProductBaseFulfillmentId extends Component {
  state = {
    loading: false,
    products: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(nextProps, this.props) ||
      !_.isEqual(nextState, this.state)
    ) {
      return true;
    }
    return false;
  }

  handleSearch = _.debounce((search, client, status) => {
    const { value } = this.props;
    this.setState({ loading: true });
    client
      .query({
        query: LOAD,
        variables: {
          id: value && value.id,
          limit: 20,
          offset: 0,
          search,
          status,
        },
      })
      .then((res) => {
        this.setState({
          products:
            (res &&
              res.data &&
              res.data.fulfillmentProductById &&
              res.data.fulfillmentProductById.nodes) ||
            [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }, 300);

  render() {
    const {
      value,
      productId,
      onChange,
      disabled = false,
      loadingFFProduct,
      getOriginId,
      isBurgerPrints,
    } = this.props;
    const { currentUser } = this.context || {};
    const { isSeller } = checkRole(currentUser);
    return (
      <Query
        query={LOAD}
        variables={{
          id: value && value.id,
          limit: 20,
          offset: 0,
          search: productId,
          status: !isSeller ? (productId ? null : true) : undefined,
        }}
        onCompleted={(res) => {
          const fulfillments = res && res.fulfillmentProductById.nodes;
          this.setState({
            products: fulfillments || [],
          });

          if (fulfillments && fulfillments[0]) {
            this.fulfillment = fulfillments[0];
            getOriginId &&
              getOriginId(fulfillments[0], _.get(fulfillments, "[0].originId"));
          }
        }}
      >
        {({ data, loading, client, error }) => {
          if (loading || loadingFFProduct) return <Spin />;
          if (error) return error.toString();
          const products = this.state.products;
          return (
            <div>
              <Select
                showSearch
                optionFilterProp="children"
                onSelect={(_, option) => {
                  option.data.isChange = true;
                  onChange(option.data, "");
                }}
                onFocus={(e) => {
                  this.handleSearch(null, client, isSeller ? undefined : true);
                }}
                // value={value && value.id}
                defaultValue={productId}
                onSearch={(e) => this.handleSearch(e, client, null)}
                suffixIcon={
                  this.state.loading ? <LoadingOutlined /> : <SearchOutlined />
                }
                filterOption={false}
                disabled={disabled}
              >
                {products.map((p) => (
                  <Select.Option key={p.originId} value={p.id} data={p}>
                    {isBurgerPrints ? `${p.title} - ${p.originId}`: p.title}
                  </Select.Option>
                ))}
              </Select>
              {value && value.name.toLowerCase() === "customcat" && (
                <div>
                  <label style={{ color: "rgba(0, 0, 0, 0.85)", padding: "0" }}>
                    Design Placement Presets
                  </label>
                  <Text
                    type="secondary"
                    style={{
                      display: "block",
                      lineHeight: "1.5",
                      marginBottom: "8px",
                      marginTop: "-5px",
                    }}
                  >
                    Read more about{" "}
                    <a
                      href="https://customcat-beta.mylocker.net/api/v1/#order-designs"
                      target="_blank"
                    >
                      Design Placement Presets
                    </a>
                  </Text>
                  <Select
                    value={value.presetId ? value.presetId : ""}
                    onSelect={(v) => {
                      onChange(this.fulfillment, v);
                    }}
                  >
                    <Select.Option value={""}>Default</Select.Option>
                    <Select.OptGroup label="DTG Products">
                      <Select.Option value="1">
                        Left Chest Placement
                      </Select.Option>
                      <Select.Option value="2">
                        Full Front Placement
                      </Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Sublimation Products">
                      <Select.Option value="4">
                        Dye Sub Default Placement
                      </Select.Option>
                    </Select.OptGroup>
                  </Select>
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

ProductBaseFulfillmentId.contextType = AppContext;
