import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { GET_PRODUCT_MAPPING } from "../../graphql/queries";
import { checkRole, handleError } from "../../helper";
import { PLATFORMS } from "../../variable";
import { LIST_SELLER_PRODUCTS_QUERY } from "../product/ProductsPolaris";
import { useToastContext } from "../shared/ToastContext";
import { MapOrderEPFormPolaris } from "./MapOrderEPFormPolaris";
import { DEFAULT_PRODUCT_LIST_FILTER } from "./MapOrderPolaris";

const Container = styled.div``;

const LOAD_QUERY = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        designPositions {
          id
          file {
            id
            thumbnailUrl
            url
          }
          designPosition {
            id
            name
          }
        }
        productBases {
          id
          title
          slug
          fulfillment {
            fulfillmentTitle
          }
          variants {
            id
            attributes {
              name
              slug
              option
            }
          }
        }
        mainImageId
        images {
          id
          file {
            id
            url
            thumbnailUrl
          }
        }
        variants {
          id
          disabled
          productBaseVariantId
          productBaseVariant {
            id
            attributes {
              name
              slug
              option
            }
            productBase {
              id
              title
            }
          }
          variantStores {
            id
            originId
            storeId
          }
        }
        fields {
          id
          title
          name
          sorting
        }
      }
    }
  }
`;

const MUTATE = gql`
  mutation mappingProductExist($input: NewMappingProductExist!) {
    mappingProductExist(input: $input) {
      id
      title
      sku
      description
      designStatus
      personalized
      status
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          url
          thumbnailUrl
        }
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
    }
  }
`;

export const MapOrderEPPolaris = ({
  item,
  suggestMappingProduct,
  onCompleted,
  removeLabel,
  onDismiss,
  needVariantMapping,
  canSetExistProduct,
  handleRemoveCurrentProduct,
}) => {
  const isNeedVariantMapping =
    needVariantMapping && [PLATFORMS.Shopify].includes(item?.store?.platform);
  const id = item?.id;
  const existProductID = item?.existProductID;

  const [search, setSearch] = useState(null);
  const { currentUser } = useContext(AppContext);
  const { isStoreManager, isMarketplaceManager } = checkRole(currentUser);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  const filter = {
    limit: 20,
    offset: 0,
  };
  const { data, error, loading } = useQuery(LOAD_QUERY, {
    variables: {
      filter: {
        ...filter,
        search,
        loadDesignPosition: true,
        ...(isStoreManager ? { storeId: "all" } : null),
        ...(isMarketplaceManager ? { storeId: null } : null),
      },
    },
    skip: isNeedVariantMapping,
    fetchPolicy: "no-cache",
  });

  const {
    data: dataPM,
    loading: loadingPM,
    error: errorPM,
  } = useQuery(GET_PRODUCT_MAPPING, {
    variables: {
      id,
    },
    skip: !isNeedVariantMapping || !id,
    fetchPolicy: "no-cache",
  });

  const timeoutRef = React.useRef(null);
  const [
    mappingProductExist,
    {
      data: dataMutation,
      loading: loadingMutation,
      error: errorMutation,
      client,
    },
  ] = useMutation(MUTATE, {
    onCompleted: () => {
      setNotify({
        msg: "Map Order to exist product success",
        err: false,
      });
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onCompleted && onCompleted();
      }, 1000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  // Get data
  useEffect(() => {
    if (canSetExistProduct) {
      setSearch(existProductID);
    }
  }, [existProductID, canSetExistProduct]);
  //
  React.useEffect(() => {
    if (onDismiss) {
      onDismiss(loadingMutation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMutation]);

  const handleSubmit = useCallback(
    (input) => {
      toggleToast && toggleToast();
      setNotify && setNotify({ msg: null, err: false });
      mappingProductExist({
        variables: {
          input,
        },
      }).then((res) => {
        const product = res?.data?.mappingProductExist;
        try {
          const variables = {
            filter: DEFAULT_PRODUCT_LIST_FILTER,
          };
          const cache = client.readQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
          });
          client.writeQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
            data: {
              ...cache,
              products: {
                ...cache.products,
                total: cache.products.total + 1,
                nodes: [product, ...cache.products.nodes],
              },
            },
          });
        } catch (e) {}
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [toggleToast, setNotify, mappingProductExist],
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;
  if (errorPM) return <div>Error: {handleError(errorPM.toString())}</div>;

  return (
    <Container>
      <MapOrderEPFormPolaris
        data={data}
        suggestMappingProduct={suggestMappingProduct}
        changeSearch={(value) => setSearch(value)}
        item={item}
        onSubmit={handleSubmit}
        loadingMutation={loadingMutation}
        loading={loading || loadingPM}
        removeLabel={removeLabel}
        isNeedVariantMapping={isNeedVariantMapping}
        productNeedMapping={dataPM?.getProductMapping}
        existProductID={existProductID}
        canSetExistProduct={canSetExistProduct}
        handleRemoveCurrentProduct={handleRemoveCurrentProduct}
        setSearch={setSearch}
      />
    </Container>
  );
};
