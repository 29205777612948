import React from "react";
import {
    StoreMajorMonotone,
    OrdersMajorMonotone,
    BillingStatementDollarMajorMonotone,
    ReportsMajorMonotone,
    ShipmentMajorMonotone,
    ProductsMajorMonotone,
    PackageMajorMonotone,
    CustomersMajorMonotone,
    SettingsMajorMonotone,
    CustomersMinor,
    CheckoutMajorMonotone,
    ToolsMajorMonotone,
    AppExtensionMinor,
    ListMajorMonotone,
    CategoriesMajorMonotone,
    ImagesMajorMonotone,
    ReportsMajorTwotone,
    DnsSettingsMajorMonotone,
    AppsMajorFilled,
    LegalMajorMonotone,
    BankMajorMonotone,
    CategoriesMajorTwotone
} from "@shopify/polaris-icons";

import TotalOrderMapping from "../components/order/TotalOrderMappingPolaris";

export const ADMIN_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "dashboard",
        icon: BankMajorMonotone
    },
    {
        label: "Users",
        url: "/admin/users",
        key: "users",
        icon: CustomersMajorMonotone,
        subNavigationItems: [
            {
                label: "All Users",
                url: "/admin/users",
            },
            {
                label: "Add New User",
                url: "/admin/users/add",
            },
        ],
    },
    {
        label: "Product Bases",
        icon: ProductsMajorMonotone,
        key: "product-base",
        url: "/admin/product-bases",
        subNavigationItems: [
            // {
            //   label: "Add Product base",
            //   url: "/admin/product-bases/add",
            //   hidden: true,
            // },
            // {
            //   label: "Edit product base",
            //   url: "/admin/product-bases/edit/:id",
            //   hidden: true,
            // },
            {
                label: "All Product Bases",
                url: "/admin/product-bases",
            },
            {
                label: "All Categories ",
                url: "/admin/categories",
            },
        ],
    },

    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/admin/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/admin/orders",
            },
            {
                label: "Supplier Orders",
                url: "/admin/orders/supplier",
            },
            {
                label: "Claims",
                url: "/admin/claims",
            },
        ],
    },
    {
        label: "Sellers",
        url: "/admin/sellers",
        key: "sellers",
        icon: CheckoutMajorMonotone,
        subNavigationItems: [
            {
                label: "All Sellers",
                url: "/admin/sellers",
            },
        ],
    },

    {
        label: "Fulfillment",
        key: "fulfillment",
        icon: CustomersMinor,
        url: "/admin/fulfillment",
        subNavigationItems: [
            {
                label: "Manage Fulfillment",
                url: "/admin/fulfillment",
                key: "fulfillment-manage",
            },
            {
                label: "Manage Suppliers",
                url: "/admin/suppliers",
                key: "suppliers",
            },
        ],
    },

    {
        label: "Billings",
        key: "billings",
        icon: BillingStatementDollarMajorMonotone,
        url: "/admin/billings/overview",
        subNavigationItems: [
            {
                label: "Overview",
                url: "/admin/billings/overview",
            },
            {
                label: "All Deposits",
                url: "/admin/billings/deposits",
            },
            {
                label: "All Transactions",
                url: "/admin/billings/transactions",
            },
        ],
    },
    {
        label: "Manager Files",
        key: "managerFiles",
        icon: CategoriesMajorMonotone,
        url: "/admin/manager-files/import",
        subNavigationItems: [
            {
                label: "Import Files",
                url: "/admin/manager-files/import",
            },
            {
                label: "Export Files",
                url: "/admin/manager-files/export",
            },
            {
                label: "Export Templates",
                url: "/admin/manager-files/export-templates",
            },
        ],
    },
    {
        label: "Reports",
        url: "/admin/reports/amazon",
        key: "reports",
        icon: ReportsMajorMonotone,
        subNavigationItems: [
            // {
            //     label: "Reports",
            //     url: "/admin/reports",
            // },
            {
                label: "Amazon",
                url: "/admin/reports/amazon",
            },
            {
                label: "Facebook MK",
                url: "/admin/reports/facebook-mk",
            },
            {
                label: "Online stores",
                url: "/admin/reports/online-stores",
            },
            {
                label: "Ebay",
                url: "/admin/reports/ebay",
            },
            {
                label: "Etsy",
                url: "/admin/reports/etsy",
            },
            {
                label: "Tiktok",
                url: "/admin/reports/tiktok",
            },
            {
                label: "Dev Statistics",
                url: "/admin/reports/dev-statistics"
            }
        ]
    },
    {
        label: "Carriers",
        key: "carriers",
        icon: ShipmentMajorMonotone,
        url: "/admin/carriers",
        subNavigationItems: [
            {
                label: "All Carriers",
                url: "/admin/carriers",
            },
            {
                label: "Add Carrier",
                url: "/admin/add-carriers",
            },
        ],
    },
    {
        label: "System",
        key: "system",
        icon: SettingsMajorMonotone,
        url: "/admin/system/settings",
        subNavigationItems: [
            {
                label: "Settings",
                url: "/admin/system/settings",
            },
            {
                label: "Cron Jobs",
                url: "/admin/system/cron-jobs",
            },
            {
                label: "Switch Fulfillment",
                url: "/admin/system/switch-fulfillment",
            },
            {
                label: "Private Features",
                url: "/admin/system/private-features"
            },
            {
                label: "Payment Accounts",
                url: "/admin/system/payment-accounts",
            },
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/admin/personalized/artworks",
        key: "personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/admin/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/admin/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/admin/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/admin/documentations",
        key: "documentations",
    },
];

export const SELLER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        icon: BankMajorMonotone,
    },
    {
        label: "Sales Channels",
        url: "/seller/stores",
        icon: StoreMajorMonotone,
        subNavigationItems: [
            {
                label: "Online Stores",
                url: "/seller/stores",
            },
            // {
            //   label: "Add Store",
            //   url: "/seller/store/add",
            // },
            {
                label: "Amazon",
                url: "/seller/stores/amazon",
            },
            // {
            //   label: "Amazon",
            //   url: "/seller/stores/amazon/add",
            //   hidden: true,
            // },
            {
                label: "Ebay",
                url: "/seller/stores/ebay",
            },
            {
                label: "Etsy",
                url: "/seller/stores/etsy",
            },
            {
                label: "Facebook Marketplace",
                url: "/seller/stores/fb-marketplace",
                private: true,
            },
            {
                label: "Tiktok",
                url: "/seller/stores/tiktok",
            },
            {
                label: "Group",
                url: "/seller/stores/groups",
            },
        ],
    },
    {
        label: "Product Bases",
        url: "/seller/product-bases",
        key: "seller-product-bases",
        icon: CategoriesMajorTwotone,
        subNavigationItems: [
            {
                label: "All Product Bases",
                url: "/seller/product-bases"
            },
            {
                label: "All Categories ",
                url: "/seller/categories",
            },
        ]
    },
    {
        label: "Products",
        url: "/seller/products",
        key: "seller-products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/seller/products",
            },
            {
                label: "Templates",
                url: "/seller/templates",
            },
            {
                label: "Collections",
                url: "/seller/collections",
            },
            {
                label: "Tags",
                url: "/seller/tags",
            },
            {
                label: "Push Products",
                url: "/seller/products/product-push",
            },
            {
                label: "Product Feeds",
                url: "/seller/products/feeds",
            },
            {
                label: "Replace Product Feed",
                url: "/seller/products/replace-feed"
            },
            {
                label: "UPCs Management",
                url: "/seller/upcs",
                private: true,
            },
            {
                label: "Product Divisions",
                url: "/seller/product-divisions",
            },
            {
                label: "Products Ignored",
                url: "/seller/products-ignored",
                private: true,
            },
            // {
            //   label: "Add Product Feed",
            //   url: "/seller/products/feeds/add",
            //   hidden: true
            // },
            // {
            //   hidden: true,
            //   label: "Edit Product Campaign",
            //   url: "/seller/products/campaign/:id"
            // }
            {
                label: "Export for Amazon",
                url: "/seller/export-amazon",
            },
            {
                label: "Re-Mockup Conditions",
                url: "/seller/re-mockups",
                private: true,
            },
            {
                label: "Re-Mockup Tasks",
                url: "/seller/re-mockups-tasks",
                private: true,
            },
            {
                label: "Product by Title",
                url: "/seller/product-duplicate",
                private: true,
            },
            {
                label: "Product from Print File",
                url: "/seller/product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/seller/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/seller/orders",
                // onClick: (e) => {
                //   history.push('/seller/orders')
                //   e.preventdefault();
                // }
            },
            {
                label: "Mapping Orders",
                url: "/seller/mapping",
                badge: <TotalOrderMapping />,
                // onClick: (e) => {
                //   history.push('/seller/mapping')
                //   e.preventdefault();
                // }
            },
            {
                label: "Claims",
                url: "/seller/claims",
                // onClick: (e) => {
                //   history.push('/seller/claims')
                //   e.preventdefault();
                // }
            },
            {
                label: "Export Tracking",
                url: "/seller/export-tracking",
            },
            {
                label: "Export Tracking History",
                url: "/seller/export-tracking-history",
            },
            {
                label: "Create Manual Order",
                url: "/seller/create-manual-order",
            },
            {
                label: "Switch Store for Order",
                url: "/seller/switch-store-for-order",
                private: true,
            },
            {
                label: "Import Tracking",
                url: "/seller/imports",
            },
            {
                label: "Export Order Files",
                url: "/seller/export-files",
            },
            {
                label: "Export Order Templates",
                url: "/seller/manager-files/export-templates",
            },
            {
                label: "Order Stats",
                url: "/seller/order-stats",
            },
        ],
    },
    {
        label: "Members",
        icon: CustomersMajorMonotone,
        url: "/seller/team-members",
        key: "team-members",
        subNavigationItems: [
            {
                label: "All Members",
                url: "/seller/team-members",
            },
            {
                label: "Add Member",
                url: "/seller/add-team-member",
            },
            {
                label: "Design Online Teams",
                url: "/seller/online-design-teams",
            },
            {
                label: "Design Teams",
                url: "/seller/design-teams",
            }
        ],
    },
    {
        label: "Fulfillments",
        icon: AppExtensionMinor,
        url: "/seller/fulfillment",
        key: "fulfillment",
        subNavigationItems: [
            {
                label: "All Fulfillments",
                url: "/seller/fulfillment",
            },
            {
                label: "Switch Fulfillment",
                url: "/seller/fulfillment/switch",
            },
            {
                label: "Switch Fulfillment Order",
                url: "/seller/fulfillment/switch-order",
            },
        ],
    },
    // {
    //   label: "Suppliers",
    //   url: "/seller/suppliers",
    //   key: "suppliers",
    //   icon: CustomersMinor,
    //   subNavigationItems: [
    //     {
    //       label: "All Suppliers",
    //       url: "/seller/suppliers",
    //     },
    //     {
    //       label: "Add Supplier",
    //       url: "/seller/supplier/add",
    //     },
    //   ],
    // },
    {
        label: "Billings",
        key: "seller-billings",
        url: "/seller/billings/deposits",
        icon: BillingStatementDollarMajorMonotone,
        subNavigationItems: [
            {
                label: "All Deposits",
                url: "/seller/billings/deposits",
            },
            {
                label: "Transactions",
                url: "/seller/billings/transactions",
            },
        ],
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/seller/design-tasks",
        key: "design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/seller/design-tasks",
            },
            {
                label: "Idea Tasks",
                url: "/seller/design-tasks/ideas",
            },
            {
                label: "Report by Status",
                url: "/seller/design-tasks/report",
            },
            {
                label: "Statistic",
                url: "/seller/design-tasks/statistic",
            },
        ],
    },
    {
        label: "Reports",
        icon: ReportsMajorTwotone,
        url: "/seller/reports/amazon",
        key: "reports",
        subNavigationItems: [
            {
                label: "Amazon",
                url: "/seller/reports/amazon",
            },
            {
                label: "Facebook MK",
                url: "/seller/reports/facebook-mk",
                private: true,
            },
            {
                label: "Online stores",
                url: "/seller/reports/online-stores",
            },
            {
                label: "Ebay",
                url: "/seller/reports/ebay",
            },
            {
                label: "Etsy",
                url: "/seller/reports/etsy",
            },
            {
                label: "Tiktok",
                url: "/seller/reports/tiktok",
            },
            {
                label: "Marketplace",
                url: "/seller/reports",
                private: true,
            },
            {
                label: "Total Cost",
                url: "/seller/report-total-cost",
            },
        ],
    },
    {
        label: "Tools",
        icon: ToolsMajorMonotone,
        url: "/tools/product-crawlers",
        key: "tools",
        subNavigationItems: [
            {
                label: "Product Crawlers",
                url: "/tools/product-crawlers",
                private: true,
            },
            {
                label: "Product Assortment",
                url: "/tools/product-assortment",
                private: true,
            },
            {
                label: "Complex Assortment",
                url: "/tools/complex-assortment",
                private: true,
            },
            // {
            //   label: "API Keys",
            //   url: "/seller/apis",
            // },
        ],
    },
    // {
    //   label: "Apps",
    //   icon: AppsMajorFilled,
    //   url: "/seller/apps",
    //   key: "apps",
    //   subNavigationItems: [
    //     {
    //       label: "All Apps",
    //       url: "/seller/apps",
    //     },
    //   ],
    // },
    {
        label: "Settings",
        icon: SettingsMajorMonotone,
        url: "/seller/settings",
        key: "settings",
        subNavigationItems: [
            {
                label: "Generals",
                url: "/seller/settings",
            },
            {
                label: "API Keys",
                url: "/seller/settings/apis",
            },
            {
                label: "Payment Accounts",
                url: "/seller/settings/payment-accounts",
                // private: true,
            },
        ],
    },
    {
        label: "Apps",
        icon: AppsMajorFilled,
        url: "/seller/apps",
        key: "apps",
        subNavigationItems: [
            {
                label: "All Apps",
                url: "/seller/apps",
            },
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/seller/personalized/artworks",
        key: "personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/seller/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/seller/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/seller/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/seller/documentations",
        key: "documentations",
    },
];

export const SUPPORTER_ADMIN_MENU = [
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        url: "/supporter/orders",
        key: "supporter-orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/supporter/orders",
                key: "supporter-orders",
            },
            {
                label: "Supplier Orders",
                url: "/supporter/orders/supplier",
                key: "supporter-order-supplier",
            },
            {
                label: "Claims",
                url: "/supporter/claims",
                key: "supporter-claims",
            },
        ],
    },
    {
        label: "Manager Files",
        icon: CategoriesMajorMonotone,
        url: "/supporter/manager-files/import",
        key: "supporter-manager-files",
        subNavigationItems: [
            {
                label: "Import Files",
                url: "/supporter/manager-files/import",
                key: "supporter-manager-files-import",
            },
            {
                label: "Export Files",
                url: "/supporter/manager-files/export",
                key: "supporter-manager-files-export",
            },
            // {
            //   label: "Export Templates",
            //   url: "/admin/manager-files/export-templates",
            // },
        ],
    },
];

export const STORE_MANAGER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        icon: BankMajorMonotone,
    },
    {
        label: "Stores",
        icon: StoreMajorMonotone,
        url: "/store-manager/stores",
        key: "stores",
        subNavigationItems: [
            {
                label: "All Stores",
                url: "/store-manager/stores",
            },
            {
                label: "Facebook Marketplace",
                url: "/store-manager/stores/fb-marketplace",
                private: true,
            },
        ],
    },
    {
        label: "Products",
        url: "/store-manager/products",
        key: "store-manager-products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/store-manager/products",
            },
            {
                label: "Templates",
                url: "/store-manager/templates",
            },
            {
                label: "Collections",
                url: "/store-manager/collections",
            },
            {
                label: "Tags",
                url: "/store-manager/tags",
            },
            {
                label: "Push Products",
                url: "/store-manager/products/product-push",
            },
            {
                label: "Product Feeds",
                url: "/store-manager/products/feeds",
            },
            {
                label: "Replace Product Feed",
                url: "/store-manager/products/replace-feed"
            },
            {
                label: "Products Ignored",
                url: "/store-manager/products-ignored",
                private: true,
            },
            // {
            //   label: "Add Product Feed",
            //   url: "/seller/products/feeds/add",
            //   hidden: true,
            // },
            // {
            //   hidden: true,
            //   label: "Edit Product Campaign",
            //   url: "/seller/products/campaign/:id",
            // },
            {
                label: "Export for Amazon",
                url: "/store-manager/export-amazon",
            },
            {
                label: "Product from Print File",
                url: "/store-manager/product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/store-manager/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/store-manager/orders",
            },
            {
                label: "Mapping Orders",
                url: "/store-manager/mapping",
                badge: <TotalOrderMapping />,
            },
            {
                label: "Claims",
                url: "/store-manager/claims",
            },
            {
                label: "Export Tracking",
                url: "/store-manager/export-tracking",
            },
            {
                label: "History Export Tracking",
                url: "/store-manager/history-export-tracking",
            },
            {
                label: "Create Manual Order",
                url: "/store-manager/create-manual-order",
            },
        ],
    },
    // {
    //     label: "Members",
    //     icon: CustomersMajorMonotone,
    //     url: "/store-manager/team-members",
    //     key: "team-members",
    //     subNavigationItems: [
    //         {
    //             label: "All Members",
    //             url: "/store-manager/team-members",
    //         },
    //         {
    //             label: "Add Member",
    //             url: "/store-manager/add-team-member",
    //         },
    //     ],
    // },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/store-manager/design-tasks",
        key: "store-manager_design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/store-manager/design-tasks",
            },
            {
                label: "Idea Tasks",
                url: "/store-manager/design-tasks/ideas",
                key: "store-manager_idea_task",
                _extra: true,
            },
        ],
    },
    {
        label: "Tools",
        icon: ToolsMajorMonotone,
        url: "/tools/product-crawlers",
        key: "tools",
        subNavigationItems: [
            {
                label: "Product Crawlers",
                url: "/tools/product-crawlers",
            },
            {
                label: "Product Assortment",
                url: "/tools/product-assortment",
            },
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/store-manager/personalized/artworks",
        key: "personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/store-manager/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/store-manager/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/store-manager/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/store-manager/documentations",
        key: "documentations",
    },
];

export const SUPPORTER_SELLER_MENU = [
    {
        label: "Products",
        url: "/seller-supporter/products",
        key: "seller-supporter-products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/seller-supporter/products",
                key: "seller-supporter-products",
            },
            {
                label: "Templates",
                url: "/seller-supporter/templates",
                key: "seller-supporter-templates",
            },
            {
                label: "Collections",
                url: "/seller-supporter/collections",
                key: "seller-supporter-collections",
            },
            {
                label: "Tags",
                url: "/seller-supporter/tags",
                key: "seller-supporter-tags",
            },
            {
                label: "Push Products",
                url: "/seller-supporter/products/product-push",
                key: "seller-supporter-products-product-push",
                _extra: true
            },
            {
                label: "Products Ignored",
                url: "/seller-supporter/products-ignored",
                key: "seller-supporter-products-ignored",
                private: true,
            },
            {
                label: "Product from Print File",
                url: "/seller-supporter/product-from-print-file",
                key: "seller-supporter-product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "seller-supporter-orders",
        url: "/seller-supporter/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/seller-supporter/orders",
                key: "seller-supporter-orders",
            },
            {
                label: "Mapping Orders",
                url: "/seller-supporter/mapping",
                badge: <TotalOrderMapping />,
                key: "seller-supporter-mapping",
            },
            {
                label: "Claims",
                url: "/seller-supporter/claims",
                key: "seller-supporter-claims",
            },
            {
                label: "Create Manual Order",
                url: "/seller-supporter/create-manual-order",
                key: "seller-supporter-create-manual-order",
            },
        ],
    },
    {
        label: "Tools",
        icon: ToolsMajorMonotone,
        url: "/tools/product-assortment",
        key: "tools",
        subNavigationItems: [
            {
                label: "Product Assortment",
                url: "/tools/product-assortment",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/seller-supporter/documentations",
        key: "seller-supporter-documentations",
    }
];

export const TEAM_SELLER_MENU = [
    {
        label: "Stores",
        url: "/admin/dashboard",
        key: "stores",
        icon: StoreMajorMonotone,
        subNavigationItems: [
            {
                title: "Add Store",
                url: "/seller/store/add",
                // icon: "skin",
            },
            {
                title: "All Stores",
                url: "/seller/stores",
                // icon: "shopping-cart",
            },
        ],
    },
    {
        label: "Product Bases",
        icon: PackageMajorMonotone,
        key: "product-base",
        subNavigationItems: [
            {
                label: "Add New",
                url: "/seller/product-bases/add",
                icon: "skin",
            },
            {
                label: "Product Bases",
                url: "/seller/product-bases",
                icon: "shopping-cart",
            },
        ],
    },
    {
        label: "Products",
        url: "/seller/products",
        key: "seller-products",
        icon: PackageMajorMonotone,
        subNavigationItems: [
            // {
            //   label: "Add Product",
            //   url: "/seller/products/add",
            //   hidden: true,
            // },
            // {
            //   label: "Add Product Campaign",
            //   url: "/seller/products/add-campaign",
            //   hidden: true,
            // },
            // {
            //   label: "Edit Product",
            //   url: "/seller/products/edit/:id",
            //   hidden: true,
            // },
            {
                label: "All Products",
                url: "/seller/products",
            },
            {
                label: "Add Product",
                url: "/seller/product/add-product",
            },
            {
                label: "Add Campaign",
                url: "/seller/product/add-campaign",
            },
            {
                label: "Collections",
                url: "/seller/product/add-collections",
            },
            {
                label: "Tags",
                url: "/seller/product/add-tags",
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/seller/orders",
                // icon: "skin",
            },
            {
                label: "Claims",
                url: "/seller/claims",
                // icon: "shopping-cart",
            },
        ],
    },
    {
        label: "Members",
        icon: CustomersMajorMonotone,
        key: "team-members",
        subNavigationItems: [
            {
                label: "Add Team Member",
                url: "/seller/add-team-member",
            },
            {
                label: "All Team Member",
                url: "/seller/team-members",
            },
        ],
    },
    {
        label: "Suppliers",
        url: "/seller/suppliers",
        key: "suppliers",
        icon: CustomersMinor,
    },
    {
        label: "Billings",
        key: "seller-billings",
        icon: BillingStatementDollarMajorMonotone,
        url: "/seller/billings/deposits",
        subNavigationItems: [
            {
                label: "All Deposits",
                url: "/seller/billings/deposits",
            },
            {
                label: "Transactions",
                url: "/seller/billings/transactions",
            },
        ],
    },
    {
        label: "Tools",
        icon: ToolsMajorMonotone,
        key: "tools",
        subNavigationItems: [
            {
                label: "Product Crawlers",
                url: "/tools/product-crawlers",
            },
            {
                label: "Product Assortment",
                url: "/tools/product-assortment",
            },
        ],
    },
];

export const SUPPLIER_MENU = [
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/supplier/orders",
        subNavigationItems: [
            {
                label: "All Orders",
                url: "/supplier/orders",
            },
            {
                label: "Claims",
                url: "/supplier/claims",
            },
            {
                label: "Import Tracking",
                url: "/supplier/import-tracking",
            },
        ],
    },
    {
        label: "Billings",
        key: "supplier-billings",
        icon: BillingStatementDollarMajorMonotone,
        url: "/supplier/billings/deposits",
        subNavigationItems: [
            {
                label: "All Deposits",
                url: "/supplier/billings/deposits",
            },
            {
                label: "Transactions",
                url: "/supplier/billings/transactions",
            },
        ],
    },
    {
        label: "Manager Files",
        key: "manager-files",
        icon: CategoriesMajorMonotone,
        url: "/supplier/imports",
        subNavigationItems: [
            {
                label: "Import Files",
                url: "/supplier/imports",
            },
            {
                label: "Exports Files",
                url: "/supplier/export-tracking",
            },
            {
                label: "Export Templates",
                url: "/supplier/manager-files/export-templates",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/supplier/documentations",
        key: "documentations",
    },
];

export const DESIGNER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "designer_dashboard",
        icon: BankMajorMonotone,
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/designer/design-tasks",
        key: "designer_design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/designer/design-tasks",
                key: "designer_design-tasks",
                _extra: true,
            },
            {
                label: "Idea Tasks",
                url: "/designer/design-tasks/ideas",
                key: "designer_idea-tasks",
                _extra: true,
            },
        ],
    },
    {
        label: "Products",
        url: "/designer/products",
        key: "designer_products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/designer/products",
                key: "designer_products-all",
            },
            {
                label: "Templates",
                url: "/designer/templates",
                key: "designer_templates",
            },
            {
                label: "Product from Print File",
                url: "/designer/product-from-print-file",
                key: "designer_product-from-print-file",
                private: true,
            },
            {
                label: "Re-Mockup Tasks",
                url: "/designer/re-mockups-tasks",
                key: "designer_re-mockups",
                private: true,
            },
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/designer/personalized/artworks",
        key: "designer_personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/designer/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/designer/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/designer/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/designer/documentations",
        key: "designer_documentations",
    },
];

export const DESIGN_LEADER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "design-leader_dashboard",
        icon: BankMajorMonotone,
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/design-leader/design-tasks",
        key: "design-leader_design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/design-leader/design-tasks",
                _extra: true
            },
            {
                label: "Idea Tasks",
                url: "/design-leader/design-tasks/ideas",
                _extra: true
            },
            {
                label: "Report by Status",
                url: "/design-leader/design-tasks/report",
            },
            {
                label: "Statistic",
                url: "/design-leader/design-tasks/statistic",
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "design-leader_orders",
        url: "/design-leader/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/design-leader/orders",
            },
            {
                label: "Mapping Orders",
                url: "/design-leader/mapping",
                key: "design-leader-mapping",
                badge: <TotalOrderMapping />,
                _extra: true
            },
        ]
    },
    {
        label: "Products",
        url: "/design-leader/re-mockups-tasks",
        key: "design-leader_re-mockups",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "Re-Mockup Tasks",
                url: "/design-leader/re-mockups-tasks",
                private: true,
            },
        ],
    },
    {
        label: "Products",
        url: "/design-leader/products",
        key: "design-leader_products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/design-leader/products",
                key: "design-leader_products-all",
            },
            {
                label: "Templates",
                url: "/design-leader/templates",
                key: "design-leader_templates",
            },
            {
                label: "Product from Print File",
                url: "/design-leader/product-from-print-file",
                key: "design-leader_product-from-print-file",
                private: true,
            },
            {
                label: "Re-Mockup Tasks",
                url: "/design-leader/re-mockups-tasks",
                key: "design-leader_re-mockups",
                private: true,
            },
        ],
    },
    {
        label: "Media",
        icon: ImagesMajorMonotone,
        url: "/design-leader/media",
        key: "design-leader_media",
    },
    {
        label: "Members",
        icon: CustomersMajorMonotone,
        url: "/design-leader/team-members",
        key: "design-leader_team-members",
        subNavigationItems: [
            {
                label: "All Members",
                url: "/design-leader/team-members",
            },
            {
                label: "Add Member",
                url: "/design-leader/add-team-member",
            },
            {
                label: "Design Online Teams",
                url: "/design-leader/online-design-teams",
            }
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/design-leader/personalized/artworks",
        key: "design-leader_personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/design-leader/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/design-leader/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/design-leader/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/design-leader/documentations",
        key: "design-leader_documentations",
    },
];

export const MARKETPLACE_MANAGER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        icon: BankMajorMonotone,
        subNavigationItems: [
            // {
            //   label: "Overviews",
            //   url: "/",
            // },
            // {
            //     label: "Amazon",
            //     url: "/marketplace-manager/reports/amazon",
            // },
            // {
            //     label: "Facebook MK",
            //     url: "/marketplace-manager/reports/facebook-mk",
            //     private: true,
            // },
            // {
            //     label: "Ebay",
            //     url: "/marketplace-manager/reports/ebay",
            // },
            // {
            //     label: "Etsy",
            //     url: "/marketplace-manager/reports/etsy",
            // },
        ],
    },
    {
        label: "Sales Channels",
        url: "/marketplace-manager/stores/amazon",
        icon: StoreMajorMonotone,
        subNavigationItems: [
            {
                label: "Amazon",
                url: "/marketplace-manager/stores/amazon",
            },
            {
                label: "Ebay",
                url: "/marketplace-manager/stores/ebay",
            },
            {
                label: "Etsy",
                url: "/marketplace-manager/stores/etsy",
            },
            {
                label: "Facebook Marketplace",
                url: "/marketplace-manager/stores/fb-marketplace",
                private: true,
            },
            {
                label: "Tiktok",
                url: "/marketplace-manager/stores/tiktok",
            },
            {
                label: "Group",
                url: "/marketplace-manager/stores/groups",
            },
        ],
    },
    {
        label: "Products",
        url: "/marketplace-manager/products",
        key: "marketplace-manager-products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/marketplace-manager/products",
            },
            {
                label: "Templates",
                url: "/marketplace-manager/templates",
            },
            {
                label: "Collections",
                url: "/marketplace-manager/collections",
            },
            {
                label: "Tags",
                url: "/marketplace-manager/tags",
            },
            {
                label: "Product Divisions",
                url: "/marketplace-manager/product-divisions",
            },
            {
                label: "Products Ignored",
                url: "/marketplace-manager/products-ignored",
                private: true,
            },
            {
                label: "Export for Amazon",
                url: "/marketplace-manager/export-amazon",
            },
            {
                label: "Product from Print File",
                url: "/marketplace-manager/product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/marketplace-manager/orders",
        subNavigationItems: [
            {
                label: "Orders",
                url: "/marketplace-manager/orders",
            },
            {
                label: "Mapping Orders",
                url: "/marketplace-manager/mapping",
                badge: <TotalOrderMapping />,
            },
            {
                label: "Claims",
                url: "/marketplace-manager/claims",
            },
            {
                label: "Export Tracking",
                url: "/marketplace-manager/export-tracking",
            },
            {
                label: "History Export Tracking",
                url: "/marketplace-manager/history-export-tracking",
            },
            {
                label: "Create Manual Order",
                url: "/marketplace-manager/create-manual-order",
            },
            {
                label: "Switch Store for Order",
                url: "/marketplace-manager/switch-store-for-order",
                private: true,
            }
        ],
    },
    {
        label: "Members",
        icon: CustomersMajorMonotone,
        url: "/marketplace-manager/team-members",
        key: "team-members",
        subNavigationItems: [
            {
                label: "All Members",
                url: "/marketplace-manager/team-members",
            },
            {
                label: "Add Member",
                url: "/marketplace-manager/add-team-member",
            },
            {
                label: "Design Online Teams",
                url: "/marketplace-manager/online-design-teams",
            }
        ],
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/marketplace-manager/design-tasks",
        key: "marketplace-manager_design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/marketplace-manager/design-tasks",
            },
            {
                label: "Idea Tasks",
                url: "/marketplace-manager/design-tasks/ideas",
                key: "marketplace-manager_idea_task",
                _extra: true,
            },
        ],
    },
    {
        label: "Tools",
        icon: ToolsMajorMonotone,
        url: "/tools/product-crawlers",
        key: "tools",
        subNavigationItems: [
            {
                label: "Product Crawlers",
                url: "/tools/product-crawlers",
            },
            {
                label: "Product Assortment",
                url: "/tools/product-assortment",
            },
        ],
    },
    {
        label: "Settings",
        icon: SettingsMajorMonotone,
        url: "/marketplace-manager/settings/apis",
        key: "settings",
        subNavigationItems: [
            {
                label: "API Keys",
                url: "/marketplace-manager/settings/apis",
            },
        ],
    },
    {
        label: "Personalized",
        icon: DnsSettingsMajorMonotone,
        url: "/marketplace-manager/personalized/artworks",
        key: "personalized",
        subNavigationItems: [
            {
                label: "Artworks",
                url: "/marketplace-manager/personalized/artworks",
            },
            {
                label: "Cliparts",
                url: "/marketplace-manager/personalized/cliparts",
            },
            {
                label: "Fonts",
                url: "/marketplace-manager/personalized/fonts",
            },
        ],
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/marketplace-manager/documentations",
        key: "documentations",
    },
];

export const IDEA_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "idea_dashboard",
        icon: BankMajorMonotone,
    },
    {
        label: "Products",
        url: "/idea/products",
        key: "idea_products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/idea/products",
                key: "idea_products-all",
            },
            {
                label: "Templates",
                url: "/idea/templates",
                key: "idea_templates",
            },
            {
                label: "Product from Print File",
                url: "/idea/product-from-print-file",
                key: "idea_product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        url: "/idea/orders",
        key: "idea_orders",
        subNavigationItems: [
            {
                label: "All Orders",
                url: "/idea/orders",
                key: "idea_orders-all",
            },
        ],
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/idea/tasks",
        key: "idea_tasks",
        subNavigationItems: [
            {
                label: "Idea Tasks",
                url: "/idea/tasks",
            },
        ],
    },
    {
        label: "Product Assortment",
        icon: ToolsMajorMonotone,
        url: "/idea/product-assortment",
        key: "idea_product-assortment",
        subNavigationItems: [
            {
                label: "Product Assortment",
                url: "/idea/product-assortment",
                key: "idea_product-assortment",
            },
        ],
    },
];

export const IDEA_LEADER_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "idea-leader_dashboard",
        icon: BankMajorMonotone,
    },
    {
        label: "Products",
        url: "/idea-leader/products",
        key: "idea-leader_products",
        icon: ProductsMajorMonotone,
        subNavigationItems: [
            {
                label: "All Products",
                url: "/idea-leader/products",
                key: "idea-leader_products-all",
            },
            {
                label: "Templates",
                url: "/idea-leader/templates",
                key: "idea-leader_templates",
            },
            {
                label: "Product from Print File",
                url: "/idea-leader/product-from-print-file",
                key: "idea-leader_product-from-print-file",
                private: true,
            },
        ],
    },
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        url: "/idea-leader/orders",
        key: "idea-leader_orders",
        subNavigationItems: [
            {
                label: "All Orders",
                url: "/idea-leader/orders",
                key: "idea-leader_orders-all",
            },
        ],
    },
    {
        label: "Members",
        icon: CustomersMajorMonotone,
        url: "/idea-leader/team-members",
        key: "idea-leader_team-members",
        subNavigationItems: [
            {
                label: "All Members",
                url: "/idea-leader/team-members",
                key: "idea-leader_team-members",
            },
            {
                label: "Add Member",
                url: "/idea-leader/add-team-member",
                key: "idea-leader_add-team-member",
            },
        ],
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/idea-leader/tasks",
        key: "idea-leader_tasks",
        subNavigationItems: [
            {
                label: "Idea Tasks",
                url: "/idea-leader/tasks",
                key: "idea-leader_tasks",
            },
        ],
    },
    {
        label: "Product Assortment",
        icon: ToolsMajorMonotone,
        url: "/idea-leader/product-assortment",
        key: "idea-leader_product-assortment",
        subNavigationItems: [
            {
                label: "Product Assortment",
                url: "/idea-leader/product-assortment",
                key: "idea-leader_product-assortment",
            },
        ],
    },
];

export const SELLER_SUPPLIER_MENU = [
    {
        label: "Orders",
        icon: OrdersMajorMonotone,
        key: "orders",
        url: "/supplier/orders",
        subNavigationItems: [
            {
                label: "All Orders",
                url: "/supplier/orders"
            },
            {
                label: "Import Tracking",
                url: "/supplier/import-tracking"
            }
        ]
    },
    // {
    //     label: "Billings",
    //     key: "supplier-billings",
    //     icon: BillingStatementDollarMajorMonotone,
    //     url: "/supplier/billings/deposits",
    //     subNavigationItems: [
    //         {
    //             label: "All Deposits",
    //             url: "/supplier/billings/deposits",
    //         },
    //         {
    //             label: "Transactions",
    //             url: "/supplier/billings/transactions",
    //         },
    //     ],
    // },
    {
        label: "Manager Files",
        key: "manager-files",
        icon: CategoriesMajorMonotone,
        url: "/supplier/imports",
        subNavigationItems: [
            {
                label: "Import Files",
                url: "/supplier/imports"
            },
            {
                label: "Exports Files",
                url: "/supplier/export-tracking",
            },
            {
                label: "Export Templates",
                url: "/supplier/manager-files/export-templates",
            },
        ]
    },
    {
        label: "Documentations",
        icon: LegalMajorMonotone,
        url: "/supplier/documentations",
        key: "documentations",
    },
]


export const ACCOUNTANT_MENU = [
    {
        label: "Dashboard",
        url: "/",
        key: "dashboard",
        icon: BankMajorMonotone
    },
    {
        label: "Tasks",
        icon: ListMajorMonotone,
        url: "/seller-accountant/design-tasks",
        key: "design-tasks",
        subNavigationItems: [
            {
                label: "Design Tasks",
                url: "/seller-accountant/design-tasks",
            }
        ],
    },
    {
        label: "Reports",
        url: "/seller-accountant/reports/amazon",
        key: "reports",
        icon: ReportsMajorMonotone,
        subNavigationItems: [
            {
                label: "Amazon",
                url: "/seller-accountant/reports/amazon",
            },
            {
                label: "Facebook MK",
                url: "/seller-accountant/reports/facebook-mk",
            },
            {
                label: "Online stores",
                url: "/seller-accountant/reports/online-stores",
            },
            {
                label: "Ebay",
                url: "/seller-accountant/reports/ebay",
            },
            {
                label: "Etsy",
                url: "/seller-accountant/reports/etsy",
            },
            {
                label: "Tiktok",
                url: "/seller-accountant/reports/tiktok",
            },
        ]
    },
]