import { gql } from "apollo-boost";

const PRODUCT_BASE_TIME_LINE = gql`
  query productBaseTimeline($id: ID!) {
    productBaseTimeline(id: $id) {
      user {
        lastName
        firstName
      }
      createdAt
      data
    }
  }
`;

const BaseFragment = gql`
  fragment BaseFragment on ProductBase {
    id
    title
    teamId
    variants {
      id
      sorting
      regularPrice
      salePrice
      attributes {
        name
        slug
        option
      }
    }
  }
`;

const PRODUCT_BASES_FOR_SELLER = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        ...BaseFragment
      }
    }
  }
  ${BaseFragment}
`;

const PRODUCT_BASE_BY_ID = gql`
  query productBaseByID($id: ID!) {
    productBaseByID(id: $id) {
      ...BaseFragment
    }
  }
  ${BaseFragment}
`;

const GET_STATE_BY_PRODUCT_BASE_IDS = gql`
  query getStateByProductBaseIDs($filter: StateByProductBaseFilter!) {
    getStateByProductBaseIDs(filter: $filter) {
      baseID
      states {
        state
        totalSale
        totalBaseCost
        totalRevenue
      }
    }
  }
`;

export {
  PRODUCT_BASE_TIME_LINE,
  PRODUCT_BASES_FOR_SELLER,
  PRODUCT_BASE_BY_ID,
  GET_STATE_BY_PRODUCT_BASE_IDS,
};
