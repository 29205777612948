import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Collapsible } from "@shopify/polaris";
import { Button, Card, Checkbox, Input, InputNumber, Table, Form } from "antd";
import update from "immutability-helper";
import _, { sum } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import styled from "styled-components";
import { ADDITIONAL_PRICE_TYPE } from "../../components/base/ProductBaseForm";
import ProductBaseVariantCarrierPricing from "../../components/base/ProductBaseVariantCarrierPricing";
import ProductBaseVariantQuickEdit from "../../components/base/ProductBaseVariantQuickEdit";
import ProductBaseVariantSupplierPricing from "../../components/base/ProductBaseVariantSupplierPricing";
import Price from "../../components/Price";
import { ICOM_TEAM_ID } from "../../constants";
import { AppContext } from "../../context";
import { checkRole, getTeamID, NumberToFixed } from "../../helper";

const Container = styled.div`
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  .ant-legacy-form-item {
    margin-bottom: 0;
    margin-top: 0 !important;
  }
`;

const type = "DragbleBodyRow";
const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      if (moveRow) moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

class ProductBaseVariants extends Component {
  static contextType = AppContext;
  state = {
    selectedRowKeys: [],
    loading: false,
    value: this.props.value,
    open: true,
  };
  getVariantName = (item) => {
    let options = item.attributes.map((attr) => attr.option);
    return options.join("/");
  };

  onChangeTime = React.createRef(null);
  onChange = (value) => {
    this.setState({ value });

    this.onChangeTime.current && clearTimeout(this.onChangeTime.current)
    this.onChangeTime.current = setTimeout(() => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }, 500)
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(nextProps, this.props) ||
      !_.isEqual(nextState, this.state)
    ) {
      return true;
    }
    return false;
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { value } = this.state;

    if (!value) return;
    const dragRow = value[dragIndex];

    this.setState(
      update(this.state, {
        value: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.value);
        }
      },
    );
  };

  handleUsingAdditional = (value) => {
    const { checked } = value?.target;

    const { form } = this.props;
    if (!form) return;
    let variants = form.getFieldValue("variants");
    let carrierPricing = form.getFieldValue("carrierPricing");

    if (checked != null) {
      const newItem = {
        usingAdditionalPrice: checked,
        additionalPriceType: undefined,
        additionalPrice: undefined,
      };
      if (checked) {
        newItem.additionalPriceType = ADDITIONAL_PRICE_TYPE;
        newItem.additionalPrice = null;
      }

      if (carrierPricing?.length > 0) {
        carrierPricing = carrierPricing.map((c) => ({ ...c, ...newItem }));
      }

      if (variants?.length > 0) {
        variants = variants.map((v) => {
          let { carrierPricing } = v || {};
          if (carrierPricing?.length > 0) {
            carrierPricing = carrierPricing.map((c) => ({ ...c, ...newItem }));
          }

          return {
            ...v,
            carrierPricing,
          };
        });
      }
    }

    form.setFieldsValue({
      variants,
      carrierPricing,
    });
  };

  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const { value, open } = this.state;
    const {
      suppliers,
      carriers,
      hasProduct,
      hasFulfillment,
      form,
      hasAdditionalShipping,
      isCustom,
      isMerchize,
      isPrintway,

      isNewForm,
    } = this.props;

    const { currentUser } = this.context;
    const { isSeller } = checkRole(currentUser);
    const teamId = getTeamID(currentUser);
    const isTeamICom = ICOM_TEAM_ID === teamId;

    let columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
        width: 60,
      },
      {
        title: "Variant",
        key: "variants",
        dataIndex: "variants",
        render: (_, record) => <span>{this.getVariantName(record)}</span>,
        width: 70,
      },
      {
        title: "Regular Price",
        key: "regular",
        dataIndex: "regular",
        render: (_, record, index) => {
          let newRegularPrice = record.regularPrice;
          if (!this.props.isEditProductBase) {
            if (newRegularPrice === 0 && record.sellerPrice) {
              newRegularPrice = NumberToFixed(record.sellerPrice / 0.35, 2);
              value[index].regularPrice = parseFloat(newRegularPrice);
              this.onChange(value);
            }
          }

          return (
            <Price
              onChange={(newValue) => {
                value[index].regularPrice = newValue;
                this.onChange(value);
              }}
              value={newRegularPrice}
            />
          );
        },
        width: 140,
      },
      {
        title: "Sale Price",
        key: "sale",
        dataIndex: "sale",
        render: (_, record, index) => (
          <Price
            onChange={(newValue) => {
              value[index].salePrice = newValue;
              this.onChange(value);
            }}
            value={record.salePrice}
          />
        ),
        width: 140,
      },
      !isSeller &&
        !isCustom && {
          title: "Seller Price",
          key: "seller",
          dataIndex: "seller",
          render: (_, record, index) => (
            <Price
              onChange={(newValue) => {
                value[index].sellerPrice = newValue;
                this.onChange(value);
              }}
              value={record.sellerPrice}
            />
          ),
          width: 140,
        },
    ].filter(Boolean);

    if (!hasFulfillment || isCustom) {
      columns = [
        ...columns,
        ...[
          {
            title: "Supplier Price",
            key: "supplier",
            render: (_, record, index) => (
              <ProductBaseVariantSupplierPricing
                onChange={(newValue) => {
                  value[index].supplierPricing = newValue;
                  if (isSeller && newValue?.[0]?.price != null) {
                    value[index].sellerPrice = newValue[0].price;
                  }
                  this.onChange(value);
                }}
                suppliers={suppliers}
                value={record.supplierPricing}
              />
            ),
            width: 200,
          },
          {
            title: "Carrier Price",
            key: "carrier",
            render: (_, record, index) => (
              <ProductBaseVariantCarrierPricing
                value={record.carrierPricing || 0}
                carriers={carriers}
                onChange={(newValue) => {
                  //value[index].carrierPricing = newValue;
                  this.onChange(
                    value.map((vv, i) => {
                      if (i === index) {
                        return { ...vv, carrierPricing: newValue };
                      }
                      return vv;
                    }),
                  );
                }}
              />
            ),
            width: 500,
          },
        ],
      ];
    } else {
      columns.push({
        title: "Fulfillment product id",
        key: "fulfillment-product",
        render: (_, record, index) => (
          <Input
            disabled={!!value[index].disabledFPI}
            onChange={(e) => {
              value[index].fulfillmentProductId = e.target.value;
              this.onChange(value);
            }}
            value={record.fulfillmentProductId}
          />
        ),
        width: 200,
      });
      if (this.props.isCustomcat) {
        columns.push(
          {
            title: "Shipping cost",
            key: "shipping-cost",
            render: (_, record, index) => (
              <InputNumber
                min={0}
                onChange={(e) => {
                  value[index].fulfillmentShippingCost = e;
                  this.onChange(value);
                }}
                value={record.fulfillmentShippingCost}
              />
            ),
            width: 200,
          },
          {
            title: "Shipping international cost",
            key: "shipping-international-cost",
            render: (_, record, index) => (
              <InputNumber
                min={0}
                onChange={(e) => {
                  value[index].fulfillmentShippingInternationalCost = e;
                  this.onChange(value);
                }}
                value={record.fulfillmentShippingInternationalCost}
              />
            ),
            width: 200,
          },
        );
      }
      if (isMerchize || isPrintway) {
        columns.push({
          title: "Fulfillment SKU",
          key: "fulfillment-sku",
          render: (_, record, index) => {
            if (isTeamICom) {
              return (
                <Input
                  onChange={(e) => {
                    value[index].fulfillmentSku = e.target.value;
                    this.onChange(value);
                  }}
                  value={record.fulfillmentSku}
                />
              );
            }
            return (
              !!record.fulfillmentSku && (
                <Input disabled value={record.fulfillmentSku} />
              )
            );
          },
          width: 200,
        });
      }
    }

    columns.push({
      title: "",
      key: "action",
      render: (_, __, index) => (
        <Button
          disabled={hasProduct}
          onClick={() => {
            value.splice(index, 1);
            this.onChange(value);
          }}
          type={"link"}
          icon={<LegacyIcon type={"delete"} />}
        />
      ),
      width: 50,
    });

    const tableWidth = sum(columns.map((c) => c.width));
    const { getFieldDecorator } = form || {};
    return (
      <Container>
        <Card
          style={{ width: "100%" }}
          title={
            <span>
              <span
                className="control-collapse"
                onClick={this.toggleOpen}
                style={{ cursor: "pointer" }}
              >
                <LegacyIcon type={open ? "caretDown" : "caretRight"} />
                <span style={{ marginRight: 20, marginLeft: 5 }}>Preview</span>
              </span>
              {!hasFulfillment ? (
                isNewForm ? (
                  <Form.Item name="hasAdditionalShipping">
                    <SubTitle
                      handleUsingAdditionalChange={
                        this.props.handleUsingAdditionalChange
                      }
                      onChange={this.handleUsingAdditional}
                    />
                  </Form.Item>
                ) : (
                  getFieldDecorator("hasAdditionalShipping", {
                    initialValue: hasAdditionalShipping,
                  })(
                    <SubTitle
                      handleUsingAdditionalChange={
                        this.props.handleUsingAdditionalChange
                      }
                      onChange={this.handleUsingAdditional}
                    />,
                  )
                )
              ) : null}
            </span>
          }
          extra={
            <ProductBaseVariantQuickEdit
              onChange={(newValue) => {
                for (let i = 0; i < selectedRowKeys.length; i++) {
                  const index = selectedRowKeys[i];
                  if (typeof value[index] !== "undefined") {
                    const key = newValue.key;
                    if (key === "supplierPricing") {
                      if (value[index].supplierPricing?.length > 0) {
                        value[index].supplierPricing = value[
                          index
                        ].supplierPricing.map((item) => ({
                          ...item,
                          price: newValue.value,
                        }));
                      }
                    } else {
                      value[index][key] = newValue.value;
                    }
                  }
                }

                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}
              isCustomcat={this.props.isCustomcat}
              disabled={selectedRowKeys.length === 0}
              showSupplierPrice={!hasFulfillment || isCustom}
            />
          }
        >
          <Collapsible
            id="variant-preview"
            open={open}
            transition={{ duration: "150ms", timingFunction: "ease" }}
          >
            <DndProvider backend={HTML5Backend}>
              <Table
                dataSource={[...value]}
                rowKey={(_, index) => index}
                pagination={false}
                columns={columns}
                rowSelection={rowSelection}
                components={this.components}
                scroll={{ x: tableWidth }}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
              />
            </DndProvider>
          </Collapsible>
        </Card>
      </Container>
    );
  }
}

ProductBaseVariants.propTypes = {
  attributes: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  suppliers: PropTypes.array,
  carriers: PropTypes.array,
  hasProduct: PropTypes.bool,
  hasFulfillment: PropTypes.bool,
};

const SubTitle = React.forwardRef(({ value, onChange }, _ref) => {
  return (
    <Checkbox
      children="Using additional shipping price"
      onChange={onChange}
      checked={value}
    />
  );
});

export default ProductBaseVariants;
