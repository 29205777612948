import get from "lodash/get";
import { useMemo } from "react";
import { useAppContext } from "../../../context";
import { arrInvalid, objectInvalid } from "../../../helper";
import {
  Administrator,
  ORDER_STATUS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../../variable";

// const SCREEN_OPTIONS = [
//   { value: "orderId", label: "Order ID", priority: 5, public: true },
//   {
//     value: "originId",
//     label: "Origin ID",
//     priority: 5,
//     public: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
//   },
//   { value: "product", label: "Product", priority: 6, public: true },
//   {
//     value: "designFiles",
//     label: "Design Files",
//     priority: 7,
//     public: isSupplier,
//   },
//   {
//     value: "productBases",
//     label: "Product Bases",
//     priority: 10,
//     public: true,
//   },
//   {
//     value: "productVariant",
//     label: "Attributes",
//     priority: 15,
//     public: true,
//   },
//   {
//     value: "mappingOrder",
//     label: "Mapping Order",
//     priority: 16,
//     public: true,
//   },
//   { value: "buyerName", label: "Buyer Name", priority: 19, public: true },
//   { value: "qty", label: "Qty", priority: 20, public: true },
//   { value: "cost", label: "Cost", priority: 25, public: true },
//   {
//     value: "shippingCost",
//     label: "Shipping Cost",
//     priority: 30,
//     public:
//       !userRole.includes(USER_ROLE.Supplier) || ![TEAM_ROLE].includes(teamRole),
//   },
//   {
//     value: "revenue",
//     label: "Revenue",
//     priority: 35,
//     public: [TEAM_ROLE.Admin].includes(role) || teamSellerLead,
//   },
//   {
//     value: "teamName",
//     label: "Team Name",
//     priority: 36,
//     public: isAdministrator,
//   },
//   {
//     value: "store",
//     label: "Store",
//     priority: 40,
//     public: [USER_ROLE.Admin, USER_ROLE.Seller].includes(role),
//   },
//   {
//     value: "storeManager",
//     label: "Store Manager",
//     priority: 45,
//     public: [TEAM_ROLE.Admin].includes(role) || teamSellerLead,
//   },
//   {
//     value: "fulfillment",
//     label: "Fulfillment",
//     priority: 50,
//     public:
//       !userRole.includes(USER_ROLE.Supplier) ||
//       ![TEAM_ROLE.Supplier].includes(teamRole),
//   },
//   {
//     value: "supplier",
//     label: "Supplier",
//     priority: 55,
//     public: isAdmin || [USER_ROLE.Admin].includes(role),
//   },
//   { value: "carrier", label: "Carrier", priority: 60, public: true },
//   {
//     value: "shippingOption",
//     label: "Shipping Option",
//     priority: 61,
//     public: true,
//   },
//   {
//     value: "printingDate",
//     label: "Printing Date",
//     priority: 65,
//     public:
//       ([USER_ROLE.Supplier].includes(role) ||
//         (isAdmin && "Supplier" === filter.viewAs)) &&
//       [null, ORDER_STATUS.Printing, ORDER_STATUS.Fulfilled].includes(
//         filter.status,
//       ),
//   },
//   { value: "createdAt", label: "Created At", priority: 70, public: true },
//   { value: "tracking", label: "Tracking", priority: 75, public: true },
//   { value: "status", label: "Status", priority: 80, public: true },
//   {
//     value: "taskStatus",
//     label: "Task status",
//     priority: 85,
//     public:
//       [null, ORDER_STATUS.PendingDesign, ORDER_STATUS.InProduction].includes(
//         filter.status,
//       ) && [TEAM_ROLE.Supporter, TEAM_ROLE.Admin].includes(teamRole),
//   },
//   {
//     value: "assignee",
//     label: "Assignee",
//     priority: 86,
//     public:
//       [
//         null,
//         ORDER_STATUS.PendingDesign,
//         ORDER_STATUS.OnHold,
//         ORDER_STATUS.InProduction,
//         ORDER_STATUS.Fulfilled,
//         ORDER_STATUS.DesignRejected,
//         ORDER_STATUS.Error,
//       ].includes(filter.status) && [TEAM_ROLE.Admin].includes(teamRole),
//   },
//   {
//     value: "acceptedBy",
//     label: "Accepted By",
//     priority: 87,
//     public:
//       [
//         null,
//         ORDER_STATUS.OnHold,
//         ORDER_STATUS.InProduction,
//         ORDER_STATUS.Fulfilled,
//         ORDER_STATUS.DesignRejected,
//         ORDER_STATUS.Error,
//       ].includes(filter.status) && [TEAM_ROLE.Admin].includes(teamRole),
//   },
//   {
//     value: "bulkAcceptDesign",
//     label: "Bulk Accept Design",
//     priority: 90,
//     public: [TEAM_ROLE.Admin].includes(role) || teamSellerLead,
//   },
//   {
//     value: "collection",
//     label: "Collection",
//     priority: 95,
//     public: [TEAM_ROLE.Admin].includes(role) || teamSellerLead,
//   },
//   {
//     value: "tag",
//     label: "Tag",
//     priority: 100,
//     public: [TEAM_ROLE.Admin].includes(role) || teamSellerLead,
//   },
// ];

/**
 * case role: Administrator
 *  - menu: supplier orders (viewAs === "Supplier")
 * 
 * /**
    * === Super Admin
    *    role: Admin
          OrdersPolaris.js:534 userRole: ['Administrator']
          OrdersPolaris.js:535 teamRole: null

          === menu supplier orders
            role: Supplier
            OrdersPolaris.js:534 userRole: ['Administrator']
            OrdersPolaris.js:535 teamRole: null


      === Supplier
        role: Supplier
        OrdersPolaris.js:544 userRole: ['Supplier']
        OrdersPolaris.js:545 teamRole: null


    * ==== Seller- Admin:
      *    role: Seller
            OrdersPolaris.js:526 userRole: ['Seller']
            OrdersPolaris.js:527 teamRole: Admin

      === Supplier
            role: Seller
            OrdersPolaris.js:553 userRole: ['Seller']
            OrdersPolaris.js:554 teamRole: Supplier
    * 
 */

const SCREEN_OPTIONS = [
  { value: "orderId", label: "Order ID", priority: 5 },
  {
    value: "originId",
    label: "Origin ID",
    priority: 5,
    roles: [Administrator, USER_ROLE.Seller],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  { value: "product", label: "Product", priority: 6 },
  {
    value: "designFiles",
    label: "Design Files",
    priority: 7,
    roles: [USER_ROLE.Seller],
    teamRoles: [TEAM_ROLE.Supplier],
  },
  {
    value: "productBases",
    label: "Product Bases",
    priority: 10,
  },
  {
    value: "productVariant",
    label: "Attributes",
    priority: 15,
  },
  {
    value: "mappingOrder",
    label: "Mapping Order",
    priority: 16,
  },
  { value: "buyerName", label: "Buyer Name", priority: 19 },
  { value: "qty", label: "Qty", priority: 20 },
  { value: "cost", label: "Cost", priority: 25 },
  {
    value: "shippingCost",
    label: "Shipping Cost",
    priority: 30,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
    ],
  },
  {
    value: "revenue",
    label: "Revenue",
    priority: 35,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
    ],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  {
    value: "teamName",
    label: "Team Name",
    priority: 36,
    roles: [Administrator],
  },
  {
    value: "store",
    label: "Store",
    priority: 40,
    roles: [Administrator, USER_ROLE.Seller],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  {
    value: "storeManager",
    label: "Store Manager",
    priority: 45,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
    ],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  {
    value: "fulfillment",
    label: "Fulfillment",
    priority: 50,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
    ],
  },
  {
    value: "supplier",
    label: "Supplier",
    priority: 55,
    roles: [Administrator],
  },
  { value: "carrier", label: "Carrier", priority: 60 },
  {
    value: "shippingOption",
    label: "Shipping Option",
    priority: 61,
  },
  {
    value: "printingDate",
    label: "Printing Date",
    priority: 65,
    roles: [Administrator, USER_ROLE.Supplier],
    status: [null, ORDER_STATUS.Printing, ORDER_STATUS.Fulfilled],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs === USER_ROLE.Supplier : true,
  },
  { value: "createdAt", label: "Created At", priority: 70 },
  { value: "tracking", label: "Tracking", priority: 75 },
  { value: "status", label: "Status", priority: 80 },
  {
    value: "taskStatus",
    label: "Task status",
    priority: 85,
    status: [null, ORDER_STATUS.PendingDesign, ORDER_STATUS.InProduction],
    teamRoles: [TEAM_ROLE.Supporter, TEAM_ROLE.Admin],
    roles: [USER_ROLE.Seller],
  },
  {
    value: "assignee",
    label: "Assignee",
    priority: 86,
    status: [
      null,
      ORDER_STATUS.PendingDesign,
      ORDER_STATUS.OnHold,
      ORDER_STATUS.InProduction,
      ORDER_STATUS.Fulfilled,
      ORDER_STATUS.DesignRejected,
      ORDER_STATUS.Error,
    ],
    roles: [USER_ROLE.Seller],
    teamRoles: [TEAM_ROLE.Admin],
  },
  {
    value: "acceptedBy",
    label: "Accepted By",
    priority: 87,
    status: [
      null,
      ORDER_STATUS.OnHold,
      ORDER_STATUS.InProduction,
      ORDER_STATUS.Fulfilled,
      ORDER_STATUS.DesignRejected,
      ORDER_STATUS.Error,
    ],
    roles: [USER_ROLE.Seller],
    teamRoles: [TEAM_ROLE.Admin],
  },
  {
    value: "bulkAcceptDesign",
    label: "Bulk Accept Design",
    priority: 90,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
    ],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  {
    value: "collection",
    label: "Collection",
    priority: 95,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
    ],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
  {
    value: "tag",
    label: "Tag",
    priority: 100,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
    ],
    conditional: ({ viewAs, role }) =>
      [Administrator].includes(role) ? viewAs !== USER_ROLE.Supplier : true,
  },
];

export const useScreenOptions = (viewAs, filtlerStatus) => {
  const { currentUser } = useAppContext();
  const userRole = get(currentUser, "roles[0]");
  const teamRole = get(currentUser, "teamUser.role", null);
  const screenOpt = useMemo(() => {
    const res = [];
    for (let item of SCREEN_OPTIONS) {
      if (objectInvalid(item)) continue;

      let roleValid = true;
      let conditionalValid = true;
      let statusValid = true;
      const { roles, teamRoles, status, conditional } = item;
      if (!arrInvalid(roles) && userRole) {
        roleValid = roles.includes(userRole);
      }

      if (!arrInvalid(teamRoles) && teamRole) {
        roleValid = roleValid && teamRoles.includes(teamRole);
      }

      if (!arrInvalid(status) && !["", undefined].includes(filtlerStatus)) {
        statusValid = status.includes(filtlerStatus);
      }

      if (conditional && typeof conditional === "function") {
        conditionalValid = conditional({ viewAs, role: userRole });
      }

      const show = [roleValid, statusValid, conditionalValid].every(Boolean);
      show &&
        res.push({
          label: item.label,
          value: item.value,
          priority: item.priority,
        });
    }

    return res;
  }, [userRole, teamRole, viewAs, filtlerStatus]);

  return screenOpt;
};
