import { gql } from "apollo-boost";
import { useCallback, useRef, useState } from "react";

export const GET_PRODUCT_WITH_VARIANTS = gql`
    query productByID($id: ID!) {
        productByID(id: $id) {
            id
            variants {
                id
                disabled
                productBaseVariantId
                productBaseVariant {
                    id
                    fulfillmentProductId
                    fulfillmentSku
                    attributes {
                        name
                        slug
                        option
                    }
                    productBase {
                        id
                        title
                    }
                }
            }
        }
    }
`;

export function useProductVariants() {
    const [l, setL] = useState(false);
    const getVariants = useCallback(async (product) => {
        const { __apolloClient__: client } = window || {};
        if (!product || !client) return product;
        setL(true);
        const { id } = product;
        const { data } = await client.query({
            query: GET_PRODUCT_WITH_VARIANTS,
            variables: { id },
        });
        if (data?.productByID) {
            product.variants = data.productByID.variants;
        }

        setL(false);
        return product;
    }, []);

    return [getVariants, l];
}
