import React, { useState, useEffect, useCallback } from "react";
import { Badge, Modal, Spinner } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import styled from "styled-components";

import noImg from "../../../assets/images/unnamed.jpeg";
import { handleError } from "../../../helper";
import { GET_VALUE_FOR_ORDER } from "../../../graphql/queries";

import { PersonalizedOptionPolaris } from "./PersonalizedOptionPolaris";

const Container = styled.div`
    .activator_wrap {
        cursor: pointer;
    }
`;

const ModalContainer = styled.div`
    .personalized-content {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        .personalized-mockup {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            label {
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    .personalized-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        > label {
            font-weight: 500;
            font-size: 15px;
        }
    }
    .personalized-item + .personalized-item {
        border-top: var(
            --p-thin-border-subdued,
            0.1rem solid var(--p-border-subdued, #dfe3e8)
        );
        padding-top: 1rem;
    }
`;

export const PersonalizedPolaris = (props) => {
    const { order } = props;
    const [popoverActive, setPopoverActive] = useState(false);
    const [parent, setParent] = useState(null);

    const [getField, { data, loading, error }] = useLazyQuery(
        GET_VALUE_FOR_ORDER
    );

    useEffect(() => {
        if (order && order.id && popoverActive) {
            getField({ variables: { orderId: order.id } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, popoverActive]);

    useEffect(() => {
        const elementContains = (parent, child) =>
            parent !== child && parent.contains(child);
        let child = document.querySelector("#personalized-modal");
        let parent = document.querySelector(".Polaris-Modal-Dialog__Modal");
        if (elementContains(parent, child)) {
            setParent(parent);
        }

        // Disable scroll
        let body = document.querySelector("body");
        if (popoverActive) {
            body.setAttribute("style", "overflow: hidden");
        } else {
            body.removeAttribute("style");
        }
    }, [popoverActive]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (parent && !parent.contains(event.target)) {
                setPopoverActive(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up.
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [parent]);

    const togglePopoverActive = useCallback(() => {
        setPopoverActive((prev) => !prev);
    }, []);

    const values = (data && data.getFieldValueForOrder) || [];
    const product =
        values &&
        values.map((v) => {
            return {
                productVariant: v.order.productVariant,
                ...v.order.product,
            };
        });

    // Main image
    let productVariant;
    let images = product
        .map((p) => {
            productVariant = p.productVariant;
            return p.images;
        })
        .flatMap((_) => _);

    const mainImageId = product[0]?.mainImageId;
    const pBaseId = get(productVariant, "productBaseVariant.productBase.id", null);
    let mainImages = [];
    const isCampaign = product?.productBases?.length > 1;

    if (pBaseId && isCampaign) {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.productBaseId === pBaseId)
                      .map((item) => item.file)
                : [];
    } else if (mainImageId || mainImageId !== "undefined") {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.file?.id === mainImageId)
                      .map((item) => item.file)
                : [];
    }
    if (mainImages.length === 0) {
        mainImages =
            images?.length > 0
                ? images.filter(Boolean).map((item) => item.file)
                : [];
    }
    const image = mainImages?.length > 0 ? mainImages[0] : null;
    const url = image
        ? image?.url
            ? image?.url
            : image?.thumbnailUrl
            ? image?.thumbnailUrl
            : null
        : null;

    return (
        <Container>
            <div className="activator_wrap" onClick={togglePopoverActive}>
                <Badge children={"Personalized"} status="success" />
            </div>

            <Modal
                open={popoverActive}
                onClose={togglePopoverActive}
                title="Personalized detail"
                sectioned
                large
            >
                <ModalContainer id="personalized-modal">
                    {error ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : (
                        <div className="personalized-content">
                            <div className="personalized-mockup">
                                <label>Mockup</label>
                                {loading ? (
                                    <Spinner size="small" />
                                ) : (
                                    <img
                                        alt=""
                                        style={{
                                            width: 240,
                                            height: "auto",
                                            objectFit: "cover",
                                        }}
                                        src={url || noImg}
                                    />
                                )}
                            </div>
                            <div className="personalized-options">
                                <label>Personalized options</label>
                                {values?.length > 0 ? (
                                    values.map((value, index) => (
                                        <PersonalizedOptionPolaris
                                            key={index}
                                            {...props}
                                            value={value}
                                        />
                                    ))
                                ) : (
                                    <span>No personalized value.</span>
                                )}
                            </div>
                        </div>
                    )}
                </ModalContainer>
            </Modal>
        </Container>
    );
};
