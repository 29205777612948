import { Button, Card, TextContainer } from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { getTeamID, handleError, isPME_TEAM } from "../../helper";
import useToggle from "../../hooks/useToggle";
import CopyBox from "../shared/CopyBox";
import { ToastContextProvider } from "../shared/ToastContext";
import { CustomcatFulfillmentConfigurePolaris } from "./CustomcatFulfillmentConfigurePolaris";
import { DreamshipFulfillmentConfigurePolaris } from "./DreamshipFulfillmentConfigurePolaris";
import { GearmentFulfillmentConfigurePolaris } from "./GearmentFulfillmentConfigurePolaris";
import { MerchizeFulfillmentConfigure } from "./MerchizeFulfillmentConfigure";
import { PrintifyFulfillmentConfigurePolaris } from "./PrintifyFulfillmentConfigurePolaris";
import { PrintwayFulfillmentConfigurePolaris } from "./PrintwayFulfillmentConfigurePolaris";
import { ScalablepressFulfillmentConfigure } from "./ScalablepressFulfillmentConfigure";
import { TeezilyFulfillmentConfigurePolaris } from "./TeezilyFulfillmentConfigurePolaris";
import { FULFILLMENTS_SLUG } from "../../constants";
import SuspenseComp from "../shared/SuspenseComp";

export const SPContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > a {
    margin: 0 0.5rem;
  }

  strong {
    margin-right: 0.5rem;
  }

  .input-wrap {
    min-width: 30rem;
    margin-left: 1rem;
  }
`;

const TeezilyContainer = styled.div`
  > a {
    margin: 0 0.5rem;
  }

  strong {
    margin-right: 0.5rem;
  }

  .input-wrap {
    min-width: 30rem;
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 1rem 0;

    li {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
`;

export const FulfillmentPolaris = ({ data, error, refetch }) => {
  const { currentUser } = useAppContext();

  const teamId = getTeamID(currentUser);
  const isPMETeam = isPME_TEAM(currentUser)

  const apps =
    data?.apps?.length > 0
      ? data.apps.filter((i) => i.name.toLowerCase() !== "trello" && i.name.toLowerCase() !== "burgerprints")
      : [];

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <ToastContextProvider>
      {apps && apps.length > 0
        ? apps.map((app, idx) => (
            <Card key={`app-${idx}`}>
              <Card.Header title={app.name} />
              <Card.Section>
                <TextContainer spacing="tight">
                  <div>{app.description}</div>
                  {app.name === "Merchize" && (
                    // <div>
                    //   <p>Store URL: {_.get(app, "settings.url")}</p>
                    // </div>
                    <Metadata
                      label="Store URL"
                      text={_.get(app, "settings.url")}
                    />
                  )}
                  {app.name !== "Merchize" && (
                    <div
                      style={{
                        display: "inline-block",
                        wordBreak: "break-all",
                      }}
                    >
                      {/* API key: {_.get(app, "settings.api_key")} */}
                      <Metadata
                        label="API key"
                        text={_.get(app, "settings.api_key")}
                      />
                    </div>
                  )}
                  <div>
                    {app.name && "customcat" === app.name.toLowerCase() && (
                      <CustomcatFulfillmentConfigurePolaris
                        installed={app.installed}
                        refetch={refetch}
                      />
                    )}
                    {app.name && "gearment" === app.name.toLowerCase() && (
                      <GearmentFulfillmentConfigurePolaris
                        installed={app.installed}
                        refetch={refetch}
                      />
                    )}
                    {app.name && "dreamship" === app.name.toLowerCase() && (
                      <DreamshipFulfillmentConfigurePolaris
                        installed={app.installed}
                        refetch={refetch}
                        app={app}
                      />
                    )}
                    {app.name && "merchize" === app.name.toLowerCase() && (
                      <MerchizeFulfillmentConfigure
                        installed={app.installed}
                        refetch={refetch}
                      />
                    )}
                    {app.name && "scalablepress" === app.name.toLowerCase() && (
                      <>
                        <SPContainer>
                          <strong>Note:</strong> Go to your
                          <a
                            href="https://scalablepress.com/manager/v2/settings/account"
                            target="_blank"
                            className="Polaris-Link"
                            rel="noopener noreferrer"
                          >
                            ScalablePress account settings
                          </a>
                          and set API webhook URL with value:
                          <div className="input-wrap">
                            <CopyBox text="https://api.merchbridge.com/scalablepress-wh" />
                          </div>
                        </SPContainer>
                        <ScalablepressFulfillmentConfigure
                          installed={app.installed}
                          refetch={refetch}
                        />
                      </>
                    )}
                    {"printify" === app?.name?.toLowerCase() && (
                      <PrintifyFulfillmentConfigurePolaris
                        installed={app.installed}
                        refetch={refetch}
                        value={app}
                      />
                    )}
                    {app.name && "printway" === app.name.toLowerCase() && (
                      <PrintwayFulfillmentConfigurePolaris
                        installed={app.installed}
                        refetch={refetch}
                      />
                    )}
                    {app.name && "teezily" === app.name.toLowerCase() && (
                      <>
                        <TeezilyContainer>
                          <strong>Note:</strong> Go to your
                          <a
                            href="https://plus.teezily.com/account/shops"
                            target="_blank"
                            className="Polaris-Link"
                            rel="noopener noreferrer"
                          >
                            Teezily account settings
                          </a>
                          and set:
                          <div>
                            <ul>
                              <li>
                                <span>API Webhook URL with value:</span>
                                <div className="input-wrap">
                                  <CopyBox text="https://api.merchbridge.com/teezily-wh" />
                                </div>
                              </li>
                              <li>
                                <span>and Webhook Secret Key with value:</span>
                                <div className="input-wrap">
                                  <CopyBox text={teamId} />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </TeezilyContainer>
                        <TeezilyFulfillmentConfigurePolaris
                          installed={app.installed}
                          refetch={refetch}
                          value={app}
                        />
                      </>
                    )}

                    {isPMETeam && isFulfillmentName(app, FULFILLMENTS_SLUG.BurgerPrints) && (
                      <BurgerPrintsWrap app={app} refetch={refetch} />
                    )}
                  </div>
                </TextContainer>
              </Card.Section>
            </Card>
          ))
        : null}
    </ToastContextProvider>
  );
};

export function Metadata({ label, text }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  return !!text ? (
    <APIKeyWrapper>
      {label}:
      <CopyBox text={text} type={open ? "text" : "password"} />
      <Button plain icon={open ? ViewMinor : HideMinor} onClick={toggleOpen} />
    </APIKeyWrapper>
  ) : null;
}

function isFulfillmentName(app, slug) {
  if (
    !app ||
    typeof app !== "object" ||
    !app.name ||
    typeof app.name !== "string"
  )
    return false;
  const nameLower = app.name.toLowerCase();
  return nameLower === slug;
}

function BurgerPrintsWrap({ app, refetch }) {
  const BurgerPrints = React.lazy(() => import("./components/BurgerPrints"));

  return (
    <SuspenseComp>
      <BurgerPrints app={app} refetch={refetch} />
    </SuspenseComp>
  );
}

const APIKeyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;

  .copy-box {
    padding: 0.5rem 1rem;
    border-radius: var(--p-border-radius-base, 3px);
    margin-top: 0.5rem;
    border-color: rgb(183, 193, 202);
    gap: 0.5rem;
  }
`;
