import {
  CaretDownFilled,
  CaretRightFilled,
  ExclamationCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { CUSTOM_FULFILLMENT } from "../../../constants";
import { KPI } from "../../../constants/task";
import {
  GET_PRINTIFY_PRODUCT_PRINT_PROVIDERS,
  GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
} from "../../../graphql/queries";
import { arrInvalid, handleError, NumberToFixed, toSlug } from "../../../helper";
import history from "../../../history";
import ProductBaseVariants from "../../../pages/admin/ProductBaseVariants";
import {
  CUSTOMCAT_SHIPPING_RATE,
  GEARMENT_SHIPPING_RATE,
  SCALABLEPRESS_DESIGN_TYPE,
} from "../../../variable";
import BoxShipping from "../../base/BoxShipping";
import CarrierPricing from "../../base/CarrierPricing";
import { BaseTimeLine } from "../../base/components/BaseTimeline";
import { ModalVariantsMissing } from "../../base/components/ModalVariantsMissing";
import ProductBaseAttributes from "../../base/ProductBaseAttributes";
import ProductBaseDesignPositions from "../../base/ProductBaseDesignPositions";
import ProductBaseFulfillment from "../../base/ProductBaseFulfillment";
import SupplierPricing from "../../base/SupplierPricing";
import {
  checkFulfillment,
  DESIGN_POSITION_DEFAULT,
  fetchProductDetailPrintway,
  fetchProductForBurgerPrints,
  fetchProductForDreamship,
  fetchProductForMerchize,
  fetchProductForTeezily,
  fetchProductsForGearment,
  fetchProductVariantsCustomcat,
  fetchProductVariantsDreamship,
  fetchProductVariantsPrintify,
  fetchVariantsForCustomcat,
  filterNewVariants,
  filterNewVariantsForMerchize,
  formatVariantsForCustomcat,
  formatVariantsForDreamship,
  genDesignPositionForPrintway,
  genDPDreamship,
  genDPScalablepress,
  getAttributesForMerchize,
  getDesignType,
  mapMerchizeSku,
  updateAttributes,
  updateDesignPositions,
} from "../../base/utils";
import Price from "../../Price";
import { CategoryAutocomplete } from "../../shared/CategoryAutocomplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import { ADDITIONAL_PRICE_TYPE } from "./ProductBaseForm";

let showNotifyOnMove = false;
const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

let designTypeSP = SCALABLEPRESS_DESIGN_TYPE;
const designTypeSPArr = [];
if (designTypeSP) {
  for (let [key, value] of Object.entries(designTypeSP)) {
    designTypeSPArr.push({ key, value });
  }
}

class ProductBaseFormV2 extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      handleBtnSave: null,
      showBtnSave: null,
      productPrintID: null,
      productProviders: [], // Printify
      isPrintify: false,
      loadingProductProviders: false,
      teamMembers: [],
      loadingFFProduct: false,
      productsGearment: [],
      openVM: false,
      loadingVM: false,
      variantsMissing: [],

      hasVariant: false,
      changedTime: null,
    };
  }
  submitButtonRef = React.createRef();

  disableSubmitButton = (active) => {
    if (!active) {
      showNotifyOnMove = true;
    }
    this.submitButtonRef.current.disabled = active;
  };

  componentDidMount() {
    const { fulfillments, value } = this.props;
    const form = this.ref.current;

    if (this.submitButtonRef) {
      this.submitButtonRef.current.disabled = true;
    }
    if (!form) return;
    showNotifyOnMove = false;

    if (value) {
      const productProviders = [];
      if (value.printifyPrintProviderName && value.printifyPrintProviderID) {
        productProviders.push({
          id: value.printifyPrintProviderID,
          title: value.printifyPrintProviderName,
        });
      }

      const ffID = get(value, "fulfillment.fulfillmentId");
      const ffType = checkFulfillment(fulfillments, ffID);

      this.setState({
        scalablePressDesignType: value.scalablePressDesignType,
        scalableOriginID: value.scalableOriginID,
        scalablePressType: value.scalablePressType,
        productProviders,
        changedTime: Date.now(),
        ...ffType,
      });
      const { isCustomcat, isDreamship } = ffType;

      const fieldsValue = getDefaultValues(value, isCustomcat, isDreamship);
      form.setFieldsValue({ ...fieldsValue });
      this.setState({ changedTime: Date.now() });
      return;
    }
    let ff = null;
    if (fulfillments?.length > 0) {
      ff = fulfillments.find(({ slug }) => slug === CUSTOM_FULFILLMENT);
      if (ff) {
        ff.fulfillmentId = ff.id;
      }
    }

    form.setFieldsValue({
      hasVariant: true,
      fulfillment: ff,
    });

    const ffType = checkFulfillment(fulfillments, ff?.fulfillmentId);
    this.setState({
      ...ffType,
      changedTime: Date.now(),
    });
  }

  componentDidUpdate(prevProps) {
    const { value, fulfillments } = this.props;
    if (!isEqual(value, prevProps.value)) {
      const form = this.ref.current;
      const productProviders = [];
      if (value.printifyPrintProviderName && value.printifyPrintProviderID) {
        productProviders.push({
          id: value.printifyPrintProviderID,
          title: value.printifyPrintProviderName,
        });
      }

      const ffID = get(value, "fulfillment.fulfillmentId");
      const ffType = checkFulfillment(fulfillments, ffID);

      this.setState({
        scalablePressDesignType: value.scalablePressDesignType,
        scalableOriginID: value.scalableOriginID,
        scalablePressType: value.scalablePressType,
        productProviders,
        ...ffType,
      });

      const { isCustomcat, isDreamship } = ffType;

      const fieldsValue = getDefaultValues(value, isCustomcat, isDreamship);
      form.setFieldsValue({ ...fieldsValue });
      this.setState({ changedTime: Date.now() });
    }
  }

  // Get product information for Printify fulfillment.
  getProductIntoPrintify = async (productID) => {
    const { __apolloClient__: client } = window;
    if (!productID || !client) return;

    this.setState({ loadingProductProviders: true });
    try {
      const { data } = await client.query({
        query: GET_PRINTIFY_PRODUCT_PRINT_PROVIDERS,
        variables: {
          productID,
        },
      });

      if (data?.getPrintifyProductPrintProviders?.length === 0) {
        notification.error({
          message: "Product print providers counld'nt found.",
        });
        return;
      }

      const nodes = data.getPrintifyProductPrintProviders.map(
        ({ id, title }) => ({ id, title }),
      );
      this.setState({ productProviders: nodes, productPrintID: productID });
    } catch (err) {
      notification.error({ message: err?.toString() });
    } finally {
      this.setState({ loadingProductProviders: false });
    }
  };

  getProductDetailPrintway = async (productCode) => {
    try {
      const { isEditProductBase } = this.props;
      const form = this.ref.current;
      const res =
        (await fetchProductDetailPrintway(productCode, isEditProductBase)) ||
        {};

      const name = res?.name || "";
      const description = res?.description || "";
      const variants = res?.variants || [];
      const attributes = res?.attributes || [];
      const dataSku = res?.dataSku || {};
      const designPositions = genDesignPositionForPrintway(dataSku, variants);

      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        designPositions,
        attributes,
        fulfillment,
        title: name,
        slug: toSlug(name),
        defaultContent: description,
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  handleUpdateVariantCarrierPricing(selected, variants) {
    const form = this.ref.current;
    const hasAdditionalShipping = form.getFieldValue("hasAdditionalShipping");

    variants = variants.map((variant) => {
      if (selected && selected.length === 0) {
        variant.carrierPricing = [];
      } else {
        if (!variant.carrierPricing) {
          variant.carrierPricing = [];
        }
        variant.carrierPricing = variant.carrierPricing.filter((v) =>
          selected.includes(v.carrierId),
        );
        for (let i = 0; i < ((selected && selected.length) || 0); i++) {
          if (
            !variant.carrierPricing.find((cp) => cp.carrierId === selected[i])
          ) {
            const newItem = {
              carrierId: selected[i],
              price: null,
              default: false,
            };

            //hasAdditionalShipping
            if (hasAdditionalShipping) {
              newItem.usingAdditionalPrice = true;
              newItem.additionalPriceType = ADDITIONAL_PRICE_TYPE;
              newItem.additionalPrice = null;
            }

            variant.carrierPricing.push(newItem);
          }
        }
      }
      return variant;
    });
    return variants;
  }

  handleChangeDesignPosition(attributes) {
    const form = this.ref.current;
    const designPositions = form.getFieldValue("designPositions");
    if (
      !attributes ||
      attributes.length === 0 ||
      !designPositions ||
      designPositions.length === 0
    )
      return;

    const newDS = designPositions.map((dp) => {
      const { productBaseVariants } = dp || {};
      const newVariants = (productBaseVariants || []).filter((v) => {
        const { attributes: attrs } = v || {};
        let flag = [];
        for (let i = 0; i < attrs.length; i++) {
          const { slug, option } = attrs[i];
          const cur = (attributes || []).find((item) => item.slug === slug);
          if (cur?.options?.length > 0) {
            flag[i] = cur.options.includes(option);
          }
        }

        return flag.every(Boolean);
      });

      return {
        ...dp,
        productBaseVariants: newVariants,
      };
    });

    form.setFieldsValue({ designPositions: newDS });
  }

  handleOnAttributeUpdate(attributes, fulfillment) {
    const form = this.ref.current;
    const oldVariants = form.getFieldValue("variants");
    let validAttributes = [];
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (attribute.name && attribute.slug && attribute.options.length) {
        validAttributes.push(attribute);
      }
    }
    let variants = getCombineVariants(validAttributes, oldVariants);
    const { isCustom } = this.state;
    if (!fulfillment || isCustom) {
      const supplierIds = form.getFieldValue("suppliers");
      variants = handleUpdateVariantSupplierPricing(
        supplierIds ? [supplierIds] : [],
        variants,
      );
      variants = this.handleUpdateVariantCarrierPricing(
        form.getFieldValue("carriers"),
        variants,
      );
    }

    this.handleChangeDesignPosition(attributes);
    form.setFieldsValue({
      variants,
    });
  }

  matchProductGearment = (id) => {
    if (!id) return;
    const { productsGearment } = this.state;
    if (productsGearment && typeof productsGearment === "object") {
      const { title, variants, attributes } = productsGearment[id] || {};

      const form = this.ref.current;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        attributes,
        fulfillment,
        title,
        slug: toSlug(title),
      });
    }
  };

  getProductInfoGearment = async () => {
    try {
      this.setState({ loadingFFProduct: true });
      const productsGearment = await fetchProductsForGearment();
      this.setState({ loadingFFProduct: false, productsGearment });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  getProductInfoCustomcat = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const form = this.ref.current;
      const {
        title,
        description,
        attributes = [],
        variants = [],
        designPositions = [],
      } = (await fetchVariantsForCustomcat(id)) || {};
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        designPositions,
        attributes,
        fulfillment,
        title,
        slug: toSlug(title),
        defaultContent: description,
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  getProductInfoDreamship = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const { title, description, attributes, variants, designPositions } =
        await fetchProductForDreamship(id);

      const form = this.ref.current;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        defaultContent: description,
        attributes,
        fulfillment,
        title,
        slug: toSlug(title),
        designPositions,
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error("error", err.toString());
    }
  };

  getProductInfoMerchize = async (id, skus) => {
    try {
      this.setState({ loadingFFProduct: true });
      const res = (await fetchProductForMerchize(id)) || {};
      const variants = res.variants || [];
      const attributes = res.attributes || [];
      const { title, description } = res || {};

      const form = this.ref.current;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      const newVar = mapMerchizeSku(variants, skus);
      form.setFieldsValue({
        variants: newVar,
        defaultContent: description,
        attributes,
        fulfillment,
        title,
        slug: toSlug(title),
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  getProductInfoTeezily = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const {
        title,
        variants = [],
        attributes = [],
        designPositions = [],
      } = await fetchProductForTeezily(id);

      const form = this.ref.current;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        title,
        slug: toSlug(title),
        variants,
        attributes,
        fulfillment,
        designPositions,
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    } finally {
      this.setState({ loadingFFProduct: false });
    }
  };

  handleChangeFulfillment = (v, designType) => {
    const { fulfillments } = this.props;
    const fulfillmentId = get(v, "fulfillmentId");
    const ffType = checkFulfillment(fulfillments, fulfillmentId);
    const { isDreamship, isScalablepress } = ffType;

    let dps = [DESIGN_POSITION_DEFAULT];
    if (isDreamship) {
      dps = genDPDreamship(v);
    } else if (isScalablepress) {
      dps = genDPScalablepress(designType);
    }
    const form = this.ref.current;
    form.setFieldsValue({ designPositions: dps });
  };

  handleUpdateOnSupplierChange(selected = []) {
    const form = this.ref.current;
    let supplierPricing = form.getFieldValue("supplierPricing");
    let variants = form.getFieldValue("variants");
    if (!supplierPricing || selected.length === 0) {
      supplierPricing = [];
    }
    supplierPricing = supplierPricing.filter((sp) =>
      selected.includes(sp.userId),
    );
    for (let i = 0; i < selected.length; i++) {
      const val = Array.isArray(selected[i]) ? selected[i][0] : selected[i];
      if (!supplierPricing.find((sp) => sp.userId === val)) {
        supplierPricing.push({
          userId: val,
          price: null,
        });
      }
    }
    if (variants && variants.length) {
      variants = handleUpdateVariantSupplierPricing(selected, variants);
    }

    form.setFieldsValue({
      supplierPricing,
      variants,
    });
  }

  getProductVariantsIntoPrintify = async (printProviderID, record) => {
    const { __apolloClient__: client } = window;
    const { productPrintID } = this.state;
    if (!productPrintID || !printProviderID || !client) return;

    try {
      const { data } = await client.query({
        query: GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
        variables: {
          productID: productPrintID,
          printProviderID: printProviderID + "",
        },
      });

      const variants = get(
        data,
        "getPrintifyProductPrintProviderVariants.variants",
        [],
      );
      if (variants.length === 0) {
        notification.error({
          message: "Product variants print providers counld'nt found.",
        });
        return;
      }

      const { variants: newVariants, attributes } =
        formatVariantsForPrintify(variants);
      const newDS = genDesignPositionForPrintify(variants, newVariants);

      const form = this.ref.current;
      const { getFieldValue, setFieldsValue } = form;
      const fulfillment = getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = newVariants;
        fulfillment.data.attributes = attributes;
      }

      setFieldsValue({
        variants: newVariants,
        designPositions: newDS,
        attributes,
        fulfillment,
      });

      const { children } = record;
      this.setState({
        printifyPrintProviderName: children?.trim(),
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  fetchVariantsMissingForPrintify = async () => {
    const { value } = this.props;
    const form = this.ref.current;
    const { printifyPrintProviderID, printifyBlueprintID } = value || {};
    try {
      const variants = await fetchProductVariantsPrintify(
        printifyBlueprintID,
        printifyPrintProviderID,
      );
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      const { variants: newVar } = formatVariantsForPrintify(newVariants);

      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {
      notification.error({ message: handleError(err.toString()) });
    }
  };

  fetchVariantsMissingForDreamship = async () => {
    const { value } = this.props;
    const form = this.ref.current;

    const baseID = get(value, "id", null);
    if (baseID == null) return;
    try {
      const variants = await fetchProductVariantsDreamship(baseID);
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      // Case attributes duplicate
      const count = (newVariants || []).reduce((acc, cur) => {
        const { attributes } = cur;
        const key = (attributes || [])
          .map(({ slug, option }) => `${slug}_${option}`)
          .join("_");
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      if (Object.values(count).some((i) => i > 1)) {
        this.setState({
          tmpError:
            "The current Dreamship product catalog differs from the current product base in terms of attributes.",
        });
        return;
      }
      const { variants: newVar } = formatVariantsForDreamship(newVariants);

      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissingForCustomcat = async () => {
    const { value } = this.props;
    const form = this.ref.current;
    const baseID = get(value, "id", null);
    if (baseID == null) return;

    try {
      const variants = await fetchProductVariantsCustomcat(baseID);
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      // Case attributes duplicate
      const count = (newVariants || []).reduce((acc, cur) => {
        const { attributes } = cur;
        const key = (attributes || [])
          .map(({ slug, option }) => `${slug}_${option}`)
          .join("_");
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      if (Object.values(count).some((i) => i > 1)) {
        this.setState({
          tmpError:
            "The current Customcat product catalog differs from the current product base in terms of attributes.",
        });
        return;
      }
      const { variants: newVar } = formatVariantsForCustomcat(newVariants);
      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissingForMerchize = async () => {
    const { merchizeSku, fulfillmentSkus } = this.state;

    if (!merchizeSku || !fulfillmentSkus) return;
    try {
      const form = this.ref.current;
      const res = (await fetchProductForMerchize(merchizeSku)) || {};
      const variants = res.variants || [];
      const newVariants = filterNewVariantsForMerchize(
        variants,
        form.getFieldValue("variants"),
        fulfillmentSkus,
      );
      this.setState({ variantsMissing: newVariants, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissing = async () => {
    const { isPrintify, isDreamship, isCustomcat, isMerchize } = this.state;
    this.setState({ loadingVM: true, openVM: true });
    if (isPrintify) {
      await this.fetchVariantsMissingForPrintify();
    } else if (isDreamship) {
      await this.fetchVariantsMissingForDreamship();
    } else if (isCustomcat) {
      await this.fetchVariantsMissingForCustomcat();
    } else if (isMerchize) {
      await this.fetchVariantsMissingForMerchize();
    }
    this.setState({ loadingVM: false });
  };

  handleUpdateOnCarrierChange(selected) {
    const form = this.ref.current;
    const { getFieldValue, setFieldsValue } = form;
    let carrierPricing = getFieldValue("carrierPricing");
    let variants = getFieldValue("variants");
    const hasAdditionalShipping = getFieldValue("hasAdditionalShipping");

    if (!carrierPricing || selected.length === 0) {
      carrierPricing = [];
    }
    if (carrierPricing && carrierPricing.length) {
      carrierPricing = carrierPricing.filter((c) =>
        selected.includes(c.carrierId),
      );
    }
    for (let i = 0; i < (selected && selected.length) || 0; i++) {
      if (!carrierPricing.find((c) => c.carrierId === selected[i])) {
        const newItem = {
          carrierId: selected[i],
          price: null,
          default: false,
        };

        //hasAdditionalShipping
        if (hasAdditionalShipping) {
          newItem.usingAdditionalPrice = true;
          newItem.additionalPriceType = ADDITIONAL_PRICE_TYPE;
          newItem.additionalPrice = null;
        }
        carrierPricing.push(newItem);
      }
    }
    // handle variants carrier pricing
    if (variants && variants.length) {
      variants = this.handleUpdateVariantCarrierPricing(selected, variants);
    }
    let defaultSelectIndex = 0;
    for (let i = 0; i < carrierPricing.length; i++) {
      if (carrierPricing[i].default) {
        defaultSelectIndex = i;
      }
    }
    if (carrierPricing.length) {
      carrierPricing[defaultSelectIndex].default = true;
    }
    setFieldsValue({
      carrierPricing: carrierPricing,
      variants,
    });
  }

  handleVariantsMissingForPrintify = (newVariants) => {
    const form = this.ref.current;
    if (!newVariants || newVariants.lengh === 0) return {};
    const { variants: newVar, attributes } =
      formatVariantsForPrintify(newVariants);

    const newDS = genDesignPositionForPrintify(newVariants, newVar);

    const values = form.getFieldsValue([
      "variants",
      "designPositions",
      "attributes",
      "fulfillment",
    ]);
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    const mergeDS = updateDesignPositions(values.designPositions, newDS);
    const mergeVariants = [...values.variants, ...newVar];
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      designPositions: mergeDS,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissingForDreamship = (newVariants) => {
    if (!newVariants || newVariants.length === 0) return {};
    const { isDreamship, isCustomcat } = this.state;
    let newVar = [];
    let attributes = [];

    if (isDreamship) {
      ({ variants: newVar, attributes } =
        formatVariantsForDreamship(newVariants));
    } else if (isCustomcat) {
      ({ variants: newVar, attributes } =
        formatVariantsForCustomcat(newVariants));
    }

    const form = this.ref.current;
    const values = form.getFieldsValue([
      "variants",
      "attributes",
      "fulfillment",
    ]);

    const mergeVariants = [...values.variants, ...newVar];
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissingForMerchize = (newVariants) => {
    if (!newVariants || newVariants.length === 0) return {};

    let attributes = getAttributesForMerchize(newVariants);

    const form = this.ref.current;
    const values = form.getFieldsValue([
      "variants",
      "attributes",
      "fulfillment",
    ]);

    const mergeVariants = [...values.variants, ...newVariants];
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissing = (selected) => {
    const { tmpVariants } = this.state;
    if (
      !selected ||
      selected.length === 0 ||
      !tmpVariants ||
      tmpVariants.length === 0
    )
      return;

    this.disableSubmitButton(false);
    this.setState({ tmpError: null });

    const { isPrintify, isDreamship, isCustomcat, isMerchize } = this.state;
    const form = this.ref.current;

    const ids = selected.map(
      ({ fulfillmentProductId }) => fulfillmentProductId,
    );
    const filteredVariants = tmpVariants.filter(
      ({ id, fulfillmentProductId }) => {
        let val = id;
        if (isMerchize) {
          val = fulfillmentProductId;
        }

        return ids.includes(val);
      },
    );

    let newFields = {};
    if (isPrintify) {
      newFields = this.handleVariantsMissingForPrintify(filteredVariants);
    } else if (isDreamship || isCustomcat) {
      newFields = this.handleVariantsMissingForDreamship(filteredVariants);
    } else if (isMerchize) {
      newFields = this.handleVariantsMissingForMerchize(filteredVariants);
    }

    form.setFieldsValue(newFields);
  };

  titleTypingRef = React.createRef(null);
  onChange = (changedValue) => {
    const form = this.ref.current;
    if ("title" in changedValue) {
      this.titleTypingRef.current && clearTimeout(this.titleTypingRef.current);
      this.titleTypingRef.current = setTimeout(() => {
        form.setFieldValue("slug", toSlug(changedValue["title"]));
      }, 500);
    }
    this.disableSubmitButton(false);

    // list field need re-render
    const keys = [
      "hasVariant",
      "fulfillment",
      "variants",
      "carriers",
      "suppliers",
      "shippingRate",
      "isSingleShipment",
    ];
    for (let k of keys) {
      if (k in changedValue) {
        this.setState({ changedTime: Date.now() });
      }
    }
  };

  handelFinish = (values, ...args) => {
    // Check every variant's regularPrice have value greater than 0
    const { variants } = values;
    const invalidPrice = (variants || []).some(
      ({ regularPrice }) => regularPrice == null || regularPrice < 1,
    );
    if (invalidPrice) {
      notification.error({
        message: "You must enter regular price!",
      });
      return;
    }

    // Check every variant's fulfillmentProductId
    const {
      isCustomcat,
      isDreamship,
      isGearment,
      isScalablepress,
      isPrintify,
      isTeezily,
      isCustom,
    } = this.state;
    const showShipping = isCustomcat || isGearment;

    const isFFSpecial = [
      isCustomcat,
      isDreamship,
      isGearment,
      isScalablepress,
      isPrintify,
      isTeezily,
    ].some(Boolean);

    if (isFFSpecial) {
      const invalidPrice = (variants || []).some(
        ({ fulfillmentProductId }) =>
          fulfillmentProductId == null || fulfillmentProductId == "",
      );

      if (invalidPrice) {
        notification.error({
          message: "You must enter fulfillment product id!",
        });
        return;
      }
    }

    // Fulfillment
    if (values.fulfillment && values.fulfillment.__typename) {
      delete values.fulfillment.__typename;
    }

    if (values.fulfillment && values.fulfillment.data !== undefined) {
      delete values.fulfillment.data;
    }

    if (values?.fulfillment != null) {
      let newFF = {};
      if (Object.keys(values.fulfillment).length > 0) {
        const { fulfillmentId, productId, presetId } = values.fulfillment;
        newFF = { fulfillmentId, productId, presetId };
      } else {
        newFF = null;
      }
      values.fulfillment = newFF;
    }

    if (values.attributes) {
      values.attributes = values.attributes.map(
        (attribute) => (delete attribute.__typename, attribute),
      );
    }

    // Color codes
    if (values.variants) {
      values.variants = values.variants.map((variant) => {
        delete variant.__typename;
        delete variant.sorting;

        variant.attributes = variant.attributes.map((a) => {
          delete a.__typename;
          return a;
        });

        return variant;
      });
    }

    // Scalabel Press
    let { scalablePressType, scalableOriginID } = this.state;
    let errorSP = {};
    let canSubmit = true;

    // Check Scalabel press
    const { isScalablepress: isFFScalabelPress } = this.state;

    if (isFFScalabelPress) {
      values["scalablePressType"] = scalablePressType;
      values["scalableOriginID"] = scalableOriginID;
    }

    if (values.designPositions && values.designPositions.length) {
      values.designPositions = values.designPositions.map((dp, index) => {
        delete dp.imageUrl;
        dp.artworkGuidelines =
          dp.artworkGuidelines &&
          dp.artworkGuidelines
            .map((ag) => {
              let { fileId, description } = ag;
              if (fileId) {
                return {
                  fileId,
                  description,
                };
              }
            })
            .filter(Boolean);
        dp.productBaseVariants =
          dp.productBaseVariants &&
          dp.productBaseVariants.map(
            (pb) => (
              delete pb.__typename,
              pb.attributes &&
                pb.attributes.map((at) => (delete at.__typename, at)),
              pb
            ),
          );
        let { width, height, horizontal, top, bottom, ...rest } = dp;
        dp = rest;
        if (["dtg", "poster"].includes(values.scalablePressDesignType)) {
          if (!width && !height) {
            errorSP[index] = {
              ...errorSP[index],
              dimensions: "For design position, width or height is required.",
            };
          }
        }

        if (["dtg"].includes(values.scalablePressDesignType)) {
          if (!top && !bottom) {
            errorSP[index] = {
              ...errorSP[index],
              position: "For design position, top or bottom is required.",
            };
          }
        }

        if (
          isFFScalabelPress &&
          ["dtg", "poster"].includes(values.scalablePressDesignType)
        ) {
          dp["scalablePressCustomize"] = {
            dimensions: {
              ...(width ? { width } : {}),
              ...(height ? { height } : {}),
            },
            position: {
              horizontal: horizontal ? horizontal : "",
              offset: {
                ...(top ? { top } : {}),
                ...(bottom ? { bottom } : {}),
              },
            },
          };
        }
        return dp;
      });
    }

    if (isFFScalabelPress) {
      if (errorSP && Object.keys(errorSP).length) {
        Object.keys(errorSP).forEach((key, index) => {
          if (index === 0) {
            let err = errorSP[key];
            for (let [, value] of Object.entries(err)) {
              notification.error({
                message: value,
              });
            }
          }
        });
        canSubmit = false;
      } else {
        canSubmit = true;
      }
    }

    if (values.images && values.images.length) {
      values.images = values.images.map((img) => img.id);
    }

    let {
      isSingleShipment,
      usFirstFee,
      usAdditionalFee,
      canadaFirstFee,
      canadaAdditionalFee,
      internationalFirstFee,
      internationalAdditionalFee,
      shippingRate,
      ...rest
    } = values;
    values = rest;

    if (showShipping) {
      values["customcatShipping"] = {
        isSingleShipment,
        usFirstFee,
        usAdditionalFee,
        canadaFirstFee,
        canadaAdditionalFee,
        internationalFirstFee,
        internationalAdditionalFee,
        shippingRate,
      };
    }

    const { printifyPrintProviderName, printifyBlueprintID } = this.state;

    const { value } = this.props;
    if (isPrintify && !value) {
      values.printifyPrintProviderName = printifyPrintProviderName;
      values.printifyBlueprintID = printifyBlueprintID;
    }

    if (!!value) {
      delete values.printifyPrintProviderID;
    }

    if (values.fulfillment?.fulfillmentInfo) {
      delete values.fulfillment.fulfillmentInfo;
    }

    let hasOptionType = false;
    if (values.variants?.length > 0) {
      values.variants = values.variants.map((v) => {
        const { fulfillmentProductId, attributes } = v || {};
        v.disabledFPI = undefined; // Reset value
        if (!hasOptionType) {
          hasOptionType = attributes.some(({ name, slug }) => {
            const pt = /^type$/i;
            return pt.test(name) || pt.test(slug);
          });
        }
        if (typeof fulfillmentProductId === "number") {
          return {
            ...v,
            fulfillmentProductId: fulfillmentProductId + "",
          };
        }
        return v;
      });
    }

    if (values.categories?.length > 0) {
      values.categories = (getIds(values.categories) || [])[0];
    }

    if (values.suppliers && !Array.isArray(values.suppliers)) {
      values.suppliers = [values.suppliers];
    }

    if (values.requiredDesign == null) {
      values.requiredDesign = false;
    }

    if (isCustom) {
      if (!values.suppliers || values.suppliers.length === 0) {
        notification.error({
          message: "You must enter suppliers.",
        });
        return;
      }
    }
    if (canSubmit && !hasOptionType) {
      const { onSubmit } = this.props;
      if (onSubmit) {
        onSubmit(values);
        showNotifyOnMove = false;
      }
    }
  };

  getProductInfoBurgerPrints = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });

      console.log('id >>> ', id);
      const res = await fetchProductForBurgerPrints(id);

      console.log('res', res);
      let {
        title,
        description,
        variants = [],
        attributes = [],
        designPositions = [],
      } = res;
      const form = this.ref.current;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      if (arrInvalid(designPositions)) {
        designPositions = [DESIGN_POSITION_DEFAULT]
      }

      form.setFieldsValue({
        title,
        variants,
        attributes,
        fulfillment,
        designPositions,
        defaultContent: description,
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    } finally {
      this.setState({ loadingFFProduct: false });
    }
  };

  render() {
    const {
      categories,
      value,
      fulfillments,
      hasProduct,
      carriers,
      suppliers,
      isEditProductBase,
      teamMembers,
      loading,
    } = this.props;

    const {
      isPrintify,
      loadingFFProduct,
      isDreamship,
      isCustomcat,
      isGearment,
      isScalablepress,
      isCustom,
      isMerchize,
      isPrintway,
      scalablePressDesignType,
      loadingProductProviders,
      productProviders,
      showBtnSave,
      openVM,
      loadingVM,
      tmpError,
      variantsMissing,
    } = this.state;
    const showShipping = isCustomcat || isGearment;

    const form = this.ref.current;
    const { getFieldValue = () => {} } = form || {};

    const hasVariant = getFieldValue("hasVariant");
    const fulfillment = getFieldValue("fulfillment");

    const variants = getFieldValue("variants");
    const selectedCarrierIds = getFieldValue("carriers");
    const selectedSupplierIds = getFieldValue("suppliers");
    const selectedShippingRate = getFieldValue("shippingRate");

    const fulfillmentAttributes = fulfillment?.data?.attributes;
    const fulfillmentVariants = fulfillment?.data?.variants;
    const valueAttributes = value?.attributes;
    const valueVariants = value?.varaints;
    const customcatShipping = value && value.customcatShiping;

    let DEFAULT_SHIPPING_RATE = [];
    if (customcatShipping && customcatShipping.shippingRate) {
      DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE.filter(
        (i) => i.value !== customcatShipping.shippingRate,
      );
    } else {
      DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE;
    }

    let currentShippingRate = null;
    if (isCustomcat) {
      currentShippingRate = DEFAULT_SHIPPING_RATE.find(
        (i) => i.value === selectedShippingRate,
      );
    }

    let currentShippCost = null;
    if (
      !DEFAULT_SHIPPING_RATE.map((i) => i.value).includes(selectedShippingRate)
    ) {
      currentShippCost = customcatShipping;
    } else {
      currentShippCost =
        currentShippingRate && currentShippingRate.shippingCost;
    }

    if (isDreamship && !customcatShipping) {
      currentShippCost = GEARMENT_SHIPPING_RATE.shippingCost;
    }

    // Check Scalabel press
    let isFFScalabelPress = isScalablepress;
    let scalablePressDT = scalablePressDesignType;

    const checkHasFF = isFulfillment(fulfillment);

    const selectedCarriers = mapItemsByIds(carriers, selectedCarrierIds);
    const selectedSuppliers = mapItemsByIds(
      suppliers,
      selectedSupplierIds
        ? Array.isArray(selectedSupplierIds)
          ? selectedSupplierIds
          : [selectedSupplierIds]
        : [],
    );

    return (
      <React.Fragment>
        <Prompt
          when={showNotifyOnMove}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Container>
          <Form
            {...formLayout}
            ref={this.ref}
            onValuesChange={this.onChange}
            onFinish={this.handelFinish}
            initialValues={{
              ...getDefaultValues(null, isCustomcat, isDreamship),
            }}
          >
            <Row gutter={16}>
              <Col span={16}>
                <Card>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input placeholder="Product base title" />
                  </Form.Item>

                  <Form.Item
                    name="niceName"
                    label="Nice Name"
                    rules={[
                      {
                        required: true,
                        message: "Product base ice name is required",
                      },
                    ]}
                  >
                    <Input placeholder="Nice name" />
                  </Form.Item>

                  <Form.Item
                    name="slug"
                    label="Slug"
                    rules={[{ required: true, message: "Slug is required" }]}
                  >
                    <Input placeholder="Product base slug" />
                  </Form.Item>

                  <Form.Item
                    name="baseCostDescription"
                    label="Base cost description"
                  >
                    <Input placeholder={"You cost $29 - $40"} />
                  </Form.Item>

                  <Form.Item name="defaultContent" label="Default content">
                    <Wysiwyg useDebounce={true} />
                  </Form.Item>

                  <Form.Item
                    label="Categories"
                    name="categories"
                    rules={[
                      { required: true, message: "Category is required" },
                    ]}
                  >
                    <CategoryAutocomplete
                      data={categories ? categories : []}
                      placeholder="Categories"
                      multiple={false}
                      quickAdd
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Form.Item label="Image" name="images">
                    <MediaSelectorButton
                      accept={"image/*"}
                      listType={"picture"}
                      multiple={true}
                    />
                  </Form.Item>
                </Card>
                <Card title={"Fulfillment"} style={{ marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="fulfillment">
                        <ProductBaseFulfillment
                          fulfillments={fulfillments}
                          isPrintify={isPrintify && !!value}
                          loadingFFProduct={loadingFFProduct}
                          getOriginId={(fulfillmentData, originId) => {
                            const fulfillmentSkus = get(
                              fulfillmentData,
                              "fulfillmentSkus",
                            );
                            this.setState({
                              fulfillmentSkus,
                              merchizeSku: originId,
                            });
                          }}
                          onChange={async (v) => {
                            const fulfillmentId = get(v, "fulfillmentId");
                            const ffType = checkFulfillment(
                              fulfillments,
                              fulfillmentId,
                            );

                            this.setState({
                              productProviders: [],
                              isPrintify: false,
                              tmpError: null,
                              tmpVariants: [],
                              ...ffType,
                            });

                            if (hasVariant && v && v.data) {
                              if (v.data.isChange) {
                                const originId = get(v, "data.originId", null);
                                if (ffType.isPrintify) {
                                  this.setState({
                                    isPrintify: true,
                                    printifyBlueprintID:
                                      Number.parseInt(originId),
                                  });
                                  await this.getProductIntoPrintify(originId);
                                  return;
                                }

                                if (ffType.isPrintway) {
                                  await this.getProductDetailPrintway(originId);
                                  return;
                                }

                                if (ffType.isCustomcat) {
                                  await this.getProductInfoCustomcat(originId);
                                  return;
                                }

                                if (ffType.isGearment) {
                                  this.matchProductGearment(originId);
                                  return;
                                }

                                if (ffType.isDreamship) {
                                  this.getProductInfoDreamship(originId);
                                  return;
                                }

                                if (ffType.isMerchize) {
                                  const skus = get(v, "data.fulfillmentSkus");
                                  this.getProductInfoMerchize(originId, skus);
                                  return;
                                }

                                if (ffType.isTeezily) {
                                  this.getProductInfoTeezily(originId);
                                  return;
                                }

                                console.log('ffType  ', ffType);
                                if (ffType.isBurgerPrints) {
                                  this.getProductInfoBurgerPrints(originId);
                                  return;
                                }

                                form.setFieldsValue({
                                  attributes: v.data.attributes,
                                });

                                const isEditBase = this.props.isEditProductBase;
                                const newVariants = getVariants(
                                  v.data.variants,
                                  isEditBase,
                                );
                                form.setFieldsValue({
                                  // variants: v.data.variants,
                                  variants: newVariants,
                                });
                                // Scalabelpress
                                let type = v.data.type;
                                const designType = getDesignType(type);
                                form.setFieldsValue({
                                  scalablePressDesignType: designType,
                                });
                                this.setState(
                                  {
                                    scalablePressType: v.data.type,
                                    scalableOriginID: v.data.originId,
                                    scalablePressDesignType: designType,
                                  },
                                  () => {
                                    this.handleChangeFulfillment(v, designType);
                                  },
                                );
                              }
                            } else if (
                              hasVariant &&
                              ffType.isGearment &&
                              this.state.productsGearment?.length === 0
                            ) {
                              await this.getProductInfoGearment();
                            } else {
                              form.setFieldsValue({
                                variants: [],
                                attributes: [],
                                fulfillment: v,
                                defaultContent: "",
                                designPositions: [DESIGN_POSITION_DEFAULT],
                              });
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="suppliers"
                        label={"Suppliers"}
                        hidden={!isCustom}
                        rules={[
                          {
                            message: "Supplier is required",
                            required: isCustom,
                          },
                        ]}
                      >
                        <Select
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(selected) => {
                            this.handleUpdateOnSupplierChange(
                              selected ? [selected] : [],
                            );
                          }}
                        >
                          {(isCustom ? teamMembers : suppliers).map(
                            (supplier, index) => (
                              <Select.Option value={supplier.id} key={index}>
                                {`${supplier.firstName} ${supplier.lastName}`}
                              </Select.Option>
                            ),
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Design type"
                        hidden={!isFFScalabelPress}
                        name="scalablePressDesignType"
                        rules={[
                          {
                            message: "Design type is required.",
                            required: isFFScalabelPress,
                          },
                        ]}
                      >
                        <Select
                          onChange={(newVlaue) => {
                            this.setState({
                              scalablePressDesignType: newVlaue,
                            });
                          }}
                        >
                          {designTypeSPArr.map((st, index) => (
                            <Select.Option value={st.key} key={index}>
                              {st.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        rules={[
                          {
                            message: "Product Print Provider is required",
                            required: isPrintify,
                          },
                        ]}
                        name="printifyPrintProviderID"
                        label="Product Print Providers"
                        hidden={!isPrintify}
                      >
                        {loadingProductProviders ? (
                          <Spin />
                        ) : (
                          <Select
                            onChange={this.getProductVariantsIntoPrintify}
                            disabled={!!value}
                            showSearch
                            filterOption={(value, option) => {
                              const opt = (
                                get(option, "data.title") || ""
                              ).toLowerCase();
                              const pt = new RegExp(value, "i");
                              return pt.test(opt);
                            }}
                          >
                            {productProviders.map((p) => (
                              <Select.Option
                                value={p.id}
                                key={`print-provider-${p.id}`}
                                data={p}
                              >
                                {p.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        hidden={
                          !(
                            isEditProductBase &&
                            (isPrintify ||
                              isDreamship ||
                              isCustomcat ||
                              isMerchize)
                          )
                        }
                      >
                        <Button
                          children="Fetch variants missing"
                          onClick={this.fetchVariantsMissing}
                        />
                      </Form.Item>
                    </Col>

                    {!hasVariant &&
                    (!fulfillment ||
                      (fulfillment && !fulfillment.fulfillmentId)) ? (
                      <Col span={24}>
                        {selectedSuppliers.length > 0 && (
                          <Card
                            style={{ marginTop: 20 }}
                            title={"Suppliers pricing"}
                          >
                            <Form.Item name="supplierPricing">
                              <SupplierPricing suppliers={selectedSuppliers} />
                            </Form.Item>
                          </Card>
                        )}
                      </Col>
                    ) : null}
                  </Row>
                </Card>

                <Card
                  title={"Carriers"}
                  style={{ marginTop: 20 }}
                  hidden={!isCustom}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item name="carriers">
                        <Select
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(selected) => {
                            this.handleUpdateOnCarrierChange(selected);
                            this.disableSubmitButton(false);
                          }}
                          mode={"multiple"}
                        >
                          {carriers.map((carrier, index) => (
                            <Select.Option value={carrier.id} key={index}>
                              {carrier.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Card
                        title={"Carrier pricing"}
                        style={{ marginTop: 20 }}
                        hidden={hasVariant || selectedCarriers.length === 0}
                      >
                        <Form.Item name="carrierPricing">
                          <CarrierPricing carriers={selectedCarriers} />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </Card>

                <Card
                  title={"Design Task Default KPI"}
                  style={{ marginTop: 20 }}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item name="kpi">
                        <Select>
                          <Select.Option value={null} key={"default-kpi"}>
                            Select Default KPI
                          </Select.Option>
                          {KPI.map((kpi, idx) => (
                            <Select.Option value={kpi} key={idx}>
                              {kpi}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                {showShipping && (
                  <BoxShipping
                    form={form}
                    isCustomcat={isCustomcat}
                    showShipping={showShipping}
                  />
                )}
                <Card
                  hidden={hasVariant}
                  title={"Pricing"}
                  style={{ marginTop: 20 }}
                >
                  <div>
                    <Form.Item
                      label="Regular Price"
                      name="regularPrice"
                      rules={[
                        {
                          required: !hasVariant,
                          message: "Regular price is required",
                        },
                      ]}
                    >
                      <Price />
                    </Form.Item>
                    <Form.Item label="Sale Price" name={"salePrice"}>
                      <Price />
                    </Form.Item>

                    <Form.Item label="Seller Price" name="sellerPrice">
                      <Price />
                    </Form.Item>
                  </div>
                </Card>
              </Col>
            </Row>

            <Card
              title={
                <div>
                  <h3>Variants</h3>
                  <p className={"sub-card-title"}>
                    <span>
                      {" "}
                      This product base has multiple options, like different
                      sizes or colors
                    </span>
                  </p>
                </div>
              }
              style={{ marginTop: 20, width: "100%" }}
            >
              <div>
                <h3>OPTIONS</h3>
                <Form.Item name="attributes">
                  <ProductBaseAttributes
                    disableSubmitButton={this.disableSubmitButton}
                    hasProduct={!!hasProduct}
                    onChange={(attributes) => {
                      this.disableSubmitButton(false);
                      this.handleOnAttributeUpdate(attributes, fulfillment);
                    }}
                  />
                </Form.Item>
              </div>
            </Card>

            <Form.Item
              style={{ marginTop: 20 }}
              name="variants"
              hidden={!hasVariant}
            >
              <ProductBaseVariants
                isCustom={isCustom}
                isCustomcat={isCustomcat}
                isMerchize={isMerchize}
                isPrintway={isPrintway}
                hasFulfillment={!!checkHasFF} // !!fulfillment
                hasProduct={!!hasProduct}
                carriers={selectedCarriers}
                suppliers={selectedSuppliers}
                isEditProductBase={isEditProductBase}
                form={form}
                hasAdditionalShipping={value?.hasAdditionalShipping}
                isNewForm
              />
            </Form.Item>

            <DesignPositionContainer>
              {(open) => (
                <Form.Item name="designPositions">
                  <ProductBaseDesignPositions
                    hasFulfillment={!isCustom}
                    disableSubmitButton={this.disableSubmitButton}
                    variants={variants}
                    isDreamship={isDreamship}
                    isFFScalabelPress={isFFScalabelPress}
                    scalablePressDT={scalablePressDT}
                    openDPCollapse={open}
                    isEdit={isEditProductBase}
                  />
                </Form.Item>
              )}
            </DesignPositionContainer>

            <Form.Item style={{ marginTop: 20, textAlign: "right" }}>
              <div>
                <Button
                  onClick={() => {
                    if (null === showBtnSave ? false : !showBtnSave) {
                      Modal.confirm({
                        title: "Cancel all unsaved changes?",
                        icon: <ExclamationCircleOutlined />,
                        content: "If Ok, you'll delete any edits you made.",
                        onOk() {
                          history.push("/admin/product-bases");
                        },
                        cancelText: "Continue",
                      });
                    } else {
                      history.push("/admin/product-bases");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  ref={this.submitButtonRef}
                  className="ml-4"
                  type="primary"
                  icon={<SaveOutlined />}
                  loading={loading}
                  htmlType={"submit"}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>

          <BaseTimeLine value={value} />
        </Container>
        <ModalVariantsMissing
          open={openVM}
          loading={loadingVM}
          error={tmpError}
          data={variantsMissing}
          onClose={() => {
            this.setState({ openVM: false, tmpError: null });
          }}
          handleVariantsMissing={this.handleVariantsMissing}
        />
      </React.Fragment>
    );
  }
}

const DesignPositionContainer = ({ children }) => {
  const [open, setOpen] = React.useState(true);

  const toggleOpen = React.useCallback(() => {
    setOpen((p) => !p);
  }, []);

  return (
    <Card
      title={
        <span onClick={toggleOpen} style={{ cursor: "pointer" }}>
          {open ? <CaretDownFilled /> : <CaretRightFilled />}
          <span style={{ marginLeft: 5 }}>Design positions</span>
        </span>
      }
      style={{ marginTop: 20, width: "100%" }}
    >
      {children(open)}
    </Card>
  );
};

// ======= Utils =========
function getDefaultValues(value, isCustomcat, isDreamship) {
  if (!value || typeof value !== "object")
    return {
      title: "",
      niceName: "",
      slug: "",
      baseCostDescription: "",
      defaultContent: "",
      attributes: [],
      variants: [],

      hasVariant: true,
      categories: [],
      suppliers: [],
      supplierPricing: [],
      carriers: [],
      carrierPricing: [],
      kpi: 0.2,
      shippingRate: null,
      isSingleShipment: false,
      usFirstFee: null,
      usAdditionalFee: null,
      canadaFirstFee: null,
      canadaAdditionalFee: null,
      internationalAdditionalFee: null,
      internationalFirstFee: null,
      regularPrice: null,
      salePrice: null,
      sellerPrice: null,
      passOnHold: false,

      designPositions: [DESIGN_POSITION_DEFAULT],
    };

  const { suppliers, carriers, customcatShiping: customcatShipping } = value;

  const basicFields = [
    "title",
    "niceName",
    "slug",
    "baseCostDescription",
    "defaultContent",
    "hasVariant",
    "variants",
    "hasAdditionalShipping",
    "fulfillment",
    "attributes",
    "categories",
    "scalablePressDesignType",
    "printifyPrintProviderID",
    "carrierPricing",
    "supplierPricing",
    "kpi",
    "regularPrice",
    "salePrice",
    "sellerPrice",
    "passOnHold",
    "designPositions",
  ].reduce((acc, key) => {
    if (value[key] !== undefined) {
      acc[key] = value[key];
    }
    return acc;
  }, {});

  const selectedShippingRate = customcatShipping?.shippingRate;
  let DEFAULT_SHIPPING_RATE = [];
  if (customcatShipping?.shippingRate) {
    DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE.filter(
      (i) => i.value !== customcatShipping.shippingRate,
    );
  } else {
    DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE;
  }

  let currentShippingRate = null;
  if (isCustomcat) {
    currentShippingRate = DEFAULT_SHIPPING_RATE.find(
      (i) => i.value === selectedShippingRate,
    );
  }

  let currentShippCost = null;
  if (
    !DEFAULT_SHIPPING_RATE.map((i) => i.value).includes(selectedShippingRate)
  ) {
    currentShippCost = customcatShipping;
  } else {
    currentShippCost = currentShippingRate && currentShippingRate.shippingCost;
  }

  if (isDreamship && !customcatShipping) {
    currentShippCost = GEARMENT_SHIPPING_RATE.shippingCost;
  }

  if (customcatShipping) {
    basicFields["shippingRate"] = selectedShippingRate;
    basicFields["isSingleShipment"] = get(
      customcatShipping,
      "isSingleShipment",
    );
  }

  if (currentShippCost) {
    basicFields["usFirstFee"] = get(currentShippCost, "usFirstFee");
    basicFields["usAdditionalFee"] = get(currentShippCost, "usAdditionalFee");
    basicFields["canadaFirstFee"] = get(currentShippCost, "canadaFirstFee");
    basicFields["canadaAdditionalFee"] = get(
      currentShippCost,
      "canadaAdditionalFee",
    );
    basicFields["internationalAdditionalFee"] = get(
      currentShippCost,
      "internationalAdditionalFee",
    );
    basicFields["internationalFirstFee"] = get(
      currentShippCost,
      "internationalFirstFee",
    );
  }
  return {
    ...basicFields,
    suppliers: getIds(suppliers),
    carriers: getIds(carriers),
  };
}
export function formatVariantsForPrintify(data) {
  if (!data || data.length === 0) return [];

  const mergeAttributes = new Map();
  const variants = data.map((item, index) => {
    const { id, options } = item || {};
    const attributes = Object.entries(options || {}).reduce((acc, opt) => {
      const [key, value] = opt;
      if (mergeAttributes.has(key)) {
        const cur = mergeAttributes.get(key);
        mergeAttributes.set(key, cur.add(value));
      } else {
        mergeAttributes.set(key, new Set([value]));
      }
      const record = {
        name: capitalize(key),
        slug: key,
        option: value,
      };
      acc.push(record);
      return acc;
    }, []);

    return {
      regularPrice: 0,
      salePrice: 0,
      sellerPrice: 0,
      sorting: index,
      attributes,
      fulfillmentProductId: id,
      disabledFPI: true, // FPI = fulfillmentProductId
    };
  });

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = capitalize(key);
      const options = Array.from(value.values());
      return {
        name,
        slug: key,
        options,
      };
    },
  );

  const unique = (variants || []).reduce((acc, cur) => {
    const key = (cur.attributes || []).map((i) => i.option).join("__");
    acc[key] = cur;
    return acc;
  }, {});

  const uniqueArr = Object.values(unique);

  const newVars = [];
  for (let i = 0; i < uniqueArr.length; i++) {
    newVars.push({ ...uniqueArr[i], sorting: i });
  }

  return { variants: newVars, attributes };
}

export function genDesignPositionForPrintify(data, variants) {
  if (!data || data.length === 0) return [];
  const placeholders = data.reduce((acc, item) => {
    const { placeholders, id } = item || {};
    if (Array.isArray(placeholders) && placeholders.length > 0) {
      acc.push(...placeholders.map((i) => ({ ...i, id })));
    }

    return acc;
  }, []);

  const placeholdersSet = new Map();
  for (let item of placeholders) {
    const { height, position, width, id } = item || {};
    const merge = `${position}-${width}-${height}`;

    if (placeholdersSet.has(merge)) {
      placeholdersSet.set(merge, [...placeholdersSet.get(merge), id]);
    } else {
      placeholdersSet.set(merge, [id]);
    }
  }
  const designPosition = Array.from(placeholdersSet.entries()).map(
    ([key, value]) => {
      const [pos, w, h] = key.split(/\-/);
      const description = `${w}x${h}px|PNG|300DPI`;

      const productBaseVariants = (value || [])
        .map((id) => {
          const tmp = (variants || []).find(
            ({ fulfillmentProductId }) => fulfillmentProductId === id,
          );
          if (!tmp) return null;

          const { attributes } = tmp;
          return {
            attributes,
            id: undefined,
          };
        })
        .filter(Boolean);

      const unique = (productBaseVariants || []).reduce((acc, cur) => {
        const key = (cur.attributes || []).map((i) => i.option).join("__");
        acc[key] = cur;
        return acc;
      }, {});

      return {
        name: pos,
        image: null,
        description,
        productBaseVariants: Object.values(unique),
      };
    },
  );

  return designPosition;
}

const colorPattern = /color(s)*/gi;

function getIds(data) {
  if (!data || data.length === 0) return [];
  return data.map(({ id }) => id);
}

function getUniqueBy(arr = [], key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

function getCombineVariants(attributes, variants) {
  if (!attributes.length) {
    return [];
  }
  let res = [];

  const n = attributes.length;
  let indices = [];
  // fill zero
  for (let i = 0; i < n; i++) {
    indices[i] = 0;
  }
  while (true) {
    let options = [];
    let attrs = [];
    for (let i = 0; i < n; i++) {
      const option = attributes[i].options[indices[i]];
      options.push(option);
      attrs.push({
        name: attributes[i].name,
        slug: attributes[i].slug,
        option: option,
      });
    }
    res.push({
      __slug: options.join("-"),
      attributes: attrs,
      regularPrice: null,
      salePrice: null,
      sellerPrice: null,
      fulfillmentProductId: null,
    });
    let next = n - 1;
    while (next >= 0 && indices[next] + 1 >= attributes[next].options.length) {
      next--;
    }
    if (next < 0) {
      break;
    }
    indices[next]++;
    for (let i = next + 1; i < n; i++) {
      indices[i] = 0;
    }
  }

  let mapVariantBySlug = {};
  if (!variants) {
    variants = [];
  }
  for (let i = 0; i < variants.length; i++) {
    const v = variants[i];
    if (v.__slug) {
      mapVariantBySlug[v.__slug] = v;
    } else {
      let options = [];
      for (let j = 0; j < v.attributes.length; j++) {
        const attr = v.attributes[j];
        options.push(attr.option);
      }
      const slug = options.join("-");
      mapVariantBySlug[slug] = v;
    }
  }
  // handle take exist value from prev variants
  for (let i = 0; i < res.length; i++) {
    const v = res[i];
    const prevVariant = mapVariantBySlug[v.__slug];
    if (prevVariant) {
      // res[i] = prevVariant;
      res[i] = { ...prevVariant, attributes: v.attributes }; // Update attributes
    }
    if (typeof res[i].__slug !== "undefined") {
      delete res[i].__slug;
    }
  }
  return res;
}

function handleUpdateVariantSupplierPricing(selected, variants) {
  variants = variants.map((variant) => {
    if (selected && selected.length === 0) {
      variant.supplierPricing = [];
    } else {
      if (!variant.supplierPricing) {
        variant.supplierPricing = [];
      }
      variant.supplierPricing = variant.supplierPricing.filter((v) =>
        selected.includes(v.userId),
      );
      for (let i = 0; i < selected.length; i++) {
        const val = Array.isArray(selected[i]) ? selected[i][0] : selected[i];
        if (!variant.supplierPricing.find((v) => v.userId === val)) {
          variant.supplierPricing.push({
            userId: val,
            price: null,
          });
        }
      }
    }
    return variant;
  });
  return variants;
}

function isFulfillment(fulfillment) {
  if (!fulfillment || typeof fulfillment !== "object") return false;
  return Object.keys(fulfillment).length > 0;
}

function mapItemsByIds(items, ids) {
  if (!items || items.length === 0 || !ids || ids.length === 0) return [];
  const res = [];
  const map = {};
  for (let item of items) {
    map[item.id] = item;
  }

  for (let id of ids) {
    res.push(map[id]);
  }
  return res;
}

function getVariants(variants, isEdit) {
  if (!variants || variants.length === 0) return [];
  return variants.map((v) => {
    const { regularPrice, sellerPrice } = v;
    v.disabledFPI = true; // For [Customcat, Dreamship, Printity, Merchize, Scalable Press, Gearment] can't change fulfillment_product_id
    if (isEdit || regularPrice !== 0 || sellerPrice === 0) return v;

    const newRP = Number.parseFloat(NumberToFixed(sellerPrice / 0.35, 2));
    return {
      ...v,
      regularPrice: newRP,
    };
  });
}

const Container = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

export default ProductBaseFormV2;
