import { Button, ButtonGroup, Checkbox, Collapsible } from "@shopify/polaris";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

export const BulkActions = memo(({ idsChecked, onClose, children }) => {
  // State
  const [open, setOpen] = useState(false);

  // Handle actions
  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  // Get data
  let timeoutRef = React.useRef(null);
  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setOpen(() => {
        return !!idsChecked?.length > 0;
      });
    }, 10);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [idsChecked]);

  // Markup
  const checkboxMarkup = useMemo(
    () => <Checkbox label={`${idsChecked.length} selected`} checked={true} />,
    [idsChecked],
  );

  return (
    <Collapsible
      open={open}
      transition={{ duration: "550ms", timingFunction: "ease" }}
    >
      <Container>
        <ButtonGroup segmented>
          <div className="selected-item">
            <Button children={checkboxMarkup} onClick={handleClose} />
          </div>
          {children}
        </ButtonGroup>
      </Container>
    </Collapsible>
  );
});

const Container = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid #dbdee0;

  .Polaris-Choice {
    padding: 0;

    .Polaris-Choice__Control {
      margin-top: 0.1rem;
    }
  }

  .selected-item {
    .Polaris-Button {
      padding-top: 0.55rem;
      padding-bottom: 0.55rem;
    }
  }
`;
