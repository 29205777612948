import { useContext } from "react";
import { createContext } from "react";

// Mapping order context
export const MOrderContext = createContext({
    toggleToast: () => Boolean,
    notifyChange: () => {},
    filter: {},
});

export const useMappingOrdeCtx = () => useContext(MOrderContext);