import { ApolloProvider } from "@apollo/react-hooks";
import { AppProvider, Spinner } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { gql } from "apollo-boost";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import styled from "styled-components";
import { apolloClient, getTokenObject, setTokenObject } from "./client";
import LinkComponent from "./components/LinkComponent";
import RenderRouter from "./components/RenderRouter";
import { theme } from "./constants/theme-polaris";
import { AppContext } from "./context";
import { GET_USERS_PAYMENT_ACCOUNT_ACCESS } from "./graphql/queries";
import {
  arrInvalid,
  checkMobile,
  checkRole,
  getBackgroundImage,
  getParamByRole,
  getParamByUserRole,
  getPrivateFeatures,
  getTeamID,
  mapPathByExtraMenu,
  uniqueArr,
} from "./helper";
import history from "./history";
import FramePolaris from "./layout/FramePolaris";
import NotFoundPage from "./pages/NotFoundPage";
import routers from "./routers";
import { TEAM_ROLE_PARAMS, USER_ROLE_PARAMS } from "./variable";

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

class App extends Component {
  handleAuthenticate = (obj) => {
    setTokenObject(obj);
    this.setState({
      currentUser: obj.user,
    });
  };

  handleSetCurrentUser = (currentUser) => {
    this.setState({ currentUser });
  };

  handleLogout = () => {
    this.setState({
      currentUser: null,
    });
    setTokenObject(null);
  };

  setStore = (store) => {
    this.setState({ store });
    localStorage.setItem("store", JSON.stringify(store));
  };

  handleTotalMappingOrder = (total) => {
    if (total === this.state.totalMappingOrder) return;
    this.setState({ totalMappingOrder: total });
  };

  handleBackgroundImage = (val) => {
    this.setState({ backgroundImage: val });
  };
  state = {
    loading: true,
    currentUser: null,
    authenticate: this.handleAuthenticate,
    setCurrentUser: this.handleSetCurrentUser,
    logout: this.handleLogout,
    store: null,
    setStore: this.setStore,
    isMobile: false,
    routers: [],
    showPaymentAccount: false,
    // totalMappingOrder: undefined,
    // setTotalMappingOrder: this.handleTotalMappingOrder,
    backgroundImage: "#fff",
    setBackgroundImage: this.handleBackgroundImage,
  };

  componentDidMount() {
    const token = getTokenObject();
    const itemStore = localStorage.getItem("store");
    if (itemStore) {
      this.setState({ store: JSON.parse(itemStore) });
    }
    if (token) {
      this.setState({ loading: true }, () => {
        apolloClient
          .query({
            query: gql`
              query me {
                me {
                  id
                  firstName
                  lastName
                  email
                  phone
                  address
                  extraMenus
                  isRootUser
                  isOnline
                  viewOriginFile
                  privileges
                  avatar {
                    id
                    name
                    url
                  }
                  teamUser {
                    role
                    designOnlineTeamID
                    team {
                      id
                      privateFeatures
                      isRoot
                    }
                  }
                  roles
                  teams {
                    id
                    privateFeatures
                    isRoot
                  }
                }
              }
            `,
          })
          .then((res) => {
            this.setState({
              currentUser: res.data.me,
              loading: false,
            });

            redirectByRole(res.data.me);

            const teamID = getTeamID(res.data.me);
            (async () => {
              if (teamID) {
                const bg = await getBackgroundImage(teamID);
                if (bg) {
                  this.handleBackgroundImage(bg);
                }
              }
            })();
          })
          .catch(() => {
            this.setState({ loading: false });
            setTokenObject(null);
          });
      });
    } else {
      // const newRouters = routers.filter((item) => {
      //   return item.roles == null;
      // });
      this.setState({ loading: false });
    }
    this.setState({ isMobile: checkMobile(), routers });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (!isEqual(prevState.currentUser, this.state.currentUser)) {
      const { currentUser } = this.state;
      if (currentUser) {
        const { roles, teamUser, id, isRootUser } = currentUser;

        let newRouters = [];
        if (roles?.length > 0) {
          newRouters = routers.filter((item) => {
            if (teamUser != null && item.teamRole != null) {
              return item.roles == null || item.teamRole === teamUser.role;
            }

            return (
              item.roles == null || roles.some((r) => item.roles?.includes(r))
            );
          });
        }

        if (teamUser != null) {
          const privateFeatures = getPrivateFeatures(currentUser);
          newRouters = newRouters.filter((item) => {
            if (!item.private) return true;

            if (arrInvalid(privateFeatures)) return true;

            return privateFeatures.some((i) => {
              const pt = new RegExp(i, "gi");
              return pt.test(item.path);
            });
          });
        }

        const { isAdministrator, isSeller, isDesignLeader, isDesigner } =
          checkRole(currentUser);
        const { extraMenus } = currentUser || {};

        if (isDesignLeader || isDesigner) {
          const newExtraMenus = uniqueArr(extraMenus);
          let prefix = isDesigner ? "/designer" : undefined;
          const { tasksMenu, pathMatch } = mapPathByExtraMenu(
            newExtraMenus,
            prefix
          );
          if (!arrInvalid(tasksMenu) && !arrInvalid(pathMatch)) {
            newRouters = newRouters.filter((f) =>
              !f.accessTask ? true : pathMatch.includes(f.path)
            );
          }
        }

        let routeCompact = newRouters.map(
          ({ teamRole, accessTask, private: pr, ...route }) => route
        );

        this.setState(
          {
            routers: routeCompact,
            showPaymentAccount: isRootUser && isAdministrator,
          },
          async () => {
            if (
              (!isAdministrator && !isSeller) ||
              (isRootUser && isAdministrator)
            )
              return;

            const result = await apolloClient.query({
              query: GET_USERS_PAYMENT_ACCOUNT_ACCESS,
              variables: {
                role: "Admin",
                includeAdministrator: true,
              },
            });

            const getUsersPaymentAccountAccess = get(
              result,
              "data.getUsersPaymentAccountAccess"
            );
            const ids = (getUsersPaymentAccountAccess || []).map(
              ({ id }) => id
            );
            const showPaymentAccount = ids.includes(id);
            let newRouters = routeCompact;

            if (!showPaymentAccount) {
              newRouters = routeCompact.filter(
                ({ path }) => !/payment-accounts/gi.test(path)
              );
            }

            this.setState({
              routers: newRouters,
              showPaymentAccount,
            });
          }
        );
      }
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return !isEqual(this.state, nextState);
  }

  render() {
    const { loading, routers } = this.state;
    return (
      <AppProvider
        i18n={enTranslations}
        linkComponent={LinkComponent}
        theme={theme}
      >
        {loading ? (
          <SpinContainer>
            <Spinner />
          </SpinContainer>
        ) : (
          <ApolloProvider client={apolloClient}>
            <style
              type="text/css"
              children={`:root{
                  --omg-custom-bg: ${this.state.backgroundImage}
                }`}
            />
            <AppContext.Provider value={this.state}>
              <Router history={history}>
                <FramePolaris>
                  <Switch>
                    {routers.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          exact={route.exact}
                          path={route.path}
                          component={RenderRouter(
                            route,
                            this.state.currentUser
                          )}
                        />
                      );
                    })}
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </FramePolaris>
              </Router>
            </AppContext.Provider>
          </ApolloProvider>
        )}
      </AppProvider>
    );
  }
}

function redirectByRole(user) {
  if (!user || typeof user !== "object") return;
  let { href } = window.location;

  let param;
  if (user.teamUser == null) {
    param = getParamByUserRole(user);
  } else {
    param = getParamByRole(user);
  }

  const params = [
    ...Object.values(TEAM_ROLE_PARAMS),
    ...Object.values(USER_ROLE_PARAMS),
  ];
  const currentParam = new RegExp(param);
  for (let p of params) {
    const pt = new RegExp(p);
    if (pt.test(href) && !currentParam.test(href)) {
      href = href.replace(p, param);
      window.location.href = href;
      break;
    }
  }
}

export default App;
