import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ApolloConsumer } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tag,
} from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../../context";
import { handleError, toSlug } from "../../../helper";
import history from "../../../history";
import {
  MAX_LENGTH,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
} from "../../../variable";
import CustomizeButton from "../../customize/CustomizeButton";
import { ColorsComponent } from "../../seller/ColorsComponent";
import CollectionsAutoComplete from "../../shared/CollectionsAutoComplete";
import PageTitle from "../../shared/PageTitle";
import TagsAutoComplete from "../../shared/TagsAutoComplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";

const Container = styled.div`
  .ant-card {
    margin-bottom: 20px;
  }
  .tag-wrap {
    border-radius: 3px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .product-fake-wrap {
    .ant-tabs-nav-wrap {
      padding-left: 0;
    }
    .ant-tabs-tab {
      padding-left: 0;
      max-width: 180px;
      white-space: break-spaces;
      text-align: left;
      padding-right: 12px;
    }
    .ant-tabs-ink-bar {
      height: auto;
    }
    .ant-tabs-bar {
      height: 400px;
    }
    .ant-tabs-content {
      height: 400px;
      overflow-y: scroll;
    }
    .product-fake-item {
      label {
        display: inline-block;
      }
      &.files {
        width: 65%;
      }
      & + .product-fake-item {
        margin-top: 1rem;
      }
    }
  }
`;

export class AddProductForm extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      showPreview: false,
      disabled: true,
      showBtnSave: null,
      productBase: null,
      template: {
        visible: false,
        loading: false,
      },
      productVariantsVisible: false,
      productAttributes: null,
      variantsState: null,
      currentProductBase: props.productBase,
      firstColor: null,
    };

    this.handleChangeAttrOption = this.handleChangeAttrOption.bind(this);
  }

  handleChangeAttrOption = (
    attrSlug,
    optionSlug,
    data,
    productBaseVariants
  ) => {
    let state = this.state;
    let newProductAttributes = state.productAttributes;
    let disabledVal =
      newProductAttributes[attrSlug]["options"][optionSlug]["disabled"];
    newProductAttributes[attrSlug]["options"][optionSlug][
      "disabled"
    ] = !disabledVal;
    let countAttrs = Object.keys(newProductAttributes).length;

    // Get list active options of every attribute
    let attrActivatedOptions = {};
    if (countAttrs > 1) {
      _.forOwn(newProductAttributes, function (el, key) {
        let optionActivated = [];
        _.forOwn(el.options, function (v) {
          if (!v.disabled) {
            optionActivated.push(v.label);
          }
        });
        attrActivatedOptions[key] = optionActivated;
      });
    }

    let newProductBaseVariants = state.variantsState
      ? state.variantsState
      : productBaseVariants;
    let affectKeys = [];
    _.forOwn(newProductBaseVariants, function (el, key) {
      if (
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (v.slug === attrSlug && v.option === data.label) {
            let pushVal = {
              key: key,
              disabled: el.disabled,
            };
            affectKeys.push(pushVal);

            let newDisabledVar = !disabledVal;
            // If product has more than 2 attributes, then check complex condition active variants.
            if (countAttrs > 1 && disabledVal) {
              // Only check case active attribute.
              if (
                el.productBaseVariant &&
                el.productBaseVariant.attributes &&
                el.productBaseVariant.attributes.length > 0
              ) {
                let countMatch = 0;
                el.productBaseVariant.attributes.forEach((at) => {
                  let atSlug = at.slug;
                  let atOption = at.option;
                  let attrOptionsActivated = attrActivatedOptions[atSlug];
                  if (
                    attrOptionsActivated.length > 0 &&
                    attrOptionsActivated.includes(atOption)
                  ) {
                    countMatch++;
                  }
                });
                if (countMatch !== countAttrs) {
                  newDisabledVar = disabledVal;
                }
              }
            }
            return (el.disabled = newDisabledVar);
          }
        });
      }
    });

    // Count product to prevent disable all variants
    let countActivated = this.countActivatedVariants(newProductBaseVariants);
    if (0 === countActivated) {
      newProductAttributes[attrSlug]["options"][optionSlug][
        "disabled"
      ] = disabledVal;
      if (affectKeys.length > 0) {
        affectKeys.forEach(function (v, k) {
          newProductBaseVariants[v.key].disabled = v.disabled;
        });
      }
      alert("You must keep at least one option for each attributes");
    }
    this.setState({
      productAttributes: newProductAttributes,
      variantsState: newProductBaseVariants,
      showBtnSave: false,
    });

    this.ref.current.setFieldsValue({
      variants: newProductBaseVariants,
    });
  };

  countActivatedVariants = (variants) => {
    let countActivated = 0;
    _.forOwn(variants, function (el, key) {
      if (
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        if (!el.disabled) {
          countActivated++;
        }
      }
    });
    return countActivated;
  };

  strToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  getProductBaseAttrs = (productBaseAttrs, activateAttrs, variants) => {
    let pBaseAttrs = {};

    let disabledAll = false;
    if (variants) {
      let countActivated = this.countActivatedVariants(variants);
      if (0 === countActivated) {
        disabledAll = true;
      }
    }

    if (productBaseAttrs && productBaseAttrs.length > 0) {
      productBaseAttrs.forEach((val) => {
        let attrOptions = {};
        let enableFirst = !activateAttrs && !variants && "color" === val.slug;
        val.options.forEach((opt) => {
          let disableVal = false;
          if (disabledAll) {
            disableVal = true;
          }
          if (
            activateAttrs &&
            activateAttrs[val.slug] &&
            activateAttrs[val.slug].length > 0
          ) {
            disableVal = true;
            if (_.includes(activateAttrs[val.slug], opt)) {
              disableVal = false;
            }
          }
          if (enableFirst) {
            let firstValue = _.head(val.options);
            if (opt === firstValue) {
              disableVal = false;
            } else {
              disableVal = true;
            }
          }
          attrOptions[this.strToSlug(opt)] = {
            label: opt,
            disabled: disableVal,
          };
        });
        pBaseAttrs[val.slug] = {
          name: val.name,
          slug: val.slug,
          options: attrOptions,
        };
      });
    }
    return pBaseAttrs;
  };

  getActivatedProductAttrs = (variants) => {
    let activatedAttrs = {};

    _.forOwn(variants, function (el, key) {
      if (
        !el.disabled &&
        el.productBaseVariant &&
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (!activatedAttrs[v.slug]) {
            activatedAttrs[v.slug] = [];
          }
          activatedAttrs[v.slug].push(v.option);
        });
      }
    });
    return activatedAttrs;
  };

  componentDidMount() {
    const { value, productBase } = this.props;
    if (!value && productBase) {
      let tags = [],
        collections = [];

      let product = {};
      let fields = [];
      if (fields) {
        fields = fields.map((f) => {
          if (typeof f.__typename !== "undefined") {
            delete f.__typename;
          }
          if (typeof f.sorting !== "undefined") {
            delete f.sorting;
          }
          return f;
        });
      }

      let firstColor;
      if (
        productBase &&
        productBase.attributes &&
        productBase.attributes.length > 0
      ) {
        productBase.attributes.forEach((att) => {
          if ("color" === att.slug && att.options && att.options.length) {
            firstColor = _.head(att.options);
            this.setState({
              firstColor,
            });
          }
        });
        this.setState({
          productAttributes: this.getProductBaseAttrs(productBase.attributes),
        });
      }

      this.ref.current.setFieldsValue({
        productBaseId: productBase.id,
        variants: this.getProductVariants(
          productBase,
          product,
          true,
          firstColor
        ),
        description: productBase.defaultContent,
        shortDescription: productBase.shortDescription,
        tags,
        collections,
        personalized: false,
        fields: fields ? fields : [],
      });

      this.setState({
        showPreview: false,
      });
    }

    let setState = {};
    if (productBase) {
      setState["currentProductBase"] = productBase;
    }

    this.setState(setState);
  }

  componentDidUpdate(prevProps) {
    let { clickSave } = this.props;
    if (clickSave !== prevProps.clickSave) {
      this.ref.current.submit();
    }
  }
  getProductVariants = (productBase, product = {}, enableFirst, firstColor) => {
    if (!productBase.variants) {
      return [];
    }

    return productBase.variants.map((variant, idx) => {
      let disabledVal = true;
      if (
        variant.attributes &&
        variant.attributes.length &&
        enableFirst &&
        firstColor
      ) {
        variant.attributes.forEach((att) => {
          if (
            att.slug &&
            "color" === att.slug &&
            att.option &&
            att.option.toLowerCase() === firstColor.toLowerCase()
          ) {
            disabledVal = false;
          }
        });
      }
      let currentBaseVarID = variant.id;
      let i = idx;
      if (Object.keys(product).length) {
        i = _.findIndex(product && product.variants, {
          productBaseVariantID: currentBaseVarID,
        });
      }
      return {
        productBaseVariantId: variant.id,
        productBaseVariant: variant,
        image: null,
        regularPrice: Object.keys(product).length
          ? product.variants[i].regularPrice
          : variant.regularPrice,
        salePrice: Object.keys(product).length
          ? product.variants[i].salePrice
          : variant.salePrice,
        disabled:
          enableFirst && firstColor
            ? disabledVal
            : Object.keys(product).length
            ? product.variants[i].disabled
            : variant && variant.disabled
            ? variant.disabled
            : false,
      };
    });
  };

  onFinish = (values) => {
    const {
      name,
      slug,
      fields,
      collections,
      tags,
      defaultMockups,
      description,
      productBaseId,
      variants,
    } = values;
    const defaultMockupIds = (defaultMockups || [])
      .map((val) => val.id)
      .filter(Boolean);

    const tagIds = tags ? tags.map((t) => t.id) : [];
    const collectionIds = collections ? collections.map((t) => t.id) : [];
    const newVariants = variants.map((v) => {
      return {
        salePrice: v.salePrice,
        regularPrice: v.regularPrice,
        productBaseVariantID: v.productBaseVariantId,
        disabled: v.disabled,
      };
    });
    const input = {
      name,
      slug,
      defaultMockupIds,
      productBaseId,
      fields,
      product: {
        title: "",
        description: description || "",
        productbaseId: productBaseId,
        shortDescription: "",
        tagIds,
        collectionIds,
        variants: newVariants,
      },
    };

    this.props.onSubmit(input);
  };

  countState = (variantsState) => {
    let counEnabled = 0;
    let counDisabled = 0;
    variantsState &&
      variantsState.length &&
      variantsState.forEach((val) => {
        if (!val.disabled) {
          counEnabled++;
        } else {
          counDisabled++;
        }
      });
    return {
      counEnabled,
      counDisabled,
    };
  };

  showNotification = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er ? er.toLowerCase() : null);
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };
  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Template name is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  render() {
    const { value, loading, productBase, currentParam } = this.props;
    const showVariants =
      productBase && productBase.variants && productBase.variants.length;

    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    const productAttrs = this.state.productAttributes;
    const productBaseVariants = this.getProductVariants(
      productBase,
      {},
      true,
      this.state.firstColor
    );

    let { variantsState } = this.state;
    let count = this.countState(
      variantsState ? variantsState : productBaseVariants
    );
    let findPB = this.props.productBases.find((pb) => pb.id === productBase.id);

    return (
      <React.Fragment>
        <Prompt
          when={false === this.state.showBtnSave}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <ApolloConsumer>
          {(_client) => (
            <AppContext.Consumer>
              {() => (
                <Container>
                  <Form
                    {...layout}
                    initialValues={{
                      description: productBase.defaultContent,
                      collections: [],
                      tags: [],
                      productBaseId: productBase ? productBase.id : null,
                      variants: [],
                      personalized: false,
                      fields: [],
                    }}
                    ref={this.ref}
                    onFinish={async (values) => {
                      this.setState({
                        showBtnSave: true,
                      });
                      if (!value) {
                        values.variants = this.ref.current.getFieldValue(
                          "variants"
                        );
                      }
                      this.onFinish(values);
                    }}
                    scrollToFirstError
                    onValuesChange={(changedValues, values) => {
                      const { name } = changedValues || {};
                      if (name) {
                        const slug = toSlug(name);
                        this.ref.current.setFieldsValue({ slug });
                      }
                      this.setState((prev) => {
                        if (prev.showBtnSave === false) {
                          return prev;
                        }

                        return { showBtnSave: false };
                      });
                    }}
                    onFinishFailed={({ _, errorFields }) =>
                      this.showNotification(errorFields)
                    }
                  >
                    <PageTitle
                      subTitle={`Templates`}
                      title={
                        this.props.title
                          ? this.props.title
                          : "Add Template for Product"
                      }
                      link={`/${currentParam}/templates`}
                    />
                    <Row gutter={16}>
                      <Col span={24}>
                        <Card>
                          <Form.Item
                            name={"name"}
                            label={"Template name"}
                            rules={[
                              {
                                required: true,
                                validator: this.validateTitle,
                              },
                            ]}
                          >
                            <Input placeholder={"Template name"} />
                          </Form.Item>
                          <Form.Item
                            name="slug"
                            label="Template slug"
                            rules={[
                              {
                                required: true,
                                message: "Template slug is required ",
                              },
                            ]}
                          >
                            <Input placeholder="Template slug" />
                          </Form.Item>
                          <Form.Item label={"Description"} name={"description"}>
                            <Wysiwyg />
                          </Form.Item>
                          <Form.Item
                            label="Default Mockup Files"
                            name="defaultMockups"
                          >
                            <MediaSelectorButton
                              prefix="mockups/"
                              folder={UPLOAD_FOLDER.MOCKUPS}
                              multiple={true}
                              listType={"picture-card"}
                              buttonType="primary"
                              accept={"image/*"}
                            />
                          </Form.Item>
                        </Card>
                        <Card title={"Organization"}>
                          <Form.Item name={"collections"} label={"Collections"}>
                            <CollectionsAutoComplete />
                          </Form.Item>
                          <Form.Item name={"tags"} label={"Tags"}>
                            <TagsAutoComplete />
                          </Form.Item>
                        </Card>
                        <Card>
                          <Form.Item
                            name={"productBaseId"}
                            label={"Product Base"}
                            rules={[
                              {
                                required: true,
                                message: "Product base is required!",
                              },
                            ]}
                          >
                            <Select
                              disabled
                              onChange={(_id, option) => {
                                const productBase = option.data;
                                let newVariants = this.getProductVariants(
                                  productBase
                                );

                                this.ref.current.setFieldsValue({
                                  variants: newVariants,
                                  description: option.data.defaultContent,
                                });
                                if (this.props.onChangeProductBase) {
                                  this.props.onChangeProductBase(productBase);
                                }

                                if (
                                  productBase &&
                                  productBase.attributes &&
                                  productBase.attributes.length > 0
                                ) {
                                  let pBaseAttrs = this.getProductBaseAttrs(
                                    productBase.attributes
                                  );

                                  this.setState({
                                    productAttributes: pBaseAttrs,
                                    variantsState: newVariants,
                                    currentProductBase: productBase,
                                  });
                                }
                              }}
                            >
                              {undefined === findPB && (
                                <Select.Option
                                  value={productBase.id}
                                  key={productBase.id}
                                  data={productBase}
                                >
                                  {productBase.title}
                                </Select.Option>
                              )}
                              {this.props.productBases.map((base, index) => (
                                <Select.Option
                                  value={base.id}
                                  key={index}
                                  data={base}
                                >
                                  {base.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Card>

                        <Card
                          title={"Product Attributes"}
                          hidden={!showVariants}
                        >
                          {productAttrs &&
                            Object.keys(productAttrs).map(
                              (attribute, index) => (
                                <div key={`attribute-${attribute}`}>
                                  <div className={"attribute-title"}>
                                    {productAttrs[attribute] &&
                                      productAttrs[attribute].name}
                                    :
                                  </div>
                                  <div style={{ marginBottom: 12 }}>
                                    {productAttrs[attribute] &&
                                      productAttrs[attribute]["options"] &&
                                      Object.keys(
                                        productAttrs[attribute]["options"]
                                      ).map((option) => {
                                        let enableAttributes =
                                          "color" ===
                                          productAttrs[attribute].slug;

                                        let currentOption;
                                        if (enableAttributes) {
                                          currentOption =
                                            productAttrs[attribute][
                                              "options"
                                            ] &&
                                            productAttrs[attribute]["options"][
                                              option
                                            ];
                                        }

                                        let optionSlug =
                                          currentOption &&
                                          this.strToSlug(
                                            currentOption["label"]
                                          );

                                        let optionDisabled =
                                          currentOption &&
                                          currentOption["disabled"];

                                        let optionLabel =
                                          currentOption &&
                                          currentOption["label"];

                                        if (optionLabel) {
                                          let hasFlag =
                                            optionLabel.match("/") &&
                                            optionLabel.split("/");
                                          if (hasFlag && hasFlag.length) {
                                            let value = hasFlag.filter((v) =>
                                              v.trim()
                                            );
                                            if (1 === value.length) {
                                              optionLabel = value;
                                            }
                                          }
                                        }

                                        return (
                                          <div
                                            style={{ display: "inline-block" }}
                                            key={`option-${option}`}
                                          >
                                            <Tag
                                              key={`option-${option}`}
                                              className={
                                                enableAttributes
                                                  ? `tag-wrap`
                                                  : ""
                                              }
                                              style={{
                                                color:
                                                  enableAttributes &&
                                                  optionDisabled
                                                    ? "#1890ff"
                                                    : "",

                                                cursor: "pointer",
                                                marginTop: 8,
                                                padding: "3px 8px",
                                              }}
                                              color={
                                                productAttrs[attribute][
                                                  "options"
                                                ][option]["disabled"]
                                                  ? ""
                                                  : "#1890ff"
                                              }
                                              onClick={() => {
                                                this.handleChangeAttrOption(
                                                  attribute,
                                                  option,
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option],
                                                  productBaseVariants
                                                );
                                              }}
                                            >
                                              {enableAttributes && (
                                                <ColorsComponent
                                                  optionSlug={optionSlug}
                                                />
                                              )}
                                              {enableAttributes
                                                ? optionLabel
                                                : productAttrs[attribute][
                                                    "options"
                                                  ] &&
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option] &&
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option]["label"]}
                                            </Tag>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              )
                            )}
                          <div className={"variant-total mt-6"}>
                            {count && count.counDisabled >= 0 && (
                              <div>
                                Total enabled: <span>{count.counEnabled}</span>
                              </div>
                            )}
                            {count && count.counDisabled >= 0 && (
                              <div>
                                Total disabled:{" "}
                                <span>{count.counDisabled}</span>
                              </div>
                            )}
                          </div>
                        </Card>

                        <Card title={"Personalized"}>
                          <Form.Item
                            name={"personalized"}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={(e) => {
                                this.setState({
                                  showPreview: e.target.checked,
                                });
                              }}
                            >
                              This is a personalized product
                            </Checkbox>
                          </Form.Item>
                          <div>
                            <Form.Item
                              name={"fields"}
                              rules={[
                                {
                                  required: this.state.showPreview,
                                  message: "Personalized is required.",
                                },
                              ]}
                            >
                              <CustomizeButton
                                showPreview={this.state.showPreview}
                              />
                            </Form.Item>
                          </div>
                        </Card>
                        <Form.Item
                          style={{ marginTop: 20, textAlign: "right" }}
                        >
                          <Button
                            onClick={() => {
                              if (
                                null === this.state.showBtnSave
                                  ? false
                                  : !this.state.showBtnSave
                              ) {
                                Modal.confirm({
                                  title: "Cancel all unsaved changes?",
                                  icon: <ExclamationCircleOutlined />,
                                  content:
                                    "If Ok, you’ll delete any edits you made.",
                                  onOk() {
                                    history.push(`/${currentParam}/templates`);
                                  },
                                  cancelText: "Continue",
                                });
                              } else {
                                history.push(`/${currentParam}/templates`);
                              }
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="ml-4"
                            type="primary"
                            loading={loading}
                            htmlType={"submit"}
                            disabled={
                              null === this.state.showBtnSave
                                ? true
                                : this.state.showBtnSave
                            }
                          >
                            Create Template
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              )}
            </AppContext.Consumer>
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

AddProductForm.propTypes = {
  productBase: PropTypes.object,
  productBases: PropTypes.array,
  value: PropTypes.any,
  onSubmit: PropTypes.func,
  onChangeProductBase: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  sku: PropTypes.string,
};
