import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";

export const ChoosePBPolaris = ({ data, fulfillmentId, onChange }) => {
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selectedOption, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    let options = [];
    if (
      data &&
      data.productBasesForAssortment &&
      data.productBasesForAssortment.length
    ) {
      options = data.productBasesForAssortment.filter((pb) => {
        if (fulfillmentId) {
          if (
            pb.fulfillment &&
            pb.fulfillment.fulfillmentId === fulfillmentId
          ) {
            return true;
          }
        } else {
          if (pb.fulfillment === null) {
            return true;
          }
        }
        return false;
      });
      options = options.map((option) => ({
        value: option.id,
        label: option.title,
      }));
    }
    setDeselectedOptions(options);
    setOptions(options);
  }, [data, fulfillmentId]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });

      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
      if (onChange) {
        onChange({ productBaseId: _.head(selected) });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      value={inputValue}
      onChange={handleInputChange}
      label="product base"
      labelHidden
      placeholder="Search product base"
    />
  );
  return (
    <Autocomplete
      options={options}
      selected={selectedOption}
      onSelect={handleSelectChange}
      textField={textField}
      emptyState="No items found!"
    />
  );
};
