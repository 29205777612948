import {
  Button,
  ButtonGroup, Checkbox, ChoiceList, Collapsible, Form,
  FormLayout, Labelled, Scrollable, Select, Stack, TextField
} from "@shopify/polaris";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import history from "../../../history";
// import { ProductBaseSelectPolaris } from "../../base/ProductBaseSelectPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { StoresSelectPolaris } from "../../store/StoresSelectPolaris";
// import { ChoiceProductPolaris } from "./ChoiceProductPolaris";
import { CollectionSelectPolaris } from "../collections/CollectionSelectPolaris";
import { ProductsSelectPolaris } from "../ProductsSelectPolaris";
import { TagsSelectPolaris } from "../tags/TagsSelectPolaris";
import { AgeGroupPolaris } from "./AgeGroupPolaris";
import { ConditionPolaris } from "./ConditionPolaris";
import { ExcludeWordsPolaris } from "./ExcludeWordsPolaris";
import { ExportModePolaris } from "./ExportModePolaris";
import { GenderPolaris } from "./GenderPolaris";
import { GoogleProductCategoryPolaris } from "./GoogleProductCategoryPolaris";
import { IdentifierExistsPolaris } from "./IdentifierExistsPolaris";
import { StockPolaris } from "./StockPolaris";
import { TargetStore } from "./TargetStore";
import { UseUpcPolaris } from "./UseUpcPolaris";
import { VariantTitlePolaris } from "./VariantTitlePolaris";


const BaseSelect = React.lazy(() => import("../../base/components/BaseSelect"));
const CustomPrice = React.lazy(() => import("./CustomPrice"));

const Container = styled.div`
  div[role="combobox"]:focus {
    outline: none;
  }
  .heading-wrap {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin: 0;
  }

  .Polaris-Stack__Item:has(> div[aria-hidden="true"]) {
    display: none;
  }
`;

const SORT_TYPE_VALUES = ["DESC", "ASC"];
const SORT_BY_VALUES = ["products.created_at", "products.total_orders"];

export const ProductFeedFormPolaris = ({
  data,
  btnLabel,
  onSubmit,
  loading,
  error,
  value,
  stores: storesProps,
  urlStoreManager,
}) => {
  const [name, setName] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [targetStore, setTargetStore] = useState(null);
  const [filter, setFilter] = useState("All");
  const [collections, setCollections] = useState([]);
  const [productBases, setProductBases] = useState([]);
  const [excludeCollections, setExcludeCollections] = useState([]);
  const [tags, setTags] = useState([]);
  const [excludeTags, setExcludeTags] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [excludeItems, setExcludeItems] = useState(null);
  const [mode, setMode] = useState("all");
  const [productTitlePrefix, setProductTitlePrefix] = useState("");
  const [productTitleSuffix, setProductTitleSuffix] = useState("");
  const [excludeWords, setExcludeWords] = useState(null);
  const [variantTitle, setVariantTitle] = useState(false);
  const [category, setCategory] = useState("");
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [brand, setBrand] = useState("");
  const [condition, setCondition] = useState("new");
  const [tax, setTax] = useState("US::0.00:n");
  const [shipping, setShipping] = useState("US:::6.95 USD");
  const [stock, setStock] = useState("all products");
  const [identifierExists, setIdentifierExists] = useState("false");
  const [useUpc, setUseUpc] = useState("false");
  const [errors, setErrors] = useState({
    name: null,
    store: null,
    brand: null,
  });
  const [isPrompt, setIsPrompt] = useState(false);
  const [hasDesign, setHasDesign] = useState(false);
  const [alternativeDomain, setAlternativeDomain] = useState(null);
  const [usingFixedColor, setUsingFixedColor] = useState(false);
  const [fixedColor, setFixedColor] = useState("");
  const [promotionId, setPromotionId] = useState(null);
  const [maxTitleLength, setMaxTitleLength] = useState("");
  const [maxContentLength, setMaxContentLength] = useState("");
  const [hasSale, setHasSale] = useState(false);
  const [modifyPrice, setModifyPrice] = useState({
    type: "Add",
    value: "",
    open: false,
  });
  const [modifyStateInTitle, setModifyStateInTitle] = useState({
    enable: false,
    modifyStateByAcronym: "false",
  });
  const [isRegenEveryDay, setIsRegenEveryDay] = useState(false);
  const [description, setDescription] = useState({
    prefix: "",
    suffix: "",
  });

  const [extraFields, setExtraFields] = useState({
    limit: "0",
    sortType: ["DESC"],
    sortBy: ["products.created_at"],
  });

  useEffect(() => {
    const newStores =
      data?.stores?.nodes?.length > 0
        ? data.stores.nodes.map((s) => {
          return {
            value: s.id,
            label: s.title,
          };
        })
        : [];
    setStores(newStores);
  }, [data]);

  useEffect(() => {
    if (value) {
      let { useUpc } = value;
      let newUseUpc = useUpc === true ? "true" : "false";
      setName(_.get(value, "name", ""));
      setCollections(_.get(value, "collections", []));
      setTags(_.get(value, "tags", []));
      setExcludeCollections(_.get(value, "excludeCollections", []));
      setExcludeTags(_.get(value, "excludeTags", []));
      setSpecialProducts(_.get(value, "excludeProducts", []));
      setExcludeWords(value && value.excludeWords ? value.excludeWords : []);
      setExcludeItems(
        _.get(value, "excludeItems") ? value.excludeItems.join("\n") : ""
      );
      const pBases = getData(value.productBases);
      setProductBases(pBases);
      setStoreId(
        _.get(
          value,
          "store.id",
          storesProps && storesProps.length === 1
            ? _.get(storesProps, "[0].id")
            : null
        )
      );

      setTargetStore(_.get(value, "storeTargetDataId", null));
      setFilter(_.get(value, "filter", "All"));
      setMode(_.get(value, "mode", "all"));
      setVariantTitle(_.get(value, "variantTitle", false));
      setCategory(_.get(value, "category", null));
      setGender(_.get(value, "gender", null));
      setAgeGroup(_.get(value, "ageGroup", null));
      setBrand(_.get(value, "brand", null));
      setCondition(_.get(value, "condition", "new"));
      setTax(_.get(value, "tax", "US::0.00:n"));
      setShipping(_.get(value, "shipping", "US:::6.95 USD"));
      setStock(_.get(value, "stock", "all products"));
      setIdentifierExists(_.get(value, "identifierExists", "false"));
      setProductTitlePrefix(_.get(value, "productTitlePrefix", ""));
      setProductTitleSuffix(_.get(value, "productTitleSuffix", ""));
      setExcludeWords(_.get(value, "excludeWords", []));
      setUseUpc(newUseUpc);
      setHasDesign(_.get(value, "hasDesign", false));
      setAlternativeDomain(_.get(value, "alternativeDomain", null));
      setUsingFixedColor(_.get(value, "usingFixedColor", null));
      setFixedColor(_.get(value, "fixedColor", false));
      setPromotionId(_.get(value, "promotionId", null));

      let newMaxTitleLength = _.get(value, "maxTitleLength", null);
      newMaxTitleLength = newMaxTitleLength ? newMaxTitleLength.toString() : "";
      setMaxTitleLength(newMaxTitleLength);

      let newMaxContentLength = _.get(value, "maxContentLength", null);
      newMaxContentLength = newMaxContentLength
        ? newMaxContentLength.toString()
        : "";
      setMaxContentLength(newMaxContentLength);
      setHasSale(_.get(value, "hasSale", false));

      const { modifyPrice, modifyPriceValue } = value;
      if (modifyPrice || modifyPriceValue) {
        setModifyPrice((prev) => ({
          ...prev,
          open: true,
          type: modifyPrice,
          value: modifyPriceValue ? modifyPriceValue + "" : "",
        }));
      }
      setModifyStateInTitle(() => ({
        enable: _.get(value, "modifyStateInTitle", false),
        modifyStateByAcronym: _.get(value, "modifyStateInTitleByAcronym", false)
          ? "true"
          : "false",
      }));

      setIsRegenEveryDay(_.get(value, "IsRegenEveryDay", false));
      const prefix = _.get(value, "descriptionPrefix", "");
      const suffix = _.get(value, "descriptionSuffix", "");
      setDescription({ prefix, suffix });

      const extraVal = {};
      if (_.get(value, 'limit')) {
        extraVal.limit = _.get(value, 'limit') + '';
      }

      if (_.get(value, 'sortType') && SORT_TYPE_VALUES.includes(_.get(value, 'sortType'))) {
        extraVal.sortType = [_.get(value, 'sortType')]
      }

      if (_.get(value, 'sortBy') && SORT_BY_VALUES.includes(_.get(value, 'sortBy'))) {
        extraVal.sortBy = [_.get(value, 'sortBy')]
      }

      setExtraFields((p) => ({ ...p, ...extraVal }))
    }
  }, [value, storesProps]);

  const handleNameChange = useCallback((value) => {
    setName(value);
    handleNameValue(value);
    setIsPrompt(true);
  }, []);
  const handleStoreChange = useCallback((value) => {
    setStoreId(value);
    handleStoreValue(value);
    setIsPrompt(true);
  }, []);
  // const handleFilterChange = useCallback((value) => {
  //     setFilter(value);
  //     setIsPrompt(true);
  // }, []);
  const handleCollection = useCallback((value) => {
    setCollections(value);
    setIsPrompt(true);
  }, []);
  const handleTag = useCallback((value) => {
    setTags(value);
    setIsPrompt(true);
  }, []);
  const handleProductBases = useCallback((value) => {
    setProductBases(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeCollections = useCallback((value) => {
    setExcludeCollections(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeTags = useCallback((value) => {
    setExcludeTags(value);
    setIsPrompt(true);
  }, []);
  const handleSpecialProducts = useCallback((value) => {
    setSpecialProducts(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeItems = useCallback((value) => {
    setExcludeItems(value);
    setIsPrompt(true);
  }, []);
  const handleModeChange = useCallback((value) => {
    setMode(value);
    setIsPrompt(true);
  }, []);
  const handleProductTitlePrefix = useCallback((value) => {
    setProductTitlePrefix(value);
    setIsPrompt(true);
  }, []);
  const handleProductTitleSuffix = useCallback((value) => {
    setProductTitleSuffix(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeWord = useCallback((value) => {
    setExcludeWords(value);
    setIsPrompt(true);
  }, []);
  const handleVariantTitle = useCallback((value) => {
    setVariantTitle(_.head(value));
    setIsPrompt(true);
  }, []);
  const handleCategory = useCallback((value) => {
    setCategory(value);
    setIsPrompt(true);
  }, []);
  const handleGender = useCallback((value) => {
    setGender(value);
    setIsPrompt(true);
  }, []);
  const handleAgeGroup = useCallback((value) => {
    setAgeGroup(value);
    setIsPrompt(true);
  }, []);
  const handleBrand = useCallback((value) => {
    setBrand(value);
    handleBrandValue(value);
    setIsPrompt(true);
  }, []);
  const handleCondition = useCallback((value) => {
    setCondition(value);
    setIsPrompt(true);
  }, []);
  const handleTax = useCallback((value) => {
    setTax(value);
    setIsPrompt(true);
  }, []);
  const handleShipping = useCallback((value) => {
    setShipping(value);
    setIsPrompt(true);
  }, []);
  const handleStock = useCallback((value) => {
    setStock(value);
    setIsPrompt(true);
  }, []);
  const handleIdentifierExists = useCallback((value) => {
    setIdentifierExists(value);
    setIsPrompt(true);
  }, []);
  const handleUseUpc = useCallback((value) => {
    setUseUpc(value);
    setIsPrompt(true);
  }, []);
  const handleMaxTitleLength = useCallback((value) => {
    setMaxTitleLength(value);
    setIsPrompt(true);
  }, []);
  const handleMaxContentLength = useCallback((value) => {
    setMaxContentLength(value);
    setIsPrompt(true);
  }, []);
  const handleToggleDesign = useCallback(() => {
    setHasDesign((prev) => !prev);
    setIsPrompt(true);
  }, []);

  const handleNameValue = (value) => {
    setErrors((prevState) => ({
      ...prevState,
      name: !value ? "Feed name is required." : null,
    }));
  };
  const handleStoreValue = (value) => {
    setErrors((prevState) => ({
      ...prevState,
      store: !value ? "Store is required." : null,
    }));
  };
  const handleBrandValue = (value) => {
    setErrors((prevState) => ({
      ...prevState,
      brand: !value ? "Default brand is required." : null,
    }));
  };

  const handleADChange = useCallback((value, validate = false) => {
    const err = value ? validateDomain(value) : false;
    setErrors((prev) => ({
      ...prev,
      alternativeDomain: err
        ? "Your alternative domain must start with https:// or http://"
        : null,
    }));
    if (!validate) {
      setIsPrompt(true);
      setAlternativeDomain(value);
    }
  }, []);

  const handleUsingPCChange = useCallback((value) => {
    setUsingFixedColor(value);
    setIsPrompt(true);
  }, []);

  const handlePCChange = useCallback((value) => {
    setFixedColor(value);
    setIsPrompt(true);
  }, []);

  const handlePromotionIdChange = useCallback((value) => {
    setIsPrompt(true);
    setPromotionId(value);
  }, []);

  const handleHasSaleChange = useCallback((value) => {
    setHasSale(value);
    setIsPrompt(true);
  }, []);

  const handleModifyStateInTitle = useCallback((value) => {
    setModifyStateInTitle((prev) => ({ ...prev, enable: value }));
    setIsPrompt(true);
  }, []);

  const handleModifyStateInTitleByAcronym = useCallback((value) => {
    setModifyStateInTitle((prev) => ({
      ...prev,
      modifyStateByAcronym: value[0],
    }));
  }, []);

  const handleDescriptionChange = useCallback((value, id) => {
    setIsPrompt(true);
    setDescription((p) => ({ ...p, [id]: value }));
  }, []);

  const handleTargetStoreChange = useCallback((value) => {
    setIsPrompt(true);
    setTargetStore(value);
  }, []);

  const customizeRef = React.useRef(null);
  const handleOnSubmit = useCallback(() => {
    handleNameValue(name);
    handleStoreValue(storeId);
    handleBrandValue(brand);
    handleADChange(alternativeDomain, true);
    setIsPrompt(false);

    let newUseUpc;
    if (useUpc === "false") {
      newUseUpc = false;
    } else if (useUpc === "true") {
      newUseUpc = true;
    }
    let newExcludeItems = [];
    if (excludeItems) {
      newExcludeItems = excludeItems
        .trim()
        .split("\n")
        .map((item) => item.trim())
        .filter(Boolean);
    }

    let newCollections = getData(collections);
    let newTags = getData(tags);
    let newExcludeCollections = getData(excludeCollections);
    let newExcludeTags = getData(excludeTags);
    let newSpecialProducts = getData(specialProducts);

    let newModifyPrice = null;
    let newModifyPriceValue = null;
    let modifyVariantPrice;
    if (modifyPrice.open) {
      newModifyPrice = modifyPrice.type;
      newModifyPriceValue = modifyPrice.value
        ? parseFloat(modifyPrice.value)
        : null;

      if (isCustomPrice) {
        modifyVariantPrice = customizeRef.current?.getVariants();
      }
    }

    let count = _.size(_.filter(_.values(errors, (e) => e !== null)));
    if (name && storeId && brand && count === 0) {
      const newMaxTitleLength = maxTitleLength
        ? parseInt(maxTitleLength)
        : null;
      const newMaxContentLength = maxContentLength
        ? parseInt(maxContentLength)
        : null;
      const input = {
        name,
        storeId,
        filter,
        collections: newCollections,
        productBases,
        tags: newTags,
        excludeCollections: newExcludeCollections,
        excludeTags: newExcludeTags,
        specialProducts: newSpecialProducts,
        excludeItems: newExcludeItems,
        mode,
        productTitlePrefix,
        productTitleSuffix,
        excludeWords,
        variantTitle,
        category,
        gender,
        ageGroup,
        brand,
        condition,
        tax,
        shipping,
        stock,
        identifierExists,
        useUpc: newUseUpc,
        hasDesign,
        alternativeDomain,
        fixedColor: usingFixedColor ? fixedColor : "",
        usingFixedColor,
        maxTitleLength: newMaxTitleLength,
        maxContentLength: newMaxContentLength,
        hasSale,
        modifyPrice: newModifyPrice,
        modifyPriceValue: newModifyPriceValue,
        modifyVariantPrice,
        promotionId,
        modifyStateInTitle: modifyStateInTitle.enable,
        modifyStateInTitleByAcronym: JSON.parse(
          modifyStateInTitle.modifyStateByAcronym
        ),
        IsRegenEveryDay: !!isRegenEveryDay,
        descriptionPrefix: description.prefix,
        descriptionSuffix: description.suffix,
        storeTargetDataId: targetStore,
        limit: extraFields.limit ? parseInt(extraFields.limit) : undefined,
        sortType: extraFields.sortType && extraFields.sortType.length > 0 ? extraFields.sortType[0] : undefined,
        sortBy: extraFields.sortBy && extraFields.sortBy.length > 0 ? extraFields.sortBy[0] : undefined,
      };
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors,
    name,
    storeId,
    filter,
    collections,
    productBases,
    excludeCollections,
    excludeTags,
    specialProducts,
    excludeItems,
    mode,
    productTitlePrefix,
    productTitleSuffix,
    excludeWords,
    variantTitle,
    category,
    gender,
    ageGroup,
    brand,
    condition,
    tax,
    shipping,
    stock,
    identifierExists,
    useUpc,
    hasDesign,
    tags,

    handleADChange,
    alternativeDomain,
    fixedColor,
    usingFixedColor,
    maxTitleLength,
    maxContentLength,
    hasSale,
    modifyPrice,
    promotionId,
    modifyStateInTitle,
    isRegenEveryDay,
    description,
    targetStore,
    extraFields
  ]);

  const contentStatus = hasDesign ? "Yes" : "No";

  const toggleOpenModify = useCallback(() => {
    setIsPrompt(true);
    setModifyPrice((prev) => ({ ...prev, open: !prev.open }));
  }, []);
  const handleModifyChange = useCallback((value) => {
    setIsPrompt(true);
    setModifyPrice((prev) => ({ ...prev, type: value }));
  }, []);
  const handleModifyValueChange = useCallback((value) => {
    setIsPrompt(true);
    setModifyPrice((prev) => ({ ...prev, value }));
  }, []);

  const handleRegenChange = useCallback((val) => {
    setIsRegenEveryDay(val);
    setIsPrompt(true);
  }, []);

  const handleExtraFieldsChange = useCallback((val, id) => {
    setExtraFields((p) => ({ ...p, [id]: val }))
    setIsPrompt(true);
  }, []);

  const isCustomPrice = modifyPrice.type === "Variant Custom";

  const descriptionHelper =
    "You also can use variable to display dynamic value. Valid variables: {PRODUCT_TITLE}";
  return (
    <React.Fragment>
      <Prompt
        when={isPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Container>
        <Form onSubmit={handleOnSubmit}>
          <FormLayout>
            <div>
              <ComponentLabelPolaris label="Feed name" required />
              <TextField
                // label="Feed name(*)"
                type="text"
                value={name}
                onChange={handleNameChange}
                error={errors.name}
                placeholder="Enter feed name"
              />
            </div>
            <StoresSelectPolaris
              errors={errors.store}
              stores={stores}
              onChange={handleStoreChange}
              error={error}
              value={storeId || []}
            />
            <TargetStore
              stores={stores}
              ignoreStore={storeId}
              targetStore={targetStore}
              onChange={handleTargetStoreChange}
            />
            <div>
              <Checkbox
                checked={hasDesign}
                onChange={handleToggleDesign}
                label="Only feed products has designs"
              />
            </div>
            <div>
              <Checkbox
                checked={hasSale}
                onChange={handleHasSaleChange}
                label="Only feed products has sales"
              />
            </div>
            <SuspenseComp>
              <BaseSelect
                source={value?.productBases || []}
                value={productBases}
                allowMultiple
                onChange={handleProductBases}
              />
            </SuspenseComp>
            <div className="heading-wrap">
              <span>Include products</span>
            </div>
            <CollectionSelectPolaris
              label={"Include collections"}
              allowMultiple
              value={collections}
              onChange={handleCollection}
            />
            <TagsSelectPolaris
              label={"Include tags"}
              value={tags}
              allowMultiple
              onChange={handleTag}
            />
            <div className="heading-wrap">
              <span>Exclude products</span>
            </div>
            <CollectionSelectPolaris
              label={"Exclude collections"}
              value={excludeCollections}
              allowMultiple
              onChange={handleExcludeCollections}
            />
            <TagsSelectPolaris
              label={"Exclude tags"}
              value={excludeTags}
              allowMultiple
              onChange={handleExcludeTags}
            />
            <ProductsSelectPolaris
              label={"Exclude special products"}
              value={specialProducts}
              allowMultiple
              onChange={handleSpecialProducts}
            />
            <div>
              <Labelled children="Exclude by items ids" />
              <Scrollable shadow style={{ maxHeight: "700px" }} focusable>
                <TextField
                  //  label="Exclude by items ids"
                  type="text"
                  value={excludeItems}
                  onChange={handleExcludeItems}
                  multiline={5}
                />
              </Scrollable>
            </div>
            <ExportModePolaris value={mode} onChange={handleModeChange} />
            <TextField
              label="Max Title Length"
              type="number"
              min={1}
              placeholder={"Enter max title length"}
              value={maxTitleLength}
              onChange={handleMaxTitleLength}
            />
            <TextField
              label="Max Content Length"
              type="number"
              min={1}
              placeholder={"Enter max content length"}
              value={maxContentLength}
              onChange={handleMaxContentLength}
            />
            <TextField
              label="Product title prefix"
              type="text"
              value={productTitlePrefix}
              onChange={handleProductTitlePrefix}
              placeholder="Enter product title prefix"
            />
            <TextField
              label="Product title suffix"
              type="text"
              value={productTitleSuffix}
              onChange={handleProductTitleSuffix}
              placeholder="Enter product title suffix"
              helpText={
                <p>
                  <i>
                    You can use theses short codes: <strong>[int(6)]</strong>{" "}
                    for random a number with 6 digits or{" "}
                    <strong>[str(6)]</strong> for random a string with 6
                    characters.
                  </i>
                </p>
              }
            />
            <TextField
              name="prefix"
              id="prefix"
              label="Description prefix"
              value={description.prefix}
              onChange={handleDescriptionChange}
              placeholder="Enter description prefix"
              helpText={descriptionHelper}
            />
            <TextField
              name="suffix"
              id="suffix"
              label="Description suffix"
              value={description.suffix}
              onChange={handleDescriptionChange}
              placeholder="Enter description suffix"
              helpText={descriptionHelper}
            />
            <ExcludeWordsPolaris
              onChange={handleExcludeWord}
              value={excludeWords}
            />
            <VariantTitlePolaris
              value={variantTitle}
              onChange={handleVariantTitle}
            />
            <GoogleProductCategoryPolaris
              onChange={handleCategory}
              value={category}
            />
            <GenderPolaris onChange={handleGender} value={gender} />
            <AgeGroupPolaris value={ageGroup} onChange={handleAgeGroup} />
            <div>
              <ComponentLabelPolaris label="Default brand" required />
              <TextField
                // label="Default brand(*)"
                value={brand}
                onChange={handleBrand}
                placeholder="Enter default brand"
                error={errors.brand}
              />
            </div>
            <ConditionPolaris value={condition} onChange={handleCondition} />
            <TextField
              label="Tax"
              value={tax}
              onChange={handleTax}
              placeholder="US::0.00:n"
            />
            <TextField
              label="Shipping"
              placeholder="US:::6.95 USD"
              value={shipping}
              onChange={handleShipping}
            />
            <StockPolaris value={stock} onChange={handleStock} />
            <IdentifierExistsPolaris
              value={identifierExists}
              onChange={handleIdentifierExists}
            />
            <UseUpcPolaris value={useUpc} onChange={handleUseUpc} />
            <TextField
              type="number"
              min={0}
              value={extraFields.limit}
              id="limit"
              onChange={handleExtraFieldsChange}
              label="Limit"
            />
            <ChoiceList
              choices={[{ value: SORT_TYPE_VALUES[0], label: "Descending" }, { value: SORT_TYPE_VALUES[1], label: "Ascending" }]}
              selected={extraFields.sortType}
              name="sortType"
              title="Sort Type"
              onChange={handleExtraFieldsChange}
            />
            <ChoiceList
              choices={[{ value: SORT_BY_VALUES[0], label: "Created At" }, { value: SORT_BY_VALUES[1], label: "Total Orders" }]}
              selected={extraFields.sortBy}
              name="sortBy"
              title="Sort By"
              onChange={handleExtraFieldsChange}
            />
            <AlternativeDomain
              value={alternativeDomain}
              onChange={handleADChange}
              error={errors.alternativeDomain}
            />
            <FixedColor
              fixedColor={fixedColor}
              usingFixedColor={usingFixedColor}
              handlePCChange={handlePCChange}
              handleUsingPCChange={handleUsingPCChange}
            />
            <PromotionId
              value={promotionId}
              onChange={handlePromotionIdChange}
            />
            <Stack spacing="tight" vertical>
              <Checkbox
                checked={modifyPrice.open}
                onChange={toggleOpenModify}
                label="Modify Price"
              />
              <Collapsible
                open={modifyPrice.open}
                transition={{
                  duration: "150ms",
                  timingFunction: "ease",
                }}
              >
                <Stack distribution="fillEvenly">
                  <Select
                    label="Modify Price Type"
                    value={modifyPrice.type}
                    options={[
                      { value: "Add", label: "Add" },
                      {
                        value: "Subtract",
                        label: "Subtract",
                      },
                      { value: "Fixed", label: "Fixed" },
                      { value: "Variant Custom", label: "Custom", disabled: (productBases || []).length === 0 },
                    ]}
                    onChange={handleModifyChange}
                    helpText={<ModifyHelper />}
                  />
                  {isCustomPrice ? null :
                    <TextField
                      label="Modify Price Value"
                      onChange={handleModifyValueChange}
                      min={0}
                      type="number"
                      value={modifyPrice.value}
                      step={0.1}
                      placeholder="Enter modif price value"
                    />}
                </Stack>
                {isCustomPrice && productBases.length > 0 ?
                  <SuspenseComp>
                    <CustomPrice baseIds={productBases} ref={customizeRef} value={value?.modifyVariantPrice} setIsPrompt={setIsPrompt} />
                  </SuspenseComp>
                  : null}
              </Collapsible>
            </Stack>

            <Stack spacing="tight" vertical>
              <Checkbox
                checked={modifyStateInTitle.enable}
                onChange={handleModifyStateInTitle}
                label="Modify US States in product title?"
              />
              <Collapsible
                open={modifyStateInTitle.enable}
                transition={{
                  duration: "150ms",
                  timingFunction: "ease",
                }}
              >
                <Stack distribution="fillEvenly">
                  <ChoiceList
                    // title="Modify state by"
                    choices={[
                      {
                        label: "Separated by dot",
                        value: "false",
                      },
                      {
                        label: "By Acronym",
                        value: "true",
                      },
                    ]}
                    selected={modifyStateInTitle.modifyStateByAcronym}
                    onChange={handleModifyStateInTitleByAcronym}
                  />
                </Stack>
              </Collapsible>
            </Stack>

            <Checkbox
              label="Re-gen every day"
              checked={isRegenEveryDay}
              onChange={handleRegenChange}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <ButtonGroup>
                <Button
                  onClick={() =>
                    history.push(`/${urlStoreManager}/products/feeds`)
                  }
                >
                  Cancel
                </Button>
                <Button primary submit loading={loading} disabled={!isPrompt}>
                  {btnLabel ? btnLabel : "Save"}
                </Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </Form>
      </Container>
    </React.Fragment>
  );
};

function AlternativeDomain({ value, onChange, error }) {
  // State
  const [open, setOpen] = useState(false);

  // Handle action
  const handleValueChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (value != null) {
      setOpen(true);
    }
  }, [value]);

  const handleOpenChange = useCallback(() => {
    setOpen((prev) => {
      if (!prev === false) {
        onChange(null);
      }

      return !prev;
    });
  }, [onChange]);

  return (
    <Fragment>
      <Checkbox
        checked={open}
        onChange={handleOpenChange}
        label="Using alternative domain?"
      />
      <Collapsible
        id="alternative-domain"
        open={open}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <TextField
          value={value}
          id="alternativeDomain"
          onChange={handleValueChange}
          placeholder="http(s)://your-domain.com"
          error={error}
        />
      </Collapsible>
    </Fragment>
  );
}

function FixedColor({
  handleUsingPCChange,
  handlePCChange,
  fixedColor,
  usingFixedColor,
}) {
  return (
    <Fragment>
      <Checkbox
        checked={usingFixedColor}
        onChange={handleUsingPCChange}
        label="Using fixed color?"
      />
      <Collapsible
        id="promotion-id"
        open={usingFixedColor}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <TextField
          value={fixedColor}
          id="fixedColor"
          onChange={handlePCChange}
        />
      </Collapsible>
    </Fragment>
  );
}

function PromotionId({ value, onChange }) {
  // State
  const [open, setOpen] = useState(false);

  // Handle action
  const handleValueChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (value != null) {
      setOpen(true);
    }
  }, [value]);

  const handleOpenChange = useCallback(() => {
    setOpen((prev) => {
      if (!prev === false) {
        onChange(null);
      }

      return !prev;
    });
  }, [onChange]);

  return (
    <Fragment>
      <Checkbox
        checked={open}
        onChange={handleOpenChange}
        label="Using promotion id?"
      />
      <Collapsible
        id="promotion-id"
        open={open}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <TextField
          value={value}
          id="promotionId"
          onChange={handleValueChange}
          placeholder="Enter promotion id"
        />
      </Collapsible>
    </Fragment>
  );
}

function ModifyHelper() {
  const prevMarkup = `
    <prev>
      Type Add: increase the origin price by an amount. <br />
      Type Subtract: decrease the origin price by an amount.
      <br />
      Type Fixed: the origin price will replace by a fixed
      amount.
      <br />
      Type Custom: Customize price for each variants.
    </prev>
  `;

  return <div dangerouslySetInnerHTML={{
    __html: prevMarkup
  }} />
}

function validateDomain(str) {
  const pattern = /^(http(s)?\:\/\/)\.*/g;

  if (str?.match(pattern) == null) {
    return true;
  }

  return false;
}

function getData(data) {
  if (!data || data.length === 0) return [];

  return data.map((item) => {
    if (typeof item === "object") {
      return item.id;
    }
    return item;
  });
}
