import React, { useState, useCallback, useRef } from "react";
import { Tabs, Modal, Card } from "@shopify/polaris";
import { ApolloConsumer } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import MediaImportUrls from "./MediaImportUrls";
import { UploadFilePolaris } from "./UploadFilePolaris";
import { MediaLibraryTabPolaris } from "./MediaLibraryTabPolaris";

export const LOAD_MEDIA_QUERY = gql`
  query mediaLibrary($filter: FileFilter) {
    mediaLibrary(filter: $filter) {
      total
      nodes {
        id
        name
        alt
        caption
        thumbnailUrl
        url
        mimeType
        size
        createdAt
        key
      }
    }
  }
`;

const ImageTypes = ["image/png", "image/jpeg", "image/gif"];

export const MediaPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    onImportUrlsChange,
    accept,
    folder,
    isPrintFile,
    multiple,
    value,
    prefix,
    userId,
    onChange,
    handleSetFile,
    loadingImport,
    maxSize,
  } = props;
  const [selectedTab, setSelectedTab] = useState(2);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [filter, setFilter] = useState({
    search: null,
    mimeType: [],
    dateFilter: {
      from: null,
      to: null,
    },
    limit: 20,
    offset: 0,
    prefix,
    ...(userId ? { userId } : null),
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const timeoutRef = useRef(null);

  const { data, fetchMore, networkStatus, client, loading } = useQuery(
    LOAD_MEDIA_QUERY,
    {
      variables: { filter },
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleTabChange = useCallback(
    (tabIndex) => setSelectedTab(tabIndex),
    []
  );

  const tabs = [
    {
      id: "import-urls",
      content: "Import from Urls",
      accessibilityLabel: "import from urls",
      panelID: "import-from-urls",
    },
    {
      id: "upload-files",
      content: "Upload Files",
      accessibilityLabel: "Upload Files",
      panelID: "upload-files",
    },
    {
      id: "media-library",
      content: "Media Library",
      accessibilityLabel: "Media Library",
      panelID: "media-library",
    },
  ];

  const handleWriteCache = (file, client, adding = false) => {
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total - 1,
            nodes: adding
              ? [...file, ...cache.mediaLibrary.nodes]
              : cache.mediaLibrary.nodes.filter((f) => f.id !== file.id),
          },
        },
      });
    } catch (e) {}
  };

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.mediaLibrary.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            let {
              mediaLibrary: { nodes },
            } = fetchMoreResult;
            let {
              filter: { limit },
            } = variables;
            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            return {
              ...prev,
              mediaLibrary: {
                ...prev.mediaLibrary,
                nodes: [
                  ...prev.mediaLibrary.nodes,
                  ...fetchMoreResult.mediaLibrary.nodes,
                ],
              },
            };
          },
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter, cantLoad]);

  const handleChangeOkBtnDisabled = useCallback(
    (value) => {
      if (1 !== selectedTab) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(value);
      }
    },
    [selectedTab]
  );
  const mimeTypeOptions = [[], ImageTypes];

  return (
    <Modal
      open={open}
      onClose={toggleShowModal}
      title={"Media"}
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Set File",
        onAction: () => handleSetFile(client),
        disabled: btnDisabled,
        loading: selectedTab === 0 ? loadingImport : false ,
      }}
      large
    >
      <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
        {0 === selectedTab && (
          <Card sectioned>
            <MediaImportUrls
              onChange={(v) => {
                if (onImportUrlsChange) {
                  onImportUrlsChange(v);
                }
              }}
            />
          </Card>
        )}
        {1 === selectedTab && (
          <Card sectioned>
            <ApolloConsumer>
              {(client) => (
                <UploadFilePolaris
                  accept={accept}
                  folder={folder}
                  isPrintFile={isPrintFile}
                  maxSize={maxSize}
                  onChange={(files) => {
                    let newValue = [];
                    if (files && files.length) {
                      if (multiple) {
                        for (let i = 0; i < files.length; i++) {
                          if (!value.find((v) => v.id === files[i].id)) {
                            newValue.push(files[i]);
                          }
                        }
                        newValue = [...value, ...newValue];
                      } else {
                        newValue = files.length ? [files[0]] : [];
                      }
                    }
                    handleWriteCache(files, client, true);
                    if (onChange) {
                      onChange(newValue);
                    }
                  }}
                  multiple={multiple}
                  handleChangeOkBtnDisabled={handleChangeOkBtnDisabled}
                />
              )}
            </ApolloConsumer>
          </Card>
        )}
        {2 === selectedTab && (
          <MediaLibraryTabPolaris
            data={data}
            filter={filter}
            multiple={multiple}
            ImageTypes={ImageTypes}
            value={value}
            onFilter={({ search, mimeType }) =>
              setFilter((prevState) => ({
                ...prevState,
                search,
                mimeType: mimeTypeOptions[Number(mimeType)],
              }))
            }
            onFilterDate={({ dateFilter }) =>
              setFilter((prevState) => ({ ...prevState, dateFilter }))
            }
            onChange={onChange}
            onScroll={handleScroll}
            loadMore={loadMore}
            networkStatus={networkStatus}
            loading={loading}
          />
        )}
      </Tabs>
    </Modal>
  );
};
