// import { gql } from "apollo-boost";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import gql from "graphql-tag";
import { get, isEqual } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { GET_TASKS_V2, TASK_AGGS_V2 } from "../../graphql/queries";
import {
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  formatAggregations,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import useToggle from "../../hooks/useToggle";
import {
  COMMON_FILTER,
  DESIGNER_SPECIAL,
  ORDER_STATUS,
  PME,
  TASK_STATUS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ToastContextProvider } from "../shared/ToastContext";
import { TaskContext } from "./context";
import {
  DESIGN_TASK_INCLUDES,
  FilterControlPolaris,
} from "./FilterControlPolaris";
import { Create } from "./idea";
import { ScreenOptionsDTPolaris } from "./ScreenOptionsDTPolaris";
import { TableTasksPolaris } from "./TableTasksPolaris";
import { TabsDesignTasksPolaris } from "./TabsDesignTasksPolaris";

const POD_GET_DESIGN_SUBS = gql`
  subscription {
    taskGetDesignFromPOD {
      id
      teamId
      ownerIds
      assigneeId
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "assigneeIds",
  "similarTaskAssigneeId",
  "status",
  "order",
  "orderBy",
  "fieldByTime",
  "hasAssign",
  "platform",
  "productBaseId",
  "storeIds",
  "personalized",
  "hasDesign",
  "kpi",
  "usingTaskBulkDesign",
  "usingDesignApp",
  "usingExpressShipping",
  "productCreatedVia",
  "hasPBArtwork",
  "designOnlineTeamID",
];

const DESIGNER_RD_OR_TEAM = /(RD|Team)$/i;

export const DesignTasksPolaris = ({ path }) => {
  const { currentUser, store } = useContext(AppContext);
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.hasAssign) {
        initialFilter.status = "Pending Design";
        if (initialFilter.hasAssign === "Assigned") {
          initialFilter.hasAssign = true;
        }
        if (initialFilter.hasAssign === "Un-assigned") {
          initialFilter.hasAssign = false;
        }
      }

      const status = initialFilter.status;
      if (status) {
        if (!DESIGN_TASK_INCLUDES.includes(status)) {
          initialFilter.status = null;
        }
      }

      if (initialFilter.orderType) {
        if ("personalized" === initialFilter.orderType) {
          initialFilter.personalized = true;
        }
        if ("normal" === initialFilter.orderType) {
          initialFilter.personalized = false;
        }
      }

      if (initialFilter.hasDesign) {
        if ("Yes" === initialFilter.hasDesign) {
          initialFilter.hasDesign = true;
        }
        if ("No" === initialFilter.hasDesign) {
          initialFilter.hasDesign = false;
        }
      }
      if (initialFilter.usingTaskBulkDesign) {
        if ("Yes" === initialFilter.usingTaskBulkDesign) {
          initialFilter.usingTaskBulkDesign = true;
        }
        if ("No" === initialFilter.usingTaskBulkDesign) {
          initialFilter.usingTaskBulkDesign = false;
        }
      }
      if (initialFilter.usingDesignApp) {
        if ("Yes" === initialFilter.usingDesignApp) {
          initialFilter.usingDesignApp = true;
        }
        if ("No" === initialFilter.usingDesignApp) {
          initialFilter.usingDesignApp = false;
        }
      }
      if (initialFilter.hasPBArtwork) {
        if ("Yes" === initialFilter.hasPBArtwork) {
          initialFilter.hasPBArtwork = true;
        }
        if ("No" === initialFilter.hasPBArtwork) {
          initialFilter.hasPBArtwork = false;
        }
      }
      if (initialFilter.kpi) {
        initialFilter.kpi = parseFloat(initialFilter.kpi);
      }

      if (initialFilter["platform"]) {
        let paramOS = initialFilter["platform"];
        paramOS = paramOS.split(",");
        initialFilter["platform"] = paramOS;
      }
      if (initialFilter["productBaseId"]) {
        let paramOS = initialFilter["productBaseId"];
        paramOS = paramOS.split(",");
        initialFilter["productBaseId"] = paramOS;
      }

      if ("Yes" === initialFilter.usingExpressShipping) {
        initialFilter.usingExpressShipping = true;
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // Get context
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);
  const lastName = get(currentUser, "lastName", null);
  const firstName = get(currentUser, "firstName", null);
  const currentUserId = get(currentUser, "id", null);
  const fullName = `${firstName} ${lastName}`.trim();
  const designerPME = fullName.includes(PME);
  const designerRBOrTeam = DESIGNER_RD_OR_TEAM.test(fullName);
  const isDesignerSpecial = [
    DESIGNER_SPECIAL.accLy,
    DESIGNER_SPECIAL.accDung,
  ].includes(currentUserId);

  let isStoreManage = false;
  let isLeader = false;
  let hasStoreFilter = false;
  // let isSellerAdmin = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    isLeader = [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.IdeaLeader,
    ].includes(teamRole);

    // isSellerAdmin = [TEAM_ROLE.Admin].includes(teamRole);

    isStoreManage = [TEAM_ROLE.StoreManager].includes(teamRole);
    hasStoreFilter = [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader, TEAM_ROLE.Accountant].includes(
      teamRole,
    );
  }

  const { isOnline, isDesignLeader, isDesigner } = checkRole(currentUser);
  const isDesignOnline = (isDesignLeader || isDesigner) && isOnline;

  // State
  const [userId, setUserId] = useState(null);
  const [screenOptions, setScreenOptions] = useState([]);
  const [fixedColumn, setFixedColumn] = useState(true);
  const [, setActiveToast] = useState(false);
  const [open, toggleOpen] = useToggle(false);
  const [dataCountV2, setDataCountV2] = useState(null);
  const [totalKpi, setTotalKpi] = useState(null);

  // Default status
  let defaultStatus = null;
  if (isDesignerSpecial) {
    defaultStatus = ORDER_STATUS.PendingDesign;
  } else if (isDesigner) {
    defaultStatus = ORDER_STATUS.Pending;
  }

  /** If account is design leader, for load `status = Pending Design`, `hasAssign = false`, `hasDesign = true` is default values */
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageDesignTasks")) || 20,
    offset: 0,
    search: null,
    assigneeIds: null,
    status: defaultStatus,
    storeIds: null,
    order: null,
    orderBy: null,
    fieldByTime: null,
    hasAssign: isDesignerSpecial ? false : null,
    platform: null,
    productBaseId: null,
    personalized: null,
    hasDesign: isDesignerSpecial ? true : null,
    kpi: null,
    usingTaskBulkDesign: null,
    usingDesignApp: null,
    usingExpressShipping: null,
    isIdeaTask: false,
    productCreatedVia: null,
    similarTaskAssigneeId: null,
    hasPBArtwork: null,
    designOnlineTeamID: null,
    kpiRange: null,
    ...initFilter,
  });

  // Queries
  const { data, loading, error, refetch } = useQuery(GET_TASKS_V2, {
    variables: {
      filter: {
        ...filter,
        storeIds: store
          ? "all" !== store.id
            ? [store.id]
            : null
          : filter.storeIds,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [getTaskAgg, { data: dataAgg }] = useLazyQuery(TASK_AGGS_V2, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const agg = res?.taskAggsV2;
      if (agg != null) {
        const newAgg = formatAggregations(agg, true);
        setDataCountV2((prev) => ({ ...prev, ...newAgg }));
      }
    },
  });

  const { data: dataTotal } = useQuery(TASK_AGGS_V2, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        ...filter,
        storeIds: store
          ? "all" !== store.id
            ? [store.id]
            : null
          : filter.storeIds,
      },
    },
    skip: filter.status !== TASK_STATUS.Done,
  });

  useEffect(() => {
    const agg = dataAgg?.taskAggsV2;
    if (agg != null) {
      const value = formatAggregations(agg, false);
      setDataCountV2((prev) => ({ ...prev, ...value }));
    }
  }, [dataAgg]);

  useEffect(() => {
    const agg = dataTotal?.taskAggsV2;
    if (agg != null) {
      const value = formatAggregations(agg, true);
      setTotalKpi((prev) => ({ ...prev, ...value }));
    }
  }, [dataTotal]);

  useEffect(() => {
    getTaskAgg({
      variables: {
        filter: {
          ...filter,
          status: null,
          hasAssign: null,
          storeIds: store
            ? "all" !== store.id
              ? [store.id]
              : null
            : filter.storeIds,
        },
      },
    });
  }, [filter, getTaskAgg]);

  // Variable screen options
  let SCREEN_OPTIONS = [
    { value: "id", label: "ID", priority: 0, public: true },
    { value: "orderID", label: "Order ID", priority: 5, public: true },
    {
      value: "originId",
      label: "Origin Order ID",
      priority: 6,
      public: true,
    },
    { value: "platform", label: "Platform", priority: 10, public: true },
    !isDesignOnline && {
      value: "store",
      label: "Store",
      priority: 10,
      public: isLeader || designerPME || designerRBOrTeam,
    },
    { value: "product", label: "Product", priority: 15, public: true },
    {
      value: "defaultKPI",
      label: "Default KPI",
      priority: 20,
      public: isLeader,
    },
    { value: "kpi", label: "KPI", priority: 25, public: true },
    { value: "createdAt", label: "Created at", priority: 30, public: true },
    {
      value: "assignDate",
      label: "Assign Date",
      priority: 35,
      public: true,
    },
    {
      value: "doneDate",
      label: "Done Date",
      priority: 35,
      public: [TASK_STATUS.Done].includes(filter.status),
    },
    {
      value: "originalProduct",
      label: "Original Product",
      priority: 36,
      public: true,
    },
    { value: "deadline", label: "Deadline", priority: 40, public: true },
    {
      value: "taskResources",
      label: "Task Resource",
      priority: 45,
      public: true,
    },
    {
      value: "designResourceUrls",
      label: "Design Resource Urls",
      priority: 46,
      public: true,
    },
    {
      value: "orderStatus",
      label: "Order status",
      priority: 50,
      public:
        ([ORDER_STATUS.PendingDesign].includes(filter.status) ||
          null === filter.status) &&
        !isDesigner,
    },
    {
      value: "status",
      label:
        [ORDER_STATUS.PendingDesign].includes(filter.status) && !isDesigner
          ? "Task status"
          : "Status",
      priority: 55,
      public: true,
    },
    { value: "designer", label: "Designer", priority: 60, public: true },
    {
      value: "designOnlineTeam",
      label: "Design Online Team",
      priority: 60,
      public: true,
    },
    {
      value: "similarTaskAssignee",
      label: "Similar Task Assignees",
      priority: 61,
      public: true,
    },
    {
      value: "bulkAcceptDesign",
      label: "Bulk Accept Design",
      priority: 62,
      public: isLeader,
    },
  ];

  SCREEN_OPTIONS = SCREEN_OPTIONS.filter((i) => i.public).filter(Boolean);

  // Did mount
  useEffect(() => {
    let options = getCookie(`screenOption_DT_${currentUserId}`);
    const defaultOpts = SCREEN_OPTIONS.map((i) => i.value);
    if (options) {
      options = options.split(",");
      options = options.filter((i) => defaultOpts.includes(i));
    } else {
      options = SCREEN_OPTIONS.map((i) => i.value);
    }
    setScreenOptions(options);
    setUserId(currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  useEffect(() => {
    if (userId) {
      setCookie(`screenOption_DT_${userId}`, screenOptions, 100);
    }
  }, [userId, screenOptions]);

  useEffect(() => {
    let {
      offset,
      limit,
      hasAssign,
      personalized,
      hasDesign,
      usingTaskBulkDesign,
      usingDesignApp,
      fieldByTime,
      usingExpressShipping,
      hasPBArtwork,
      storeIds,
      kpiRange,
      ...rest
    } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    if (hasAssign != null) {
      if (hasAssign) {
        hasAssign = "Assigned";
      } else {
        hasAssign = "Un-assigned";
      }
    }

    let orderType = null;
    if (true === personalized) {
      orderType = "personalized";
    }
    if (false === personalized) {
      orderType = "normal";
    }
    let hasDesignConvert = null;
    if (true === hasDesign) {
      hasDesignConvert = "Yes";
    }
    if (false === hasDesign) {
      hasDesignConvert = "No";
    }
    if (true === usingTaskBulkDesign) {
      usingTaskBulkDesign = "Yes";
    }
    if (false === usingTaskBulkDesign) {
      usingTaskBulkDesign = "No";
    }
    if (true === usingDesignApp) {
      usingDesignApp = "Yes";
    }
    if (false === usingDesignApp) {
      usingDesignApp = "No";
    }
    if (true === hasPBArtwork) {
      hasPBArtwork = "Yes";
    }
    if (false === hasPBArtwork) {
      hasPBArtwork = "No";
    }

    if (usingExpressShipping) {
      usingExpressShipping = "Yes";
    } else {
      usingExpressShipping = null;
    }

    params = convertObjectToParams({
      limit,
      paged,
      orderType,
      hasDesign: hasDesignConvert,
      usingDesignApp: usingDesignApp,
      hasPBArtwork: hasPBArtwork,
      ...rest,
      hasAssign,
      usingTaskBulkDesign,
      usingExpressShipping,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = React.useCallback((value) => {
    const {
      search,
      designer,
      status,
      order,
      orderBy,
      filterTime,
      stateAssign,
      orderSource,
      productBaseId,
      storeIds,
      personalized,
      hasDesign,
      kpi,
      usingTaskBulkDesign,
      usingDesignApp,
      usingExpressShipping,
      productCreatedVia,
      similarTaskAssigneeId,
      hasPBArtwork,
      designOnlineTeamID,
      kpiRange,
    } = value;
    let hasAssign = stateAssign;
    let assigneeIds = designer;

    const showSTA = status === "Pending Design" && !hasAssign;
    let newSimilarTaskAssigneeId =
      "all" === similarTaskAssigneeId
        ? null
        : showSTA
        ? similarTaskAssigneeId
        : null;

    let fieldByTime =
      filterTime.from && filterTime.to && filterTime.field ? filterTime : null;
    let platform = orderSource;
    platform = platform.length ? platform : null;
    setFilter((prevState) => {
      if (
        !isEqual(prevState.search, search) ||
        !isEqual(prevState.assigneeIds, assigneeIds) ||
        !isEqual(prevState.status, status) ||
        !isEqual(prevState.hasAssign, hasAssign) ||
        !isEqual(prevState.order, order) ||
        !isEqual(prevState.orderBy, orderBy) ||
        !isEqual(prevState.fieldByTime, fieldByTime) ||
        !isEqual(prevState.platform, platform) ||
        !isEqual(prevState.productBaseId, productBaseId) ||
        !isEqual(prevState.storeIds, storeIds) ||
        !isEqual(prevState.personalized, personalized) ||
        !isEqual(prevState.hasDesign, hasDesign) ||
        !isEqual(prevState.kpi, kpi) ||
        !isEqual(prevState.usingTaskBulkDesign, usingTaskBulkDesign) ||
        !isEqual(prevState.usingDesignApp, usingDesignApp) ||
        !isEqual(prevState.usingExpressShipping, usingExpressShipping) ||
        !isEqual(prevState.productCreatedVia, productCreatedVia) ||
        !isEqual(prevState.hasPBArtwork, hasPBArtwork) ||
        !isEqual(prevState.similarTaskAssigneeId, newSimilarTaskAssigneeId) ||
        !isEqual(prevState.designOnlineTeamID, designOnlineTeamID) ||
        !isEqual(prevState.kpiRange, kpiRange)
      ) {
        return {
          ...prevState,
          offset: 0,
          search,
          assigneeIds,
          status,
          hasAssign,
          order,
          orderBy,
          fieldByTime,
          platform,
          productBaseId,
          storeIds,
          personalized,
          hasDesign,
          kpi,
          usingTaskBulkDesign,
          usingDesignApp,
          hasPBArtwork,
          usingExpressShipping,
          productCreatedVia,
          similarTaskAssigneeId: newSimilarTaskAssigneeId,
          designOnlineTeamID,
          kpiRange,
        };
      }
      return prevState;
    });
  }, []);

  const handleTabChange = React.useCallback(({ status }) => {
    let custom = {
      status,
    };
    if (status) {
      if (status === "Assigned") {
        custom["status"] = "Pending Design";
        custom["hasAssign"] = true;
      } else if (status === "Un-assigned") {
        custom["status"] = "Pending Design";
        custom["hasAssign"] = false;
      } else {
        custom["hasAssign"] = null;
      }
    }
    setFilter((prev) => {
      const isUpdateFilter =
        prev.status !== status || prev.hasAssign !== custom["hasAssign"];

      if (isUpdateFilter) {
        return {
          ...prev,
          ...custom,
          offset: 0,
        };
      }
      return prev;
    });
  }, []);

  const handlePaginationChange = React.useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
    setCookie("perPageDesignTasks", limit, 100);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  // const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  // Variables
  const { data: subsData } = useSubscription(POD_GET_DESIGN_SUBS);

  useEffect(() => {
    if (
      subsData &&
      subsData.taskGetDesignFromPOD.teamId === currentUser.teamUser.team.id
    ) {
      if (isStoreManage) {
        if (subsData.taskGetDesignFromPOD.ownerIds.includes(currentUser.id)) {
          setActiveToast(subsData.taskGetDesignFromPOD.id);
        }
      } else if (isDesigner) {
        if (subsData.taskGetDesignFromPOD.assigneeId == currentUser.id) {
          setActiveToast(subsData.taskGetDesignFromPOD.id);
        }
      } else {
        setActiveToast(subsData.taskGetDesignFromPOD.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsData]);

  // const toastMarkup = activeToast ? (
  //     <Toast
  //         content={`Design task ${activeToast} was generated!`}
  //         duration={2000}
  //         onDismiss={toggleActive}
  //     />
  // ) : null;

  return (
    <Container>
      {loadingMarkup}
      {/* {toastMarkup} */}
      <div>
        <ScreenOptionsDTPolaris
          options={SCREEN_OPTIONS}
          defaultValue={screenOptions}
          onChange={(value) => setScreenOptions(value)}
          onChangeFixedColumn={(value) => setFixedColumn(value)}
        />
      </div>
      <ToastContextProvider refetch={refetch} setFilter={setFilter}>
        <Page title="Design Tasks" fullWidth>
          <FilterControlPolaris
            filters={filter}
            isDesigner={isDesigner}
            isLeader={isLeader}
            hasStoreFilter={hasStoreFilter}
            onChangeFilter={handleFilterChange}
          />
          <Card>
            <TabsDesignTasksPolaris
              dataCountV2={dataCountV2}
              value={filter.status}
              hasAssign={filter.hasAssign}
              isDesigner={isDesigner}
              isLeader={isLeader}
              onChange={handleTabChange}
            />
            {loading ? (
              <SkeletonPagePolaris />
            ) : error ? (
              <div> Error: {handleError(error.toString())}</div>
            ) : data?.tasksV2?.nodes?.length > 0 ? (
              <TaskContext.Provider value={{ filter }}>
                <TableTasksPolaris
                  data={data}
                  filter={filter}
                  screenOptions={screenOptions}
                  dataSO={SCREEN_OPTIONS}
                  currentUser={currentUser}
                  fixedColumn={fixedColumn}
                  isDesigner={isDesigner}
                  refetch={refetch}
                  handlePaginationChange={handlePaginationChange}
                  setFilter={setFilter}
                  dataTotal={totalKpi}
                  isDesignOnline={isDesignOnline}
                />
              </TaskContext.Provider>
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        </Page>

        <Create open={open} onClose={toggleOpen} />
      </ToastContextProvider>
    </Container>
  );
};
