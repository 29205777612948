import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Icon,
  Select,
  Popover,
  ButtonGroup,
  Checkbox,
  Scrollable,
  Spinner,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { DatePickerPolaris } from "../shared/DatePickerPolaris";
import { MediaFileDetailsPolaris } from "./MediaFileDetailsPolaris";
import { LOAD_MEDIA_QUERY } from "./MediaPolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 30rem;
  .media-library_wrap {
    width: 76%;
    border-right: var(
      --p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8)
    );
  }
  .filter-control_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    padding: 1.6rem;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    .search_wrap {
      width: 60%;
    }
    .more-filter_wrap {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }
  }
  .media-library-content_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 2rem;
    width: 100%;
    padding: 1rem 1.6rem 1.6rem;
    justify-content: start;
    .image {
      width: 160px;
      height: 160px;
      cursor: pointer;
      border-radius: 3px;
      position: relative;
      box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px 2px rgba(0, 0, 0, 0.1);
      .image-inner {
        width: 100%;
        height: 100%;
        background-size: cover;
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .check {
        position: absolute;
        top: 0;
        right: 0;
        .Polaris-Choice {
          padding: 0;
          .Polaris-Choice__Control {
            margin: 0;
            padding: 0;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    .picture-outlined {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 20px;
      }
    }
  }
  .file-detail_wrap {
    width: 24%;
  }
  .loading_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 6rem;
  }
`;

export const MediaLibraryTabPolaris = (props) => {
  const {
    onChange,
    onFilter,
    onFilterDate,
    data,
    multiple,
    value,
    ImageTypes,
    filter,
    loadMore,
    onScroll,
    networkStatus,
    loading,
  } = props;
  const [selected, setSelected] = useState("0");
  const [inputValue, setInputValue] = useState(null);
  const [popoverActive, setPopoverActive] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    from: null,
    to: null,
  });
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onFilter) {
      typingTimeoutRef.current = setTimeout(() => {
        onFilter({
          search: inputValue,
          mimeType: selected,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, inputValue]);

  const handleSelected = useCallback((value) => setSelected(value), []);
  const handleInputChange = useCallback((value) => setInputValue(value), []);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((prev) => !prev),
    []
  );

  const handleChangeDate = useCallback(
    (value) => {
      if (onFilterDate) {
        onFilterDate({ dateFilter: value ? value : dateFilter });
      }
      togglePopoverActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFilter]
  );

  const isImage = useCallback(
    (value) => ImageTypes.includes(value.mimeType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpdateCache = useCallback((file, client) => {
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total - 1,
            nodes: cache.mediaLibrary.nodes.map((f) => {
              if (f.id === file.id) {
                return { ...f, file };
              }
              return f;
            }),
          },
        },
      });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWriteCache = useCallback((file, client, adding = false) => {
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total - 1,
            nodes: adding
              ? [...file, ...cache.mediaLibrary.nodes]
              : cache.mediaLibrary.nodes.filter((f) => f.id !== file.id),
          },
        },
      });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Filter time
    </Button>
  );

  const images =
    data && data.mediaLibrary && data.mediaLibrary.nodes
      ? data.mediaLibrary.nodes
      : [];

  let len = value.length;

  return (
    <Container>
      <div className="media-library_wrap">
        <div className="filter-control_wrap">
          <div className="search_wrap">
            <TextField
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Filter media"
              prefix={<Icon source={SearchMinor} color="inkLighter" />}
              onClearButtonClick={() => handleInputChange(null)}
              clearButton
            />
          </div>
          <div className="more-filter_wrap">
            <Select
              options={[
                { value: "0", label: "All" },
                { value: "1", label: "Images" },
              ]}
              value={selected}
              onChange={handleSelected}
            />
            <Popover
              activator={activator}
              active={popoverActive}
              onClose={togglePopoverActive}
              sectioned
              fluidContent
            >
              <div id="popover-filter-time">
                <DatePickerPolaris
                  value={dateFilter}
                  onChange={({ start, end }) =>
                    setDateFilter((prevState) => ({
                      ...prevState,
                      from: start,
                      to: end,
                    }))
                  }
                />
                <div className="actions_wrap">
                  <ButtonGroup>
                    <Button
                      children="Clear"
                      size="slim"
                      onClick={() => {
                        let newDate = { from: null, to: null };
                        setDateFilter(() => newDate);
                        handleChangeDate(newDate);
                      }}
                    />
                    <Button
                      primary
                      children="Done"
                      size="slim"
                      onClick={() => handleChangeDate(null)}
                    />
                  </ButtonGroup>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <Scrollable
          shadow
          style={{ height: "70rem" }}
          onScrolledToBottom={onScroll}
        >
          <div id="media-library" className="media-library-content_wrap">
            {(loading && !loadMore) ? (
              <div className="loading_wrap">
                <Spinner size="large" hasFocusableParent  />
              </div>
            ) : (
              <>
                {images && images.length > 0 ? (
                  images.map((image, index) => (
                    <div
                      key={`${index} - ${image.id}`}
                      className="image"
                      title={image.name}
                      onClick={() => {
                        let newValue;
                        if (value.find((v) => v.id === image.id)) {
                          newValue = value.filter((v) => v.id !== image.id);
                        } else {
                          if (multiple) {
                            newValue = [...value, image];
                          } else {
                            newValue = [image];
                          }
                        }
                        if (onChange) {
                          onChange(newValue);
                        }
                      }}
                    >
                      <div
                        className="check"
                        style={{
                          visibility: value.find((v) => v.id === image.id)
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        <Checkbox label="" checked />
                      </div>
                      {isImage(image) ? (
                        <div
                          className="image-inner"
                          style={{
                            backgroundImage: `url(${
                              image.thumbnailUrl
                                ? image.thumbnailUrl
                                : image.url
                            })`,
                          }}
                        />
                      ) : (
                        <div className="picture-outlined">
                          <span>
                            {getExtension(image)}
                          </span>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div style={{ margin: "0 auto"}}>
                    <EmptyStatePolaris />
                  </div>
                )}
              </>
            )}
          </div>
          {loadMore && (
            <div
              style={{
                padding: "4rem 0 2rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner size="large" hasFocusableParent />
            </div>
          )}
        </Scrollable>
      </div>
      <div className="file-detail_wrap">
        {len > 0 ? (
          <div className="inner">
            {value.length > 0 && (
              <MediaFileDetailsPolaris
                value={value[len - 1]}
                isImage={isImage(value[len - 1])}
                onChange={(file, client) => {
                  value[len - 1] = file;
                  handleUpdateCache(file, client);
                  if (onChange) {
                    onChange(value);
                  }
                }}
                onDelete={(file, client) => {
                  value.splice(len - 1, 1);
                  handleWriteCache(file, client);
                  if (onChange) {
                    onChange(value);
                  }
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </Container>
  );
};

function getExtension(image) {
  if (!image || !image.name) return "";

  const [nLeft] = image.name.split(/\?/);
  const [, ext] = nLeft?.split(/\./) || []

  return ext ? ext : ""
}