import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useMemo,
} from "react";
import { Select, ChoiceList } from "@shopify/polaris";
import moment from "moment";
import _ from "lodash";

import { AppContext } from "../../context";
import { TEAM_ROLE } from "../../variable";
import { FilterCustomTimePolaris } from "./FilterCustomTimePolaris";

const ordersCreatedAt = "orders.created_at";
let optionsField = [
    { value: "tasks.created_at", label: "Task Created Date" },
    { value: "orders.created_at", label: "Order Created Date" },
    { value: "tasks.deadline", label: "Deadline" },
    { value: "tasks.done_date", label: "Done Date" },
];

export const FilterTimeByPolaris = ({
    // isLeader,
    onChange,
    onChangeRange,
    filterTime: { field, rangeLabel, range },
    isReport,
    isStatistic,
    isIdeaTask,
}) => {
    // Report page.
    // optionsField = isReport
    //     ? optionsField.filter((i) => i.value !== "orders.created_at")
    //     : optionsField;

    const FIELD_OPTIONS = useMemo(() => {
        const result =
            isReport || isIdeaTask
                ? optionsField.filter((i) => i.value !== ordersCreatedAt)
                : optionsField;

        return result;
    }, [isIdeaTask, isReport]);

    // Context
    const { currentUser } = useContext(AppContext);
    const teamRole = currentUser?.teamUser?.role;
    let showAssignDate = false;
    if ([TEAM_ROLE.DesignLeader].includes(teamRole)) {
        showAssignDate = true;
    }

    const [selectValue, setSelectValue] = useState(`${field}`);
    const [choiceValue, setChoiceValue] = useState(["All Time"]);
    const [customTime, setCustomTime] = useState({
        range: range,
        rangeLabel: rangeLabel,
    });

    const handleSelectChange = useCallback(
        (selected) => {
            const newOptionsField = showAssignDate
                ? [
                      ...FIELD_OPTIONS,
                      { value: "tasks.assign_date", label: "Assign Date" },
                  ]
                : [...FIELD_OPTIONS];
            const matchedOption = newOptionsField.find((option) => {
                return option.value === selected;
            });
            let label = matchedOption && matchedOption.label;
            if ("Task Created Date" === label) {
                label = null;
            }
            if (onChange) {
                onChange(selected, label);
            }
            setSelectValue(selected);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [FIELD_OPTIONS, setSelectValue, showAssignDate]
    );

    const renderChildren = useCallback(
        (isSelected) =>
            isSelected && (
                <FilterCustomTimePolaris
                    customTime={customTime}
                    onChange={({ from, to, fromLabel, toLabel }) => {
                        setCustomTime({
                            rangeLabel: `${fromLabel} - ${toLabel}`,
                            range: {
                                from,
                                to,
                            },
                        });
                        if (onChangeRange) {
                            onChangeRange(
                                { from, to },
                                `${fromLabel} - ${toLabel}`
                            );
                        }
                    }}
                />
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [choiceValue]
    );

    const options = [
        { value: "All Time", label: "All Time" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 days", label: "Last 7 days" },
        { value: "This month", label: "This month" },
        { value: "Last month", label: "Last month" },
        { value: "Custom", label: "Custom", renderChildren },
    ];

    useEffect(() => {
        if (null === range && "Custom" !== rangeLabel) {
            setChoiceValue("All Time");
        } else {
            let checkValue = _.values(_.mapValues(options, "value"));
            if (checkValue.includes(rangeLabel)) {
                setChoiceValue(`${rangeLabel}`);
            } else {
                setChoiceValue(`Custom`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field, range, rangeLabel]);

    const handleChoiceValue = useCallback((selected) => {
        let range = {
            from: null,
            to: null,
        };
        if (selected[0]) {
            switch (selected[0]) {
                case "Today":
                    range.from = moment().startOf("day");
                    range.to = moment().endOf("day");
                    break;
                case "Yesterday":
                    range.from = moment().subtract(1, "days").startOf("day");
                    range.to = moment().subtract(1, "days").endOf("day");
                    break;
                case "Last 7 days":
                    range.from = moment().subtract(7, "days").startOf("day");
                    range.to = moment();
                    break;
                case "This month":
                    range.from = moment().startOf("month");
                    range.to = moment();
                    break;
                case "Last month":
                    range.from = moment()
                        .subtract(1, "months")
                        .startOf("months");
                    range.to = moment().subtract(1, "months").endOf("months");
                    break;
                default:
                    range = null;
                    break;
            }
        }

        if (onChangeRange) {
            onChangeRange(range, selected[0]);
        }
        setChoiceValue(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* {isLeader && ( */}
            {isStatistic ? null : (
                <>
                    <Select
                        label="For"
                        options={
                            showAssignDate
                                ? [
                                      ...FIELD_OPTIONS,
                                      {
                                          value: "tasks.assign_date",
                                          label: "Assign Date",
                                      },
                                  ]
                                : [...FIELD_OPTIONS]
                        }
                        value={selectValue}
                        onChange={handleSelectChange}
                    />
                    <div style={{ marginBottom: 15 }}></div>
                </>
            )}
            {/* )} */}
            <ChoiceList
                title="Value"
                // titleHidden={!isLeader}
                choices={options}
                selected={choiceValue}
                onChange={handleChoiceValue}
            />
        </>
    );
};
