import {
  Button,
  Checkbox,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import _, { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { CUSTOM_FULFILLMENT, PRINTWAY_SLUG } from "../../constants";
import { useAppContext } from "../../context";
import { arrInvalid, objectInvalid, reducerFn } from "../../helper";
import {
  MAX_LENGTH,
  META_DATA,
  META_DATA_KEY_MB,
  PLATFORMS,
  PLATFORM_ETSY,
  PME_TEAM_ID,
  TITLE_LENGTH_MESS,
} from "../../variable";
import {
  getPrintifyShippingMethod,
  getPrintwayShippingMethods,
} from "../base/utils";
import { SimpleCustomizePolaris } from "../customize/SimpleCustomizePolaris";
import { CollectionSelectPolaris } from "../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../product/tags/TagsSelectPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ChoosePBPolaris } from "./ChoosePBPolaris";
import { MappingVariantsPolaris } from "./MappingVariantsPolaris";
import { SetAttributesPolaris } from "./SetAttributesPolaris";
import { ShippingService } from "./ShippingService";
import { checkFulfillment } from "../order/ManualOrder/components/Form";

const Container = styled.div`
  .pb-wrap {
    margin-top: 2.4rem;
  }
  .personalized-wrap {
    .label {
      height: 23px;
      font-weight: 500;
      display: inline-flex;
      position: relative;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
    .list-item {
      list-style-type: none;
      padding: 0 0 0 0.5rem;
      color: rgba(0, 0, 0, 0.85);
      .key {
        font-weight: 500;
      }
    }
  }
  .loading-wrap {
    margin: 0 -3rem;
  }
`;

export const MapOrderFormPolaris = ({
  data,
  item,
  loading,
  loadingMutation,
  onSubmit,
}) => {
  // Context
  const { currentUser } = useAppContext();
  const { teamUser } = currentUser || {};
  const { team } = teamUser || {};
  const { id: teamId } = team || {};
  // const ctx = useMappingOrderType();

  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState(null);
  const [pbSelected, setPBSelected] = useState("all");
  const [productTitle, setProductTitle] = useState(null);
  const [activateVariants, setActivateVariants] = useState([]);
  const [values, setValues] = useState([]);
  const [simpleChecked, setSimpleChecked] = useState(false);
  const [groupChecked, setGroupChecked] = useState([]);
  const [groupItems, setGroupItems] = useState([]);
  const [collections, setCollections] = useState(
    PME_TEAM_ID === teamId ? ["hGypw"] : [],
  );
  const [tags, setTags] = useState([]);
  const [checkPersonalized, setCheckPersonalized] = useState(false);
  const [fields, setFields] = useState([]);
  const [mappingVariant, setMappingVariant] = useState([]);
  const [shippingOption, setShippingOption] = useState(null);

  const [errors, setErrors] = useState({});
  const [errPersonal, setErrPersonal] = useState({});
  const [modifiedData, setModifiedData] = useState({});
  const [forceMapping, setForceMapping] = useState(false);
  const [mapToBaseVariantId, setMapToBaseVariantId] = useState(null);
  const [isPrintway, setIsPrintway] = useState(false);
  const [shippingOptionsPw, setShippingOptionPw] = useState([]);
  const [state, setState] = React.useReducer(reducerFn, {
    autoMapLaterOrders: true,
    isPrintway: false,
    isPrintify: false,
    option: [],
  });

  useEffect(() => {
    if (item) {
      let title = _.get(item, "product.name");
      handleProductTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const validateTitle = useCallback((value) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    setErrors((prev) => ({ ...prev, productTitle: error }));
  }, []);

  const handleSelectedChange = useCallback(
    (value) => {
      setPBSelected(value);
      setShippingOption(null);

      const slug = getFulfillSlug(value, productBases);
      const { isPrintify, isPrintway } = checkFulfillment(slug);
      setState({ isPrintify, isPrintway, options: [] });
    },
    [productBases],
  );

  const handleProductTitle = useCallback((value) => {
    setProductTitle(value);
    validateTitle(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePBChange = useCallback(
    ({ productBaseId }) => {
      setShippingOption(null);
      if (
        data &&
        data.productBasesForAssortment &&
        data.productBasesForAssortment.length
      ) {
        let newPB = data.productBasesForAssortment.find(
          (pb) => pb.id === productBaseId,
        );
        setProductBase(newPB);
      }
    },
    [data],
  );
  const handleCollections = useCallback((value) => {
    setCollections(value);
  }, []);
  const handleTags = useCallback((value) => setTags(value), []);
  const handleCheckPersonalized = useCallback(
    (value) => setCheckPersonalized(value),
    [],
  );

  const handleFieldsChange = useCallback((value) => {
    setFields(() => [...value]);
  }, []);

  useEffect(() => {
    let productBases = [];
    const ffIds =
      data?.fulfillmentConfigs?.length > 0
        ? data.fulfillmentConfigs.map(({ fulfillmentId }) => fulfillmentId)
        : [];
    if (data?.fulfillments?.length > 0) {
      productBases = data.fulfillments
        .filter((ff) => ffIds.includes(ff.id) || ff.slug === CUSTOM_FULFILLMENT)
        .map((ff) => {
          return {
            value: ff.id,
            label: ff.name,
            slug: ff.slug,
          };
        });
    }
    productBases.unshift({ value: "all", label: "MerchBridge" });
    setProductBases(productBases);
  }, [data]);

  useEffect(() => {
    if (!checkPersonalized) {
      setFields([]);
    }
  }, [checkPersonalized]);

  const disabled = React.useMemo(() => {
    let check1 = item.variants && item.variants.length === values.length;
    if (forceMapping) {
      check1 = true;
    }

    let res = true;
    if (check1) {
      res = false;

      if (
        activateVariants &&
        activateVariants.length > 0 &&
        collections.length &&
        tags.length
      ) {
        res = false;
        if (checkPersonalized) {
          if (fields.length) {
            res = false;
            if (errPersonal) {
              let errs = Object.values(errPersonal)
                .map((item) => Object.values(item))
                .flat()
                .filter((i) => i != null);
              if (errs.length) {
                res = true;
              }
            }
          } else {
            res = true;
          }
        }
      } else {
        res = true;
      }
    }

    return res;
  }, [
    item,
    values,
    forceMapping,
    activateVariants,
    collections,
    tags,
    checkPersonalized,
    fields,
    errPersonal,
  ]);

  const metaDataArr = _.get(item, "metaDta", []);
  const metaKey =
    metaDataArr && metaDataArr.length
      ? metaDataArr.find(
          (m) => m.key === "_mb_personalized" || m.key === "mb_personalized",
        )
      : false;
  const isPersonalizedDisable = !!metaKey;
  const platforms = ["amazon", "shopify", "woocommerce", PLATFORM_ETSY];
  const platform = _.get(item, "store.platform");
  const showPersonalizedInfo = platforms.includes(platform);
  const showAutoMap = [PLATFORMS.Tiktok].includes(platform);

  const handleSubmit = useCallback(() => {
    let fieldValues = [];
    let newFields =
      fields && fields.length > 0
        ? fields.map((item) => {
            let { fieldValue, ...rest } = item;
            fieldValues.push(fieldValue);
            return rest;
          })
        : [];
    let mappingOrderId = item && item.id;
    let productBaseId = productBase && productBase.id;
    let newMappingVariant =
      mappingVariant &&
      mappingVariant
        .sort((a, b) => a.sorting - b.sorting)
        .map((variant) => ({
          variantId: variant.variantId,
          productBaseVariantId: variant.productBaseVariantId,
        }));
    let personalized = isPersonalizedDisable || checkPersonalized;
    let collectionIds = collections;
    let tagIds = tags;
    let activeVariants = activateVariants;

    // Check title length
    validateTitle(productTitle);
    let countErr = _.size(_.filter(_.values(errors), (e) => e !== null));

    let canSubmit = productTitle && countErr === 0;

    const input = {
      mappingOrderId,
      productBaseId,
      mappingVariant: forceMapping ? [] : newMappingVariant,
      personalized,
      fields: newFields,
      fieldValues: fieldValues.filter((f) => f.key),
      productTitle,
      collectionIds,
      tagIds,
      activeVariants: forceMapping ? [] : activeVariants,
      shippingOption,
      forceMapping,
      mapToBaseVariantId: forceMapping ? mapToBaseVariantId : undefined,
      autoMapLaterOrders: showAutoMap ? state.autoMapLaterOrders : undefined,
    };

    if (onSubmit && !!canSubmit) {
      onSubmit(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields,
    productBase,
    mappingVariant,
    checkPersonalized,
    productTitle,
    collections,
    tags,
    activateVariants,
    item,
    shippingOption,
    forceMapping,
    mapToBaseVariantId,

    state.autoMapLaterOrders,
    showAutoMap,
  ]);

  // Personalized
  const pt = new RegExp(META_DATA_KEY_MB, "i");
  const metaData = item?.metaData
    ? item.metaData
        .filter((i) => pt.test(i.key))
        .map((i) => {
          if (i?.value?.length && Array.isArray(i.value)) {
            return i.value.map((item) => {
              return {
                name: item.key,
                value: item.value,
              };
            });
          }
          return null;
        })
        .filter(Boolean)
        .flat()
    : [];

  const personalizedMarkup =
    item?.personalized?.length > 0
      ? item.personalized
      : metaData?.length > 0
      ? metaData
      : [];

  const fulfillment = useMemo(() => {
    return productBases?.length > 0 && pbSelected != null
      ? productBases.find((node) => node.value === pbSelected)
      : undefined;
  }, [productBases, pbSelected]);

  // Only platform Etsy can see "Digital Product" base
  useEffect(() => {
    let newData = _.clone(data);
    if (data && item && item.store && "etsy" != item.store.platform) {
      let newBases = newData.productBasesForAssortment.filter(
        (el) => el.isDigitalProduct == false,
      );
      newData.productBasesForAssortment = newBases;
    }
    setModifiedData(newData);
  }, [data]);

  const handleVariantChange = React.useCallback(
    (values) => {
      setMappingVariant(values);
      if (values && values.length > 0) {
        let newAV = [];
        // if (activateVariants && activateVariants.length > 0) {
        //   newAV = activateVariants;
        // }
        _.map(values, function (v) {
          newAV.push(v.productBaseVariantId);
        });

        //get unique id
        let newActiveVariants = newAV
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => newAV[e])
          .map((e) => newAV[e]);
        if (newActiveVariants.length > 0) {
          setActivateVariants(() => [...newActiveVariants]);
        }
      } else {
        setActivateVariants([]);
      }
      setValues(values);
      setShippingOption(null);

      const { isPrintify, isPrintway } = state;

      console.log("values", values);
      console.log("state", state);
      console.log("productBase", productBase);
      if (isPrintway) {
        const variantID = get(values, "[0].productBaseVariantId");
        if (variantID && !arrInvalid(productBase?.variants)) {
          const match = productBase.variants.find((i) => i?.id === variantID);
          if (match?.fulfillmentSku?.length > 0) {
            (async (fulfillmentSku) => {
              let options = [];
              const shippingServices = await getPrintwayShippingMethods([
                fulfillmentSku,
              ]);

              if (!arrInvalid(shippingServices)) {
                const matchById = shippingServices.find(
                  (item) => item.itemSKU === fulfillmentSku,
                );
                if (!objectInvalid(matchById)) {
                  if (!arrInvalid(matchById.shippingServices)) {
                    options = matchById.shippingServices.map((method) => ({
                      value: method,
                      label: method,
                    }));
                  }
                }
              }

              setState({ options });
            })(match.fulfillmentSku);
          } else {
            setState({ options: [] });
          }
        } else {
          setState({ options: [] });
        }
      } else if (isPrintify) {
        if (productBase.id) {
          (async function (baseID) {
            const shippingServices = await getPrintifyShippingMethod(baseID);
            if (shippingServices.length > 0) {
              setState({ options: shippingServices });
            }
          })(productBase.id);
        } else {
          setState({ options: [] });
        }
      }
    },
    [productBase, state.isPrintify, state.isPrintway],
  );

  const handleAutoMapLaterOrders = React.useCallback((val) => {
    setState({ autoMapLaterOrders: val });
  }, []);

  return (
    <Container>
      {loading ? (
        <div className="loading-wrap">
          <SkeletonPagePolaris />
        </div>
      ) : (
        <FormLayout>
          <FormLayout.Group>
            <Select
              label="Product base"
              value={pbSelected}
              options={productBases}
              onChange={handleSelectedChange}
            />
            <div className="pb-wrap">
              <ChoosePBPolaris
                data={modifiedData}
                fulfillmentId={"all" === pbSelected ? null : pbSelected}
                onChange={handlePBChange}
              />
            </div>
          </FormLayout.Group>
          <TextField
            label="Product title"
            value={productTitle}
            onChange={handleProductTitle}
            error={errors["productTitle"]}
          />
          <MappingVariantsPolaris
            item={item}
            productBaseId={productBase ? productBase.id : null}
            variants={item.variants ? item.variants : []}
            forceMapping={forceMapping}
            setForceMapping={setForceMapping}
            setMapToBaseVariantId={setMapToBaseVariantId}
            productBaseVariants={productBase ? productBase.variants : []}
            onChange={handleVariantChange}
            autoMapMarkup={
              showAutoMap && (
                <Checkbox
                  label="Auto mapping for later orders"
                  checked={!!state.autoMapLaterOrders}
                  onChange={handleAutoMapLaterOrders}
                />
              )
            }
          />
          <SetAttributesPolaris
            productBase={productBase}
            activateVariants={activateVariants}
            onChange={({
              activeVariants,
              simpleChecked,
              groupChecked,
              groupItems,
            }) => {
              setActivateVariants(() => [...activeVariants]);
              if (activeVariants && activeVariants.length > 0) {
                setSimpleChecked(true);
              } else {
                setSimpleChecked(false);
              }
              if (undefined !== simpleChecked) {
                setSimpleChecked(simpleChecked);
              }
              if (undefined !== groupChecked) {
                setGroupChecked(groupChecked);
              }
              if (undefined !== groupItems) {
                setGroupItems(groupItems);
              }
            }}
            simpleChecked={simpleChecked}
            groupChecked={groupChecked}
            groupItems={groupItems}
          />
          <CollectionSelectPolaris
            label="Collections"
            allowMultiple
            value={collections}
            onChange={handleCollections}
            haveQuickAdd
            required
            hasPopular
            limit={200}
          />
          <TagsSelectPolaris
            label="Tags"
            allowMultiple
            value={tags}
            onChange={handleTags}
            haveQuickAdd
            required
            limit={200}
          />
          {showPersonalizedInfo && personalizedMarkup?.length > 0 ? (
            <div className="personalized-wrap">
              <label className="label">Personalized info</label>
              <ul className="list-item">
                {personalizedMarkup.map((md, idx) => {
                  let hasImage = _.includes(md.value, META_DATA);
                  return (
                    <li key={`personalized-${idx}`}>
                      <span className="key" style={{ wordBreak: "break-all" }}>
                        {md.name && md.name.trim()}: {""}
                      </span>{" "}
                      {hasImage ? (
                        <div>
                          <CustomLinkPolaris href={md.value}>
                            <img
                              src={md.value}
                              alt=""
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: "cover",
                              }}
                            />
                          </CustomLinkPolaris>
                        </div>
                      ) : (
                        <span style={{ wordBreak: "break-all" }}>
                          {md.value}
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          <Checkbox
            label="This is a personalized product"
            checked={checkPersonalized}
            onChange={handleCheckPersonalized}
            disabled={isPersonalizedDisable}
          />
          {checkPersonalized ? (
            <SimpleCustomizePolaris
              value={fields}
              personalized={(item && item.personalized) || metaData}
              isMapOrder
              onChange={handleFieldsChange}
              onErrorChange={(value) => setErrPersonal(value)}
              item={item}
            />
          ) : null}
          <ShippingService
            item={item}
            fulfillment={fulfillment}
            shippingOption={shippingOption}
            setShippingOption={setShippingOption}
            options={state.options}
          />
          <Button
            primary
            fullWidth
            disabled={disabled}
            loading={loadingMutation}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </FormLayout>
      )}
    </Container>
  );
};

function getFulfillSlug(id, data) {
  if (!id || id === "all" || !data || data.length === 0) return null;

  const cur = data.find(({ value }) => value === id);
  if (!cur) return null;

  return cur.slug;
}
