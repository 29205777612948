import React from "react";
import {
  SkeletonPage,
  Layout,
  Card,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 1.5rem;
`;

export const SkeletonPagePolaris = ({ sizeSmall }) => {
  return (
    <Container>
      <SkeletonPage fullWidth secondaryActions={1}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
            {!sizeSmall && (
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            )}
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </Container>
  );
};
