import React, { Component } from "react";
import PropTypes from "prop-types";
import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import Price from "../Price";

class ProductBaseVariantQuickEdit extends Component {
  state = {
    selected: null,
    price: 0,
  };

  render() {
    const { disabled, isCustomcat, showSupplierPrice } = this.props;

    let items = [
      {
        title: "Regular Price",
        key: "regularPrice",
      },
      {
        title: "Sale Price",
        key: "salePrice",
      },
      {
        title: "Seller Price",
        key: "sellerPrice",
      },
      showSupplierPrice && {
        title: "Supplier Pricing",
        key: "supplierPricing",
      },
      ...(isCustomcat
        ? [
            {
              title: "Shipping Cost",
              key: "fulfillmentShippingCost",
            },
            {
              title: "Shipping International Cost",
              key: "fulfillmentShippingInternationalCost", //?
            },
          ]
        : []),
    ].filter(Boolean);

    return (
      <React.Fragment>
        <Dropdown
          disabled={disabled}
          overlay={
            <Menu>
              {items.map((item, index) => (
                <Menu.Item
                  onClick={() => {
                    this.setState({
                      selected: item,
                    });
                  }}
                  key={index}
                >
                  {item.title}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button type={"link"} icon={<CaretDownOutlined />}>
            Quick Edit
          </Button>
        </Dropdown>
        {this.state.selected && (
          <Modal
            width="520px"
            onOk={() => {
              if (this.props.onChange) {
                this.props.onChange({
                  key: this.state.selected.key,
                  value: this.state.price,
                });
              }
              this.setState({
                selected: null,
                price: 0,
              });
            }}
            onCancel={() => {
              this.setState({ selected: null, price: 0 });
            }}
            visible={true}
            title={`Set Bulk ${this.state.selected.title}`}
          >
            <Price
              value={this.state.price}
              onChange={(price) => {
                this.setState({ price });
              }}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

ProductBaseVariantQuickEdit.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ProductBaseVariantQuickEdit;
