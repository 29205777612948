import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Card,
  Loading,
  Page,
  Tabs,
  TextField,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { productBaseFragment } from "../../../fragments/index";
import {
  getCookie,
  getParamByRole,
  reducerFn,
  setCookie,
} from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { TableTemplatesPolaris } from "./TableTemplatesPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LIST_TEMPLATE = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      total
      nodes {
        id
        name
        slug
        grouping
        isProductTemplate
        defaultMockupIds
        productBases {
          id
          mockupTemplates {
            id
            originTemplateID
            isDefault
          }
        }
        data {
          personalized
          productBases {
            ...ProductBasse
          }
          ProductBaseIds
          fields {
            name
            title
            type
            extraFee
            configure
            sorting
          }
          products {
            title
            description
            productbaseId
            shortDescription
            tagIds
            tags {
              id
              name
            }
            variants {
              salePrice
              regularPrice
              productBaseVariantID
              disabled
            }
            collectionIds
            collections {
              id
              name
            }
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

export const TemplatesPolaris = ({ handleUseTemplate, allTemplate = true }) => {
  const [selected, setSelected] = useState(0);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageTemplates")) || 20,
    offset: 0,
    product: null,
    search: null,
  });
  const [state, setState] = useReducer(reducerFn, {
    search: "",
  });

  // Context
  const { currentUser } = useAppContext();
  const currentParam = getParamByRole(currentUser);

  const { data, loading, error } = useQuery(LIST_TEMPLATE, {
    variables: {
      filter,
    },
  });

  useEffect(() => {
    if (0 === selected) {
      setFilter((prevState) => ({ ...prevState, product: null }));
    } else if (1 === selected) {
      setFilter((prevState) => ({ ...prevState, product: true }));
    } else if (2 === selected) {
      setFilter((prevState) => ({ ...prevState, product: false }));
    }
  }, [selected]);

  // Actions
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setFilter((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  }, []);
  const handlePagination = useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageTemplates", limit, 100);
  }, []);

  const filterTimeoutRef = React.useRef(null);
  const handleSearch = useCallback((val) => {
    setState({ search: val });

    filterTimeoutRef.current && clearTimeout(filterTimeoutRef.current);
    filterTimeoutRef.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search: val }));
    }, 500);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  const tabs = [
    {
      id: "all-templates",
      content: "All Templates",
      accessibilityLabel: "All Templates",
      panelID: "all-templates",
    },
    {
      id: "product-templates",
      content: "Product Templates",
      accessibilityLabel: "Product Templates",
      panelID: "product-templates",
    },
    {
      id: "campaign-templates",
      content: "Campaign Templates",
      panelID: "campaign-templates",
    },
  ];

  // Actions
  const actionMarkup = (
    <ButtonGroup>
      <Button
        children="Add Template for Campaign"
        url={`/${currentParam}/templates/for-campaign`}
      />
    </ButtonGroup>
  );

  return (
    <Container>
      {loadingMarkup}
      <Page title="All Templates" fullWidth primaryAction={actionMarkup}>
        <Card sectioned>
          <TextField
            value={state.search}
            onChange={handleSearch}
            placeholder="Enter template name"
          />
        </Card>
        <Card>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            {error && <div>Error: {error.toString()}</div>}
            {loading ? (
              <SkeletonPagePolaris />
            ) : data?.templates?.nodes?.length > 0 ? (
              <TableTemplatesPolaris
                allTemplate={allTemplate}
                selectedTab={selected}
                data={data}
                handleUseTemplate={handleUseTemplate}
                filter={filter}
                setFilter={handlePagination}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Tabs>
        </Card>
      </Page>
    </Container>
  );
};
