import { useMutation } from "@apollo/react-hooks";
import { Button, InlineError, Modal, Stack } from "@shopify/polaris";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { RE_SUBMIT_ORDER_TO_FF } from "../../../graphql/mutations";
import { handleError, sleep } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { BulkActions as BulkActs } from "../../shared/BulkActions";
import { ToastContextProvider } from "../../shared/ToastContext";
import { SwitchOrderForOrderComp } from "../switch-store";
import { ORDER_STATUS } from "../../../variable";

export function BulkActions({
    ids,
    idsChecked,
    handleCheckedAll,
    handleCompleted,
    status,
}) {
    // State
    const [openModal, toggleOpenModal] = useToggle(false);
    const [action, setAction] = useState(null);
    const timeoutRef = useRef(null);

    // Actions
    const onCompleted = useCallback(() => {
        toggleOpenModal();

        timeoutRef.current = setTimeout(() => {
            handleCompleted && handleCompleted();
        }, 1500);
    }, [handleCompleted]);

    useEffect(
        () => () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        },
        []
    );

    const handleToggleModal = useCallback(
        (key) => () => {
            toggleOpenModal();
            setAction(key);
        },
        []
    );

    const actions = [
        [
            ORDER_STATUS.Error,
            ORDER_STATUS.PendingDesign,
            ORDER_STATUS.Pending,
            ORDER_STATUS.OnHold,
            ORDER_STATUS.PendingPayment,
        ].includes(status) && {
            content: "Switch Store for Orders",
            action: handleToggleModal("switchStore"),
        },
        [ORDER_STATUS.Error].includes(status) && {
            content: "Re-submit to Fulfillment",
            action: handleToggleModal("reSubmitOrders"),
        },
    ].filter(Boolean);

    const props = {
        open: openModal,
        toggleOpen: () => {
            setAction(null);
            toggleOpenModal();
        },
        ids: idsChecked,
        handleCompleted: onCompleted,
    };
    const actionsMarkup = {
        switchStore: <SwitchStore {...props} />,
        reSubmitOrders: <ReSubmitOrders {...props} ids={ids} />,
    };

    return actions?.length > 0 ? (
        <Fragment>
            <ToastContextProvider>
                <BulkActs
                    idsChecked={idsChecked}
                    onClose={() => handleCheckedAll(false)}
                >
                    {actions.map((act, i) => {
                        return (
                            <Button
                                children={act.content}
                                onClick={act.action}
                                key={`action-${i}`}
                            />
                        );
                    })}
                </BulkActs>
                {actionsMarkup[action]}
            </ToastContextProvider>
        </Fragment>
    ) : null;
}

function SwitchStore({ open, toggleOpen, ids, handleCompleted }) {
    return (
        <Modal
            title="Switch Store for Orders"
            open={open}
            onClose={toggleOpen}
            sectioned
        >
            <SwitchOrderForOrderComp
                ids={ids}
                handleCompleted={handleCompleted}
            />
        </Modal>
    );
}

function ReSubmitOrders({ open, toggleOpen, ids, handleCompleted }) {
    const [loading, setLoading] = useState(false);
    const [reSubmit] = useMutation(RE_SUBMIT_ORDER_TO_FF);

    const handleSubmit = useCallback(async () => {
        if (ids?.length > 0) {
            let i = 0;
            setLoading(true);
            while (i < ids.length) {
                const newIds = ids.slice(i, 5 + i);
                await Promise.all(
                    newIds.map(async (id) => {
                        try {
                            return await reSubmit({
                                variables: { orderId: id },
                            });
                        } catch (err) {}
                    })
                );
                i += 5;
                sleep(1000 * 5);
            }
            setLoading(false);
            handleCompleted();
        }
    }, [ids, handleCompleted]);

    return (
        <Modal
            title="Re-Submit to Fulfillment"
            open={open}
            onClose={toggleOpen}
            sectioned
            secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Stack>
                <p>Are you sure to re-submit to Fulfillment for that orders?</p>
                <InlineError message="Make sure that you cancelled this order in Fulfillment service" />
            </Stack>
        </Modal>
    );
}
