import { gql } from "apollo-boost";
import {
  TaskFragment,
  TaskAggerationFragment,
  TaskByIdFragment,
  FieldFragment,
  FileFragment,
  CountTaskByUserFragment,
  CountTaskRejectForUserFragment,
  IdeaTaskFragment,
  reportTasksResultFragment,
} from "../fragments";

export const GET_TASKS = gql`
  query tasks($filter: TaskFilter) {
    tasks(filter: $filter) {
      total
      nodes {
        ...TaskFragment
      }
    }
  }
  ${TaskFragment}
`;

export const GET_TASKS_V2 = gql`
  query tasksV2($filter: TaskFilter) {
    tasksV2(filter: $filter) {
      total
      nodes {
        ...TaskFragment
      }
      aggregations
    }
  }
  ${TaskFragment}
`;

export const TASK_AGGS_V2 = gql`
  query taskAggsV2($filter: TaskFilter) {
    taskAggsV2(filter: $filter)
  }
`;

export const GET_TASK_AGGS = gql`
  query taskAggs($filter: TaskFilter) {
    taskAggs(filter: $filter) {
      ...TaskAggerationFragment
    }
  }
  ${TaskAggerationFragment}
`;

export const GET_TASK_BY_ID = gql`
  query taskById($id: ID!) {
    taskById(id: $id) {
      ...TaskByIdFragment
    }
  }
  ${TaskByIdFragment}
`;

export const GET_VALUE_FOR_ORDER = gql`
  query getFieldValueForOrder($orderId: ID!) {
    getFieldValueForOrder(orderId: $orderId) {
      id
      createdAt
      field {
        ...FieldFragment
      }
      value
      order {
        id
        product {
          id
          mainImageId
          images {
            id
            productBaseId
            file {
              ...FileFragment
            }
          }
        }
        productVariant {
          id
          productBaseVariant {
            id
            productBase {
              id
            }
          }
        }
      }
    }
  }
  ${FieldFragment}
  ${FileFragment}
`;

export const GET_TASK_SUBMISSION = gql`
  query getTaskSubmission($taskId: ID!, $status: String!) {
    getTaskSubmission(taskId: $taskId, status: $status) {
      id
      taskId
      status
      rejectReason
      rejectResources {
        ...FileFragment
      }
      configByUser {
        firstName
        lastName
      }
      designs {
        id
        submissionId
        designPositionId
        designPosition {
          id
          name
          description
          artworkGuidelines {
            id
            file {
              ...FileFragment
            }
          }
        }
        file {
          ...FileFragment
        }
      }
    }
  }
  ${FileFragment}
`;

export const GET_SIMILAR_PRODUCT = gql`
  query getSimilarProduct(
    $id: ID!
    $productIds: [ID!]
    $pLimit: Int
    $pOffset: Int
  ) {
    getSimilarProduct(
      id: $id
      productIds: $productIds
      pLimit: $pLimit
      pOffset: $pOffset
    ) {
      productIds
      total
      nodes {
        sku
        designs {
          orderId
          designUrl {
            url
            thumbUrl
          }
          designer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_DESIGN_POSITION_FROM_ORDER_TASK = gql`
  query getDesignPositionFromOrderTask($taskId: ID!) {
    getDesignPositionFromOrderTask(taskId: $taskId) {
      id
      image {
        ...FileFragment
      }
      name
      displayName
      sorting
      description
    }
  }
  ${FileFragment}
`;

export const QUERY_TASK_LOG = gql`
  query getTaskLog($filter: TaskLogFilter) {
    getTaskLog(filter: $filter) {
      total
      nodes {
        id
        log
        createdAt
      }
    }
  }
`;

export const GET_COUNT_TASK_BY_USER = gql`
  query getCountTaskByUser($filter: FilterCountTask!) {
    getCountTaskByUser(filter: $filter) {
      total
      nodes {
        ...CountTaskByUserFragment
      }
    }
  }
  ${CountTaskByUserFragment}
`;

export const REPORT_COUNT_TASK_SUBMISSION_BY_CHECKED = gql`
  query reportCountTaskSubmissionByChecked(
    $filter: CountTaskSubmissionByCheckerFilter!
  ) {
    reportCountTaskSubmissionByChecked(filter: $filter) {
      checker
      totalCheckTimes
      totalRejectedTimes
      totalAcceptedTimes
    }
  }
`;

export const GET_COUNT_TASK_REJECT_FOR_USER = gql`
  query getCountTaskRejectForUser($filter: FilterCountTaskReject!) {
    getCountTaskRejectForUser(filter: $filter) {
      total
      nodes {
        ...CountTaskRejectForUserFragment
      }
    }
  }
  ${CountTaskRejectForUserFragment}
`;

export const GET_IDEA_TASK = gql`
  query ideaTaskById($id: ID!) {
    ideaTaskById(id: $id) {
      ...IdeaTaskFragment
    }
  }
  ${IdeaTaskFragment}
`;

export const REPORT_TASKS_OTHER_DONE_CANCELLED_DENIED = gql`
  query reportTasksOtherDoneCancelledDenied($filter: ReportTasksFilter!) {
    reportTasksOtherDoneCancelledDenied(filter: $filter) {
      ...reportTasksResultFragment
    }
  }
  ${reportTasksResultFragment}
`;

export const GET_TASK_SUBMISSION_BY_ORDER_IDS = gql`
  query getTaskSubmissionByOrderIDs($orderIDs: [ID!]!) {
    getTaskSubmissionByOrderIDs(orderIDs: $orderIDs) {
      orderID
      checker {
        fullName
        email
      }
      taskSubmission {
        status
        id
      }
    }
  }
`;

export const GET_ETSY_TASK_SUBMISSION = gql`
  query getEtsyTaskSubmission($taskID: String!, $token: String!) {
    getEtsyTaskSubmission(taskID: $taskID, token: $token) {
      taskSubmission {
        id
        taskId
        status
        designs {
          id
          file {
            id
            name
            url
            thumbnailUrl
          }
        }
      }

      personalized
      taskStatus
      isDigital
      store {
        id
        extraInfo
      }

      order {
        id
        originId
        quantity
        createdAt

        shippingAddress {
          id
          address1
          address2
          firstName
          lastName
          city
          state
          country
          postalCode
        }

        product {
          title
          mainImageId

          images {
            id
            file {
              id
              url
            }
          }
        }
      }
    }
  }
`;
