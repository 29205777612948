import { gql } from "apollo-boost";

export const DELETE_FILE_AMZ = gql`
    mutation deleteFile($id: ID!, $deleteAmz: Boolean) {
        deleteFile(id: $id, deleteAmz: $deleteAmz)
    }
`;

export const DELETE_FILE = gql`
    mutation deleteFile($id: ID!) {
        deleteFile(id: $id)
    }
`;
