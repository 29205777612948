import get from "lodash/get";
import React from "react";

const initialValue = {
  loading: false,
  data: null,
  error: null,
};

function useCustomQuery({ queryOptions = {}, path }) {
  const [state, setState] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialValue
  );

  const queryOptionsStr = JSON.stringify(queryOptions);
  React.useEffect(() => {
    async function getData() {
      const {skip, ...queryOptions} = JSON.parse(queryOptionsStr)
      const { __apolloClient__: client } = window;
      if (!client || skip) return;

      try {
        setState({ ...initialValue, loading: true });
        const res = await client.query({
          ...queryOptions,
          fetchPolicy: "no-cache",
        });

        setState({ data: get(res, path) });
      } catch (err) {
        setState({ error: err?.toString() });
      } finally {
        setState({ loading: false });
      }
    }
    getData();
  }, [queryOptionsStr, path]);

  return state;
}

export { useCustomQuery };
