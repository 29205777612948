import { Query } from "@apollo/react-components";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  DataTable,
  Icon,
  Modal,
  Page,
  TextContainer,
} from "@shopify/polaris";
import { CircleInformationMajorMonotone } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _, { get, isEqual } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import authImage from "../../assets/images/ebay-app-oauth-2.png";
import CopyBox from "../../components/shared/CopyBox";
import Error from "../../components/shared/Error";
import { PaginationPolaris } from "../../components/shared/PaginationPolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { SelectRowsProvider } from "../../components/store/SelectRowsProvider";
import { StoresFilterPolaris } from "../../components/store/StoresFilterPolaris";
import { AppContext } from "../../context";
import { TEAM_ROLE, TEAM_ROLE_PARAMS } from "../../variable";
import { EbayActions } from "./EbayActions";

const EBAY_STORE_QUERY = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
        email
        platform
        status
        suspended
        group {
          id
          name
        }
      }
    }
    getAuthEbayURL
  }
`;

const FETCH_STORE_ORDERS_MUTATION = gql`
  mutation fetchStoreOrders($storeId: ID!) {
    fetchStoreOrders(storeId: $storeId)
  }
`;

class Ebay extends Component {
  static contextType = AppContext;
  state = {
    error: null,
    active: false,
    checked: false,
    ebayAuthUrl: "",
    fetching: null,
    data: {
      total: 0,
      nodes: [],
    },
    filter: {
      limit: 20,
      offset: 0,
      platformSelected: ["ebay"],
      status: null,
      search: null,
    },
  };

  handleFilterChange = (filter) => {
    const { search, status } = filter;
    this.setState((prev) => {
      if (
        !isEqual(prev.filter.search, search) ||
        !isEqual(prev.filter.status, status)
      ) {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            search,
            status,
            offset: 0,
          },
        };
      }
    });
  };

  handlePaginationChange = (offset, limit) => {
    this.setState((prev) => {
      return {
        ...prev,
        filter: {
          ...prev.filter,
          offset,
          limit,
        },
      };
    });
  };

  render() {
    const { data, checked, ebayAuthUrl } = this.state;
    const { currentUser } = this.context;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    const role = _.get(currentUser, "teamUser.role");
    if ([TEAM_ROLE.StoreManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    const isMarketplaceManager = [TEAM_ROLE.MarketplaceManager].includes(role);

    return (
      <Container>
        <Page
          fullWidth
          breadcrumbs={
            isMarketplaceManager
              ? []
              : [
                  {
                    content: "Sales Channels",
                    url: `/${currentParam}/stores`,
                  },
                ]
          }
          title="All Ebay Accounts"
          primaryAction={
            <Button
              onClick={() => {
                this.setState({ active: true });
              }}
              size="medium"
              primary
            >
              Add Ebay Account
            </Button>
          }
        >
          <Query
            onCompleted={(res) => {
              this.setState({
                data: res.stores,
                ebayAuthUrl: res.getAuthEbayURL,
              });
            }}
            variables={{ filter: this.state.filter }}
            query={EBAY_STORE_QUERY}
            fetchPolicy={"cache-and-network"}
          >
            {({ _, loading, error, refetch }) => {
              const dataSourcesFn = (checkItemMarkup) =>
                (data.nodes || []).map((s) => {
                  let storeStatus = "Connected";
                  let badgeStatus = "success";
                  if (s.status && s.status.length > 0) {
                    storeStatus = s.status;
                  }
                  if (s.platform && "ebay" === s.platform && s.suspended) {
                    storeStatus = "Suspended";
                    badgeStatus = "critical";
                  }

                  if (s.status === "Deactivated") {
                    storeStatus = "Deactivated";
                    badgeStatus = "attention";
                  }

                  // Group
                  const groupName = s?.group?.name;
                  return [
                    checkItemMarkup(s.id),
                    <div className={"avatar-container item"}>
                      <div>
                        <Avatar customer name="John Smith" />
                      </div>
                      <div className={"avatar-right"}>
                        <div>{s.title}</div>
                        {s.email}
                      </div>
                    </div>,
                    <div className="group-wrap item">{groupName}</div>,
                    <div className={"status-container item"}>
                      <Button
                        size="slim"
                        plain
                        icon={<Icon source={CircleInformationMajorMonotone} />}
                      />
                      <Badge status={badgeStatus}>{storeStatus}</Badge>
                    </div>,
                    <div className={"actions item"}>
                      <EbayActions store={s} refetch={refetch} />
                    </div>,
                  ];
                });

              // Variables
              let { limit, offset } = this.state.filter;
              const total = get(data, "total", 0);
              const totalPage = Math.ceil(total / limit);
              const page = offset / limit + 1;
              const aggregation = {
                page,
                totalPage,
                offset,
                limit,
                onChange: this.handlePaginationChange,
                total,
              };
              return (
                <div>
                  <Card sectioned>
                    <StoresFilterPolaris
                      filter={this.state.filter}
                      onChange={this.handleFilterChange}
                    />
                  </Card>
                  {loading ? (
                    <SkeletonPagePolaris />
                  ) : error ? (
                    <div style={{ marginTop: 10 }}>
                      <Error error={error} />
                    </div>
                  ) : (
                    <Card>
                      <SelectRowsProvider
                        data={data.nodes || []}
                        refetch={refetch}
                      >
                        {({ checkAllMarkup, checkItemMarkup }) => (
                          <div className="table-wrapper">
                            <DataTable
                              columnContentTypes={[
                                "text",
                                "text",
                                "text",
                                "text",
                                "numeric",
                              ]}
                              headings={[
                                checkAllMarkup,
                                "Account",
                                "Group",
                                "Status",
                                "Action",
                              ]}
                              rows={dataSourcesFn(checkItemMarkup)}
                              hideScrollIndicator
                              verticalAlign="middle"
                            />
                            <PaginationContainer>
                              <PaginationPolaris
                                aggregation={aggregation}
                                showTotal
                              />
                            </PaginationContainer>
                          </div>
                        )}
                      </SelectRowsProvider>
                    </Card>
                  )}
                </div>
              );
            }}
          </Query>
        </Page>
        <Modal
          open={this.state.active}
          onClose={() => this.setState({ active: false })}
          title="Connect Your Ebay Account"
        >
          <ModalContainer>
            <Modal.Section>
              <TextContainer>
                <h2>
                  Manage and fulfill your eBay orders from all your accounts at
                  once with MerchBridge
                </h2>
                <div className={"image"}>
                  <img src={authImage} alt={""} />
                </div>
                <div className={"warning-box"}>
                  <p>
                    Warning: To protect your account from being suspended by
                    eBay, Please:
                  </p>
                  <ul>
                    <li>Read and follow the documentation carefully</li>
                    <li>
                      Only use the Auth eBay Account URL from the PC or VPS of
                      the eBay account that you want to connect
                    </li>
                  </ul>
                </div>
                <Checkbox
                  label="I have read and agreed with the documentation."
                  checked={checked}
                  onChange={(checked) => {
                    this.setState({ checked });
                  }}
                />
                {checked && <CopyBox text={ebayAuthUrl} />}
              </TextContainer>
            </Modal.Section>
          </ModalContainer>
        </Modal>
      </Container>
    );
  }
}

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }

  .table-wrapper {
    overflow-x: auto;
    .Polaris-DataTable__Cell--header {
      white-space: nowrap;
    }

    .Polaris-DataTable {
      .Polaris-DataTable__ScrollContainer {
        overflow-x: initial;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .Polaris-Page-Header {
    padding-top: 0;
  }
  .avatar-container {
    display: flex;
    width: 40rem;

    .avatar-right {
      margin-left: 10px;
    }
  }

  .status-container {
    display: flex;
    align-items: center;
    width: 20rem;

    button {
      margin-right: 5px;
    }
  }
  .actions {
    width: 26rem;

    button {
      margin-right: 8px;
    }
  }
  .group-wrap {
    white-space: normal;
    width: 20rem;
  }

  .api-key-wrap {
    width: 30rem;
  }
`;

const ModalContainer = styled.div`
  .image {
    display: flex;
    justify-content: center;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  .warning-box {
    background: rgb(233, 162, 173);
    padding: 10px;
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

Ebay.propTypes = {};

export default Ebay;
