import React, { useState, useCallback, useEffect, useRef } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import styled from "styled-components";

import { getUnique } from "../../../helper";
import { QuickAddTagPolaris } from "./QuickAddTagPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export const TAGS = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      total
      nodes {
        id
        name
      }
    }
  }
`;

const TitleContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const Container = styled.div``;

export const TagsSelectPolaris = ({
  onChange,
  allowMultiple,
  label,
  value,
  getCoupleValue,
  haveQuickAdd,
  labelHidden,
  required,
  errorMessage,
  limit,
  resource,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [filter, setFilter] = useState({
    limit: inputValue ? limit || 20 : 20,
    offset: 0,
    search: null,
  });
  const typingTimeoutRef = useRef(null);

  const { data, loading } = useQuery(TAGS, {
    variables: {
      filter,
    },
    fetchPolicy: "network-only",
  });
  const onChangeRef = useRef(null);

  useEffect(() => {
    let nodes = data?.tags?.nodes || [];
    let resourceArr = resource
      ? Array.isArray(resource)
        ? resource
        : [resource]
      : [];
    nodes = resourceArr?.length > 0 ? [...resourceArr, ...nodes] : nodes;

    nodes = getUnique(nodes, "id");

    let newData =
      nodes?.length > 0
        ? nodes.map((t) => {
            return {
              value: t.id,
              label: <TitleContainer>{t.name}</TitleContainer>,
            };
          })
        : [];
    setOptions(newData);
  }, [data, resource]);

  useEffect(() => {
    let result = showAdd(inputValue, options);
    setShowQuickAdd(result);
  }, [inputValue, options]);

  const showAdd = (inputValue, options) => {
    if (!inputValue) return false;
    let matched = options.find((option) => {
      let newValue = _.get(option.label, "props.children");
      return newValue && newValue.toLowerCase() === inputValue.toLowerCase();
    });
    if (!options) return true;
    if (!matched) return true;
    return false;
  };

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      typingTimeoutRef.current = setTimeout(() => {
        setFilter((prevState) => {
          return {
            ...prevState,
            search: value,
            limit: value ? limit : 20,
          };
        });
      }, 500);
    },
    [limit],
  );

  const resourceFirst = React.useRef(false);
  useEffect(() => {
    let newValue = [];
    let newData = [];

    const resourceArr = resource
      ? Array.isArray(resource)
        ? resource
        : [resource]
      : [];
    let mergeVal = (value || []);
    if (!resourceFirst.current) {
      mergeVal.push(...resourceArr);
    }
    if (mergeVal && _.isArray(mergeVal)) {
      newData = mergeVal.map((v) => {
        if (typeof v === "object") {
          newValue.push(v.id);
          return {
            label: v.name,
            value: v.id,
          };
        } else {
          newValue.push(v);
          return null;
        }
      });
    }
    newValue = allowMultiple ? newValue : [mergeVal];
    newData = newData.filter(Boolean);

    if (newData.length > 0) {
      setOptions((prevState) => {
        let result = getUnique([...prevState, ...newData], "value");
        return result;
      });
      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, ...mergeVal], "id");
        return result;
      });
    }

    let tagIds = getIds(resource);
    setSelectedOptions((prev) => {
      const mergeVal = resourceFirst.current ? prev : tagIds;
      const newValue = prev?.length > 0 ? [...prev, ...mergeVal] : mergeVal;
      const values = Array.from(new Set(newValue).values());
      resourceFirst.current = true;

      return values;
    });
  }, [value, allowMultiple, resource]);

  useEffect(
    () => () => {
      setSelectedOptions([]);
      setDeselectedOptions([]);
    },
    [],
  );

  const handleSelectChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      let newValue = _.get(_.head(selectedValue), "props.children");

      let newData = _.get(data, "tags.nodes", []);
      let couple =
        newData && newData.length > 0
          ? newData.filter((d) => selected.includes(d.id))
          : [];

      let newResult = [];
      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, ...couple], "id");
        result = result.filter((i) => selected.includes(i.id));
        newResult = result;
        return result;
      });

      setSelectedOptions(selected);
      if (!allowMultiple) {
        setInputValue(newValue);
      }

      onChangeRef.current && clearTimeout(onChangeRef.current);
      onChangeRef.current = setTimeout(() => {
        if (onChange) {
          if (allowMultiple) {
            onChange(getCoupleValue ? newResult : selected);
            return;
          } else {
            onChange(_.head(selected));
          }
        }
      }, 100);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptions.filter((item) => item.id !== value);
      setDeselectedOptions(newDeselected);
      if (onChange && allowMultiple) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptions],
  );

  const handleAddCompleted = useCallback((data) => {
    if (data) {
      let item = {
        value: data.id,
        label: <TitleContainer>{data.name}</TitleContainer>,
      };
      let newSelected = [];
      let currentOptions = [];
      setOptions((prevState) => {
        currentOptions = [item, ...prevState];
        return currentOptions;
      });
      setSelectedOptions((prevState) => {
        newSelected = [item.value, ...prevState];
        if (onChange && allowMultiple) {
          onChange(newSelected);
        }
        return newSelected;
      });

      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, data], "id");
        result = result.filter((i) => newSelected.includes(i.id));
        return result;
      });

      setShowQuickAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textField = (
    <Autocomplete.TextField
      label={label ? label : "Tags"}
      labelHidden={true}
      placeholder="Search for tag"
      value={inputValue}
      onChange={handleInputChange}
      error={errorMessage}
    />
  );

  const contentBeforeMarkup = (
    <QuickAddTagPolaris
      inputValue={inputValue}
      onCompleted={handleAddCompleted}
    />
  );

  return (
    <Container>
      {!labelHidden ? (
        <ComponentLabelPolaris
          required={required}
          label={label ? label : "Tags"}
        />
      ) : null}
      <Autocomplete.ComboBox
        options={options}
        selected={selectedOptions}
        onSelect={handleSelectChange}
        textField={textField}
        // willLoadMoreResults
        allowMultiple={allowMultiple}
        contentBefore={
          haveQuickAdd && showQuickAdd ? contentBeforeMarkup : null
        }
        loading={loading}
        emptyState={<span>No items found!</span>}
      />
      {allowMultiple && deselectedOptions && deselectedOptions.length > 0 ? (
        <TagContainer>
          {deselectedOptions.map((item) => (
            <Tag
              children={item.name}
              onRemove={() => handleRemove(item.id)}
              key={item.id}
            />
          ))}
        </TagContainer>
      ) : null}
    </Container>
  );
};

const getIds = (data) => {
  let colIds = data ? (Array.isArray(data) ? data : [data]) : [];
  colIds = colIds
    .map((item) => {
      if (typeof item === "object") {
        return item.id;
      }

      return item;
    })
    .filter(Boolean);

  return colIds;
};
