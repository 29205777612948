import React from "react";
import { Page, Loading, Card } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { FulfillmentPolaris } from "../../components/fulfillment/FulfillmentPolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../components/shared/EmptyStatePolaris";

export const LOAD_APPS = gql`
  query apps {
    apps {
      id
      name
      description
      installUrl
      uninstallUrl
      installed
      settings
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const FulfillmentPagePolaris = () => {
  const { data, loading, error, refetch } = useQuery(LOAD_APPS, {
    fetchPolicy: "no-cache",
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Page title="All Fulfillments" fullWidth>
        {loading ? (
          <Card>
            <SkeletonPagePolaris />
          </Card>
        ) : data && data.apps && data.apps.length > 0 ? (
          <FulfillmentPolaris
            data={data}
            error={error}
            loading={loading}
            refetch={refetch}
          />
        ) : (
          <Card>
            <EmptyStatePolaris />
          </Card>
        )}
      </Page>
    </Container>
  );
};
