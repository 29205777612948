import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";

import { ComponentLabelPolaris } from "./ComponentLabelPolaris";

export const AutoCompletePolaris = (props) => {
  const {
    options: propsOptions,
    value,
    onChange,
    label,
    placeholder,
    required,
    labelHidden,
    error,
    isFilter,
    clearButton = false,
    handleClear = () => {},
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  useEffect(() => {
    let newOptions =
      propsOptions && propsOptions.length > 0 ? propsOptions : [];
    if (newOptions.length) {
      setOptions(newOptions);
      setDeselectedOptions(newOptions);
      setSelectedOptions(value ? [value] : []);
      let currentOption = newOptions.find((o) => o.value === value);
      setInputValue(currentOption ? currentOption.label : null);
    } else {
      setOptions([]);
      setInputValue(null);
    }
  }, [propsOptions, value]);

  useEffect(() => {
    setSelectedOptions(value ? [value] : []);
  }, [value]);

  const handleInput = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => {
        if (!option.disabled) {
          if ("string" === typeof option.label) {
            return option.label.match(filterRegex);
          } else {
            let label = _.get(option, "label.props.children", null);
            return label.match(filterRegex);
          }
        }
        return false;
      });

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
      if (onChange) {
        if (isFilter) {
          onChange({ value: _.head(selected), label: _.head(selectedValue) });
        } else {
          onChange(_.head(selected));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      label="option"
      labelHidden
      value={inputValue}
      onChange={handleInput}
      placeholder={placeholder ? placeholder : "Filter item"}
      error={error}
      clearButton={clearButton}
      onClearButtonClick={handleClear}
    />
  );

  return (
    <>
      {!labelHidden && label ? (
        <ComponentLabelPolaris label={label} required={required} />
      ) : null}
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelection}
        textField={textField}
        emptyState={<span>No items found!</span>}
      />
    </>
  );
};
