import { Mutation } from "@apollo/react-components";
import {
  Button,
  DropZone,
  Icon,
  InlineError,
  Spinner,
  Stack,
} from "@shopify/polaris";
import { DeleteMinor, TickMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export const REQUEST_MUTATION = gql`
  mutation createUploadUrl($input: RequestFileUpload!) {
    createUploadUrl(input: $input) {
      key
      url
    }
  }
`;

export const CREATE_FILE_MUTATION = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      id
      name
      key
      mimeType
      size
      url
      thumbnailUrl
      createdAt
    }
  }
`;

const Container = styled.div`
  min-height: 60rem;
  .uploaded-wrap {
    display: block;
    margin-top: 2rem;
  }
  .image-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .image-title-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }
`;

export const UploadFilePolaris = (props) => {
  const {
    accept,
    multiple,
    folder,
    isPrintFile,
    onChange,
    type,
    handleChangeOkBtnDisabled,
    maxSize,
  } = props;
  const [files, setFiles] = useState([]);
  const [, setRejectedFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState({});
  const [errMaxSize, setErrMaxSize] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(fileList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleDrop = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles, createUploadUrl, client) => {
      setFiles((files) => [...files, ...acceptedFiles]);
      setRejectedFiles(rejectedFiles);

      let newFiles = acceptedFiles;
      for (let i = 0; i < newFiles.length; i++) {
        customRequest(newFiles[i], i, createUploadUrl, client);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const customRequest = useCallback((file, index, createUploadUrl, client) => {
    setLoading((prevState) => ({ ...prevState, [index]: true }));
    if (handleChangeOkBtnDisabled) {
      handleChangeOkBtnDisabled(true);
    }
    checkMimeType(file, async (mime) => {
      let fileType = file.type;
      let fileName = file.name;
      if (mime) {
        fileType = mime;
        switch (mime) {
          case "image/webp":
            fileName = fileName.replace(/\.[^/.]+$/, ".webp");
            break;
          case "image/gif":
            fileName = fileName.replace(/\.[^/.]+$/, ".gif");
            break;
          case "image/jpeg":
            fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
            break;
          case "image/png":
            fileName = fileName.replace(/\.[^/.]+$/, ".png");
            break;
          default:
            break;
        }
      }
      try {
        const res = await createUploadUrl({
          variables: {
            input: {
              name: fileName,
              mimeType: fileType,
              size: file.size,
              folder: folder ? folder : null,
            },
          },
        });
        const { key, url } = res.data.createUploadUrl;
        await axios.put(url, file, {
          headers: { "Content-Type": fileType },
          timeout: 1000 * 60 * 30,
        });
        const fileResponse = await client.mutate({
          mutation: CREATE_FILE_MUTATION,
          variables: {
            input: {
              name: fileName,
              key: key,
              size: file.size,
              mimeType: fileType,
              isPrintFile: isPrintFile,
            },
          },
        });
        let respon = [];
        if (fileResponse) {
          if (handleChangeOkBtnDisabled) {
            handleChangeOkBtnDisabled(false);
          }
          respon.push(fileResponse.data.createFile);
          setLoading((prevState) => ({ ...prevState, [index]: false }));
        }
        setFileList((prevState) => [...prevState, ...respon]);
      } catch (e) {}
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback((index) => {
    setFiles((prevState) => prevState.filter((_item, idx) => index !== idx));
    setFileList((prevState) => prevState.filter((_item, idx) => index !== idx));
  }, []);

  const checkMimeType = (file, cb) => {
    const mimes = [
      {
        mime: "image/png",
        pattern: [0x89, 0x50, 0x4e, 0x47],
      },
      {
        mime: "image/jpeg",
        pattern: [0xff, 0xd8, 0xff],
      },
      {
        mime: "image/gif",
        pattern: [0x47, 0x49, 0x46, 0x38],
      },
      {
        mime: "image/webp",
        pattern: [
          0x52,
          0x49,
          0x46,
          0x46,
          undefined,
          undefined,
          undefined,
          undefined,
          0x57,
          0x45,
          0x42,
          0x50,
          0x56,
          0x50,
        ],
      },
    ];
    const isMime = (bytes, mime) => {
      return mime.pattern.every((p, i) => !p || bytes[i] === p);
    };
    const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
    const blob = file.slice(0, numBytesNeeded);
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (!e || !reader.result) return cb(null);
      const bytes = new Uint8Array(reader.result);
      const mime = mimes.find((m) => isMime(bytes, m));
      return cb(mime ? mime.mime : null);
    };
    reader.readAsArrayBuffer(blob);
  };

  const fileUpload = <DropZone.FileUpload />;
  const uploadedFiles =
    files.length > 0 ? (
      <Stack vertical>
        {files.map((file, index) => (
          <div className="image-wrap" key={`image-${index}`}>
            <div className="image-title-wrap">
              {loading[index] ? (
                <Spinner size="small" />
              ) : (
                <Icon source={TickMinor} />
              )}
              <span>{file.name}</span>
            </div>
            <Button
              plain
              icon={DeleteMinor}
              onClick={() => handleDelete(index)}
            />
          </div>
        ))}
      </Stack>
    ) : null;

  const handleCustomValidator = (file) => {
    const isFile = file instanceof File;
    if (!isFile || maxSize == null || typeof maxSize !== "number") {
      setErrMaxSize(false);
      return true;
    }

    if (!file.size || file.size < 1 || file.size > maxSize) {
      setErrMaxSize(true);
      return false;
    }

    setErrMaxSize(false);
    return true;
  };

  return (
    <Container>
      <Mutation mutation={REQUEST_MUTATION}>
        {(createUploadUrl, { client }) => (
          <DropZone
            accept={accept}
            type={type}
            allowMultiple={multiple}
            customValidator={handleCustomValidator}
            onDrop={(droppedFiles, acceptedFiles, rejectedFiles) =>
              handleDrop(
                droppedFiles,
                acceptedFiles,
                rejectedFiles,
                createUploadUrl,
                client,
              )
            }
            labelHidden="Evidence"
          >
            {fileUpload}
          </DropZone>
        )}
      </Mutation>
      {errMaxSize ? (
        <span style={{ display: "block", marginTop: 10 }}>
          <InlineError message="File is too large (max 50 MB)" />
        </span>
      ) : null}
      <div className="uploaded-wrap">{uploadedFiles}</div>
    </Container>
  );
};
