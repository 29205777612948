import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Select } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CUSTOM_FULFILLMENT } from "../../constants";
import { AppContext } from "../../context";
import { checkRole } from "../../helper";
import ProductBaseFulfillmentId from "./ProductBaseFulfillmentId";
import { checkFulfillment } from "./utils";
class ProductBaseFulfillment extends Component {
  state = {
    fulfillment: null,
  };
  onChange = (k, v, data, fulfillmentInfo) => {
    let { value } = this.props;
    if (this.props.onChange) {
      if (k === "fulfillmentId" && !v) {
        value = null;
        this.props.onChange({ ...value });
        return;
      }
      if (!value) {
        value = {};
      }
      value[k] = v;
      value["data"] = data;
      value["presetId"] = value["presetId"] ? value["presetId"] : "";
      value["fulfillmentInfo"] = fulfillmentInfo;
      this.props.onChange({ ...value });
    }
  };

  onChangePreset = (v) => {
    const { value, onChange } = this.props;
    value["presetId"] = v;
    onChange({ ...value });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(nextProps, this.props) ||
      !_.isEqual(nextState, this.state)
    ) {
      return true;
    }
    return false;
  }

  render() {
    let {
      fulfillments,
      value,
      isPrintify,
      loadingFFProduct,
      getOriginId,
    } = this.props;
    const { currentUser } = this.context;
    const { isSeller } = checkRole(currentUser);
    let fulfillment;

    if (value) {
      fulfillment = fulfillments.find((f) => f.id === value.fulfillmentId);
    }
    if (isSeller && fulfillments.length > 0) {
      const i = fulfillments.findIndex(
        ({ slug }) => slug === CUSTOM_FULFILLMENT,
      );
      [fulfillments[i], fulfillments[0]] = [fulfillments[0], fulfillments[i]];
    }
    const { isCustom, isBurgerPrints } = checkFulfillment(fulfillments, fulfillment?.id);
    return (
      <div>
        <Form.Item>
          <Select
            onSelect={(v, opt) => {
              this.onChange("fulfillmentId", v, null, opt);
            }}
            value={value && value.fulfillmentId ? value.fulfillmentId : null}
            disabled={!!value && isPrintify}
          >
            {!isSeller && (
              <Select.Option value={null}>MerchBridge</Select.Option>
            )}
            {fulfillments.map((f, index) => (
              <Select.Option key={index} value={f.id} data={f}>
                {f.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {value && fulfillment && !isCustom && (
          <Form.Item label={"Fulfillment Product ID/Name"} labelCol={{ span: 24 }}>
            <ProductBaseFulfillmentId
              loadingFFProduct={loadingFFProduct}
              productId={value && value.productId}
              value={{ ...fulfillment, presetId: value.presetId }}
              disabled={!!value && isPrintify}
              getOriginId={getOriginId}
              isBurgerPrints={isBurgerPrints}
              onChange={(v, presetId) => {
                this.onChange("productId", v.id, v, fulfillment);
                this.setState({ fulfillment: { ...v } });
                this.onChangePreset(presetId);
                value.presetId = presetId;
              }}
            />
          </Form.Item>
        )}
      </div>
    );
  }
}

ProductBaseFulfillment.contextType = AppContext;

ProductBaseFulfillment.propTypes = {
  fulfillments: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default ProductBaseFulfillment;
