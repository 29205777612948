import capitalize from "lodash/capitalize";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";
import {
  FULFILLMENTS_SLUG,
  MERCHIZE_SLUG,
  PRINTIFY_SLUG,
  PRINTWAY_SLUG,
  TEEZILY_DESIGN_SPECIFICATION,
} from "../../../constants";
import {
  GET_CUSTOMCAT_PRODUCT_PROVIDER_VARIANTS,
  GET_CUSTOMCAT_VARIANTS_BY_FF_PRODUCT_ID,
  GET_DREAMSHIP_PRODUCT_INFO,
  GET_DREAMSHIP_PRODUCT_PROVIDER_VARIANTS,
  GET_GEARMENT_PRODUCTS_INFO,
  GET_MERCHIZE_PRODUCT_INFO,
  GET_MERCHIZE_PRODUCT_INFO_DB,
  GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
  GET_PRINTWAY_PRODUCT_DETAIL,
  GET_PRINTWAY_SHIPPING_METHODS,
  GET_TEEZILY_PRODUCT_DETAIL,
  GET_SHIPPING_OPTION_BY_BASE,
  GET_BURGERPRINTS_PRODUCT,
} from "../../../graphql/queries";
import {
  arrInvalid,
  NumberToFixed,
  numberWithCommas,
  objectInvalid,
} from "../../../helper";
import { SCALABLEPRESS_DESIGN_TYPE } from "../../../variable";
import { startCase } from "lodash";

const CC = "customcat";
const GM = "gearment";
const DS = "dreamship";
const SP = "scalablepress";
const CF = "custom-fulfillment";
const TZL = "teezily";

const initType = {
  isCustomcat: false,
  isDreamship: false,
  isGearment: false,
  isScalablepress: false,
  isPrintify: false,
  isCustom: false,
  isPrintway: false,
  isMerchize: false,
  isTeezily: false,
};

export function checkFulfillment(fulfillments, ffID) {
  if (!fulfillments || fulfillments.length === 0 || !ffID) return initType;

  const ffIDs = fulfillments.map(({ id }) => id);
  if (!ffIDs.includes(ffID)) return initType;

  function fn(slug) {
    return fulfillments.some((item) => item.id === ffID && item.slug === slug);
  }
  return {
    isCustomcat: fn(CC),
    isDreamship: fn(DS),
    isGearment: fn(GM),
    isScalablepress: fn(SP),
    isPrintify: fn(PRINTIFY_SLUG),
    isCustom: fn(CF),
    isPrintway: fn(PRINTWAY_SLUG),
    isMerchize: fn(MERCHIZE_SLUG),
    isTeezily: fn(TZL),
    isBurgerPrints: fn(FULFILLMENTS_SLUG.BurgerPrints),
  };
}

// Gen design postion for Dreamship
export function genDPDreamship(fulfillment) {
  const printAreas = get(fulfillment, "data.printAreas", []);
  if (!printAreas || printAreas.length === 0) return [];
  const dps = [];
  for (let pa of printAreas) {
    let { key, h, w, dpi } = pa;
    w = numberWithCommas(w);
    h = numberWithCommas(h);

    let description = `${w}x${h}px|PNG|${dpi}DPI`;
    dps.push({
      description: description,
      image: null,
      name: key,
    });
  }

  return dps;
}

// Gen design position for Scalablepress
export function genDPScalablepress(designType) {
  const position = ["front", "back", "left", "right"];
  const sPCustomize = {
    width: 0,
    height: 0,
    horizontal: "C",
    top: 0,
    bottom: 0,
    // colors: "",

    description: null,
    image: null,
  };

  const dps = [];
  if (["screenprint", "dtg"].includes(designType)) {
    for (let p of position) {
      dps.push({
        name: p,
        ...sPCustomize,
      });
    }
  } else {
    dps.push({
      name: "front",
      ...sPCustomize,
    });
  }

  return dps;
}

export const DESIGN_POSITION_DEFAULT = {
  description: "5000x5000px|PNG|300DPI",
  image: null,
  name: "Default",
};

export function getDesignType(type) {
  let res;
  switch (type) {
    case "Garment":
    case "ThirdParty":
      res = "dtg";
      break;
    case SCALABLEPRESS_DESIGN_TYPE.mug: {
      res = "mug";
      break;
    }
    case SCALABLEPRESS_DESIGN_TYPE.mug: {
      res = "case";
      break;
    }
    case SCALABLEPRESS_DESIGN_TYPE.poster: {
      res = "poster";
      break;
    }

    default: {
      break;
    }
  }

  return res;
}

const PRODUCT_DETAIL_PRINTWAY_KEYS = {
  size: "Size",
  material: "Material",
  style: "Style",
  color: "Color",
};

const INSTOCK = "in_stock";

export async function getProductDetailPrintway(productCode) {
  const { __apolloClient__: client } = window;
  if (!productCode || !client) return {};
  try {
    const { data } = await client.query({
      query: GET_PRINTWAY_PRODUCT_DETAIL,
      variables: {
        productCode,
      },
    });

    // const product = get(data, "getPrintwayProductDetail.product", {});
    const product = get(data, "getPrintwayProductDetail.data", {});

    return product;
  } catch (err) {}
}

export async function getPrintwayShippingMethods(variantSKUs) {
  // item_sku
  if (!variantSKUs || !Array.isArray(variantSKUs) || variantSKUs.length === 0)
    return;

  const { __apolloClient__: client } = window;
  try {
    const { data } = await client.query({
      query: GET_PRINTWAY_SHIPPING_METHODS,
      variables: {
        variantSKUs,
      },
    });
    return data?.getPrintwayShippingMethods;
  } catch (err) {}
}

export async function getPrintifyShippingMethod(productBaseID) {
  if (!productBaseID) return;
  const { __apolloClient__: client } = window;
  try {
    const { data } = await client.query({
      query: GET_SHIPPING_OPTION_BY_BASE,
      variables: {
        productBaseID,
      },
    });

    const options = [];
    for (const item of data?.getShippingOptionByBase || []) {
      const option = { value: item.id + "", label: startCase(item.name) }; /// shipping_option is string type in DB
      options.push(option);
    }

    return options;
  } catch (_) {}
}

export async function fetchProductDetailPrintway(productCode, isEdit) {
  const { __apolloClient__: client } = window;
  if (!productCode || !client) return;

  try {
    const product = await getProductDetailPrintway(productCode);
    const { product_name: name, description, variants: data_sku } = product;

    let variants = [];
    const mergeAttributes = new Map();
    const dataFiltered = (data_sku || []).filter(
      ({ availability }) => availability === INSTOCK,
    );

    if (Array.isArray(dataFiltered) && dataFiltered.length > 0) {
      variants = dataFiltered.map(
        (
          {
            item_sku: fulfillmentSku,
            variant_id: fulfillmentProductId,
            locations,
            attributes,
          },
          index,
        ) => {
          let regularPrice = 0;
          const firstLocation = locations?.length > 0 ? locations[0] : null;
          let tier_cost = 0;
          if (firstLocation) {
            ({ tier_cost } = firstLocation);

            if (!isEdit) {
              if (regularPrice === 0 && tier_cost) {
                regularPrice = NumberToFixed(tier_cost / 0.35, 2);
              }
            }
          }

          const newAttrs = [];
          for (let attr of attributes) {
            if (!attr || typeof attr !== "object") continue;

            const { name: key, value } = attr;
            if (mergeAttributes.has(key)) {
              const cur = mergeAttributes.get(key);
              mergeAttributes.set(key, cur.add(value));
            } else {
              mergeAttributes.set(key, new Set([value]));
            }

            newAttrs.push({
              slug: kebabCase(key),
              name: key,
              option: value,
            });
          }

          return {
            regularPrice: parseFloat(regularPrice),
            salePrice: 0,
            sellerPrice: tier_cost,
            sorting: index,
            attributes: newAttrs,
            fulfillmentProductId,
            fulfillmentSku,
          };
        },
      );
    }

    const attributes = Array.from(mergeAttributes.entries()).map(
      ([key, value]) => {
        const name = capitalize(key);
        const options = Array.from(value.values());

        return {
          name,
          slug: kebabCase(key),
          options,
        };
      },
    );

    return {
      name,
      description,
      variants,
      attributes,
      dataSku: dataFiltered,
    };
  } catch (err) {}

  return null;
}

export function genDesignPositionForPrintway(data, variants) {
  if (!data || data.length === 0) return [];

  const pa = data.reduce((acc, { variant_id: item_sku, locations }) => {
    if (!locations || locations.length === 0) return acc;
    const { print_areas } = locations[0];
    if (!print_areas || print_areas.length === 0) return acc;

    acc.push(...print_areas.map((o) => ({ ...o, id: item_sku })));
    return acc;
  }, []);

  const merge = new Map();
  for (let { height, width, area: key, id } of pa) {
    const m = [key, width, height].filter(Boolean).join("-");
    if (merge.has(m)) {
      merge.set(m, [...merge.get(m), id]);
    } else {
      merge.set(m, [id]);
    }
  }

  const designPosition = Array.from(merge.entries()).map(([key, value]) => {
    const [pos, w, h] = key.split(/\-/);
    const description = `${w}x${h}px|PNG|300DPI`;
    const productBaseVariants = (value || [])
      .map((id) => {
        const { attributes } =
          (variants || []).find(
            ({ fulfillmentProductId }) => fulfillmentProductId === id,
          ) || {};

        return {
          attributes,
          id: undefined,
        };
      })
      .filter(({ attributes }) => attributes?.length > 0);

    return {
      name: pos,
      image: null,
      description,
      productBaseVariants,
    };
  });

  return designPosition;
}

// ===================== CUSTOMCAT =====================
const des_key = "product_description_bullet";
const des_pt = new RegExp(`^${des_key}`);
export function formatVariantsForCC(data) {
  if (!data || typeof data !== "object") return {};
  const description = mergeDescriptionCC(data);
  const title = get(data, "product_name");
  const restInfo = getVariantsCC(data);

  return {
    description,
    title,
    ...restInfo,
  };
}

function mergeDescriptionCC(data) {
  // CC = Customcat
  if (!data || typeof data !== "object") return "";

  const desMatch = {};
  for (let k in data) {
    if (des_pt.test(k)) {
      const newKey = k.slice(des_key.length);
      desMatch[newKey] = data[k];
    }
  }

  const keySort = Object.keys(desMatch).sort(
    (a, b) => parseInt(a) - parseInt(b),
  );

  const res = keySort.reduce((acc, key) => {
    const val = desMatch[key];
    const mk = val ? "<p>" + val + "</p>" : "";
    acc += mk ? mk + "\n" : "";
    return acc;
  }, "");

  return res;
}

function getVariantsCC(data) {
  if (!data || typeof data !== "object") return {};

  let productColors = get(data, "product_colors");
  if (!productColors || productColors.length === 0) return {};

  productColors = productColors.filter(({ skus }) => {
    if (!skus || skus.length === 0) return false;

    return skus.some(({ in_stock }) => in_stock === 1); // != 1 => `out_of_stock`
  });

  if (!productColors || productColors.length === 0) return {};

  let variants = [];
  const mergeAttributes = new Map();
  const mergeDS = new Map(); // DesignPosition
  const colorCodes = [];
  for (let val of productColors) {
    if (!val || typeof val !== "object") continue;

    const { color, color_hex, skus } = val;
    if (color_hex) {
      colorCodes.push({
        name: color,
        value: color_hex.startsWith("#") ? color_hex : "#" + color_hex,
        patternImage: [],
      });
    }

    if (!skus || skus.length === 0) continue;
    for (let sku of skus) {
      if (!sku || typeof sku !== "object") continue;

      const { catalog_sku_id, cost, mrsp, size, pallet, in_stock } = sku;
      if (in_stock !== 1) continue;

      const attributes = [{ name: "Color", slug: "color", option: color }];
      attributes.push({
        name: "Size",
        slug: "size",
        option: size,
      });

      variants.push({
        attributes,
        regularPrice: parseFloat(mrsp),
        sellerPrice: parseFloat(cost),
        sorting: variants.length,
        salePrice: 0,
        fulfillmentProductId: catalog_sku_id,
      });

      getAttributesFF({ color: color, size: size }, mergeAttributes);

      getDesignPositionCC(pallet, mergeDS, attributes);
    }
  }

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = upperFirst(key);
      const options = Array.from(value.values());

      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  const varLen = (variants || []).length;
  const designPositions = Array.from(mergeDS.entries()).map(([key, value]) => {
    const [name, w, h, suff] = key.split(/\-/);
    const description = `${w}x${h}${suff}|PNG|300DPI`;

    const val = Array.from(value.values());
    const len = (val || []).length;
    return {
      name,
      image: null,
      description,
      productBaseVariants: varLen !== len ? val : [],
    };
  });

  return {
    variants,
    attributes,
    designPositions,
    colorCodes,
  };
}

function getAttributesFF(obj, mergeVal) {
  if (!obj || typeof obj !== "object") return mergeVal;

  for (let o in obj) {
    const val = obj[o];
    if (!val) continue;

    const cur = mergeVal.get(o);
    if (cur) {
      mergeVal.set(o, cur.add(val));
      continue;
    }

    mergeVal.set(o, new Set([val]));
  }
}

function getDesignPositionCC(arr, mergeVal, attributes) {
  if (!arr || arr.length === 0) return mergeVal;
  for (let { pallet_height, pallet_width, product_view } of arr) {
    const str = pallet_width || pallet_height;
    const pt = /[a-z]+/i;
    const no_pt = /[+-]?([0-9]*[.])?[0-9]+/;

    const [suffix] = str.match(pt) || [];
    let newW = pallet_width;
    if (newW) {
      [newW] = newW.match(no_pt) || [];
    }

    let newH = pallet_height;
    if (newH) {
      [newH] = newH.match(no_pt) || [];
    }

    const key = [product_view, newW, newH, suffix].filter(Boolean).join("-");

    const item = { id: undefined, attributes };
    const cur = mergeVal.get(key);
    if (cur) {
      mergeVal.set(key, cur.add(item));
    } else {
      mergeVal.set(key, new Set([item]));
    }
  }

  return mergeVal;
}

export async function fetchVariantsForCustomcat(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return {};

  try {
    const { data } = await client.query({
      query: GET_CUSTOMCAT_VARIANTS_BY_FF_PRODUCT_ID,
      variables: {
        id,
      },
    });

    const info = get(data, "getCustomcatVariantsByFFProductId");
    return formatVariantsForCC(info);
  } catch (err) {
    console.error("error", err);
  }
  return {};
}

// ===================== GEARMENT =====================
function formatProductsForGM(data) {
  if (!data || typeof data !== "object" || data.status !== "success") return {};

  const { result } = data;
  if (!result || result.length === 0) return {};

  const res = {};
  for (let item of result) {
    if (!item || typeof item !== "object") continue;
    const { product_name: title, variants: vars, product_id } = item;

    if (!vars || vars.length === 0) continue;
    const variants = [];
    const mergeAttributes = new Map();
    const colorCodesObj = {};

    for (let i = 0; i < vars.length; i++) {
      const v = vars[i];
      const {
        variant_id: fulfillmentProductId,
        size,
        color,
        price,
        hex_color_code,
      } = v || {};
      const attributes = [
        {
          name: "Color",
          slug: "color",
          option: color,
        },
        {
          name: "Size",
          slug: "size",
          option: size,
        },
      ];

      if (colorCodesObj[color] == null && hex_color_code) {
        colorCodesObj[color] = {
          name: color,
          value: hex_color_code.startsWith("#")
            ? hex_color_code
            : "#" + hex_color_code,
          patternImage: [],
        };
      }

      variants.push({
        attributes,
        regularPrice: Math.max(price * 3, 0).toFixed(2),
        sellerPrice: Math.max(price, 0),
        salePrice: 0,
        sorting: i,
        fulfillmentProductId,
      });

      getAttributesFF({ color, size }, mergeAttributes);
    }

    const colorCodes = Object.values(colorCodesObj);
    const attributes = Array.from(mergeAttributes.entries()).map(
      ([key, value]) => {
        const name = upperFirst(key);
        const options = Array.from(value.values());

        return {
          name,
          slug: kebabCase(key),
          options,
        };
      },
    );

    res[product_id] = {
      title,
      variants,
      attributes,
      colorCodes,
    };
  }

  return res;
}

export async function fetchProductsForGearment() {
  const { __apolloClient__: client } = window;
  if (!client) return {};

  try {
    const { data } = await client.query({
      query: GET_GEARMENT_PRODUCTS_INFO,
    });

    const info = get(data, "getGearmentProductsInfo", {});
    return formatProductsForGM(info);
  } catch (err) {
    console.error("error", err);
  }
  return {};
}

// ===================== DREAMSHIP =====================
function formatProductsForDS(data) {
  if (!data || typeof data !== "object") return {};

  const { description, name: title, item_variants, print_areas } = data;

  const variants = [];
  const colorCodesObj = {};
  const mergeAttributes = new Map();
  const mergeDS = new Map();
  if (Array.isArray(item_variants) && item_variants.length > 0) {
    const variantsFiltered = item_variants.filter(
      ({ availability }) => availability === "in_stock",
    );

    for (let i = 0; i < variantsFiltered.length; i++) {
      const variant = variantsFiltered[i];
      if (!variant || typeof variant !== "object") continue;
      const {
        id: fulfillmentProductId,
        cost: sellerPrice,
        color,
        size,
      } = variant;
      const { name: colorName, primary_hex } = color || {};
      const { name: sizeName } = size || {};

      const attributes = [
        {
          name: "Color",
          slug: "color",
          option: colorName,
        },
        {
          name: "Size",
          slug: "size",
          option: sizeName,
        },
      ];

      if (colorCodesObj[colorName] == null && primary_hex) {
        colorCodesObj[colorName] = {
          name: colorName,
          value: primary_hex.startsWith("#") ? primary_hex : "#" + primary_hex,
          patternImage: [],
        };
      }

      variants.push({
        attributes,
        regularPrice: Math.max(sellerPrice * 3, 0).toFixed(2),
        sellerPrice: Math.max(sellerPrice, 0),
        salePrice: 0,
        sorting: i,
        fulfillmentProductId,
      });
      getAttributesFF({ color: colorName, size: sizeName }, mergeAttributes);
    }
  }

  if (Array.isArray(print_areas) && print_areas.length > 0) {
    getDesignPositionDS(print_areas, mergeDS);
  }

  const colorCodes = Object.values(colorCodesObj);
  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = upperFirst(key);
      const options = Array.from(value.values());

      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  const designPositions = Array.from(mergeDS.entries()).map(([key]) => {
    const [name, w, h, dpi] = key.split(/\-/);
    const description = `${w}x${h}px|PNG|${dpi}DPI`;

    return {
      name,
      image: null,
      description,
      productBaseVariants: [],
    };
  });

  return {
    title,
    description,
    variants,
    attributes,
    colorCodes,
    designPositions,
  };
}

function getDesignPositionDS(printAreas, mergeVal) {
  if (!printAreas || printAreas.length === 0) return mergeVal;

  for (let p of printAreas) {
    if (!p || typeof p !== "object") continue;
    const { dpi, h, key, w } = p;

    const newKey = [key, w, h, dpi].filter(Boolean).join("-");
    const item = { id: undefined };
    const cur = mergeVal.get(newKey);
    if (cur) {
      mergeVal.set(newKey, cur.add(item));
    } else {
      mergeVal.set(newKey, new Set([item]));
    }
  }

  return mergeVal;
}

export async function fetchProductForDreamship(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return {};

  try {
    const { data } = await client.query({
      query: GET_DREAMSHIP_PRODUCT_INFO,
      variables: {
        id,
      },
    });
    const info = get(data, "getDreamshipProductInfo");

    return formatProductsForDS(info);
  } catch (err) {
    console.error("error", err);
  }
}

// ===================== MERCHIZE =====================
function formatProductForMC(data) {
  if (!data || typeof data !== "object") return {};

  const { productObject, attributes, variants: originVariants } = data;
  const title = get(productObject, "name");
  const description = get(productObject, "content");

  const attrs = {};
  for (let { _id, name } of attributes) {
    attrs[_id] = {
      name: upperFirst(name),
      slug: kebabCase(name),
    };
  }

  const variants = [];
  const mergeAttributes = new Map();
  for (let i = 0; i < originVariants.length; i++) {
    const item = originVariants[i];
    if (!item || typeof item !== "object") continue;

    const { _id, options, base_cost: sellerPrice } = originVariants[i];

    const attrObj = {};
    const attributes = (options || [])
      .map(({ attribute, name }) => {
        const matched = attrs[attribute];
        if (!matched) return null;
        attrObj[matched.slug] = name;
        return {
          ...matched,
          option: name,
        };
      })
      .filter(Boolean);

    variants.push({
      attributes,
      regularPrice: Number.parseFloat(Math.max(sellerPrice * 3, 0).toFixed(2)),
      sellerPrice: Math.max(sellerPrice, 0),
      salePrice: 0,
      sorting: i,
      fulfillmentProductId: _id,
    });

    getAttributesFF(attrObj, mergeAttributes);
  }

  const newAttributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = upperFirst(key);
      const options = Array.from(value.values());

      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  return {
    title,
    description,
    attributes: newAttributes,
    variants,
  };
}

export async function fetchProductForMerchize(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return {};

  try {
    const { data } = await client.query({
      query: GET_MERCHIZE_PRODUCT_INFO,
      variables: {
        id,
      },
    });

    const info = get(data, "getMerchizeProductInfo");

    if (info?.attributes?.length > 0 && info?.variants?.length > 0) {
      return formatProductForMC(info);
    } else {
      const { data } = await client.query({
        query: GET_MERCHIZE_PRODUCT_INFO_DB,
        variables: {
          id,
        },
      });

      if (data?.getMerchizeProductInfoFromDB != null)
        return data.getMerchizeProductInfoFromDB;
    }
    return {};
  } catch (err) {
    console.error(err?.toString());
  }
}

export async function fetchProductForTeezily(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return {};

  try {
    const { data } = await client.query({
      query: GET_TEEZILY_PRODUCT_DETAIL,
      variables: {
        reference: id,
      },
    });

    const results = get(data, "getTeezilyProductDetail.results");
    if (!results || !Array.isArray(results) || results.length === 0) return {};

    // case return multiple values by reference_id
    let result = results.filter(Boolean).find((i) => i.reference === id);
    if (!result) {
      [result] = results;
    }

    if (
      !result ||
      !result.variants ||
      !Array.isArray(result.variants) ||
      result.variants.length === 0
    )
      return {};

    const { name, variants: vars, reference } = result;
    const variants = [];
    const mergeAttributes = new Map();
    for (let i = 0; i < vars.length; i++) {
      const v = vars[i];
      if (objectInvalid(v)) continue;
      const { color, size, reference: fulfillmentProductId } = v;

      const attributes = [
        {
          name: "Color",
          slug: "color",
          option: color,
        },
        {
          name: "Size",
          slug: "size",
          option: size,
        },
      ];

      variants.push({
        attributes,
        regularPrice: 0,
        sellerPrice: 0,
        salePrice: 0,
        sorting: 0,
        fulfillmentProductId,
      });

      getAttributesFF({ color, size }, mergeAttributes);
    }

    const attributes = Array.from(mergeAttributes.entries()).map(
      ([key, value]) => {
        const name = upperFirst(key);
        const options = Array.from(value.values());

        return {
          name,
          slug: kebabCase(key),
          options,
        };
      },
    );

    let designPositions;
    const designKeys = TEEZILY_DESIGN_SPECIFICATION[reference]; // ["front", "back"]
    if (designKeys?.length > 0) {
      designPositions = designKeys.map((name) => ({
        description: "",
        image: null,
        name,
      }));
    }

    return {
      title: name,
      variants,
      attributes,
      designPositions,
    };
  } catch (err) {
    console.error(err?.toString());
  }
}

function getOption(attrs, slug = "color") {
  if (!attrs || !Array.isArray(attrs) || attrs.length === 0) return null;

  const val = attrs.find((item) => item?.slug === slug);
  if (val) {
    return (val.option || "").trim();
  }

  return null;
}

export function mapMerchizeSku(variants, skus) {
  if (
    !variants ||
    !Array.isArray(variants) ||
    variants.length === 0 ||
    !skus ||
    typeof skus !== "object" ||
    Object.keys(skus).length === 0
  )
    return variants;

  const result = [];
  const mapVars = new Set();
  for (const va of variants) {
    let newItem = va;
    if (va?.attributes?.length > 0) {
      const { fulfillmentProductId, attributes } = va;
      const matchVal = skus[fulfillmentProductId];
      const color = getOption(attributes);
      const size = getOption(attributes, "size");
      const __attr = `${color}__${size}`;
      newItem.__attr = __attr;

      if (matchVal) {
        if (
          color === (matchVal.color || "").trim() ||
          size === (matchVal.size || "").trim()
        ) {
          newItem.fulfillmentSku = matchVal.sku;
          mapVars.add(__attr);
        }
      }
    }

    result.push(newItem);
  }

  const newVars = [];
  for (let item of result) {
    const { __attr, ...restItem } = item;
    if (!mapVars.has(__attr)) {
      newVars.push(restItem);
      continue;
    }

    if (!restItem.fulfillmentSku) continue;
    newVars.push(restItem);
  }
  return newVars;
}

export function filterNewVariantsForMerchize(newVariants, oldVariants, skus) {
  if (arrInvalid(newVariants) || arrInvalid(oldVariants)) return oldVariants;

  const ids = oldVariants.map(
    ({ fulfillmentProductId }) => fulfillmentProductId,
  );
  const res = [];
  for (let v of newVariants) {
    if (!ids.includes(v.fulfillmentProductId)) {
      res.push(v);
    }
  }

  const newVar = mapMerchizeSku(res, skus);
  return newVar;
}

export function filterNewVariants(variants, oldVariants) {
  if (
    !variants ||
    variants.length === 0 ||
    !oldVariants ||
    oldVariants.length === 0
  )
    return [];
  const ids = oldVariants.map(({ fulfillmentProductId }) =>
    parseInt(fulfillmentProductId),
  );
  const res = [];
  for (let v of variants) {
    if (!ids.includes(v.id)) {
      res.push(v);
    }
  }

  return res;
}

export function getAttributesForMerchize(variants) {
  if (!variants || variants.length === 0)
    return { variants: [], attributes: [] };

  const mergeAttrs = new Map();
  for (let i = 0; i < variants.length; i++) {
    const item = variants[i];
    const { attributes = [] } = item || {};

    for (let attr of attributes) {
      const { name, option } = attr || {};
      if (mergeAttrs.has(name)) {
        const cur = mergeAttrs.get(name);
        mergeAttrs.set(name, cur.add(option));
      } else {
        mergeAttrs.set(name, new Set([option]));
      }
    }
  }

  const attributes = Array.from(mergeAttrs.entries()).map(([key, value]) => {
    const name = upperFirst(key);
    const options = Array.from(value.values());
    return {
      name,
      slug: lowerCase(key),
      options,
    };
  });

  return attributes;
}

export function formatVariantsForCustomcat(data) {
  if (!data || data.length === 0) return { variants: [], attributes: [] };

  const mergeAttrs = new Map();
  const res = [];

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const { id, seller_price, regular_price, attributes = [] } = item || {};

    for (let attr of attributes) {
      const { name, option } = attr || {};
      attr["name"] = upperFirst(name);
      attr["slug"] = lowerCase(name);

      if (mergeAttrs.has(name)) {
        const cur = mergeAttrs.get(name);
        mergeAttrs.set(name, cur.add(option));
      } else {
        mergeAttrs.set(name, new Set([option]));
      }
    }

    res.push({
      sorting: i,
      regularPrice: regular_price,
      salePrice: 0,
      sellerPrice: seller_price,
      attributes,
      fulfillmentProductId: id,
    });
  }

  const attributes = Array.from(mergeAttrs.entries()).map(([key, value]) => {
    const name = upperFirst(key);
    const options = Array.from(value.values());
    return {
      name,
      slug: lowerCase(key),
      options,
    };
  });

  return { variants: res, attributes };
}

// Customcat fulfillment
export async function fetchProductVariantsCustomcat(baseID) {
  if (!baseID || baseID.length === 0) return;
  const { __apolloClient__: client } = window;
  if (client == null) return;

  try {
    const { data } = await client.query({
      query: GET_CUSTOMCAT_PRODUCT_PROVIDER_VARIANTS,
      variables: {
        productBaseID: baseID,
      },
    });

    const variants = get(data, "getCustomcatProductProviderVariants", []);
    return variants;
  } catch (err) {}
}

// Dreamship Fulfillment
export async function fetchProductVariantsDreamship(baseID) {
  if (!baseID || baseID.length === 0) return;
  const { __apolloClient__: client } = window;
  if (client == null) return;
  try {
    const { data } = await client.query({
      query: GET_DREAMSHIP_PRODUCT_PROVIDER_VARIANTS,
      variables: {
        productBaseID: baseID,
      },
    });

    const variants = get(data, "getDreamshipProductProviderVariants", []);
    return variants;
  } catch (err) {}
}

export function formatVariantsForDreamship(data) {
  if (!data || data.length === 0) return { variants: [], attributes: [] };

  const mergeAttrs = new Map();
  const res = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const { id, cost, attributes = [] } = item || {};

    for (let attr of attributes) {
      const { name, option } = attr || {};
      attr["name"] = upperFirst(name);
      attr["slug"] = name;

      if (mergeAttrs.has(name)) {
        const cur = mergeAttrs.get(name);
        mergeAttrs.set(name, cur.add(option));
      } else {
        mergeAttrs.set(name, new Set([option]));
      }
    }

    res.push({
      sorting: i,
      regularPrice: 0,
      salePrice: 0,
      sellerPrice: cost,
      attributes,
      fulfillmentProductId: id,
    });
  }

  const attributes = Array.from(mergeAttrs.entries()).map(([key, value]) => {
    const name = upperFirst(key);
    const options = Array.from(value.values());
    return {
      name,
      slug: lowerCase(key),
      options,
    };
  });

  return { variants: res, attributes };
}

export async function fetchProductVariantsPrintify(productID, printProviderID) {
  const { __apolloClient__: client } = window;
  if (!productID || !printProviderID || !client) return;

  try {
    const { data } = await client.query({
      query: GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
      variables: {
        productID: productID + "",
        printProviderID: printProviderID + "",
      },
    });

    const variants = get(
      data,
      "getPrintifyProductPrintProviderVariants.variants",
      [],
    );
    return variants;
  } catch (err) {}

  return [];
}

export function formatVariantsForPrintify(data) {
  if (!data || data.length === 0) return { variants: [], attributes: [] };

  const mergeAttributes = new Map();
  const variants = data.map((item, index) => {
    const { id, options } = item || {};
    const attributes = Object.entries(options || {}).reduce((acc, opt) => {
      const [key, value] = opt;
      if (mergeAttributes.has(key)) {
        const cur = mergeAttributes.get(key);
        mergeAttributes.set(key, cur.add(value));
      } else {
        mergeAttributes.set(key, new Set([value]));
      }
      const record = {
        name: capitalize(key),
        slug: key,
        option: value,
      };
      acc.push(record);
      return acc;
    }, []);

    return {
      regularPrice: 0,
      salePrice: 0,
      sellerPrice: 0,
      sorting: index,
      attributes,
      fulfillmentProductId: id,
    };
  });

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = capitalize(key);
      const options = Array.from(value.values());
      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  return { variants, attributes };
}

export function updateAttributes(oldAttrs, newAttrs) {
  if (!oldAttrs || oldAttrs.length === 0 || !newAttrs || newAttrs.length === 0)
    return;

  for (let att of newAttrs) {
    if (oldAttrs.findIndex(({ slug }) => slug === att.slug) === -1) {
      oldAttrs.push(att);
    }
  }

  for (let attr of oldAttrs) {
    const { options, slug } = attr;
    const { options: newOpts } = newAttrs.find((i) => i.slug === slug);
    const merge = [...options];
    for (let opt of newOpts) {
      if (!options.includes(opt)) {
        merge.push(opt);
      }
    }
    attr.options = merge;
  }

  return oldAttrs;
}

export function updateDesignPositions(oldDS, ds) {
  if (!oldDS || oldDS.length === 0 || !ds || ds.length === 0) return;
  function getKey(item) {
    if (!item) return "";
    const { name, description } = item;
    return `${name}${description}`;
  }

  function mergeAttrs(attributes) {
    const options = (attributes || [])
      .map(({ option }) => option)
      .filter(Boolean)
      .join("_");
    return options;
  }

  for (let item of ds) {
    const key = getKey(item);

    const conditional = oldDS.findIndex((i) => {
      const target = getKey(i);
      return target === key;
    });
    if (conditional === -1) {
      oldDS.push(item);
    }
  }

  for (let item of oldDS) {
    const { productBaseVariants } = item;
    const key = getKey(item);
    const mapDS = ds.find((i) => getKey(i) === key);
    if (
      !mapDS ||
      !mapDS.productBaseVariants ||
      mapDS.productBaseVariants.length === 0
    )
      continue;
    const uniqueVariants = (productBaseVariants || []).reduce((acc, cur) => {
      const { attributes } = cur;
      const options = mergeAttrs(attributes);
      acc.push(options);
      return acc;
    }, []);

    for (let cur of mapDS.productBaseVariants) {
      const currentOptions = mergeAttrs(cur.attributes);
      if (!uniqueVariants.includes(currentOptions)) {
        productBaseVariants.push(cur);
      }
    }

    const unique = (productBaseVariants || []).reduce((acc, cur) => {
      const key = (cur.attributes || []).map((i) => i.option).join("__");
      acc[key] = cur;
      return acc;
    }, {});

    item.productBaseVariants = Object.values(unique);
  }

  return oldDS;
}

export async function fetchProductForBurgerPrints(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return {};

  try {
    const { data } = await client.query({
      query: GET_BURGERPRINTS_PRODUCT,
      variables: {
        shortCode: id,
      },
    });

    const value = get(data, "getBurgerPrintsProduct");
    if (!value || typeof value !== "object") return {};

    const { html_desc: description, name, short_code, variations } = value;

    if (short_code !== id) return {};
    const variants = [];
    const mergeAttributes = new Map();
    for (let i = 0; i < variations.length; i++) {
      const v = variations[i];
      if (objectInvalid(v)) continue;

      const { sku: fulfillmentProductId, size, color, price } = v; // color_hex
      const attributes = [
        {
          name: "Color",
          slug: "color",
          option: color,
        },
        {
          name: "Size",
          slug: "size",
          option: size,
        },
      ];

      variants.push({
        attributes,
        regularPrice: NumberToFixed(price * 3, 2),
        salePrice: 0,
        sellerPrice: NumberToFixed(price, 2),
        sorting: i,
        fulfillmentProductId,
      });

      getAttributesFF({ color, size }, mergeAttributes);
    }

    const attributes = Array.from(mergeAttributes.entries()).map(
      ([key, value]) => {
        const name = upperFirst(key);
        const options = Array.from(value.values());

        return {
          name,
          slug: kebabCase(key),
          options,
        };
      },
    );


    // TODO: design position
    return {
      title: name,
      description,
      variants,
      attributes,
    }
  } catch (error) {
    console.error(error?.toString());
  }
}
