import { Button, Heading, Stack, TextField } from "@shopify/polaris";
import {
  cloneDeep,
  filter,
  get,
  kebabCase,
  size,
  startCase,
  values,
} from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  FULFILLMENTS_SLUG,
  PRINTIFY_SLUG,
  PRINTWAY_SLUG,
} from "../../../../constants";
import {
  arrInvalid,
  objectInvalid,
  reducerFn,
  toSlug,
} from "../../../../helper";
import { useProductVariants } from "../../../../hooks/useProductVariants";
import {
  getPrintifyShippingMethod,
  getPrintwayShippingMethods,
} from "../../../base/utils";
import { ShippingService } from "../../../mapping_order/ShippingService";
import { ProductVariantSelectPolaris } from "../../../product/ProductVariantSelectPolaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { FieldPersonalized } from "./FieldPersonalized";
import { FieldProduct } from "./FieldProduct";
import { FieldStore } from "./FieldStore";
import { ProductImage } from "./ProductImage";
import { ShippingAddress } from "./ShippingAddress";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .section-form {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    width: 100%;
    justify-content: space-between;

    .section-left,
    .section-right {
      width: calc(50% - 1rem);
      display: flex;
      flex-direction: column;

      .section-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }
  }

  .section-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  @media (max-width: 1024px) {
    .section-form {
      flex-direction: column;

      .section-left,
      .section-right {
        width: 100%;
      }
    }
  }
`;

const SHIPPING_ADDRESS = [
  "firstName",
  "lastName",
  "company",
  "phone",
  "address1",
  "address2",
  "city",
  "country",
  "state",
  "postalCode",
  "email",
];

const PER_REVENUE = 250;

export function Form({ onSubmit, loading }) {
  // State
  const [fields, setFields] = React.useState({
    productId: null,
    productVariantId: null,
    storeId: null,
    quantity: "1",
    originId: null,
    lineItemID: null,
    revenue: "",
    ShippingTotal: "0",
    DiscountTotal: "0",
    TaxTotal: "0",
    personalized: undefined,
    firstName: null,
    lastName: null,
    company: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    country: "US",
    state: null,
    postalCode: null,
    email: null,
  });
  const [product, setProduct] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [shippingOption, setShippingOption] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [shippingMethods, setShippingMethods] = React.useReducer(reducerFn, {
    isPrintway: false,
    isPrintify: false,
    options: [],
  });
  // Hooks
  const [getVariants] = useProductVariants();

  const basicFields = [
    [
      {
        id: "originId",
        label: "Order ID",
        required: true,
        placeholder: "Enter order ID",
      },
      {
        id: "lineItemID",
        label: "Line Item ID",
        placeholder: "Enter line item ID",
      },
    ],
    [
      {
        id: "quantity",
        label: "Quantity",
        required: true,
        type: "number",
        placeholder: "Enter first name",
      },
      {
        id: "revenue",
        label: "Revenue",
        required: true,
        type: "number",
        placeholder: "Enter revenue",
      },
    ],
    [
      {
        id: "ShippingTotal",
        label: "ShippingTotal",
        required: true,
        type: "number",
        placeholder: "Enter shipping total",
      },
      {
        id: "DiscountTotal",
        label: "Discount Total",
        required: true,
        type: "number",
        placeholder: "Enter discount total",
      },
      {
        id: "TaxTotal",
        label: "Tax Total",
        required: true,
        type: "number",
        placeholder: "Enter tax total",
      },
    ],
  ];

  const FIELDS_REQUIRED = React.useMemo(() => {
    const defaultValue = {
      productId: "Product",
      productVariantId: "Product variant",
      originId: "Order",
      firstName: "First name",
      lastName: "Last name",
      // email: 'Email',
      postalCode: "Postal Code",
      address1: "Address 1",
      city: "City",
      state: "State",
      country: "Country",
      storeId: "Store",
    };
    return basicFields?.length > 0
      ? basicFields.reduce((acc, { required, id, label }) => {
          if (required) {
            return {
              ...acc,
              [id]: label,
            };
          }
          return acc;
        }, defaultValue)
      : {};
  }, [basicFields]);

  // Handle actions
  const renderField = React.useCallback(
    ({ id, placeholder, type = "text", multiline = null }) => (
      <TextField
        id={id}
        placeholder={placeholder}
        type={type}
        min={type === "number" ? 0 : ""}
        multiline={multiline}
        error={errors[id]}
        value={fields[id]}
        onChange={handleFieldsChange}
      />
    ),
    [basicFields],
  );

  const validateFields = React.useCallback(
    (value, id) => {
      let error = null;
      let label;
      for (let [key, newLabel] of Object.entries(FIELDS_REQUIRED)) {
        if ([key].includes(id)) {
          label = newLabel;
        }
      }

      if ((!value || !value.length) && label) {
        error = `${label} is required.`;
      } else {
        if (id === "revenue") {
          const { quantity } = fields;
          const maxRevenue = +quantity * PER_REVENUE;
          if (+value > maxRevenue) {
            error = "Invalid revenue, only accept maximum $250/qty";
          }
        }

        if (id === "quantity") {
          const { revenue } = fields;
          const maxRevenue = +value * PER_REVENUE;
          let newError = null;

          if (+revenue > maxRevenue) {
            newError = "Invalid revenue, only accept maximum $250/qty";
          }
          setErrors((prev) => ({ ...prev, revenue: newError }));
        }

        if (id === "state" && fields.country === "US") {
          if (value.length > 2) {
            error = "Only use the USA two-letter abbreviation for states.";
          }
        }
      }
      setErrors((prev) => ({ ...prev, [id]: error }));
    },
    [fields],
  );

  const handleFieldsChange = React.useCallback(
    (value, id) => {
      setFields((prev) => ({ ...prev, [id]: value }));
      validateFields(value, id);

      // reset state field
      if (isUnitedStates(id, value)) {
        setErrors((p) => ({ ...p, state: null }));
      }
    },
    [validateFields],
  );

  const handleSubmit = React.useCallback(() => {
    const fieldsRequired = Object.keys(FIELDS_REQUIRED).reduce((acc, key) => {
      if (isUnitedStates("country", fields["country"]) && key === "state") {
        return acc;
      }

      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        return {
          ...acc,
          [key]: fields[key],
        };
      }
      return { ...acc };
    }, {});

    Object.entries(fieldsRequired).forEach(([id, value]) => {
      validateFields(value, id);
      if (isUnitedStates(id, value)) {
        setErrors((p) => ({ ...p, state: null }));
      }
    });

    const noError = size(filter(values(errors), (e) => e !== null)) === 0;
    const canSubmit = Object.entries(fieldsRequired).every(([, value]) =>
      Boolean(value),
    );
    if (noError && canSubmit) {
      const newFields = cloneDeep(fields);
      const fieldsNumber = [
        "quantity",
        "revenue",
        "ShippingTotal",
        "DiscountTotal",
        "TaxTotal",
      ];
      for (let key of fieldsNumber) {
        newFields[key] = Number(newFields[key]);
      }
      newFields["productId"] = newFields["productId"][0];
      newFields["shippingAddress"] = Object.entries(newFields).reduce(
        (acc, [key, value]) => {
          if (SHIPPING_ADDRESS.includes(key)) {
            let val = value;
            if (key === "state") {
              val = val ? val : "";
            }
            return {
              ...acc,
              [key]: val,
            };
          }
          return acc;
        },
        {},
      );
      newFields["shippingOption"] = shippingOption;

      const input = Object.entries(newFields).reduce((acc, [key, value]) => {
        if (!SHIPPING_ADDRESS.includes(key)) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {});

      if (input != null) {
        if (onSubmit) {
          setDisabled(true);
          onSubmit(input);
        }
      }
    }
  }, [fields, FIELDS_REQUIRED, basicFields, errors, onSubmit, shippingOption]);

  const handlePersonalizedChange = useCallback(
    (value) => {
      handleFieldsChange(value, "personalized");
    },
    [handleFieldsChange],
  );

  const shippingValue = SHIPPING_ADDRESS.reduce(
    (acc, curr) => ({ ...acc, [curr]: fields[curr] }),
    {},
  );

  // product
  const fulfillment = useMemo(() => {
    if (!product) return null;

    const bases = product.productBases;
    const base = bases?.length > 0 ? bases.find((f) => f.fulfillment) : null;

    let result;
    if (base != null) {
      result = {
        slug: toSlug(base.fulfillment.fulfillmentTitle),
      };
    }
    return result;
  }, [product]);

  const getProductVariants = useCallback(async (product) => {
    (async function () {
      const productWithVariants = await getVariants(product);
      setProduct(productWithVariants);
    })();
  }, []);

  const handleProductDetail = useCallback(
    async (productInfo) => {
      const { isPrintway, isPrintify } = shippingMethods;
      if (isPrintway) {
        const { fulfillmentSku } = productInfo;
        const shippingServices = await getPrintwayShippingMethods([
          fulfillmentSku,
        ]);
        if (!arrInvalid(shippingServices)) {
          const matchById = shippingServices.find(
            (item) => item.itemSKU === fulfillmentSku,
          );
          if (!objectInvalid(matchById)) {
            if (!arrInvalid(matchById.shippingServices)) {
              const options = matchById.shippingServices.map((method) => ({
                value: method,
                label: method,
              }));

              setShippingMethods({ options });
            }
          }
        }
      } else if (isPrintify) {
        const { baseID } = productInfo;
        const shippingServices = await getPrintifyShippingMethod(baseID);
        if (shippingServices.length > 0) {
          setShippingMethods({ options: shippingServices });
        }
      }
    },
    [shippingMethods],
  );

  return (
    <Container>
      <div className="section-form">
        <div className="section-left">
          <Heading children="Order Item" element="h2" />
          <div className="section-container">
            <div>
              <FieldProduct
                error={errors["productId"]}
                onChange={async (v, dataSource) => {
                  const data = dataSource?.data ?? null;
                  const fulfillmentTitle = get(
                    data,
                    "productBases[0].fulfillment.fulfillmentTitle",
                  );
                  const ffSlug = kebabCase(fulfillmentTitle || "");

                  await getProductVariants(data);
                  // setProduct(data);

                  //
                  const newValue = Array.isArray(v) ? v : [v];
                  handleFieldsChange(newValue, "productId");
                  handleFieldsChange(undefined, "personalized");

                  const shippingMethods = checkFulfillment(ffSlug);
                  setShippingMethods({ ...shippingMethods, options: [] });
                }}
              />
              {!!product && <ProductImage product={product} />}
            </div>
            <div>
              <ProductVariantSelectPolaris
                product={product}
                error={errors["productVariantId"]}
                onChange={(value, productInfo) => {
                  handleProductDetail(productInfo);
                  handleFieldsChange(value, "productVariantId");
                }}
              />
            </div>
            <div>
              <FieldStore
                onChange={(v) => handleFieldsChange(v, "storeId")}
                error={errors["storeId"]}
              />
            </div>
            {basicFields.map((group, idx) => {
              if (Array.isArray(group) && group.length > 0) {
                return (
                  <Stack key={`group-${idx}`} distribution="fill">
                    {group.map((field) => {
                      const { id, placeholder, multiline, type } = field;
                      return (
                        <div className="field-wrap" key={id}>
                          <ComponentLabelPolaris
                            label={field.label}
                            required={!!field.required}
                          />
                          {renderField({
                            id,
                            placeholder,
                            multiline,
                            type,
                          })}
                        </div>
                      );
                    })}
                  </Stack>
                );
              }

              return null;
            })}
            {!!product?.personalized && (
              <FieldPersonalized
                product={product}
                onChange={handlePersonalizedChange}
              />
            )}
            <ShippingService
              fulfillment={fulfillment}
              shippingOption={shippingOption}
              setShippingOption={setShippingOption}
              options={shippingMethods.options}
            />
          </div>
        </div>
        <div className="section-right">
          <Heading children="Shipping Address" element="h2" />
          <div className="section-container">
            <ShippingAddress
              errors={errors}
              setErrors={setErrors}
              value={shippingValue ?? {}}
              onChange={(value, id) => {
                handleFieldsChange(value, id);
              }}
            />
          </div>
        </div>
      </div>
      <div className="section-btn">
        <Button
          children="Submit"
          onClick={handleSubmit}
          primary
          loading={loading}
          disabled={disabled}
        />
      </div>
    </Container>
  );
}

const isUnitedStates = (id, val) => id === "country" && val !== "US";

export const checkFulfillment = (slug) => {
  const res = {
    isPrintway: false,
    isPrintify: false,
  };

  switch (slug) {
    case FULFILLMENTS_SLUG.Printway:
      res.isPrintway = true;
      break;

    case FULFILLMENTS_SLUG.Printify:
      res.isPrintify = true;
      break;
    default:
      break;
  }

  return res;
};
