import { useQuery } from "@apollo/react-hooks";
import { Autocomplete, Button, Icon, Spinner, Tag } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getUnique, handleError } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { QuickAddCollectionPolaris } from "./QuickAddCollectionPolaris";

export const LIST_COLLECTION = gql`
  fragment cat on Collection {
    id
    name
  }
  query collections($filter: CollectionFilter) {
    collections(filter: $filter) {
      total
      nodes {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
              children {
                ...cat
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_COLLECTION_POPULAR = gql`
  query collections {
    collections(filter: { limit: -1, offset: -1, popular: true }) {
      nodes {
        id
        name
      }
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const Container = styled.div`
  .popular-btns_wrap {
    display: block;
    padding: 2rem 1rem 1rem 1.5rem;
    border: 1px solid #c3cbd6;
    margin: 1rem 0 1.5rem;
    border-radius: 3px;
    position: relative;
    .popular-btns_inner {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }
    label {
      display: inline-block;
      position: absolute;
      left: 15px;
      top: -10px;
      padding: 0 10px;
      background: #fff;
    }
  }
`;

export const CollectionSelectPolaris = ({
  value,
  onChange,
  allowMultiple,
  label,
  labelHidden,
  getCoupleValue,
  haveQuickAdd,
  required,
  hasPopular,
  errorMessage,
  limit = 20,
  isAssortment,
  resource,
}) => {
  // State
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [lBMarkup, setLBMarkup] = useState([]);

  const [filter, setFilter] = useState({
    limit: inputValue ? limit : 20,
    offset: 0,
    search: null,
  });
  const typingTimeoutRef = useRef(null);
  const onChangeRef = useRef(null);

  // Queries
  const firstRef = React.useRef(true);
  const { data, error } = useQuery(LIST_COLLECTION, {
    variables: { filter },
    fetchPolicy: "network-only",
    onCompleted: () => {
      firstRef.current = true;
    },
  });

  const {
    data: dataPopular,
    loading: loadingPopular,
    error: errorPopular,
  } = useQuery(LIST_COLLECTION_POPULAR, {
    fetchPolicy: "network-only",
    skip: !hasPopular,
  });

  const buildCollectionData = (input) => {
    let result = [];
    const buildData = (data, prefix = "") => {
      data.forEach((input) => {
        let build = {
          label:
            prefix && "" !== prefix ? prefix + " " + input.name : input.name,
          value: input.id,
        };
        result.push(build);
        if (input && input.children && input.children.length > 0) {
          buildData(input.children, prefix + "--");
        }
      });
    };
    buildData(input, "");
    return result;
  };

  const inputRef = useRef(false);
  const valueFirst = useRef(false);
  useEffect(() => {
    // Has popular
    const nodes = (
      dataPopular?.collections?.nodes || []
    ).map(({ id, name }) => ({ id, name }));
    setLBMarkup(nodes);

    let dO = resource ? (Array.isArray(resource) ? resource : [resource]) : [];
    if (nodes?.length > 0) {
      // dO = nodes.filter((i) => {
      //   if (Array.isArray(value)) {
      //     return value.includes(i.id);
      //   }
      //   return false;
      // });
      dO = [...dO, ...nodes];
    }

    let colIds = getIds(resource);
    let newVal = value;
    if (!valueFirst.current) {
      newVal = [...colIds, ...(value || [])];
    }

    dO = dO.filter((i) => {
      if (Array.isArray(newVal)) {
        return newVal.includes(i.id);
      }
      return false;
    });

    if (resource && !inputRef.current) {
      const { name } = resource;
      setInputValue(name);
      inputRef.current = true;
    }

    setDeselectedOptions((prevState) => {
      let result = getUnique([...prevState, ...dO], "id");
      return result;
    });

    valueFirst.current = true;
  }, [value, isAssortment, dataPopular, resource]);

  useEffect(() => {
    let newValue = [];
    let newData = [];
    if (value && _.isArray(value)) {
      newData = value.map((v) => {
        if (typeof v === "object") {
          newValue.push(v.id);
          return {
            label: v.name,
            value: v.id,
          };
        } else {
          newValue.push(v);
          return null;
        }
      });
    }
    newValue = allowMultiple
      ? newValue
      : Array.isArray(value)
      ? value
      : [value];
    newData = newData.filter(Boolean);
    if (newData.length > 0) {
      setOptions((prevState) => {
        let result = getUnique([...prevState, ...newData], "value");
        return result;
      });
      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, ...value], "id");
        return result;
      });
    }
    setSelectedOptions(newValue);
  }, [value, allowMultiple]);

  useEffect(() => {
    if (!firstRef.current) return;

    const nodes = data?.collections?.nodes || [];
    if (nodes.length > 0) {
      firstRef.current = false;
    }
    const resourceArr = resource
      ? Array.isArray(resource)
        ? resource
        : [resource]
      : [];

    let result = buildCollectionData(
      resource ? getUnique([...resourceArr, ...nodes], "id") : nodes,
    );

    setOptions(result);

    let colIds = getIds(resource);
    setSelectedOptions((prev) => {
      const newValue = prev?.length > 0 ? [...prev, ...colIds] : colIds;
      const values = Array.from(new Set(newValue).values());
      return values;
    });
  }, [data, resource]);

  useEffect(
    () => () => {
      setSelectedOptions([]);
    },
    [],
  );

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      typingTimeoutRef.current = setTimeout(() => {
        setFilter((prevState) => {
          return {
            ...prevState,
            search: value,
            limit: value ? limit : 20,
          };
        });
      }, 500);
    },
    [limit],
  );

  useEffect(() => {
    let result = showAdd(inputValue, options);
    setShowQuickAdd(result);
  }, [inputValue, options]);

  const showAdd = (inputValue, options) => {
    if (!inputValue) return false;
    let matched = options.find(
      (option) =>
        option.label && option.label.toLowerCase() === inputValue.toLowerCase(),
    );
    if (!options) return true;
    if (!matched) return true;
    return false;
  };

  const handleSelected = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);

      let newData = _.get(data, "collections.nodes", []);
      const buildFullData = (input) => {
        let result = [];
        const buildData = (data) => {
          data.forEach((input) => {
            result.push(input);
            if (input && input.children && input.children.length > 0) {
              buildData(input.children);
            }
          });
        };
        buildData(input);
        return result;
      };
      newData = newData && buildFullData(newData);
      let couple =
        newData && newData.length > 0
          ? newData.filter((d) => selected.includes(d.id))
          : [];

      let newResult = [];
      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, ...couple], "id");
        result = result.filter((i) => selected.includes(i.id));
        newResult = result;
        return result;
      });

      onChangeRef.current && clearTimeout(onChangeRef.current);
      onChangeRef.current = setTimeout(() => {
        if (onChange) {
          if (allowMultiple) {
            onChange(getCoupleValue ? newResult : selected);
            return;
          } else {
            onChange(_.head(selected));
          }
        }
        setInputValue(_.head(selectedValue));
      }, 100);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, data],
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      let newDeselected = deselectedOptions.filter((item) => item.id !== value);
      setSelectedOptions(newSelected);
      setDeselectedOptions(newDeselected);
      if (onChange && allowMultiple) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptions],
  );

  const handleAddCompleted = useCallback((data) => {
    if (data) {
      let item = {
        value: data.id,
        label: data.name,
      };
      let newSelected = [];
      let currentOptions = [];
      setOptions((prevState) => {
        currentOptions = [item, ...prevState];
        return currentOptions;
      });
      setSelectedOptions((prevState) => {
        newSelected = [item.value, ...prevState];
        if (onChange && allowMultiple) {
          onChange(newSelected);
        }
        return newSelected;
      });

      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, data], "id");
        result = result.filter((i) => newSelected.includes(i.id));
        return result;
      });

      setShowQuickAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickLabelBtn = useCallback(
    (item) => {
      let currentIds = selectedOptions;

      let isTM = {};
      let isNotTM = {};
      if (lBMarkup?.length > 0) {
        isTM = lBMarkup.find((i) => i.name === "TM") || {};
        isNotTM = lBMarkup.find((i) => i.name === "NO-TM") || {};
      }
      let dO = [];

      if (currentIds.includes(item.id)) {
        if (onChange) {
          onChange(currentIds.filter((i) => i !== item.id));
          setDeselectedOptions((prev) => {
            return prev.filter((i) => i.id !== item.id);
          });
        }
      } else {
        let newValue = currentIds;
        if (item.id === isTM.id) {
          newValue = newValue.filter((i) => i !== isNotTM.id);
          setDeselectedOptions((prev) => {
            return prev.filter((i) => i.id !== isNotTM.id);
          });
        }
        if (item.id === isNotTM.id) {
          newValue = newValue.filter((i) => i !== isTM.id);
          setDeselectedOptions((prev) => {
            return prev.filter((i) => i.id !== isTM.id);
          });
        }
        if (onChange) {
          dO = [...newValue, item.id];
          onChange(dO);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, lBMarkup],
  );

  const textField = (
    <Autocomplete.TextField
      onChange={handleInputChange}
      label={label ? label : "Parents collection"}
      labelHidden={true}
      value={inputValue}
      placeholder="Search for collection"
      error={errorMessage}
      prefix={
        false ? (
          <span className="block mt-2">
            <Spinner size="small" />
          </span>
        ) : (
          <Icon source={SearchMinor} color="base" />
        )
      }
    />
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  const contentBeforeMarkup = (
    <QuickAddCollectionPolaris
      inputValue={inputValue}
      onCompleted={handleAddCompleted}
    />
  );

  let currentIds = selectedOptions;

  return (
    <Container>
      {!labelHidden ? (
        <ComponentLabelPolaris
          required={required}
          label={label ? label : "Parents collection"}
        />
      ) : null}
      <div>
        {loadingPopular ? (
          <Spinner size="small" />
        ) : errorPopular ? (
          <div>{errorPopular.toString()}</div>
        ) : lBMarkup?.length > 0 ? (
          <div className="popular_wrap">
            <div className="popular-btns_wrap">
              <label>Popular collections</label>
              <div className="popular-btns_inner">
                {lBMarkup.map((i, index) => (
                  <Button
                    key={index}
                    size="slim"
                    onClick={() => handleClickLabelBtn(i)}
                    primary={currentIds.includes(i.id)}
                  >
                    {i.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Autocomplete.ComboBox
        options={options}
        selected={selectedOptions}
        onSelect={handleSelected}
        textField={textField}
        allowMultiple={allowMultiple}
        contentBefore={
          haveQuickAdd && showQuickAdd ? contentBeforeMarkup : null
        }
        emptyState={<span>No items found!</span>}
      />
      {allowMultiple && deselectedOptions && deselectedOptions.length > 0 ? (
        <TagContainer>
          {deselectedOptions.map((item) => (
            <Tag
              children={item.name}
              onRemove={() => handleRemove(item.id)}
              key={item.id}
            />
          ))}
        </TagContainer>
      ) : null}
    </Container>
  );
};

const getIds = (data) => {
  let colIds = data ? (Array.isArray(data) ? data : [data]) : [];
  colIds = colIds
    .map((item) => {
      if (typeof item === "object") {
        return item.id;
      }

      return item;
    })
    .filter(Boolean);

  return colIds;
};
