import {
  Badge,
  Button,
  Checkbox,
  Collapsible,
  DataTable,
  Icon,
  Stack,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import { get, includes, snakeCase, startCase } from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import amazonLogo from "../../assets/images/amazon-logo.png";
import ebayLogo from "../../assets/images/ebay-logo.png";
import etsyLogo from "../../assets/images/etsy-logo.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import shopBaseLogo from "../../assets/images/shopbase.png";
import shopifyLogo from "../../assets/images/shopify.png";
import tiktokLogo from "../../assets/images/tiktok-logo.png";
import wooCommerceLogo from "../../assets/images/wooCommerce.png";
import { useAppContext } from "../../context";
import { checkRole, formatTime, throttle } from "../../helper";
import useToggle from "../../hooks/useToggle";
import { META_DATA, PLATFORM_ETSY } from "../../variable";
import ModalImageClaimPolaris from "../seller/ModalImageClaimPolaris";
import { BulkActions } from "../shared/BulkActions";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { Actions } from "./actions/Actions";
import { BulkActionMapping } from "./BulkActionMapping";
import { CircleAlertMajorMonotone } from "@shopify/polaris-icons";

const PaginationContainer = styled.div`
  padding: 1.6rem;
  background: var(--p-surface-subdued, #ebeef1);
  text-align: center;
  border-radius: var(--p-border-radius-wide, 3px);
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow-x: scroll;
`;

const Container = styled.div`
  overflow-x: auto;

  .Polaris-DataTable__ScrollContainer {
    overflow-x: inherit;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .origin-id-wrap {
    width: calc(20rem - 3.2rem);
  }

  .created-at-wrap {
    width: calc(18rem - 3.2rem);
  }

  .actions-wrap {
    width: max-content;
  }

  .qty-wrap {
    width: calc(7rem - 3.2rem);
  }

  .product-data-wrap,
  .meta-data-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    width: calc(32rem - 3.2rem);
  }

  .store-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    colum-gap: 1rem;
    width: max-content;
  }

  .meta-item {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }

  .product-wrap {
    width: calc(58rem - 3.2rem);
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
  }
  .shipping-address-wrap {
    width: calc(38rem - 3.2rem);
  }

  .note-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    column-gap: 0.5rem;

    .Polaris-Icon {
      margin: 3px 0 0 0;

      .Polaris-Icon__Svg {
        fill: #d4b31e;
        width: 90%;
      }
    }

    .tooltip {
      font-size: 13px;
      font-style: italic;
      color: #1e1e1e;
    }
  }

  span[aria-describedby] {
    flex: 1;
  }
`;

// Store logo
const SRC_STORE_LOGO = {
  woocommerce: wooCommerceLogo,
  shopify: shopifyLogo,
  ebay: ebayLogo,
  amazon: amazonLogo,
  shopbase: shopBaseLogo,
  etsy: etsyLogo,
  facebook: facebookLogo,
  tiktok: tiktokLogo,
};

function StoreWrap({ platform, store }) {
  let style = {
    width: "auto",
    height: "40px",
    marginRight: "0.785rem",
  };

  if (
    ["ebay", "facebook", "shopbase", "etsy", "amazon", "tiktok"].includes(
      platform,
    )
  ) {
    style["height"] = "30px";
  }

  return (
    <div className="store-wrap item">
      <div>
        {platform && (
          <img
            alt=""
            src={SRC_STORE_LOGO[platform]}
            style={style}
            loading="lazy"
          />
        )}
      </div>
      <div>
        <span>{store}</span>
      </div>
    </div>
  );
}

// Personalize wrap
export function PersonalizeWrap({ data = [] }) {
  if (!data || data.length === 0) return null;

  return data
    .map((item, index) => {
      if (!item) return null;
      const { name, value } = item;
      return (
        <Stack spacing="tight" key={`personalized-${index}`}>
          <TextStyle variation="strong">
            {name} {":"}
          </TextStyle>
          <TextStyle>{value}</TextStyle>
        </Stack>
      );
    })
    .filter(Boolean);
}

// Meta data wrap
const MetaDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .meta-item {
    &:has(img) {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
`;
export function MetaDataWrap({ data = [] }) {
  const [open, toggleOpen] = useToggle(false);
  if (!data || data.length === 0) return null;

  const genContent = (data) => {
    return data
      .map((meta, index) => {
        if (!meta) return null;

        const { value, key } = meta;
        const metaHasImg = includes(value, META_DATA);

        const newKey = key ? startCase(key) : null;
        const keyMarkup = newKey && (
          <TextStyle children={newKey} variation="strong" />
        );

        const hasValue = value && typeof value !== "object";
        const valueMarkup = metaHasImg ? (
          <>
            <label>{newKey && startCase(newKey)}: </label>
            <div>
              <CustomLinkPolaris href={value}>
                <img src={value} alt="" loading="lazy" />
              </CustomLinkPolaris>
            </div>
          </>
        ) : (
          <>
            {keyMarkup}
            {`: ${value}`}
          </>
        );

        return (
          <div key={`meta-data-${index}`} className="meta-item">
            {hasValue ? valueMarkup : null}
          </div>
        );
      })
      .filter(Boolean);
  };

  const first = data.slice(0, 4);
  const second = data.slice(4);
  return (
    <MetaDataContainer>
      {genContent(first)}
      {second.length > 0 ? (
        <Stack vertical>
          <Button
            plain
            children="View more"
            onClick={toggleOpen}
            disclosure={open ? "up" : "down"}
          />
          <Collapsible
            open={open}
            transition={{ duration: "500ms", timingFunction: "ease" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: 10 }}
            >
              {genContent(second)}
            </div>
          </Collapsible>
        </Stack>
      ) : null}
    </MetaDataContainer>
  );
}

function ProductWrap({ product, record }) {
  if (product == null) return null;

  const { images, name } = product;
  const files =
    images?.length > 0 ? images.map(({ id, src }) => ({ id, url: src })) : null;

  let label = record.label;
  let note = record.note;
  let noteCut = (note || "").substring(0, 85);

  return (
    <div className="product-wrap item">
      {files?.length > 0 && (
        <ModalImageClaimPolaris
          width={200}
          height={200}
          files={{ files }}
          limit={1}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}>
        <span>{name}</span>
        {label ? (
          <div>
            <Badge status="attention">{label}</Badge>
          </div>
        ) : null}
        {note ? (
          <div className="note-wrap">
            <Icon source={CircleAlertMajorMonotone} />
            <Tooltip preferredPosition="above" content={note}>
              <span className="tooltip">{noteCut}</span>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function ProductDataWrap({ node }) {
  if (!node) return null;

  const productId = node.productId;
  const variantId = node.variantId;
  const sku = node.sku;
  const price = node.price;
  const shippingService = node.shippingService;
  const note = node.note;

  // SkuName Tiktok
  const skuName = get(node, "originOrder.sku_name");
  const orderDate = get(node, "originOrder.orderDate");
  const orderDateMarkup = formatTime(orderDate, "YYYY-MM-DD");

  return (
    <div className="product-data-wrap item">
      {orderDateMarkup ? (
        <span>
          <TextStyle variation="strong">Order Date:</TextStyle>{" "}
          {orderDateMarkup}
        </span>
      ) : null}
      <span>
        <TextStyle variation="strong">Product ID:</TextStyle> {productId}
      </span>
      <span>
        <TextStyle variation="strong">Variation ID:</TextStyle> {variantId}
      </span>
      <span>
        <TextStyle variation="strong">SKU:</TextStyle> {sku}
      </span>
      <span>
        <TextStyle variation="strong">Price:</TextStyle> {price}$
      </span>
      {skuName ? (
        <span>
          <TextStyle variation="strong">Origin Attributes:</TextStyle> {skuName}
        </span>
      ) : null}

      {shippingService ? (
        <span>
          <TextStyle variation="strong">Shipping Option:</TextStyle>{" "}
          {shippingService}
        </span>
      ) : null}
      {note ? (
        <span>
          <TextStyle variation="strong">Note:</TextStyle>
          <span style={{ marginLeft: 5 }}>{note}</span>
        </span>
      ) : null}
    </div>
  );
}

export function TableMOPolaris({
  data,
  filter,
  setFilter,
  refetch,
  agg,
  total: totalProp,
  setDoAction,
}) {
  //Props
  const { limit, offset, type } = filter;
  const isOnlineStore = React.useMemo(() => type === "online store", [type]);
  // State
  const [rows, setRows] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [itemsChecked, setItemsChecked] = useState({});
  const [idsChecked, setIdsChecked] = useState([]);
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);
  const { currentUser } = useAppContext();
  const { isDesignLeader } = checkRole(currentUser);

  const handleIdsChecked = useCallback((object) => {
    object = Object(object);
    let result = [];

    for (let [key, value] of Object.entries(object)) {
      if (value) {
        result.push(key);
      }
    }
    return result;
  }, []);

  const handleCheckedAll = useCallback(
    (newChecked) => {
      setCheckedAll(newChecked);
      let propsT = data?.listMappingOrder?.nodes
        ? data.listMappingOrder.nodes
        : [];

      let idsChecked = [];
      if (newChecked) {
        let item = {};
        for (let i = 0; i < propsT.length; i++) {
          item = {
            ...item,
            [propsT[i].id]: true,
          };
        }
        setItemsChecked(item);
        idsChecked = handleIdsChecked(item);
      } else {
        setItemsChecked({});
        idsChecked = [];
      }
      setIdsChecked(idsChecked);
    },
    [data, handleIdsChecked],
  );

  const checkAllMarkup = useMemo(
    () => (
      <Checkbox
        label=""
        checked={checkedAll}
        onChange={(newChecked) => handleCheckedAll(newChecked)}
      />
    ),
    [checkedAll, handleCheckedAll],
  );
  const handleChecked = useCallback(
    (id, newChecked) => {
      let countItemsChecked;
      let idsChecked = [];

      setItemsChecked((prev) => {
        let result = { ...prev, [id]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;

        // onSelect
        idsChecked = handleIdsChecked(result);
        return result;
      });

      if (newChecked) {
        setCheckedAll(true);
      } else if (!countItemsChecked) {
        setCheckedAll(false);
      }
      setIdsChecked(idsChecked);
    },
    [handleIdsChecked],
  );

  const handleCompleted = useCallback(() => {
    setCheckedAll(false);
    setItemsChecked({});
    setIdsChecked([]);
  }, []);

  // Get data
  useEffect(() => {
    const newData = data?.listMappingOrder?.nodes;
    const countRepeat = (newData || []).reduce((acc, item) => {
      if (!item || item.orderId == null) return acc;
      acc[item.orderId] = (acc[item.orderId] || 0) + 1;
      return acc;
    }, {});

    const newRows =
      newData?.length > 0
        ? newData
            .map((node) => {
              if (node == null) return null;

              // OriginId
              let originId = node.orderId;
              const ebayOriginId = node.ebayOriginId;
              if (ebayOriginId) {
                originId = ebayOriginId;
              }
              const originIdMarkup = (
                <div className="origin-id-wrap item">
                  <span>{originId}</span>
                </div>
              );

              // Qty
              const qty = node.quantity;
              const qtyMarkup = (
                <div className="qty-wrap item">
                  <span>{qty}</span>
                </div>
              );

              // Store
              const store = node.store?.title;
              const platform = node.store?.platform;

              const isPlatformEtsy = [PLATFORM_ETSY].includes(
                platform?.toLowerCase(),
              );

              const storeMarkup = (
                <StoreWrap platform={platform} store={store} />
              );

              // Created at
              const createdAt = node.createdAt;
              const createdMarkup = (
                <div className="created-at-wrap item">
                  <span>{formatTime(createdAt, "YYYY-MM-DD HH:mm:ss")}</span>
                </div>
              );

              // Product data
              const productDataMarkup = <ProductDataWrap node={node} />;

              // Meta data
              const metaData = node.metaData;
              const personalized = node.personalized;

              const personalizedMarkup = (
                <PersonalizeWrap data={personalized} />
              );

              const customer = get(node, "orderData.customer");
              let customerName = "";
              if (customer) {
                const f = get(customer, "first_name");
                const l = get(customer, "last_name");
                customerName = [f, l].filter(Boolean).join(" ");
              }

              const metaDataMarkup = (
                <div className="meta-data-wrap item">
                  {customerName && (
                    <span>
                      <TextStyle variation="strong">Buyer Name: </TextStyle>
                      <span>{customerName}</span>
                    </span>
                  )}
                  <MetaDataWrap data={metaData} />
                  {isPlatformEtsy && personalizedMarkup}
                </div>
              );

              // Product
              const product = node.product;
              const productMarkup = (
                <ProductWrap product={product} record={node} />
              );

              // Actions
              const isOS = isOnlineStoreByOrder(node);
              const actionMarkup = (
                <div className="actions-wrap item">
                  <MappingOrderTypeProvider
                    isOnlineStore={isOnlineStore || isOS}
                  >
                    <Actions
                      item={node}
                      actionCompleted={handleCompleted}
                      refetch={refetch}
                      setDoAction={setDoAction}
                    />
                  </MappingOrderTypeProvider>
                </div>
              );

              // Checkbox
              const id = node.id;
              const checkCls =
                (countRepeat[originId] || 0) > 1
                  ? "check-box-wrap repeat"
                  : "check-box-wrap";
              const checkbox = (
                <div className={checkCls}>
                  <Checkbox
                    id={id}
                    checked={!!itemsChecked[id]}
                    onChange={(newChecked, id) => handleChecked(id, newChecked)}
                  />
                </div>
              );

              const shippingAddress = get(node, "orderData.shipping_address");
              const shippingAddressMarkup = (
                <div className="shipping-address-wrap item">
                  <ShippingAddress data={shippingAddress} />
                </div>
              );
              return [
                checkbox,
                originIdMarkup,
                productMarkup,
                qtyMarkup,
                storeMarkup,
                createdMarkup,
                productDataMarkup,
                metaDataMarkup,
                shippingAddressMarkup,
                actionMarkup,
              ];
            })
            .filter(Boolean)
        : [];
    setRows(newRows);
  }, [
    data,
    refetch,
    itemsChecked,
    handleChecked,
    handleCompleted,
    isOnlineStore,
    setDoAction,
  ]);

  //
  useEffect(() => {
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domsChild = document.querySelectorAll(".actions-wrap");
    for (let domChild of domsChild) {
      const domParent = domChild.closest(".Polaris-DataTable__Cell");
      if (!domParent) continue;

      if (addClass) {
        domParent.classList.add("custom-cell");
        continue;
      }
      domParent.classList.remove("custom-cell");
    }

    // Repeat origin id
    const rows = document.querySelectorAll(".Polaris-DataTable__TableRow");
    for (let row of Array.from(rows)) {
      if (row) {
        if (row.style) {
          row.style = "";
        }
        const cells = row.querySelectorAll(".Polaris-DataTable__Cell");
        for (let c of Array.from(cells)) {
          c.style = "";
        }
      }
    }

    const ordersRepeat = document.querySelectorAll(".check-box-wrap.repeat");

    for (let item of Array.from(ordersRepeat)) {
      const row = findAncestor(item, "Polaris-DataTable__TableRow");
      if (row) {
        const cells = row.querySelectorAll(".Polaris-DataTable__Cell");
        for (let c of Array.from(cells)) {
          c.style.backgroundColor = "#ddd";
        }
      }
    }
  }, [rows, coordinates]);

  // Handle scroll
  useEffect(() => {
    const wrapRefCurrent = wrapRef.current;
    const handler = () => {
      throttle(() => {
        if (wrapRefCurrent) {
          setCoordinates({
            scrollLeft: wrapRefCurrent.scrollLeft,
            scrollWidth: wrapRefCurrent.scrollWidth,
            offsetWidth: wrapRefCurrent.offsetWidth,
          });
        }
      });
    };

    if (wrapRefCurrent) {
      wrapRefCurrent.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRefCurrent) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRefCurrent.removeEventListener("scroll", handler);
      }
    };
  }, []);

  // Markup
  const colTypes = new Array(9).fill("text").concat("numeric");
  const headings = [
    checkAllMarkup,
    "Origin ID",
    "Product",
    "Qty",
    "Store",
    "Created At",
    "Product Data",
    "Meta Data",
    "Shipping Address",
    <div className="actions-wrap">Actions</div>,
  ];
  // Variables

  let newType = type === "online store" ? "online stores" : type;
  const totalByType = `${snakeCase(newType)}.doc_count`;
  const total = type ? get(agg, totalByType, 0) : totalProp;

  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Fragment>
      {isDesignLeader ? null : (
        <BulkActions
          idsChecked={idsChecked}
          onClose={() => handleCheckedAll(false)}
        >
          <BulkActionMapping ids={idsChecked} onCompleted={handleCompleted} />
        </BulkActions>
      )}
      <Container ref={wrapRef}>
        <DataTable
          rows={rows}
          columnContentTypes={colTypes}
          headings={headings}
          hideScrollIndicator
          verticalAlign="middle"
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </Fragment>
  );
}

const ShippingAddress = ({ data }) => {
  if (!data || typeof data !== "object") return null;
  const {
    address1,
    address2,
    city,
    company,
    country,
    postal_code,
    state,
    email,
    first_name,
    last_name,
    cpf_or_cnpj_number,
  } = data;
  const values = {
    Fullname: [first_name, last_name].filter(Boolean).join(" "),
    Email: email?.String || "",
    Company: company?.String || "",
    "Address 1": address1,
    "Address 2": address2?.String || "",
    City: city,
    State: state?.String || "",
    "Zipcode": postal_code?.String || postal_code || "",
    Country: country,
    "CPF/CNPJ number": cpf_or_cnpj_number || "",
  };

  return (
    <Stack vertical spacing="tight">
      {Object.entries(values).map(([key, value], index) => {
        if (!value) return null;
        return (
          <Stack spacing="extraTight" key={`${key}-${index}`}>
            <TextStyle variation="strong" children={key + ": "} />
            <span>{value}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};

export function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

function isOnlineStoreByOrder(order) {
  const platfrom = get(order, "store.platform", null);
  return ["woocommerce", "shopify", "shopbase"].includes(platfrom);
}

const MappingOrderTypeContext = React.createContext(null);
function MappingOrderTypeProvider({ children, ...props }) {
  const bag = React.useMemo(() => props, [props]);

  return (
    <MappingOrderTypeContext.Provider value={bag}>
      {children}
    </MappingOrderTypeContext.Provider>
  );
}

export function useMappingOrderType() {
  return React.useContext(MappingOrderTypeContext);
}
