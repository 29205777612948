import React from "react";
import { Thumbnail, Badge } from "@shopify/polaris";
import styled from "styled-components";

import woocommerceLogo from "../../assets/images/wooCommerce.png";
import shopifyLogo from "../../assets/images/shopify.png";
import ebayLogo from "../../assets/images/ebay-logo.png";
import amazonLogo from "../../assets/images/amazon-logo.png";
import etsyLogo from "../../assets/images/etsy-logo.png";
import shopbaseLogo from "../../assets/images/shopbase.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import tiktokLogo from "../../assets/images/tiktok-logo.png";

const Container = styled.div`
    .Polaris-Thumbnail {
        background: transparent;
        :after {
            box-shadow: none;
        }
    }
`;

export const ColStorePolaris = ({ store }) => {
    const { platform } = store;
    let srcLogoStore = {
        woocommerce: woocommerceLogo,
        shopify: shopifyLogo,
        ebay: ebayLogo,
        amazon: amazonLogo,
        shopbase: shopbaseLogo,
        etsy: etsyLogo,
        facebook: facebookLogo,
        tiktok: tiktokLogo,
    };
    return (
        <Container>
            <Thumbnail
                source={srcLogoStore[platform]}
                alt="Black choker necklace"
            />
            {store.title}
            {store.usingFakeVariants && (
                <p style={{ marginTop: ".5rem" }}>
                    <Badge status="info">Fake Variants</Badge>
                </p>
            )}
        </Container>
    );
};
