import { gql } from "apollo-boost";

export const GET_PRINTIFY_AVAIABLE_SHOPS = gql`
  query getPrintifyAvaiableShops($apiKey: String!) {
    getPrintifyAvaiableShops(apiKey: $apiKey) {
      id
      salesChannel
      title
    }
  }
`;

export const GET_PRINTIFY_PRODUCT_PRINT_PROVIDERS = gql`
  query getPrintifyProductPrintProviders($productID: String!) {
    getPrintifyProductPrintProviders(productID: $productID) {
      id
      title
    }
  }
`;

export const GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS = gql`
  query getPrintifyProductPrintProviderVariants(
    $productID: String!
    $printProviderID: String!
  ) {
    getPrintifyProductPrintProviderVariants(
      productID: $productID
      printProviderID: $printProviderID
    ) {
      id
      title
      variants {
        id
        title
        options
        placeholders
      }
    }
  }
`;

export const GET_DREAMSHIP_PRODUCT_PROVIDER_VARIANTS = gql`
  query getDreamshipProductProviderVariants($productBaseID: ID!) {
    getDreamshipProductProviderVariants(productBaseID: $productBaseID)
  }
`;

export const GET_CUSTOMCAT_PRODUCT_PROVIDER_VARIANTS = gql`
  query getCustomcatProductProviderVariants($productBaseID: ID!) {
    getCustomcatProductProviderVariants(productBaseID: $productBaseID)
  }
`;

export const GET_PRINTWAY_PRODUCT_DETAIL = gql`
  query getPrintwayProductDetail($productCode: String!) {
    getPrintwayProductDetail(productCode: $productCode)
  }
`;

export const GET_PRINTWAY_SHIPPING_METHODS = gql`
  query getPrintwayShippingMethods($variantSKUs: [String!]!) {
    getPrintwayShippingMethods(variantSKUs: $variantSKUs) {
      itemSKU
      shippingServices
    }
  }
`;

export const GET_CUSTOMCAT_VARIANTS_BY_FF_PRODUCT_ID = gql`
  query getCustomcatVariantsByFFProductId($id: String!) {
    getCustomcatVariantsByFFProductId(id: $id)
  }
`;

export const GET_GEARMENT_PRODUCTS_INFO = gql`
  query getGearmentProductsInfo {
    getGearmentProductsInfo
  }
`;

export const GET_DREAMSHIP_PRODUCT_INFO = gql`
  query getDreamshipProductInfo($id: String!) {
    getDreamshipProductInfo(id: $id)
  }
`;

export const GET_MERCHIZE_PRODUCT_INFO = gql`
  query GetMerchizeProductInfo($id: String!) {
    getMerchizeProductInfo(id: $id)
  }
`;

export const GET_TEEZILY_PRODUCT_DETAIL = gql`
  query getTeezilyProductDetail($reference: String!) {
    getTeezilyProductDetail(reference: $reference)
  }
`;

export const GET_MERCHIZE_PRODUCT_INFO_DB = gql`
  query getMerchizeProductInfoFromDB($id: String!) {
    getMerchizeProductInfoFromDB(id: $id) {
      id
      originId
      title
      description
      attributes {
        name
        slug
        options
      }
      variants {
        fulfillmentProductId
        salePrice
        regularPrice
        sellerPrice
        sorting
        attributes {
          name
          slug
          option
        }
      }
    }
  }
`;

export const GET_FULFILLMENTS = gql`
  query fulfillments {
    fulfillments {
      id
      name
    }
  }
`;

export const GET_SHIPPING_OPTION_BY_BASE = gql`
  query getShippingOptionByBase($productBaseID: ID!) {
    getShippingOptionByBase(productBaseID: $productBaseID) {
      id
      name
    }
  }
`;

export const GET_DREAMSHIP_SHOPS = gql`
  query getDreamshipShops {
    getDreamshipShops {
      id
      name
      key
    }
  }
`;

export const GET_BURGERPRINTS_PRODUCT = gql`
  query getBurgerPrintsProduct($shortCode: String!) {
    getBurgerPrintsProduct(shortCode: $shortCode)
  }
`;