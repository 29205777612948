import { Mutation } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormLayout,
  Modal,
  Page,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import authImage from "../../assets/images/etsy-app-oauth.png";
import { apolloClient } from "../../client";
import { ComponentLabelPolaris } from "../../components/shared/ComponentLabelPolaris";
import CopyBox from "../../components/shared/CopyBox";
import Error from "../../components/shared/Error";
import { AppContext } from "../../context";
import { isEmail, isPME_TEAM } from "../../helper";
import history from "../../history";
import { TEAM_ROLE, TEAM_ROLE_PARAMS } from "../../variable";

const ExtraInfo = React.lazy(() =>
  import("../../components/store/components/ExtraInfo"),
);

const Container = styled.div`
  .Polaris-Page-Header {
    padding-top: 0;
  }
  .actions {
    display: flex;
    margin: 10px 0;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;

const ModalContainer = styled.div`
  .image {
    display: flex;
    justify-content: center;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  .warning-box {
    background: rgb(233, 162, 173);
    padding: 10px;
  }
`;

const CREATE_SALE_CHANNEL_MUTATION = gql`
  mutation createAccountSaleChannel($input: NewAccount!) {
    createAccountSaleChannel(input: $input) {
      id
      title
      email
      platform
      apiKey
      usingEtsyAPI
      etsyStoreID
    }
  }
`;

class AddEtsyAccount extends Component {
  static contextType = AppContext;
  state = {
    name: "",
    email: "",
    error: null,
    errors: {
      name: null,
      email: null,
    },
    loading: false,
    usingEtsyAPI: false,
    showOauthLinkModal: false,
    agreeOAuthTerms: false,
    etsyOAuthUrl: "OAuth URL is generating...",
    active: false,
    msg: null,

    extraInfo: {
      friendly: "",
      url: "",
      emailContact: "",
      facebookLink: "",
      instagramLink: "",
      youtubeLink: "",
    },
  };

  timeRef = React.createRef(null);

  toggleActive = () => {
    this.setState((prev) => !prev.active);
  };

  componentWillUnmount() {
    clearTimeout(this.timeRef.current);
  }

  handleExtraChange = (value, id) => {
    this.setState((p) => ({
      ...p,
      extraInfo: { ...p.extraInfo, [id]: value },
    }));
  };

  render() {
    const {
      errors,
      name,
      email,
      usingEtsyAPI,
      agreeOAuthTerms,
      etsyOAuthUrl,
      extraInfo,
    } = this.state;
    const { currentUser } = this.context;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    const role = _.get(currentUser, "teamUser.role");
    if ([TEAM_ROLE.StoreManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }

    const isPMETeam = isPME_TEAM(currentUser);

    return (
      <Container>
        <Page
          breadcrumbs={[
            {
              content: "Etsy",
              onAction: () => {
                history.push(`/${currentParam}/stores/etsy`);
              },
            },
          ]}
          title="Add Etsy Account"
        >
          {this.state.active && this.state.msg ? (
            <Toast content={this.state.msg} onDismiss={this.toggleActive} />
          ) : null}
          <Mutation
            onError={(err) => {
              this.setState({ error: err, loading: false });
            }}
            mutation={CREATE_SALE_CHANNEL_MUTATION}
          >
            {(createAccountSaleChannel) => (
              <Card>
                <Card.Section>
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      if (!name.trim() && !email.trim()) {
                        this.setState({
                          errors: {
                            ...errors,
                            name: "Full Name is required!",
                            email: "Email is required!",
                          },
                        });
                        return;
                      } else {
                        if (!email.trim()) {
                          this.setState({
                            errors: {
                              ...errors,
                              email: "Email is required!",
                            },
                          });
                          return;
                        }
                        if (!name.trim()) {
                          this.setState({
                            errors: {
                              ...errors,
                              name: "Store Name is required!",
                            },
                          });
                          return;
                        }
                      }
                      // handle submit add store
                      this.setState(
                        {
                          loading: true,
                          msg: null,
                          active: true,
                        },
                        () => {
                          createAccountSaleChannel({
                            variables: {
                              input: {
                                platform: "etsy",
                                name: name,
                                email: email,
                                usingEtsyAPI: usingEtsyAPI,
                                extraInfo: isPMETeam
                                  ? {
                                      ...extraInfo,
                                    }
                                  : undefined,
                              },
                            },
                            update: (
                              proxy,
                              { data: { createAccountSaleChannel } },
                            ) => {
                              this.setState({
                                loading: false,
                                msg: "Create Etsy account success.",
                              });

                              if (
                                createAccountSaleChannel &&
                                "etsy" == createAccountSaleChannel.platform &&
                                createAccountSaleChannel.usingEtsyAPI
                              ) {
                                this.setState({ showOauthLinkModal: true });
                                try {
                                  apolloClient
                                    .mutate({
                                      mutation: gql`
                                        mutation generateEtsyOAuthURL(
                                          $etsyStoreName: String!
                                        ) {
                                          generateEtsyOAuthURL(
                                            etsyStoreName: $etsyStoreName
                                          )
                                        }
                                      `,
                                      variables: {
                                        etsyStoreName: name,
                                      },
                                    })
                                    .then((res) => {
                                      if (
                                        res &&
                                        res.data &&
                                        res.data.generateEtsyOAuthURL
                                      ) {
                                        this.setState({
                                          etsyOAuthUrl:
                                            res.data.generateEtsyOAuthURL,
                                        });
                                      }
                                    })
                                    .catch((ex) => {});
                                } catch (ex) {}
                              } else {
                                this.timeRef.current = setTimeout(() => {
                                  history.push(`/${currentParam}/stores/etsy`);
                                }, 1500);
                              }
                            },
                          });
                        },
                      );
                    }}
                  >
                    <Card sectioned title="General">
                      <FormLayout>
                        <Error error={this.state.error} />
                        <Checkbox
                          label="Would you like to use Etsy API in this store?"
                          checked={usingEtsyAPI}
                          onChange={(newVal) => {
                            this.setState({ usingEtsyAPI: newVal });
                          }}
                        />
                        <div>
                          <ComponentLabelPolaris label="Store name" required />
                          <TextField
                            onChange={(value) => {
                              let newError = { ...errors, name: null };
                              if (!value.trim()) {
                                newError = {
                                  ...errors,
                                  name: "Store Name is required!",
                                };
                              }
                              this.setState({
                                name: value,
                                errors: newError,
                              });
                            }}
                            value={name}
                            error={errors.name}
                            placeholder="Enter store name"
                          />
                        </div>
                        <div>
                          <ComponentLabelPolaris label="Store Email" required />
                          <TextField
                            onChange={(value) => {
                              if (!isEmail(value)) {
                                this.setState({
                                  email: value,
                                  errors: {
                                    ...errors,
                                    email: "Invalid email address!",
                                  },
                                });
                              } else {
                                this.setState({
                                  email: value,
                                  errors: {
                                    ...errors,
                                    email: null,
                                  },
                                });
                              }
                            }}
                            value={email}
                            error={errors.email}
                            placeholder="Enter store email"
                            // label={"Email(*)"}
                          />
                        </div>
                      </FormLayout>
                    </Card>

                    {isPMETeam ? (
                      <React.Suspense fallback={null}>
                        <ExtraInfo
                          values={extraInfo}
                          onChange={this.handleExtraChange}
                        />
                      </React.Suspense>
                    ) : null}

                    <div className={"actions"}>
                      <Button
                        onClick={() => {
                          history.push(`/${currentParam}/stores/etsy`);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button primary submit loading={this.state.loading}>
                        Add Account
                      </Button>
                    </div>
                  </Form>
                </Card.Section>
              </Card>
            )}
          </Mutation>

          <Modal
            open={this.state.showOauthLinkModal}
            onClose={() => {
              this.setState({ showOauthLinkModal: false });
              history.push(`/${currentParam}/stores/etsy`);
            }}
            title="OAuth Your Etsy Account"
          >
            <ModalContainer>
              <Modal.Section>
                <TextContainer>
                  <h2>
                    Manage and fulfill your Etsy orders from all your accounts
                    at once with MerchBridge
                  </h2>
                  <div className={"image"}>
                    <img src={authImage} alt={""} />
                  </div>
                  <div className={"warning-box"}>
                    <p>
                      Warning: To protect your account from being suspended by
                      Etsy, Please:
                    </p>
                    <ul>
                      <li>Read and follow the documentation carefully</li>
                      <li>
                        Only use the Auth Etsy Account URL from the PC or VPS of
                        the Etsy account that you want to connect
                      </li>
                    </ul>
                  </div>
                  <Checkbox
                    label="I have read and agreed with the documentation."
                    checked={agreeOAuthTerms}
                    onChange={(checked) => {
                      this.setState({ agreeOAuthTerms: checked });
                    }}
                  />
                  {agreeOAuthTerms && <CopyBox text={etsyOAuthUrl} />}
                </TextContainer>
              </Modal.Section>
            </ModalContainer>
          </Modal>
        </Page>
      </Container>
    );
  }
}

AddEtsyAccount.propTypes = {};

export default AddEtsyAccount;
