import { gql } from "apollo-boost";

export const GET_FULFILLMENTS = gql`
  fragment cat on Category {
    id
    name
    description
    image {
      id
      name
      thumbnailUrl
      url
    }
  }
  query fulfillments($filter: TeamUserFilter) {
    fulfillmentConfigs {
      id
      fulfillmentId
    }
    fulfillments {
      id
      name
      slug
      products {
        title
        originId
        status
        attributes {
          name
          slug
          options
        }
        variants {
          regularPrice
          salePrice
          sellerPrice
          fulfillmentProductId
          sorting
          attributes {
            name
            slug
            option
          }
        }
      }
    }
    productBaseCategories {
      ...cat
      children {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
            }
          }
        }
      }
    }
    teamMembers(filter: $filter) {
      hits {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    suppliers {
      id
      firstName
      lastName
    }
    carriers {
      id
      name
      code
    }
  }
`;

export const GET_PRODUCT_BASES = gql`
  query productBases($filter: ProductBaseFilter) {
    productBases(filter: $filter) {
      total
      nodes {
        id
        title
        baseCostDescription
        status
        images {
          id
          name
          url
          thumbnailUrl
        }
        suppliers {
          id
          firstName
          lastName
        }
        supplierPricing {
          id
          price
          userId
        }
        fulfillment {
          fulfillmentId
          fulfillmentTitle
        }
      }
    }
  }
`;

export const GET_PRODUCT_BASE_BY_ID = gql`
  query productBaseByID($id: ID!) {
    productBaseHasProduct(id: $id)
    productBaseByID(id: $id) {
      id
      title
      niceName
      slug
      marketplaceIdentifyKey
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      kpi
      passOnHold
      hasAdditionalShipping
      requiredDesign
      mockupTemplates {
        id
        productBaseID
        originTemplateID
        sorting
        isDefault
      }
      colorCodes {
        name
        value
        patternImageId
        patternImage {
          id
          url
          name
          thumbnailUrl
        }
      }
      fulfillment {
        fulfillmentId
        productId
        presetId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
        default
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        id
        extraFee
        image {
          id
          thumbnailUrl
          url
        }
        name
        displayName
        description
        productBaseVariants {
          id
          attributes {
            name
            slug
            option
          }
        }
        artworkGuidelines {
          id
          file {
            id
            name
          }
          description
        }
        resize
        position
        scalablePressCustomize
      }
      variants {
        id
        fulfillmentProductId
        fulfillmentSku
        fulfillmentShippingCost
        fulfillmentShippingInternationalCost
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          id
          userId
          price
        }
        carrierPricing {
          id
          carrierId
          price
          default
          usingAdditionalPrice
          additionalPriceType
          additionalPrice
        }
        regularPrice
        salePrice
        sellerPrice
      }
      status
      customcatShiping {
        isSingleShipment
        usFirstFee
        usAdditionalFee
        canadaFirstFee
        canadaAdditionalFee
        internationalFirstFee
        internationalAdditionalFee
        shippingRate
      }
      scalablePressType
      scalableOriginID
      scalablePressDesignType
      nasFileNameSyntax
      isDigitalProduct
      printifyPrintProviderID
      printifyPrintProviderName
      printifyBlueprintID
    }
  }
`;

export const CREATE_PRDUCT_BASE = gql`
  mutation createProductBase($input: NewProductBase!) {
    createProductBase(input: $input) {
      id
      title
      niceName
      slug
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      mockupTemplates {
        id
        productBaseID
        originTemplateID
        isDefault
      }
      fulfillment {
        fulfillmentId
        productId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        id
        image {
          id
          thumbnailUrl
          url
        }
        name
        description
        productBaseVariants {
          id
          attributes {
            name
            slug
            option
          }
        }
      }
      variants {
        id
        fulfillmentProductId
        fulfillmentShippingCost
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          id
          userId
          price
        }
        carrierPricing {
          id
          carrierId
          price
          default
        }
        regularPrice
        salePrice
        sellerPrice
        sorting
        status
      }
      status
      isDigitalProduct
    }
  }
`;

export const UPDATE_PRODUCT_BASE = gql`
  mutation updateProductBase($input: EditProductBase!) {
    updateProductBase(input: $input) {
      id
      title
      slug
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      fulfillment {
        fulfillmentId
        productId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
        default
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        image {
          id
          thumbnailUrl
          url
        }
        name
        description
      }
      variants {
        id
        fulfillmentProductId
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          userId
          price
        }
        carrierPricing {
          carrierId
          price
          default
        }
        regularPrice
        salePrice
        sellerPrice
      }
      status
    }
  }
`;
