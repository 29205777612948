import { useMutation } from "@apollo/react-hooks";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import _, { get } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import noImg from "../../assets/images/unnamed.jpeg";
import { AppContext } from "../../context";
import { arrInvalid, objectInvalid, reducerFn, toSlug } from "../../helper";
import { useProductVariants } from "../../hooks/useProductVariants";
import useToggle from "../../hooks/useToggle";
import {
  META_DATA,
  META_DATA_KEY_MB,
  PLATFORMS,
  PLATFORM_ETSY,
  PLATFORM_FACEBOOK,
  TEAM_ROLE,
  TEAM_ROLE_PARAMS,
  USER_ROLE,
} from "../../variable";
import {
  getPrintifyShippingMethod,
  getPrintwayShippingMethods,
} from "../base/utils";
import { GEN_THUMBNAIL_IMAGE } from "../seller/ModalImageClaimPolaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { ModalImagePolaris } from "../shared/ModalImagePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { MappingVariantsEPPolaris } from "./MappingVariantsEPPolaris";
import { ShippingService } from "./ShippingService";
import { checkFulfillment } from "../order/ManualOrder/components/Form";

const Container = styled.div`
  padding-top: 1.6rem;

  .selected-wrap {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;

    .design-positions-wrap {
      flex: 1 1 auto;
      margin-top: -0.6rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.6rem 2rem;

      .Polaris-Tabs__Title {
        padding-bottom: 1rem;
        padding-top: 0;
      }
    }

    .design-position-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.875rem;

      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
          rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
        border-radius: 5px;
      }
    }
  }

  .selected-info {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: calc(50% - 1.5rem);

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
      border-radius: 5px;
    }
    .image-wrap {
      width: 120px;
      height: 120px;
      position: relative;

      .action-remove {
        position: absolute;
        right: 0;
        display: inline-block;
        top: 3px;
      }
    }
  }

  .personalized-wrap {
    > label {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .input-group {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2rem;
  }

  .personalized-wrap {
    .label {
      height: 23px;
      font-weight: 500;
      display: inline-flex;
      position: relative;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
    .list-item {
      list-style-type: none;
      padding: 0 0 0 0.5rem;
      color: rgba(0, 0, 0, 0.85);
      .key {
        font-weight: 500;
      }
    }
  }

  .loading-wrap {
    margin: 0 -3rem;
  }
`;

export const MapOrderEPFormPolaris = ({
  data,
  changeSearch,
  suggestMappingProduct,
  item,
  onSubmit,
  loadingMutation,
  loading,
  removeLabel,
  productNeedMapping,
  isNeedVariantMapping,
  existProductID,
  canSetExistProduct,
  handleRemoveCurrentProduct,
  setSearch,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [fieldValues, setFieldValues] = useState([]);
  const [mappingVariant, setMappingVariant] = useState([]);
  const [queryChange, setQueryChange] = useState(false);
  const [placeholder, setPlaceholder] = useState("Search product");
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useContext(AppContext);
  const userRole = _.get(currentUser, "roles", []);
  const teamRole = _.get(currentUser, "teamUser.role", null);
  const [shippingOption, setShippingOption] = useState(null);
  const [forceMapping, setForceMapping] = useState(false);
  const [mapToVariantId, setMapToVariantId] = useState(null);
  const [shippingOptionsPw, setShippingOptionPw] = useState([]);
  const [state, setState] = React.useReducer(reducerFn, {
    autoMapLaterOrders: true,
  });

  const [shippingMethods, setShippingMethods] = React.useReducer(reducerFn, {
    isPrintway: false,
    isPrintify: false,
    options: [],
  });

  // Hooks
  const [getVariants, loadingVariant] = useProductVariants();

  // Context
  // const ctx = useMappingOrderType();

  // Gen thumbnail
  const [genThumbnail] = useMutation(GEN_THUMBNAIL_IMAGE, {
    onError: () => {},
  });
  const handleImgErr = useCallback(
    (p) => {
      const image = p?.images?.length > 0 ? p.images[0]?.file : null;
      if (image != null) {
        genThumbnail({
          variables: {
            files: [image.id],
            fileType: "id",
          },
        });
      }
    },
    [genThumbnail],
  );

  // exist product id
  useEffect(() => {
    const nodes = data?.products?.nodes || [];
    let existProduct = nodes.find((i) => i?.id === existProductID);
    if (canSetExistProduct && existProduct != null) {
      (async function () {
        existProduct = await getVariants(existProduct);
        setSelectedItem(existProduct);
      })();
    }
  }, [existProductID, data, canSetExistProduct]);

  // Need mapping
  useEffect(() => {
    let product = productNeedMapping?.product;
    if (isNeedVariantMapping && product != null) {
      (async function () {
        product = await getVariants(product);
        setSelectedItem(product);
      })();
    }
  }, [productNeedMapping, isNeedVariantMapping]);

  let showUrlProduct =
    (userRole.includes(USER_ROLE.Seller) &&
      [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.MarketplaceManager,
      ].includes(teamRole)) ||
    userRole.includes("Administrator");

  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
    currentParam = TEAM_ROLE_PARAMS.StoreManager;
  }

  if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
    currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
  }

  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    let formatData = [];
    if (
      data &&
      data.products &&
      data.products.nodes &&
      data.products.nodes.length
    ) {
      let newData = data.products.nodes;
      if (suggestMappingProduct) {
        newData = [
          suggestMappingProduct,
          ...newData.filter((p) => p.id !== suggestMappingProduct.id),
        ];
        setSelectedOptions([suggestMappingProduct.id]);
        let input = suggestMappingProduct.title.trim();
        setPlaceholder(input);
      }
      formatData = newData.map((p) => {
        let ffTitle = "";
        if (p.productBases && p.productBases.length) {
          ffTitle = p.productBases
            .map((pb) => {
              let fTitle =
                pb.fulfillment && pb.fulfillment.fulfillmentTitle
                  ? pb.fulfillment.fulfillmentTitle
                  : "";
              return `${fTitle ? fTitle + " - " : "Merch Bridge - "}${
                pb.title
              }`;
            })
            .filter(Boolean)
            .join(", ");
        }
        let labelMarkup = (
          <div className="label-wrap">
            {p.images && p.images.length ? (
              <img
                alt=""
                src={
                  (p.images[0].file && p.images[0].file.thumbnailUrl) ||
                  (p.images[0].file && p.images[0].file.url)
                }
                onError={() => handleImgErr(p)}
              />
            ) : null}
            <div className="title">{p.title && p.title.trim()}</div>
            <div>{`(${ffTitle ? ffTitle : "Merch Bridge"})`}</div>
            <div>{`(${p.sku})`}</div>
          </div>
        );
        return {
          value: p.id,
          label: labelMarkup,
        };
      });
    }
    setOptions(formatData);
    if (removeLabel) {
      setInputValue(null);
    }
  }, [data, suggestMappingProduct, removeLabel]);

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      let selected = selectedOptions[0];
      let newData =
        data?.products?.nodes?.length > 0 ? data.products.nodes : [];

      if (suggestMappingProduct) {
        newData = [
          suggestMappingProduct,
          ...newData.filter((p) => p.id !== suggestMappingProduct.id),
        ];
      }
      let selectedItem = newData.find((p) => p.id === selected);

      (async function (selectedItem) {
        if (selectedItem) {
          const newSelectedItem = await getVariants(selectedItem);
          setSelectedItem(newSelectedItem);
        }
      })(selectedItem);
    }
  }, [data, selectedOptions, suggestMappingProduct]);

  const handleFieldValue = useCallback(() => {
    let { fields } = selectedItem;
    let personalized = _.get(item, "personalized");

    let fieldValues =
      fields && fields.length > 0
        ? fields.map((i) => {
            let field = {
              id: i.id,
              title: i.title,
              value: "",
            };

            let newField = "";
            if (personalized && personalized.length > 0) {
              personalized.forEach((p) => {
                if (
                  field.title &&
                  field.title.toLowerCase().trim() ===
                    p.name.toLowerCase().trim()
                ) {
                  newField = p.value;
                }
              });
            }
            field.value = newField;
            return field;
          })
        : [];
    setFieldValues(() => [...fieldValues]);
  }, [selectedItem, item]);

  useEffect(() => {
    if (selectedItem) {
      handleFieldValue();
    }
  }, [selectedItem, handleFieldValue, item]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
    setQueryChange(() => true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (changeSearch) {
        changeSearch(value);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelection = useCallback(
    (selected) => {
      setShippingOption(null);
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        let children =
          matchedOptions && _.get(matchedOptions, "label.props.children");
        let label = "";
        for (let i = 0; i < children.length; i++) {
          if (
            children[i] &&
            children[i].props &&
            children[i].props.className &&
            "title" === children[i].props.className
          ) {
            label = children[i].props.children;
          }
        }
        return label;
      });
      setSelectedOptions(selected);
      setPlaceholder(_.head(selectedValue));
      setInputValue(null);
      setShippingOptionPw([]);
    },
    [options],
  );

  const toggleShowModal = useCallback(
    () => setShowModal((prevState) => !prevState),
    [],
  );

  const handleVariantChange = useCallback(
    (value) => {
      setMappingVariant(value);
      setShippingOption(null);

      const variantID = get(value, "[0].productVariantId");
      const { fulfillmentSku, baseID } = getVariantInfo(
        variantID,
        selectedItem?.variants,
      );

      const { isPrintify, isPrintway } = shippingMethods;
      if (isPrintway) {
        if (fulfillmentSku) {
          (async (fulfillmentSku) => {
            let options = [];
            const shippingServices = await getPrintwayShippingMethods([
              fulfillmentSku,
            ]);
            if (!arrInvalid(shippingServices)) {
              const matchById = shippingServices.find(
                (item) => item.itemSKU === fulfillmentSku,
              );
              if (!objectInvalid(matchById)) {
                if (!arrInvalid(matchById.shippingServices)) {
                  options = matchById.shippingServices.map((method) => ({
                    value: method,
                    label: method,
                  }));
                }
              }
            }

            setShippingMethods({ options });
          })(fulfillmentSku);
        } else {
          setShippingMethods({ options: [] });
        }
      } else if (isPrintify) {
        if (baseID) {
          (async function (baseID) {
            const shippingServices = await getPrintifyShippingMethod(baseID);
            if (shippingServices.length > 0) {
              setShippingMethods({ options: shippingServices });
            }
          })(baseID);
        } else {
          setShippingMethods({ options: [] });
        }
      }
    },
    [selectedItem, shippingMethods],
  );

  const textField = (
    <Autocomplete.TextField
      label="Choose a product"
      labelHidden
      placeholder={placeholder}
      value={inputValue}
      onChange={handleInputChange}
    />
  );

  let urlSelectedItem = "";
  if (selectedItem && selectedItem.images && selectedItem.images.length) {
    // Main image
    const mainImageId = selectedItem?.mainImageId;
    const images = selectedItem?.images;
    let mainImages = [];
    if (mainImageId || mainImageId !== "undefined") {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.file?.id === mainImageId)
              .map((item) => item.file)
          : [];
    }
    if (mainImages.length === 0) {
      mainImages =
        images?.length > 0
          ? images.filter(Boolean).map((item) => item.file)
          : [];
    }

    let image = _.head(mainImages);
    urlSelectedItem = image?.url
      ? image.url
      : image?.thumbnailUrl
      ? image.thumbnailUrl
      : null;
  }

  const needVariantMapping = productNeedMapping?.originVariantNeedMapping;
  // const disabledBtn =
  //     !selectedItem ||
  //     !(item && item.variants) ||
  //     !(mappingVariant.length === (item.variants && item.variants.length)) ||
  //     (needVariantMapping?.length
  //         ? needVariantMapping.length !== mappingVariant.length
  //         : false);

  // const mergedForceMapping = React.useMemo(
  //     () => ctx?.isOnlineStore && !!forceMapping,
  //     [forceMapping, ctx?.isOnlineStore]
  // );

  const disabled = React.useMemo(() => {
    let check1 = forceMapping
      ? true
      : mappingVariant.length === (item.variants && item.variants.length);

    return (
      !selectedItem ||
      !(item && item.variants) ||
      !check1 ||
      (needVariantMapping?.length
        ? needVariantMapping.length !== mappingVariant.length
        : false)
    );
  }, [selectedItem, item, mappingVariant, needVariantMapping, forceMapping]);

  const platforms = [
    PLATFORMS.Amazon,
    PLATFORMS.Shopify,
    PLATFORMS.WooCommerce,
    PLATFORM_ETSY,
    PLATFORM_FACEBOOK,
  ];
  const platform = _.get(item, "store.platform");
  const showPersonalizedInfo = platforms.includes(platform);
  const showAutoMap = [PLATFORMS.Tiktok].includes(platform);

  const handleSubmit = useCallback(() => {
    let mappingOrderId = item.id;
    let productId = selectedItem && selectedItem.id;
    let newFieldValues =
      fieldValues && fieldValues.length > 0
        ? fieldValues.map((i) => ({
            fieldId: i.id,
            value: i.value,
            customValue: true,
            //   customValue: i?.value?.length > 0 ? true : false,
          }))
        : [];

    let input = {
      mappingOrderId,
      productId,
      mappingVariant: forceMapping ? [] : mappingVariant,
      fieldValues: newFieldValues,
      shippingOption,
      forceMapping,
      mapToVariantId: forceMapping ? mapToVariantId : undefined,
      autoMapLaterOrders: showAutoMap ? state.autoMapLaterOrders : undefined,
    };
    if (onSubmit) {
      onSubmit(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item,
    selectedItem,
    mappingVariant,
    fieldValues,
    shippingOption,
    forceMapping,
    mapToVariantId,

    state.autoMapLaterOrders,
    showAutoMap,
  ]);

  let urlProduct = null;
  let originalId = selectedItem ? selectedItem.id : null;
  let originalSku = selectedItem ? selectedItem.sku : null;
  if (selectedItem && selectedItem.productBases) {
    const link =
      selectedItem.productBases.length > 1
        ? `/${currentParam}/products/campaign`
        : `/${currentParam}/products/edit`;
    urlProduct = `${link}/${originalId}`;
  }

  // Personalized
  const pt = new RegExp(META_DATA_KEY_MB, "i");
  const metaData = item?.metaData
    ? item.metaData
        .filter((i) => pt.test(i.key))
        .map((i) => {
          if (i?.value?.length && Array.isArray(i.value)) {
            return i.value.map((item) => {
              return {
                name: item.key,
                value: item.value,
              };
            });
          }
          return null;
        })
        .filter(Boolean)
        .flat()
    : [];

  const personalizedMarkup =
    item?.personalized?.length > 0
      ? item.personalized
      : metaData?.length > 0
      ? metaData
      : [];

  //selectedItem
  const fulfillment = useMemo(() => {
    if (!selectedItem) return null;

    let base =
      selectedItem.productBases?.length > 0
        ? selectedItem.productBases.find((f) => f.fulfillment)
        : null;
    let result;
    if (base != null) {
      result = {
        slug: toSlug(base.fulfillment.fulfillmentTitle),
      };
    }

    const fulfillmentInfo = checkFulfillment(result?.slug);
    setShippingMethods(fulfillmentInfo);
    return result;
  }, [selectedItem]);

  const handleRemoveProduct = useCallback(() => {
    handleRemoveCurrentProduct();
    setSelectedItem(null);
    setSearch(null);
  }, [handleRemoveCurrentProduct, setSelectedItem, setSearch]);

  const handleAutoMapLaterOrders = React.useCallback((val) => {
    setState({ autoMapLaterOrders: val });
  }, []);

  return (
    <Container>
      {loading && !queryChange ? (
        <div className="loading-wrap">
          <SkeletonPagePolaris />
        </div>
      ) : (
        <FormLayout>
          {isNeedVariantMapping ? null : (
            <>
              <ComponentLabelPolaris label="Choose a product" />
              {!canSetExistProduct && (
                <Autocomplete
                  options={options}
                  onSelect={handleSelection}
                  selected={selectedOptions}
                  textField={textField}
                  emptyState={<span>No item found!</span>}
                  id="mp__choose-product"
                  willLoadMoreResults
                />
              )}
            </>
          )}

          {selectedItem && (
            <SelectedItem
              urlSelectedItem={urlSelectedItem}
              showUrlProduct={showUrlProduct}
              urlProduct={urlProduct}
              selectedItem={selectedItem}
              toggleShowModal={toggleShowModal}
              handleRemoveProduct={handleRemoveProduct}
            />
          )}
          <MappingVariantsEPPolaris
            item={item}
            product={selectedItem}
            onChange={handleVariantChange}
            isNeedVariantMapping={isNeedVariantMapping}
            productNeedMapping={productNeedMapping}
            forceMapping={forceMapping}
            setForceMapping={setForceMapping}
            setMapToVariantId={setMapToVariantId}
            loadingVariant={loadingVariant}
            autoMapMarkup={
              showAutoMap && (
                <Checkbox
                  label="Auto mapping for later orders"
                  checked={!!state.autoMapLaterOrders}
                  onChange={handleAutoMapLaterOrders}
                />
              )
            }
          />
          {showPersonalizedInfo && personalizedMarkup?.length > 0 ? (
            <div className="personalized-wrap">
              <label className="label">Personalized info</label>
              <ul className="list-item">
                {personalizedMarkup.map((md, idx) => {
                  let hasImage = _.includes(md.value, META_DATA);
                  return (
                    <li key={`personalized-${idx}`}>
                      <span className="key" style={{ wordBreak: "break-all" }}>
                        {md.name && md.name.trim()}: {""}
                      </span>{" "}
                      {hasImage ? (
                        <div>
                          <CustomLinkPolaris href={md.value}>
                            <img
                              src={md.value}
                              alt=""
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: "cover",
                              }}
                            />
                          </CustomLinkPolaris>
                        </div>
                      ) : (
                        <span style={{ wordBreak: "break-all" }}>
                          {md.value}
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          {fieldValues && fieldValues.length > 0 ? (
            <div className="personalized-wrap">
              <>
                <label>Personalized values</label>
                <div className="input-group">
                  {fieldValues.map((v, idx) => (
                    <div key={idx}>
                      <TextField
                        label={v.title}
                        value={v.value}
                        onChange={(e) => {
                          let input = e;
                          v["value"] = input;
                          fieldValues.map((i, index) =>
                            index === idx ? v : i,
                          );
                          setFieldValues(() => [...fieldValues]);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            </div>
          ) : null}
          <ShippingService
            item={item}
            fulfillment={fulfillment}
            shippingOption={shippingOption}
            setShippingOption={setShippingOption}
            options={shippingMethods.options}
          />
          <div>
            <Button
              disabled={disabled}
              primary
              fullWidth
              children={"Submit"}
              loading={loadingMutation}
              onClick={handleSubmit}
            />
          </div>
        </FormLayout>
      )}
      <ModalImagePolaris
        toggleShowModal={toggleShowModal}
        open={showModal}
        source={urlSelectedItem}
        title={originalSku}
      />
    </Container>
  );
};

export function SelectedItem({
  urlSelectedItem,
  showUrlProduct,
  urlProduct,
  selectedItem,
  toggleShowModal,
  handleRemoveProduct,
}) {
  // Props
  const itemInfo = useMemo(() => {
    let pBase =
      selectedItem.productBases?.length > 0
        ? selectedItem.productBases.map((i) => i.title).join(", ")
        : [];
    let skuSelectedItem = selectedItem.sku;
    const designPositions =
      selectedItem.designPositions?.length > 0
        ? selectedItem.designPositions
            .map((dp) => {
              if (dp.file != null) {
                return {
                  content: dp.designPosition?.name,
                  file: dp.file,
                  id: dp.id,
                  panelID: dp.id,
                };
              }

              return null;
            })
            .filter(Boolean)
        : [];

    return {
      pBase,
      skuSelectedItem,
      designPositions,
    };
  }, [selectedItem]);

  return (
    <div className="selected-wrap">
      <div className="selected-info">
        <div className="image-wrap">
          {urlSelectedItem && (
            <img
              alt=""
              src={urlSelectedItem}
              onClick={toggleShowModal}
              style={{ cursor: "pointer" }}
            />
          )}
          <span className="action-remove">
            <Button
              size="slim"
              icon={CircleCancelMinor}
              onClick={handleRemoveProduct}
              plain
            />
          </span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span style={{ fontWeight: "500" }}>Product Title:</span>{" "}
          {selectedItem?.title?.length > 0 ? (
            <span>{selectedItem.title}</span>
          ) : null}
        </div>
        <span>
          <span style={{ fontWeight: "500" }}>Product Bases:</span>{" "}
          {itemInfo.pBase}
        </span>
        <span>
          <span style={{ fontWeight: "500" }}>SKU:</span>{" "}
          {showUrlProduct ? (
            <CustomLinkPolaris href={urlProduct}>
              {itemInfo.skuSelectedItem}
            </CustomLinkPolaris>
          ) : (
            <span>{itemInfo.skuSelectedItem}</span>
          )}
        </span>
      </div>
      <div className="design-positions-wrap">
        {(itemInfo.designPositions || []).map((item, index) => {
          return (
            <DesignPosition key={`design-position-${index}`} item={item} />
          );
        })}
      </div>
    </div>
  );
}

function DesignPosition({ item }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  const dpInfo = useMemo(() => {
    if (item?.file) {
      const file = item.file;
      const thumbnailUrl = file.thumbnailUrl ? file.thumbnailUrl : file.url;
      const url = file.url ? file.url : file.thumbnailUrl;
      const title = item.content;

      return {
        thumbnailUrl,
        url,
        title,
      };
    }

    return {};
  }, [item]);

  return (
    <div className="design-position-wrap">
      <span>{dpInfo.title}</span>
      <img
        src={dpInfo.thumbnailUrl || dpInfo.url || noImg}
        alt=""
        style={{ cursor: "pointer" }}
        onClick={() => toggleOpen()}
      />
      <ModalImagePolaris
        toggleShowModal={toggleOpen}
        open={open}
        source={dpInfo.url || dpInfo.thumbnailUrl}
        title={dpInfo.title}
      />
    </div>
  );
}

function getVariantInfo(baseVariantID, variants) {
  if (!baseVariantID || baseVariantID.length == 0) return {};
  if (arrInvalid(variants)) return {};

  const match = variants.find((i) => i.id === baseVariantID);
  if (!match) return {};

  const baseID = get(match, "productBaseVariant.productBase.id");
  const fulfillmentSku = get(match, "productBaseVariant.fulfillmentSku");

  return { fulfillmentSku, baseID };
}
