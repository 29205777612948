import { Spinner } from "@shopify/polaris";
import React from "react";

function SuspenseComp({ children, fallback }) {
  return (
    <React.Suspense fallback={fallback || <Spinner size="small" />}>
      {children}
    </React.Suspense>
  );
}

export default SuspenseComp;
