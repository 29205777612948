import { useMutation, useQuery } from "@apollo/react-hooks";
import { notification, Skeleton } from "antd";
import get from "lodash/get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CUSTOM_FULFILLMENT } from "../../../constants";
import { handleError } from "../../../helper";
import PageTitle from "../../shared/PageTitle";
import {
  GET_FULFILLMENTS,
  GET_PRODUCT_BASE_BY_ID,
  UPDATE_PRODUCT_BASE,
} from "./graphqls";
import ProductBaseForm from "./ProductBaseForm";
import ProductBaseFormV2 from "./ProductBaseFormV2";

export function EditProductBase() {
  const { id } = useParams();

  // State
  const [value, setValue] = useState(null);

  // Queries
  const { loading, data, error, refetch } = useQuery(GET_PRODUCT_BASE_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  const { loading: loading2, data: data2 } = useQuery(GET_FULFILLMENTS, {
    variables: {
      filter: {
        limit: 200,
        offset: 0,
        role: "Supplier",
        status: true,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [update, { loading: loadingU }] = useMutation(UPDATE_PRODUCT_BASE, {
    onError: (err) => {
      notification.error({ message: handleError(err.toString()) });
    },
    onCompleted: () => {
      notification.success({
        message: "Product base has been saved.",
      });
      if (refetch) refetch();
    },
  });

  useEffect(() => {
    const value = data?.productBaseByID;
    if (value) {
      value["designPositions"] = getDesignPositions(value.designPositions);

      setValue(value);
    }
  }, [data]);

  const handleSubmit = useCallback(
    (values) => {
      if (id == null) return;
      const newC =
        Array.isArray(values.categories) === true
          ? values.categories
          : [values.categories];
      const removeTypeNames = [
        "supplierPricing",
        "carrierPricing",
        "attributes",
        "designPositions",
        "variants",
      ];
      for (let key of removeTypeNames) {
        if (values[key] && values[key].length > 0) {
          values[key] = values[key].map(({ __typename, ...v }) => {
            if (key === "designPositions") {
              if (v.image) {
                v.image = v.image.id;
              }

              if (v.productBaseVariants?.length > 0) {
                v.productBaseVariants = v.productBaseVariants.map(
                  ({ __typename, ...b }) => {
                    const attributes =
                      b.attributes?.length > 0
                        ? b.attributes.map(({ __typename, ...a }) => a)
                        : undefined;
                    return {
                      ...b,
                      attributes,
                    };
                  },
                );
              }
            }

            if (key === "variants") {
              const subKeys = [
                "attributes",
                "supplierPricing",
                "carrierPricing",
              ];

              for (let subKey of subKeys) {
                if (v[subKey] && v[subKey].length > 0) {
                  v[subKey] = v[subKey].map(({ __typename, ...s }) => s);
                }
              }
            }

            return v;
          });
        }
      }

      update({
        variables: {
          input: { ...values, id, categories: newC },
        },
      });
    },
    [update, id],
  );

  const mergeLoading = useMemo(() => loading || loading2, [loading, loading2]);

  const teamMembers = useMemo(() => {
    return get(data2, "teamMembers.hits", []).map(({ user }) => user);
  }, [data2]);

  const ffIds = (data2?.fulfillmentConfigs || []).map(
    ({ fulfillmentId }) => fulfillmentId,
  );
  const fulfillments = (data2?.fulfillments || []).filter(
    ({ id, slug }) => ffIds.includes(id) || slug === CUSTOM_FULFILLMENT,
  );

  return (
    <div>
      <PageTitle
        subTitle="Product bases"
        title="Edit product base"
        link="/seller/product-bases"
      />
      {mergeLoading ? (
        <Skeleton active />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : data ? (
        // <ProductBaseForm
        <ProductBaseFormV2
          hasProduct={data.productBaseHasProduct}
          loading={loadingU}
          fulfillments={fulfillments}
          categories={data2?.productBaseCategories}
          suppliers={data2?.suppliers}
          carriers={data2?.carriers}
          teamMembers={teamMembers || []}
          value={value}
          isEditProductBase
          onSubmit={handleSubmit}
        />
      ) : null}
    </div>
  );
}

function getDesignPositions(data) {
  if (!data || data.length === 0) return [];
  return data.map(({ scalablePressCustomize, ...rest }) => {
    const { dimensions, position } = scalablePressCustomize || {};
    const { width, height } = dimensions || {};
    const { horizontal, offset } = position || {};
    const { top, bottom } = offset || {};

    if (
      scalablePressCustomize &&
      Object.keys(scalablePressCustomize).length > 0
    ) {
      return {
        ...rest,
        width,
        height,
        horizontal,
        top,
        bottom,
      };
    } else {
      return rest;
    }
  });
}
