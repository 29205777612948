import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Collapsible,
  TextContainer,
  Card,
  ChoiceList,
  Checkbox,
} from "@shopify/polaris";
import styled from "styled-components";
import { useAppContext } from "../../context";

const Container = styled.div`
  padding: 0 3.2rem;
  ${({isMobile}) => isMobile && 'padding: 0;'}
  .Polaris-Card {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-right: 1px;
  }
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
    .Polaris-Button {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top-width: 0;
    }
  }
  .collapsible-wrap {
    padding: 2rem;
    h3 {
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .Polaris-ChoiceList__Choices {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > * {
        width: 25%;
      }
    }
    .other-setting {
      padding-top: 1.6rem;
    }
  }
  @media (min-width: 1024px) {
    .collapsible-wrap {
      .Polaris-ChoiceList__Choices {
        > * {
          width: 20%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .collapsible-wrap {
      .Polaris-ChoiceList__Choices {
        > * {
          width: 50%;
        }
      }
    }
  }
`;

export const ScreenOptionsPolaris = (props) => {
  let {
    defaultValue,
    onChange,
    options,
    hasFixedColumn,
    onChangeFixedColumn,
    fixedColumn,
  } = props;
  // Context
  const { isMobile } = useAppContext();

  // State
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [checkedFixed, setCheckedFixed] = useState(!!fixedColumn);

  useEffect(() => setSelected(defaultValue), [defaultValue]);

  const handleSelectChange = useCallback((value) => {
    setSelected(value);
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = useCallback(() => setActive((prev) => !prev), []);

  const comparePriority = useCallback((a, b) => {
    const priorityA = a && a.priority ? a.priority : 0;
    const priorityB = b && b.priority ? b.priority : 0;
    let comparison = 0;
    if (priorityA > priorityB) {
      comparison = 1;
    } else if (priorityA < priorityB) {
      comparison = -1;
    }
    return comparison;
  }, []);

  let OPTIONS = options.length
    ? options
        .sort(comparePriority)
        .map((item) => ({ value: item.value, label: item.label }))
    : [];

  return (
    <Container isMobile={isMobile}>
      <Card>
        <Collapsible
          open={active}
          id="screenOptions_pa"
          transition={{ duration: "550ms", timingFunction: "ease" }}
        >
          <div className="collapsible-wrap">
            <h3>Columns visible</h3>
            <TextContainer>
              <ChoiceList
                choices={OPTIONS}
                selected={selected}
                onChange={handleSelectChange}
                allowMultiple
              />
            </TextContainer>
            {hasFixedColumn ? (
              <div className="other-setting">
                <h3>Other setting</h3>
                <Checkbox
                  label="Fixed actions column"
                  checked={checkedFixed}
                  onChange={(value) => {
                    setCheckedFixed(value);
                    if (onChangeFixedColumn) {
                      onChangeFixedColumn(value);
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        </Collapsible>
      </Card>
      <div className="btn-wrap">
        <Button
          ariaExpanded={active}
          ariaControls={"screenOptions_pa"}
          onClick={handleToggle}
          children="Screen options"
          disclosure={active ? "up" : "down"}
        />
      </div>
    </Container>
  );
};
