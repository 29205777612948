import { useMutation } from "@apollo/react-hooks";
import { Card } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef } from "react";
import { useAppContext } from "../../../context";
import { SWITCH_STORE_FOR_ORDERS } from "../../../graphql/mutations";
import { getParamByRole, handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";
import { Form } from "./components/Form";
import history from "../../../history";

export function SwitchStoreForOrder() {
    return (
        <Card sectioned>
            <SwitchOrderForOrderComp />
        </Card>
    );
}

export function SwitchOrderForOrderComp({ handleCompleted, ids }) {
    // Context
    const { currentUser } = useAppContext();
    const param = getParamByRole(currentUser);
    const { toggleToast, setNotify } = useToastContext();

    // Ref
    const timeoutRef = useRef(null);

    // Mutation
    const [switchStore, { loading }] = useMutation(SWITCH_STORE_FOR_ORDERS, {
        onCompleted: () => {
            setNotify({
                msg: "Switch store for order(s) were successfully.",
                err: false,
            });

            handleCompleted && handleCompleted();
            timeoutRef.current = window.setTimeout(() => {
                history.push(`/${param}/orders`);
            }, 1500);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Effect
    useEffect(
        () => () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        },
        []
    );

    // Actions
    const handleSubmit = useCallback(
        (input) => {
            if (input != null) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });

                switchStore({
                    variables: input,
                });
            }
        },
        [toggleToast, setNotify, switchStore]
    );

    return <Form loading={loading} onSubmit={handleSubmit} ids={ids} />;
}
