import React, { useState, useCallback, useEffect } from "react";
import { DropZone, Stack, Icon, Button, Spinner } from "@shopify/polaris";
import { DeleteMinor, TickMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import axios from "axios";
import styled from "styled-components";

import { DELETE_FILE_AMZ } from "../../graphql/mutations";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const Container = styled.div`
  .uploaded_wrap {
    display: block;
    margin-top: 2rem;
  }
  .image-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .image-title-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }
`;

export const REQUEST_MUTATION = gql`
  mutation createUploadUrl($input: RequestFileUpload!) {
    createUploadUrl(input: $input) {
      key
      url
    }
  }
`;

export const CREATE_FILE_MUTATION = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      id
      name
      key
      mimeType
      size
      url
      thumbnailUrl
      createdAt
    }
  }
`;

export const UploadTemplateFilePolaris = (props) => {
  // Props
  const { onChange, templateFile } = props;

  // State
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Mutation
  const [createUploadUrl, { client }] = useMutation(REQUEST_MUTATION, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [deleteFile] = useMutation(DELETE_FILE_AMZ, {
    onError: () => {},
    onCompleted: () => {},
  });

  // Handle actions
  const handleDrop = useCallback(
    (_droppedFiles, acceptedFiles) => {
      if (!files.length) {
        setFiles(() => [...acceptedFiles]);
        let file = acceptedFiles.length > 0 ? acceptedFiles[0] : null;
        if (file) {
          customRequest(file);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  const customRequest = async (file) => {
    setLoading(true);
    try {
      const res = await createUploadUrl({
        variables: {
          input: {
            name: file.name,
            mimeType: file.type,
            size: file.size,
          },
        },
      });
      const { key, url } = res.data.createUploadUrl;
      await axios.put(url, file, {
        headers: { "Content-Type": file.type },
        timeout: 1000 * 60 * 30,
      });
      const fileResponse = await client.mutate({
        mutation: CREATE_FILE_MUTATION,
        variables: {
          input: {
            name: file.name,
            key,
            size: file.size,
            mimeType: file.type,
          },
        },
      });
      let respon = [];
      if (fileResponse) {
        respon.push(fileResponse.data.createFile);
        setLoading(false);
      }
      setFileList(() => [...respon]);
    } catch {}
  };

  const handleDelete = useCallback(
    (index) => {
      let currentItem =
        fileList && fileList.length > 0
          ? fileList.find((_i, idx) => idx === index)
          : null;
      setFiles((prev) => prev.filter((_item, idx) => index !== idx));
      setFileList((prev) => prev.filter((_item, idx) => index !== idx));

      let id = currentItem?.id;
      if (id) {
        deleteFile({
          variables: {
            id: id,
            deleteAmz: true,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileList]
  );

  useEffect(() => {
    let firstFile = fileList && fileList.length > 0 ? fileList[0] : [];
    let fileId = firstFile?.id;
    if (onChange) {
      onChange(fileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  // Get data
  useEffect(() => {
    if (templateFile) {
      setFiles([templateFile]);
    }
  }, [templateFile]);

  // Markup
  const uploadedFiles =
    files.length > 0 ? (
      <Stack vertical>
        {files.filter(Boolean).map((file, index) => (
          <div className="image-wrap" key={`image-${index}`}>
            <div className="image-title-wrap">
              {loading ? <Spinner size="small" /> : <Icon source={TickMinor} />}
              <span>{file.name}</span>
            </div>
            <Button
              plain
              icon={DeleteMinor}
              onClick={() => handleDelete(index)}
              disabled={loading}
            />
          </div>
        ))}
      </Stack>
    ) : null;

  return (
    <Container>
      <ComponentLabelPolaris label="Upload template file" required />
      <DropZone onDrop={handleDrop} allowMultiple={false}>
        <DropZone.FileUpload />
      </DropZone>
      <div className="uploaded_wrap">{uploadedFiles}</div>
    </Container>
  );
};
