import { AddCategoryPolaris } from "../components/admin/category/AddCategoryPolaris";
import { EditCategoryPolaris } from "../components/admin/category/EditCategoryPolaris";
import { AddDepositPagePolaris } from "../components/billings/AddDepositPagePolaris";
import { DocumentationsPage } from "../components/documentations";
import { ExportAmazonAddPolaris } from "../components/export-amazon/ExportAmazonAddPolaris";
import { ExportAmazonEditPolaris } from "../components/export-amazon/ExportAmazonEditPolaris";
import { ExportAmazonViewConfig } from "../components/export-amazon/ExportAmazonViewConfig";
import { ViewProductsPolaris } from "../components/export-amazon/ViewProductsPolaris";
import { AddMemberPolaris } from "../components/members/AddMemberPolaris";
import { EditMemberPolaris } from "../components/members/EditMemberPolaris";
import { AddCollectionPolaris } from "../components/product/collections/AddCollectionPolaris";
import { EditCollectionPolaris } from "../components/product/collections/EditCollectionPolaris";
import { AddProductDivisionPolaris } from "../components/product/divisions/AddProductDivisionPolaris";
import { EditProductDivisionPolaris } from "../components/product/divisions/EditProductDivisionPolaris";
import { ProductDuplicate } from "../components/product/duplicate";
import { AddProductFeedPolaris } from "../components/product/feeds/AddProductFeedPolaris";
import { EditProductFeedPolaris } from "../components/product/feeds/EditProductFeedPolaris";
import { ProductFeedsPolaris } from "../components/product/feeds/ProductFeedsPolaris";
import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { AddForCampaign as AddTemplateForCampaign } from "../components/product/templates/AddForCampaign";
import { AddForProduct as AddTemplateForProduct } from "../components/product/templates/AddForProduct";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import { RecreateMockupAddPolaris } from "../components/recreate-mockup/RecreateMockupAddPolaris";
import { RecreateMockupEditPolaris } from "../components/recreate-mockup/RecreateMockupEditPolaris";
import EditTask from "../components/seller/EditTask";
import { AddStorePolaris } from "../components/store/AddStorePolaris";
import { EditStorePolaris } from "../components/store/EditStorePolaris";
import { CreateStore } from "../components/store/fb-marketplace/components/CreateStore";
import { Edit } from "../components/store/fb-marketplace/components/Edit";
import { GroupAddPolaris } from "../components/store/group/GroupAddPolaris";
import { GroupEditPolaris } from "../components/store/group/GroupEditPolaris";
import SwitchToAPIStorePage from "../components/store/SwitchToAPIStore";
import ImportTracking from "../components/supplier/ImportTracking";
import AddSwitchFulfillment from "../components/switch-fulfillment/AddSwitchFulfillment";
import { Reports } from "../components/tasks/report/Reports";
import { EditPushProductsPolaris } from "../components/tools/EditPushProductsPolaris";
import { NewPushProductsPolaris } from "../components/tools/NewPushProductsPolaris";
import MainLayout from "../layout/MainLayout";
import { CategoriesPagePolaris } from "../pages/admin/CategoriesPagePolaris";
import { SwitchFulfillmentPage } from "../pages/admin/SwitchFulfillmentPage";
import {
  AmazonChannelPage,
  EbayChannelPage,
  EtsyChannelPage,
  FacebookMKPage,
  OnlineStoresPage,
  OverviewsPage,
  TiktokChannelPage,
} from "../pages/reports";
import AddAmazonAccount from "../pages/seller/AddAmazonAccount";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddEtsyAccount from "../pages/seller/AddEtsyAccount";
import AddProductPage from "../pages/seller/AddProduct";
import SellerAddProductBasePage from "../pages/seller/AddProductBase";
import { AddProductBasePage } from "../pages/seller/AddProductBasePage";
import { AddProductCrawlerPagePolaris } from "../pages/seller/AddProductCrawlerPagePolaris";
import AddTiktokAccountPage from "../pages/seller/AddTiktokAccount";
import Amazon from "../pages/seller/Amazon";
import Apis from "../pages/seller/Apis";
import { AppsPagePolaris } from "../pages/seller/AppsPagePolaris";
import { SellerClaimsPagePolaris } from "../pages/seller/ClaimsPolaris";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import Collections from "../pages/seller/Collections";
import { ComplexAssortmentPage } from "../pages/seller/ComplexAssortment";
import { CreateManualOrderPagePolaris } from "../pages/seller/CreateManualOrderPagePolaris";
import { DepositsPagePolaris } from "../pages/seller/DepositsPagePolaris";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";
import DesignTeamsPage from "../pages/seller/DesignTeamPage";
import Ebay from "../pages/seller/Ebay";
import EditProductPage from "../pages/seller/EditProduct";
import { EditProductBasePage } from "../pages/seller/EditProductBasePage";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import EditSwitchFulfillment from "../pages/seller/EditSwitchFulfillment";
import Etsy from "../pages/seller/Etsy";
import { ExportAmazonPagePolaris } from "../pages/seller/ExportAmazonPagePolaris";
import { ExportTrackingPagePolaris } from "../pages/seller/ExportTrackingPagePolaris";
import { FBMarketplacePagePolaris } from "../pages/seller/FBMarketplacePagePolaris";
import { FulfillmentPagePolaris } from "../pages/seller/FulfillmentPagePolaris";
import { GroupPagePolaris } from "../pages/seller/GroupPagePolaris";
import { HistoryExportTrackingPagePolaris } from "../pages/seller/HistoryExportTrackingPagePolaris";
import { IdeaTaskPagePolaris } from "../pages/seller/IdeaTaskPagePolaris";
import { MappingOrderPagePolaris } from "../pages/seller/MappingOrdersPagePolaris";
import OnlineDesignTeamPage from "../pages/seller/OnlineDesignTeamPage";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import OrderStats from "../pages/seller/OrderStats";
import { PaymentAccounts } from "../pages/seller/PaymentAccount";
import PersonalizedPage from "../pages/seller/PersonalizedPage";
import { ProductAssortmentPagePolaris } from "../pages/seller/ProductAssortmentPagePolaris";
import SellerProductBasesPage from "../pages/seller/ProductBases";
import { ProductBasesPage } from "../pages/seller/ProductBasesPage";
import { ProductCrawlerPagePolaris } from "../pages/seller/ProductCrawlerPagePolaris";
import { ProductCrawlersPagePolaris } from "../pages/seller/ProductCrawlersPagePolaris";
import { ProductDivisionPagePolaris } from "../pages/seller/ProductDivisionsPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsIgnoredPagePolaris } from "../pages/seller/ProductsIgnoredPagePolaris";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";
import { ProductsPushPagePolaris } from "../pages/seller/ProductsPushPagePolaris";
import { ProductsRMPagePolaris } from "../pages/seller/ProductsRMPagePolaris";
import { RecreateMockupPagePolaris } from "../pages/seller/RecreateMockupPagePolaris";
import { ReplaceFeedPage } from "../pages/seller/ReplaceFeedPage";
import { ReportsPagePolaris } from "../pages/seller/ReportsPagePolaris";
import ReportTotalCostByFulfillment from "../pages/seller/ReportTotalCostByFulfillment";
import { SettingsPage } from "../pages/seller/Settings";
import { StatisticPagePolaris } from "../pages/seller/StatisticPagePolaris";
import Stores from "../pages/seller/Stores";
import SwitchFulfillment from "../pages/seller/SwitchFulfillments";
import { SwitchStoreForOrderPage } from "../pages/seller/SwitchStoreForOrderPage";
import Tags from "../pages/seller/Tags";
import { TeamMembersPagePolaris } from "../pages/seller/TeamMembersPagePolaris";
import TiktokPage from "../pages/seller/TiktokPage";
import { TransactionsPagePolaris } from "../pages/seller/TransactionsPagePolaris";
import Upcs from "../pages/seller/Upcs";
import AddTemplate from "../pages/supplier/AddTemplate";
import EditTemplatePage from "../pages/supplier/EditTemplate";
import ExportsPage from "../pages/supplier/Exports";
import ExportTemplatesPage from "../pages/supplier/ExportTemplate";
import ImportPage from "../pages/supplier/Import";

export default [
  {
    path: "/seller/stores",
    exact: true,
    component: Stores,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/ebay",
    exact: true,
    component: Ebay,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/amazon",
    exact: true,
    component: Amazon,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/amazon/add",
    exact: true,
    component: AddAmazonAccount,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/etsy",
    exact: true,
    component: Etsy,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/etsy/add",
    exact: true,
    component: AddEtsyAccount,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/tiktok",
    exact: true,
    component: TiktokPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/tiktok/add",
    exact: true,
    component: AddTiktokAccountPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/store/add",
    exact: true,
    // component: AddStore,
    component: AddStorePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/store/edit/:id",
    exact: true,
    // component: EditStore,
    component: EditStorePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/store/switch/:id",
    exact: true,
    component: SwitchToAPIStorePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/tools/product-crawlers",
    exact: true,
    // component: ProductCrawlersPage,
    component: ProductCrawlersPagePolaris,
    layout: MainLayout,
    title: "All Crawlers",
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/tools/product-crawler",
    exact: true,
    // component: ProductCrawler,
    component: AddProductCrawlerPagePolaris,
    layout: MainLayout,
    title: "Product crawler",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/tools/product-crawlers/:id",
    exact: true,
    // component: ProductCrawlerByIdPage,
    component: ProductCrawlerPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/products/product-push",
    exact: true,
    // component: ProductsPush,
    component: ProductsPushPagePolaris,
    title: "Product Push",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/product-push/add",
    exact: true,
    // component: AddProductPush,
    component: NewPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/product-push/:id",
    exact: true,
    // component: EditProductPush,
    component: EditPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/add",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/add/:id",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/clone",
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/feeds",
    exact: true,
    // component: ProductFeeds,
    component: ProductFeedsPolaris,
    layout: MainLayout,
    title: "Product Feeds",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/feeds/edit/:id",
    exact: true,
    // component: EditProductFeed,
    component: EditProductFeedPolaris,
    layout: MainLayout,
    title: "Edit Product Feed",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/feeds/add",
    exact: true,
    // component: AddProductFeed,
    component: AddProductFeedPolaris,
    layout: MainLayout,
    title: "Add Product Feed",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/tags",
    exact: true,
    component: Tags,
    layout: MainLayout,
    title: "Tags",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/templates/for-product",
    exact: true,
    component: AddTemplateForProduct,
    layout: MainLayout,
    title: "Add Template for Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/templates/for-campaign",
    exact: true,
    component: AddTemplateForCampaign,
    layout: MainLayout,
    title: "Add Template for Campaign",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/collections",
    exact: true,
    component: Collections,
    layout: MainLayout,
    title: "Collections",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/add/collection",
    exact: true,
    // component: AddCollections,
    component: AddCollectionPolaris,
    layout: MainLayout,
    title: "Add Collection",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/edit/collection/:id",
    exact: true,
    // component: EditCollection,
    component: EditCollectionPolaris,
    layout: MainLayout,
    title: "Edit Collection",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/tools/product-assortment",
    exact: true,
    // component: ProductAssortment,
    component: ProductAssortmentPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/tools/complex-assortment",
    exact: true,
    // component: ProductAssortment,
    component: ComplexAssortmentPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  // {
  //   path: "/seller/suppliers",
  //   exact: true,
  //   // component: SellerSuppliers,
  //   component: SuppliersPagePolaris,
  //   layout: MainLayout,
  //   roles: ["Seller"],
  // },
  // {
  //   path: "/seller/supplier/add",
  //   exact: true,
  //   // component: AddSellerSupplier,
  //   component: AddSupplierPolaris,
  //   layout: MainLayout,
  //   roles: ["Seller"],
  // },
  // {
  //   path: "/seller/supplier/edit/:id",
  //   exact: true,
  //   // component: EditSellerSupplier,
  //   component: EditSupplierPolaris,
  //   layout: MainLayout,
  //   roles: ["Seller"],
  // },
  {
    path: "/seller/team-members",
    exact: true,
    // component: TeamMembers,
    component: TeamMembersPagePolaris,
    layout: MainLayout,
    title: "Members",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/add-team-member",
    exact: true,
    // component: AddTeamMember,
    component: AddMemberPolaris,
    layout: MainLayout,
    title: "Add New Member",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/edit-team-member/:id",
    exact: true,
    // component: EditTeamMember,
    component: EditMemberPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/online-design-teams",
    exact: true,
    component: OnlineDesignTeamPage,
    layout: MainLayout,
    title: "Design Online Team",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/design-teams",
    exact: true,
    component: DesignTeamsPage,
    layout: MainLayout,
    title: "Design Team",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/edit-task/:id",
    exact: true,
    component: EditTask,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products",
    exact: true,
    // component: SellerProductsPage,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/edit/:id",
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/orders",
    exact: true,
    // component: SellerOrders,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/mapping",
    exact: true,
    // component: MappingOrders,
    component: MappingOrderPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/claims",
    exact: true,
    // component: SellerClaimsPage,
    component: SellerClaimsPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  // {
  //   path: "/seller/product-bases",
  //   exact: true,
  //   component: SellerProductBasesPage,
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Admin",
  // },
  // {
  //   path: "/seller/product-bases/add",
  //   exact: true,
  //   component: SellerAddProductBasePage,
  //   title: "Add Product Base",
  //   layout: MainLayout,
  //   roles: ["Seller"],
  //   teamRole: "Admin",
  // },
  {
    path: "/seller/billings/deposits",
    exact: true,
    // component: SellerDepositsPage,
    component: DepositsPagePolaris,
    title: "All Deposits",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/billings/deposits/add",
    exact: true,
    // component: AddDeposit,
    component: AddDepositPagePolaris,
    title: "Add Deposits",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/billings/transactions",
    exact: true,
    // component: SellerTransactionsPage,
    component: TransactionsPagePolaris,
    title: "Transactions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/fulfillment",
    exact: true,
    // component: Apps,
    component: FulfillmentPagePolaris,
    title: "All Fulfillment",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/fulfillment/switch",
    exact: true,
    component: SwitchFulfillment,
    title: "Switch Fulfillment",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/fulfillment/switch-order",
    exact: true,
    component: SwitchFulfillmentPage,
    title: "Switch Fulfillment Order",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/fulfillment/switch/add",
    exact: true,
    component: AddSwitchFulfillment,
    title: "Add Switch Fulfillment",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/fulfillment/switch/edit/:id",
    exact: true,
    component: EditSwitchFulfillment,
    title: "Switch Fulfillment",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/design-tasks",
    exact: true,
    // component: DesignTasksPage,
    component: DesignTasksPagePolaris,
    title: "All Tasks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/design-tasks/ideas",
    exact: true,
    component: IdeaTaskPagePolaris,
    title: "Idea Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/upcs",
    exact: true,
    component: Upcs,
    title: "UPCs",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/settings/apis",
    exact: true,
    component: Apis,
    title: "API Keys",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/settings",
    exact: true,
    component: SettingsPage,
    title: "Settings",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/settings/payment-accounts",
    exact: true,
    component: PaymentAccounts,
    title: "Payment Accounts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/manager-files/export-templates",
    exact: true,
    component: ExportTemplatesPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/order-stats",
    exact: true,
    component: OrderStats,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/manager-files/add-template",
    exact: true,
    component: AddTemplate,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/manager-files/edit-template/:id",
    exact: true,
    component: EditTemplatePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/imports",
    exact: true,
    component: ImportPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-files",
    exact: true,
    component: ExportsPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-divisions",
    exact: true,
    component: ProductDivisionPagePolaris,
    title: "Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-divisions/add",
    exact: true,
    component: AddProductDivisionPolaris,
    title: "Add Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-divisions/edit/:id",
    exact: true,
    component: EditProductDivisionPolaris,
    title: "Add Product Divisions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products-ignored",
    exact: true,
    component: ProductsIgnoredPagePolaris,
    title: "Product Ignored",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/export-amazon",
    exact: true,
    component: ExportAmazonPagePolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-amazon/add",
    exact: true,
    component: ExportAmazonAddPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-amazon/:id",
    exact: true,
    component: ExportAmazonEditPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports",
    exact: true,
    component: ReportsPagePolaris,
    title: "Reports",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/report-total-cost",
    exact: true,
    component: ReportTotalCostByFulfillment,
    title: "Report Total Cost",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-amazon/view-products/:id",
    exact: true,
    component: ViewProductsPolaris,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-amazon/view-configs/:id",
    exact: true,
    component: ExportAmazonViewConfig,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/re-mockups",
    exact: true,
    component: RecreateMockupPagePolaris,
    title: "Recreate mockups",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/re-mockups/add",
    exact: true,
    component: RecreateMockupAddPolaris,
    title: "Recreate mockups additions",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/re-mockups/edit/:id",
    exact: true,
    component: RecreateMockupEditPolaris,
    title: "Edit recreate mockup",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/re-mockups-tasks",
    exact: true,
    component: ProductsRMPagePolaris,
    title: "Product recreate mockup",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/stores/groups",
    exact: true,
    component: GroupPagePolaris,
    title: "Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/groups/add",
    exact: true,
    component: GroupAddPolaris,
    title: "Add Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/groups/edit/:id",
    exact: true,
    component: GroupEditPolaris,
    title: "Edit Group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-tracking",
    exact: true,
    component: ExportTrackingPagePolaris,
    title: "Export tracking",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-tracking-history",
    exact: true,
    component: HistoryExportTrackingPagePolaris,
    title: "History export tracking",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/export-tracking-history/:refetch",
    exact: true,
    component: HistoryExportTrackingPagePolaris,
    title: "History export tracking",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/design-tasks/report",
    exact: true,
    component: Reports,
    title: "Reports task tracking",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/design-tasks/statistic",
    exact: true,
    component: StatisticPagePolaris,
    title: "Statistic page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/apps",
    exact: true,
    component: AppsPagePolaris,
    title: "Apps page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/stores/fb-marketplace",
    exact: true,
    component: FBMarketplacePagePolaris,
    title: "Facebook marketplace",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/stores/fb-marketplace/add",
    exact: true,
    component: CreateStore,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/stores/fb-marketplace/edit/:id",
    exact: true,
    component: Edit,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/create-manual-order",
    exact: true,
    component: CreateManualOrderPagePolaris,
    title: "Create Manual Order",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-duplicate",
    exact: true,
    component: ProductDuplicate,
    title: "Product By Tittle Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/reports/overviews",
    exact: true,
    component: OverviewsPage,
    title: "Reports",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports/etsy",
    exact: true,
    component: EtsyChannelPage,
    title: "Etsy Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports/ebay",
    exact: true,
    component: EbayChannelPage,
    title: "Ebay Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports/amazon",
    exact: true,
    component: AmazonChannelPage,
    title: "Amazon Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports/tiktok",
    exact: true,
    component: TiktokChannelPage,
    title: "Tiktok Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/reports/facebook-mk",
    exact: true,
    component: FacebookMKPage,
    title: "Facebook Marketplace Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/reports/online-stores",
    exact: true,
    component: OnlineStoresPage,
    title: "Online stores Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/switch-store-for-order",
    exact: true,
    title: "Switch Store For Order Page",
    component: SwitchStoreForOrderPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
    private: true,
  },
  {
    path: "/seller/product-bases",
    exact: true,
    title: "Product Bases",
    component: ProductBasesPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-bases/add",
    exact: true,
    title: "Add product base",
    component: AddProductBasePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/product-bases/edit/:id",
    exact: true,
    component: EditProductBasePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/categories",
    exact: true,
    component: CategoriesPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/add-category",
    exact: true,
    component: AddCategoryPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/edit-category/:id",
    exact: true,
    component: EditCategoryPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/import-tracking",
    exact: true,
    component: ImportTracking,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
  {
    path: "/seller/products/replace-feed",
    exact: true,
    component: ReplaceFeedPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Admin",
  },
];
